import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-multi-factor-authentication-code',
  templateUrl: './multi-factor-authentication-code.component.html',
  styleUrls: ['./multi-factor-authentication-code.component.scss']
})
export class MultiFactorAuthenticationCodeComponent {
  @Input() destination: string;
  @Input() sub: (code: string) => void;
}
