import {HttpParams} from '@angular/common/http';

export function createHttpParams(params: {}): HttpParams {
  let httpParams: HttpParams = new HttpParams();
  Object.keys(params).forEach(param => {
    // console.log('Reading' +  param);
    if (params[param] !== undefined && params[param] !== null) {
      // console.log('Setting ' +  param + ' ' + params[param]);
      httpParams = httpParams.set(param, params[param]);
    }
  });

  return httpParams;
}
