import {Injectable} from '@angular/core';
import {BackendService} from '../backend/backend.service';
import {HttpClient} from '@angular/common/http';
import {UsageItemResponse, UsageResponse} from '../response/response.model';
import {ToastrService} from 'ngx-toastr';
import {LocaleResolverService} from '../locale/locale-resolver.service';

@Injectable()
export class UsageService extends BackendService {

    constructor(
        protected http: HttpClient,
        protected localeResolverService: LocaleResolverService,
    ) {
        super(http, localeResolverService);
    }

    getUsageItem(personIdParam: string, usageType: string) {
        const httpOptions = this.createHttpOptions();
        return this.http.get<UsageItemResponse>(this.getApiUrl() + '/people/' + personIdParam + '/usage/' + usageType, httpOptions);
    }

    getDocumentsUsage(personIdParam: string) {
        const httpOptions = this.createHttpOptions();
        return this.http.get<UsageResponse>(this.getApiUrl() + '/people/' + personIdParam + '/usage/documents', httpOptions);
    }

    getUserUsage(personIdParam: string) {
        const httpOptions = this.createHttpOptions();
        return this.http.get<UsageResponse>(this.getApiUrl() + '/people/' + personIdParam + '/usage', httpOptions);
    }


}
