import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BackendService} from '../backend/backend.service';
import {Person} from '../person/person.model';
import {LocaleResolverService} from '../locale/locale-resolver.service';
import {Medic} from '../medic/medic.model';
import {
    BulkDocumentPresignedpostResponse,
    PatientOnboardingStageResponse,
    PersonResponse, ProfileResponse,
    SubscriptionAgreementResponse
} from '../response/response.model';
import {Subscription} from '../subscription/subscription.model';
import {Profile} from '../profile/profile.model';

@Injectable()
export class OnboardingService extends BackendService {
    constructor(
      protected http: HttpClient,
      protected localeResolverService: LocaleResolverService,
    ) {
        super(http, localeResolverService);
    }

    finishOnboardingStage() {
        const httpOptions = this.createHttpOptions();
        return this.http.post(this.getApiUrl() + '/person/onboarding/finish', httpOptions);
    }

    getOnboardingHealth() {
        const httpOptions = this.createHttpOptions();
        return this.http.get<ProfileResponse>(this.getApiUrl() + '/person/onboarding/health', httpOptions);
    }

    getPatientOnboardingStage() {
        const httpOptions = this.createHttpOptions();
        return this.http.get<PatientOnboardingStageResponse>(this.getApiUrl() + '/person/onboarding/stage', httpOptions);
    }

    postOnboardingProfile(person: Person) {
        const httpOptions = this.createHttpOptions();
        return this.http.post(this.getApiUrl() + '/person/onboarding/profile', person, httpOptions);
    }

    postOnboardingProfilePhoto(formData: FormData) {
        return this.http.put<BulkDocumentPresignedpostResponse>(
          this.getApiUrl() + '/person/onboarding/profilePhoto', formData, {withCredentials: true}
          );
    }

    postOnboardingHealth(profile: Profile) {
        const httpOptions = this.createHttpOptions();
        return this.http.post(this.getApiUrl() + '/person/onboarding/health', profile, httpOptions);
    }

    postDoctorProfile(med: Medic) {
        const httpOptions = this.createHttpOptions();
        return this.http.post(this.getApiUrl() + '/doctor/onboarding/profile', med, httpOptions);
    }

    postMedicInstProfile(med: Medic) {
        const httpOptions = this.createHttpOptions();
        return this.http.post(this.getApiUrl() + '/medic-inst/onboarding/profile', med, httpOptions);
    }

    startSubscriptionTrial() {
        const httpOptions = this.createHttpOptions();
        return this.http.post(this.getApiUrl() + '/person/onboarding/subscription/trial', httpOptions);
    }

    startSubscriptionWithPaymentStripe(subscription: Subscription) {
        const httpOptions = this.createHttpOptions();
        return this.http.put<SubscriptionAgreementResponse>(
          this.getApiUrl() + '/person/onboarding/subscription', subscription, httpOptions
        );
    }
}
