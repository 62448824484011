import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {BloodPressure} from './blood-pressure.model';
import {LocaleResolverService} from '../locale/locale-resolver.service';
import {BackendService} from '../backend/backend.service';
import {BloodPressureListResponse} from '../response/response.model';

@Injectable()
export class BloodPressureService extends BackendService {

  constructor(
      protected http: HttpClient,
      protected localeResolverService: LocaleResolverService,
  ) {
    super(http, localeResolverService);
  }

  create(bloodPressure: BloodPressure) {
    const httpOptions = this.createHttpOptions();
    return this.http.post(this.getApiUrl() + '/measurements/bloodPressures', bloodPressure, httpOptions);
  }

  listBloodPressures(id: string, page: number, pageSize: number, lastId: number, start: string, end: string, sort = 'asc') {
    const httpHeaders = this.buildBaseHttpHeaders();

    let params = new HttpParams();
    if (page !== undefined && page !== null) {
      params = params.append('page', page.toString());
    }
    if (pageSize !== undefined && pageSize !== null) {
      params = params.append('pageSize', pageSize.toString());
    }
    if (lastId !== undefined && lastId !== null) {
      params = params.append('lastId', lastId.toString());
    }
    if (start !== undefined && start !== null) {
      params = params.append('start', encodeURIComponent(start.toString()));
    }
    if (end !== undefined && end !== null) {
      params = params.append('end', encodeURIComponent(end.toString()));
    }
    if (sort !== undefined && sort !== null) {
      params = params.append('sort', encodeURIComponent(sort.toString()));
    }

    return this.http.get<BloodPressureListResponse>(
        this.getApiUrl() + '/people/' + id + '/measurements/bloodPressures',
        (params.keys().length > 0) ? { headers: httpHeaders, params: params } : { headers: httpHeaders });
  }

  loadLatestBloodPressure(personIdParam: string) {
    return this.listBloodPressures(personIdParam, 1, 1, null, null, null, 'desc');
  }

  delete(id: number) {
    const httpOptions = this.createHttpOptions();
    return this.http.delete(this.getApiUrl() + '/measurements/bloodPressures/' + id, httpOptions);
  }
}
