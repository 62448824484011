import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Country} from '../../core/location/country.model';
import {CityService} from '../../core/location/city.service';
import {catchError, debounceTime, distinctUntilChanged, map, switchMap, tap} from 'rxjs/operators';
import {CountryListResponse} from '../../core/response/response.model';
import {Location} from '../../core/location/location.model';
import {concat, Observable, of, Subject} from 'rxjs';
import {CountryService} from '../../core/location/country.service';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit {
  @Input() isRequired: boolean;
  @Input() location: Location;
  @Output() locationSelect = new EventEmitter<Location>();
  @Output() locationValid = new EventEmitter<boolean>();
  countries$: Observable<Country[]>;
  countriesInput$ = new Subject<string>();
  countriesLoading = false;
  isValid: boolean;
  locationFormGroup: FormGroup;
  private debounce = 500;

  constructor(
    private cityService: CityService,
    private countryService: CountryService,
    private fb: FormBuilder
  ) {
  }

  ngOnInit() {
    if (!this.location) {
      this.location = new Location();
    }

    if (this.location.country.code === '' && this.location.country.name === '') {
      this.location.country = undefined;
    }

    this.locationFormGroup = this.fb.group({
      city: this.isRequired ? ['', Validators.required] : [''],
      country: this.isRequired ? ['', Validators.required] : ['']
    });

    this.onLocationChanges();
    this.loadCountries();
    // this.loadCities();

    this.locationFormGroup.patchValue(this.location);
  }

  isEqualCountry(countryA: Country, countryB: Country): boolean {
    return countryA && countryB && countryA.code === countryB.code;
  }

  private onLocationChanges() {
    this.locationFormGroup.valueChanges.subscribe(values => {

      if (this.locationFormGroup.valid && values.country) {
        this.location.city = values.city;
        this.location.country = {
          code: values.country.code,
          name: values.country.name ? values.country.name : values.country.text,
          text: values.country.text,
        };
        // this.location.cityRef = { id: this.locationFormGroup.get('city').value.id, name: ''};
        this.locationSelect.emit(this.location);
        this.locationValid.emit(true);
      } else {
        console.log('Location is NOT valid');
        this.locationValid.emit(false);
      }
    });
  }

  private loadCountries() {
    this.countries$ = concat(
      of([]), // default items
      this.countriesInput$.pipe(
        debounceTime(this.debounce),
        distinctUntilChanged(),
        tap(() => this.countriesLoading = true),
        switchMap(term => this.countryService
          .listCountries(term as string)
          .pipe(
            catchError(() => of([])),
            map((res: CountryListResponse) => {
              return res.results;
            }),
            tap(() => this.countriesLoading = false)
          ))
      )
    );
  }

  // private loadCities() {
  //   this.cities$ = concat(
  //     of([]), // default items
  //     this.citiesInput$.pipe(
  //       debounceTime(this.debounce),
  //       distinctUntilChanged(),
  //       tap(() => this.citiesLoading = true),
  //       switchMap(term => this.cityService
  //         .listCities(term, (this.locationFormGroup.get('country').value ? this.locationFormGroup.get('country').value.code : ''))
  //         .pipe(
  //           catchError(() => of([])),
  //           map((res: CitiesResponse) => {
  //             return res.result;
  //           }),
  //           tap(() => this.citiesLoading = false)
  //         ))
  //     )
  //   );
  // }

  click(event) {
    this.countriesInput$.next('');

    this.locationFormGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});
  }

  checkValidity() {
    this.isValid = true;
    this.locationFormGroup.get('city').markAsTouched({ onlySelf: true });
    this.locationFormGroup.get('country').markAsTouched({ onlySelf: true });
  }
}
