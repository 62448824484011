import {Injectable} from '@angular/core';
import {BackendService} from '../backend/backend.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {GeneralResponse, MedicationCodeListResponse, MedicationListResponse, MedicationResponse} from '../response/response.model';
import {Medication} from './medication.model';
import {removeInsignificantTrailingZeros} from '../../../lib/formats';
import {LocaleResolverService} from '../locale/locale-resolver.service';
import {QueryFilter} from '../filters/query-filter.model';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class MedicationService extends BackendService {

    constructor(
        protected http: HttpClient,
        protected localeResolverService: LocaleResolverService,
        private translateService: TranslateService
    ) {
        super(http, localeResolverService);
    }

    createMedication(id, body) {
        const httpOptions = this.createHttpOptions();
        return this.http.post<GeneralResponse>(this.getApiUrl() + '/people/' + id + '/medications', body, httpOptions);
    }

    updateMedication(id: number, medication: Medication) {
        const httpOptions = this.createHttpOptions();
        return this.http.put(this.getApiUrl() + '/medications/' + medication.id, medication, httpOptions);
    }

    getMedication(personIdParam: string, id: number) {
        const httpOptions = this.createHttpOptions();
        return this.http.get<MedicationResponse>(this.getApiUrl() + '/people/' + personIdParam + '/medications/' + id, httpOptions);
    }

    listMedications(
      id: string, q: string, base: boolean, page: number, pageSize: number, lastId: number, queryFilters: QueryFilter[] = []
    ) {
        const httpHeaders = this.buildBaseHttpHeaders();

        let params = new HttpParams();
        if (page !== undefined && page !== null) {
            params = params.append('page', page.toString());
        }
        if (pageSize !== undefined && pageSize !== null) {
            params = params.append('pageSize', pageSize.toString());
        }
        if (lastId !== undefined && lastId !== null) {
            params = params.append('lastId', lastId.toString());
        }
        if (base !== undefined && base !== null) {
            params = params.append('base', base.toString());
        }
        if (q !== undefined && q !== null) {
            params = params.append('q', q.toString());
        }
        for (const queryFilter of queryFilters) {
            params = params.append(queryFilter.name, String(queryFilter.value));
        }

        return this.http.get<MedicationListResponse>(this.getApiUrl() + '/people/' + id + '/medications', (params.keys().length > 0) ? {
            headers: httpHeaders,
            params: params
        } : {headers: httpHeaders});
    }

    listMedicationsCodes(query: string) {
        const httpHeaders = this.createHttpHeaders();
        const options: any = {
            headers: httpHeaders,
        };

        let params;
        if (query !== undefined && query !== null) {
            params = new HttpParams();
            params = params.append('q', query.toString());
            options.params = params;
        }
        return this.http.get<MedicationCodeListResponse>(this.getApiUrl() + '/medications-codes', (!params) ? {headers: httpHeaders} : {
            headers: httpHeaders,
            params: params
        });
    }

    deleteMedication(id: number) {
        const httpOptions = this.createHttpOptions();
        return this.http.delete(this.getApiUrl() + '/medications/' + id, httpOptions);
    }

    applicationSummary(medication: Medication): string {
        return ''.concat(
          [
              removeInsignificantTrailingZeros(medication.morning),
              removeInsignificantTrailingZeros(medication.lunch),
              removeInsignificantTrailingZeros(medication.evening),
              removeInsignificantTrailingZeros(medication.night)
          ].join(' - '), '    ',
          medication.when ? this.translateService.instant('codes.medication.when.' + medication.when) : ''
        );
    }
}
