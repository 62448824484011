import {Injectable} from '@angular/core';
import {BackendService} from '../backend/backend.service';
import {HttpClient} from '@angular/common/http';
import {CountryListResponse} from '../response/response.model';
import {LocaleResolverService} from '../locale/locale-resolver.service';

@Injectable()
export class CountryService extends BackendService {

  constructor(
    protected http: HttpClient,

    protected localeResolverService: LocaleResolverService
  ) {
    super(http, localeResolverService);
  }

  listCountries(q: string) {
    const httpHeaders = this.buildBaseHttpHeaders();
    let params = this.buildBaseHttpParams();
    params = params.append('q', q);
    return this.http.get<CountryListResponse>(this.getApiUrl() + '/countries', { headers: httpHeaders, params: params });
  }
}
