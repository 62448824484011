  import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
  import {CalendarView} from 'angular-calendar';
  import {ChartDataSets, ChartOptions, ChartType, Chart} from 'chart.js';
  import * as chartConfig from '../../../../shared/charts/chart.config';
  import {ActivatedRoute} from '@angular/router';
  import {LocaleResolverService} from '../../../../core/locale/locale-resolver.service';
  import {TranslateService} from '@ngx-translate/core';
  import {take} from 'rxjs/operators';
  import {startEndDates} from '../../../../../lib/dates';
  import * as moment from 'moment-timezone';
  import {BodyTemperatureService} from '../../../../core/measurements/body-temperature.service';
  import * as ChartAnnotation from 'chartjs-plugin-annotation';

  @Component({
    selector: 'app-body-temperature-chart',
    templateUrl: './body-temperature-chart.component.html',
    styleUrls: ['./body-temperature-chart.component.scss']
  })
  export class BodyTemperatureChartComponent implements OnInit {
    @Input() feverLine = 37;
    @Input() maxBodyTemperature = 45;
    @Input() minBodyTemperature = 30;
    chartColors = chartConfig.chartColors;
    chartData: ChartDataSets[] = [{ data: [] }];
    chartOptions: ChartOptions;
    chartType: ChartType = 'line';
    end: any;
    locale: string;
    personIdParam: string;
    start: any;
    stats: any;
    translatedLabel = '-';
    values: any[];
    view: string = CalendarView.Month;
    viewDate: Date = new Date();

    constructor(
      private bodyTemperatureService: BodyTemperatureService,
      private route: ActivatedRoute,
      private ref: ChangeDetectorRef,
      private localeResolver: LocaleResolverService,
      private translateService: TranslateService
    ) {
    }

    ngOnInit() {
      this.locale = this.localeResolver.obtainLocale();
      this.localeResolver.localeSubject.subscribe(v => {
        if (v) {
          this.locale = v;
        }
      });

      this.translatedLabel = this.translateService.instant('journal.bodyTemperature.title');
      this.ref.markForCheck();

      const routeParams$ = this.route.params.pipe(take(1));
      routeParams$.subscribe(r => {
        this.personIdParam = r['id'];
        this.fetchValues();
      });

      const namedChartAnnotation = ChartAnnotation;
      namedChartAnnotation['id'] = 'annotation';
      Chart.pluginService.register(namedChartAnnotation);
  }

  fetchValues() {
    const dates = startEndDates('month', this.viewDate);
    this.start = dates.start;
    this.end = dates.end;

    this.setChartOption(this.start, this.end, this);

    this.bodyTemperatureService.listBodyTemperatures(this.personIdParam, null, null, null, this.start, this.end, 'asc')
        .subscribe(r => {
          const values = r.results;

          this.values = values.map(e => {
            return { x: e.date, y: e.value };
          });

          this.populateChartData(this.values);
          this.ref.markForCheck();
        });
  }

  private populateChartData(val) {
    this.chartData = [
      {
        data: val,
        label: this.translatedLabel,
        pointRadius: 7,
      }
    ];
  }

  private setChartOption(min, max, ref) {
    this.chartOptions = {
      animation: {
        duration: 1000,
        easing: 'easeOutBack'
      },
      hover: {
        animationDuration: 1000,
      },
      responsiveAnimationDuration: 1000,
      responsive: true,

      scales: {
        xAxes: [{
          type: 'time',
          distribution: 'series',
          position: 'bottom',
          time: {
            unit: 'week',
            tooltipFormat: 'LLL',
            displayFormats: {
              day: 'dd'
            },
            min: min,
            max: max
          },
          scaleLabel: {
            display: true,
          }
        }],
        yAxes: [{
          ticks: {
            max: this.maxBodyTemperature,
            min: this.minBodyTemperature
          }
        }]
      },

      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            return tooltipItem.yLabel + ' °C';
          }
        }
      },
    };

    // prevent typescript unknown annotation in chartOptions
    const x = 'annotation';
    this.chartOptions[x] = {
      annotations: [
        {
          type: 'line',
          mode: 'horizontal',
          scaleID: 'y-axis-0',
          value: this.feverLine,
          borderColor: 'rgba(255, 50, 50, 0.5)',
          borderWidth: 2,
          // drawTime: 'beforeDatasetsDraw',
          label: {
            enabled: false,
            content: this.feverLine
          }
        }]
    };
  }

  addDate(start, addDays) {
    return moment(start).add(addDays, 'days').format('LL');
  }

}
