import {Profile} from '../../core/profile/profile.model';
import {Disease} from '../../core/health/disease.model';
import {Medication} from '../../core/health/medication.model';
import {Allergy} from '../../core/health/allergy.model';
import {Operation} from '../../core/health/operation.model';
import {FamilyDisease} from '../../core/health/family-disease.model';
import {Immunisation} from '../../core/health/immunisation.model';
import {DocumentHolder} from '../documents/document.model';
import {Person} from '../../core/person/person.model';
import {BloodGlucose} from '../../core/measurements/blood-glucose.model';
import {Weight} from '../../core/measurements/weight.model';
import {Stress} from '../../core/measurements/stress.model';
import {Spo2} from '../../core/measurements/spo2.model';
import {Sleep} from '../../core/measurements/sleep.model';
import {Pain} from '../../core/measurements/pain.model';
import {HeartRate} from '../../core/measurements/heart-rate.model';
import {Feeling} from '../../core/measurements/feeling.model';
import {BodyTemperature} from '../../core/measurements/body-temperature.model';
import {BloodPressure} from '../../core/measurements/blood-pressure.model';
import {EmergencyPassConfig} from './emergency-card-config.model';

export class EmergencyPass {
  person: Person;
  profile: Profile;
  url: string;

  allergies: Allergy[];
  diseases: Disease[];
  documents: DocumentHolder[];
  familyDiseases: FamilyDisease[];
  immunisations: Immunisation[];
  medications: Medication[];
  operations: Operation[];

  journal: {
    latestBloodGlucose: BloodGlucose;
    latestBloodPressure: BloodPressure;
    latestBodyTemperature: BodyTemperature;
    latestFeeling: Feeling;
    latestHeartRate: HeartRate;
    latestPain: Pain;
    latestSleep: Sleep;
    latestSpo2: Spo2;
    latestStress: Stress;
    latestWeight: Weight;
  };

  config: EmergencyPassConfig;
}
