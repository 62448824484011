import {Injectable, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import * as moment from 'moment';

@Injectable()
export class LocaleResolverService implements OnInit {
    static DEFAULT_LOCALE = 'de-AT';

    langSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    locale: string = LocaleResolverService.DEFAULT_LOCALE;
    localeSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    ngOnInit(): void {
        this.notifyLocaleValue();
    }

    private notifyLocaleValue() {
        const locale = this.obtainLocale();
        this.localeSubject.next(locale);
        this.langSubject.next(locale.split('-')[0]);
    }

    obtainLocale(): string {
        const currentLocale = localStorage.getItem('locale');

        if (!currentLocale) {
            this.setLocale(LocaleResolverService.DEFAULT_LOCALE);
            return LocaleResolverService.DEFAULT_LOCALE;
        }
        return currentLocale;
    }

    getLanguage() {
        const locale = this.obtainLocale();
        return locale.split('-')[0];
    }

    getDateFormat() {
        return moment.localeData() ? moment.localeData().longDateFormat('L') : '';
    }

    setLocale(locale: string) {
        localStorage.setItem('locale', locale);
        this.notifyLocaleValue();
    }
}
