import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ImmunisationService} from '../../../../core/health/immunisation.service';
import {DocumentHolder} from '../../../documents/document.model';
import {Immunisation} from '../../../../core/health/immunisation.model';
import {NgbActiveModal, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {fromNgbDateStructToDate, toNgbDateStruct} from '../../../../../lib/dates';
import {LocaleResolverService} from '../../../../core/locale/locale-resolver.service';
import {BaseComponent} from '../../../../../base/base-component';
import * as moment from 'moment-timezone';
import {CustomValidators} from 'ngx-custom-validators';
import {NgbDateDynamicParserFormatter} from '../../../../../lib/ngb-date-dynamic-parser-formatter';
import {ProfileService} from '../../../../core/profile/profile.service';
import {DocumentTypeEnum} from '../../../documents/document-type.enum';
import {ImmunisationTakenEnum} from '../../../../shared/enums/immunisation-taken.enum';
import {TranslatedToastrService} from '../../../../core/translated-toastr/translated-toastr.service';

@Component({
  selector: 'app-immunisation-edit',
  templateUrl: './immunisation-edit.component.html',
  styleUrls: ['./immunisation-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateDynamicParserFormatter }
  ]
})
export class ImmunisationEditComponent extends BaseComponent implements OnInit {
  @Input() immunisation: Immunisation;
  @Input() personIdParam: string;
  @Input() readOnly = false;
  @Output() saved = new EventEmitter<boolean>();
  date: NgbDateStruct;
  documents: DocumentHolder[] = [];
  DocumentTypeEnum = DocumentTypeEnum;
  immunisationFormGroup: FormGroup;
  isSubmitted = false;
  isValid = true;
  takenTypes = [
    {label: 'codes.immunisation.taken.' + ImmunisationTakenEnum.SINGLE_VACCINATION, value:  ImmunisationTakenEnum.SINGLE_VACCINATION},
    {label: 'codes.immunisation.taken.' + ImmunisationTakenEnum.PART_VACCINATON, value:  ImmunisationTakenEnum.PART_VACCINATON},
  ];

  constructor(
    private activeModal: NgbActiveModal,
    private immunisationService: ImmunisationService,
    private translatedToastrService: TranslatedToastrService,
    private fb: FormBuilder,
    protected profileService: ProfileService,
    protected localeResolverService: LocaleResolverService,
    protected ref: ChangeDetectorRef,
  ) {
    super(profileService, localeResolverService, ref);
  }

  ngOnInit() {
    super.ngOnInit();

    // init form group
    this.immunisationFormGroup = this.fb.group({
      id: [null],
      batchNumber: [null],
      title: [null, Validators.required],
      vaccine: [null],
      date: ['', Validators.compose([Validators.required, CustomValidators.date])],
      taken: [this.immunisation ? this.immunisation.taken : 1],
      doctor: [null],
      visible: [null],
      favourite: [null],
      notes: [''],
      emergencyCardVisible: [null]
    });

    this.initDate();

    if (!this.immunisation) {
      this.immunisation = new Immunisation();
      this.ref.markForCheck();
    } else {
      this.initForm();
    }
  }

  close(saved: boolean) {
    this.saved.emit(saved);
    this.activeModal.close(saved);
  }

  onDateChange(date: NgbDateStruct) {
    this.date = date;
    this.immunisationFormGroup.controls['date'].setValue(fromNgbDateStructToDate(date));
    this.ref.markForCheck();
  }

  onEmergencyCardVisibleChange(emergencyCardVisible: boolean) {
    this.immunisationFormGroup.controls['emergencyCardVisible'].setValue(emergencyCardVisible);
  }

  onFavouriteChange(favourite: boolean) {
    this.immunisationFormGroup.controls['favourite'].setValue(favourite);
  }

  onTakenChange() {
    this.ref.markForCheck();
  }

  onVisibleChange(visible: boolean) {
    this.immunisationFormGroup.controls['visible'].setValue(visible);
  }

  save() {
    this.isSubmitted = true;
    this.immunisationFormGroup.controls['date'].setValue(fromNgbDateStructToDate(this.date));

    if (this.immunisationFormGroup.valid) {

      this.immunisation = this.immunisationFormGroup.value;
      this.immunisation.taken = this.immunisation.taken && this.immunisation.taken.toString() === 'null' ? null : this.immunisation.taken;

      if (this.immunisation.id) {
        // updating existing immunisation
        this.immunisationService.updateImmunisation(this.immunisation.id, this.immunisation).subscribe(res => {
          this.isSubmitted = false;
          this.saved.emit(true);

          this.translatedToastrService.success('message.success.immunisation.update');
          this.close(true);
        },
          () => this.translatedToastrService.error('message.error.immunisation.update'));

      } else {
        // create new immunisation
        this.immunisationService
          .createImmunisation(this.personIdParam, this.immunisation)
          .subscribe(res => {
            this.isSubmitted = false;
            this.saved.emit(true);
            this.resetForm();

            this.translatedToastrService.success('message.success.immunisation.update');
            this.close(true);
          });
      }
    }
  }

  private initDate() {
    const now = moment();
    this.date = { day: now.date(), month: (now.month() + 1), year: now.year() };
  }

  private initForm() {
    this.date = toNgbDateStruct(this.immunisation.date);
    this.immunisationFormGroup.patchValue(this.immunisation);
    if (this.readOnly) {
      this.immunisationFormGroup.disable();
    }
    this.ref.markForCheck();
  }

  private resetForm() {
    this.immunisationFormGroup.reset();
    this.initDate();
    this.immunisation = new Immunisation();
    this.ref.markForCheck();
  }
}
