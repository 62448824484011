import {Injectable} from '@angular/core';
import {BackendService} from '../backend/backend.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {JournalConfigResponse} from '../response/response.model';
import {LocaleResolverService} from '../locale/locale-resolver.service';
import {JournalConfig} from './journal-config.model';

@Injectable()
export class JournalService extends BackendService {

  constructor(
    protected http: HttpClient,
    protected localeResolverService: LocaleResolverService
  ) {
    super(http, localeResolverService);
  }

  getMyJournalConfig() {
    const httpHeaders = this.buildBaseHttpHeaders();
    const params = new HttpParams();
    return this.http.get<JournalConfigResponse>(
      this.getApiUrl() + '/people/me/journalConfig', {
        headers: httpHeaders,
        params: params
      });
  }

  updateMyJournalConfig(journalConfig: JournalConfig) {
    const httpOptions = this.createHttpOptions();
    return this.http.put(this.getApiUrl() + '/people/me/journalConfig', journalConfig, httpOptions);
  }
}
