import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BackendService} from '../backend/backend.service';
import {PaypalAgreement} from '../../shared/payment/paypal/paypal-agreement/paypal-agreement.model';
import {LocaleResolverService} from '../locale/locale-resolver.service';

@Injectable()
export class BillingService extends BackendService {

    constructor(
        protected http: HttpClient,
    protected localeResolverService: LocaleResolverService,
    ) {
        super(http, localeResolverService);
    }

    executePaypalAgreement(agreement: PaypalAgreement) {
        const httpOptions = this.createHttpOptions();
        return this.http.post(this.getApiUrl() + '/billing/paypal/agreement', agreement, httpOptions);
    }
}
