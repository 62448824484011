import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {take} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {AuthenticationService, AuthError} from '../../core/authentication/authentication.service';
import {NavigationService} from '../../core/navigation/navigation.service';
import {TranslatedToastrService} from '../../core/translated-toastr/translated-toastr.service';

@Component({
  selector: 'pat-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  isLoaded = false;
  isSubmitted = false;
  userFormGroup: FormGroup;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private navigationService: NavigationService,
    private translatedToastrService: TranslatedToastrService,
  ) { }

  ngOnInit() {
    this.initFormGroup();

    this.activatedRoute.params.pipe(take(1)).subscribe(
      resp => {
        if ('email' in resp) {
          this.userFormGroup.controls['email'].setValue(resp['email']);
        }
        this.isLoaded = true;
      }
    );
  }

  done(error: AuthError, result: any): void {
    // TODO change!
    if (error) {
      this.translatedToastrService.error(error.message);
      return;
    } else {
      const email = this.userFormGroup.get('email').value;
      this.navigationService.navigateToLoginResetPassword(email);
    }
  }

  onClickConfirm() {
    this.isSubmitted = true;
    if (this.userFormGroup.valid) {
      const email = this.userFormGroup.get('email').value;
      this.authenticationService.requestPasswordReset(email, this);
    }
  }

  private initFormGroup() {
    this.userFormGroup = this.formBuilder.group({
      email: ['', Validators.required],
    });
  }
}
