import {Injectable} from '@angular/core';
import {BackendService} from '../../core/backend/backend.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {
    BulkDocumentPresignedpostResponse,
    DicomViewResponse,
    DocumentListResponse,
    DocumentResponse,
    SignedUrlResponse
} from '../../core/response/response.model';
import {Observable} from 'rxjs';
import {DocumentHolder} from './document.model';
import {ToastrService} from 'ngx-toastr';
import {map} from 'rxjs/operators';
import {LocaleResolverService} from '../../core/locale/locale-resolver.service';
import {Router} from '@angular/router';


import {DocumentTypeEnum} from './document-type.enum';
import {saveAs} from 'file-saver';
import {QueryFilter} from '../../core/filters/query-filter.model';

@Injectable()
export class DocumentService extends BackendService {

    constructor(
        protected http: HttpClient,
        protected localeResolverService: LocaleResolverService,
        protected router: Router,
        public  toastr: ToastrService
    ) {
        super(http, localeResolverService);
    }

    createDocument(personIdParam: string, formData) {
        return this.http.post(
            this.getApiUrl() + '/people/' + personIdParam + '/documents', formData, {withCredentials: true});
    }

    createSignedGetUrl(id: string, body) {
        const httpOptions = this.createHttpOptions();
        return this.http.post(this.getApiUrl() + '/people/' + id + '/signedget', body, httpOptions);
    }

    createDicomDocumentView(id: string, body) {
        const httpOptions = this.createHttpOptions();
        return this.http.post<DicomViewResponse>(this.getApiUrl() + '/people/' + id + '/dicomViews', body, httpOptions);
    }

    releaseDicomDocumentView(sessionId: string) {
        const httpOptions = this.createHttpOptions();
        return this.http.delete(this.getApiUrl() + '/dicomViews/' + sessionId, httpOptions);
    }

    createPresignedPost(id: string, body) {
        const httpOptions = this.createHttpOptions();
        return this.http.post(this.getApiUrl() + '/people/' + id + '/documents/presignedpost', body, httpOptions);
    }

    createBulkDocumentPresignedPost(id: string, body) {
        const httpOptions = this.createHttpOptions();
        return this.http.post<BulkDocumentPresignedpostResponse>(
            this.getApiUrl() + '/people/' + id + '/documents/bulkpresignedpost', body, httpOptions);
    }

    saveMyProfilePhoto(formData) {
        return this.saveProfilePhoto('me', formData);
    }

    saveProfilePhoto(personIdParam: string, formData) {
        return this.http.put<BulkDocumentPresignedpostResponse>(
            this.getApiUrl() + '/people/' + personIdParam + '/profilePhoto', formData, {withCredentials: true});
    }

    postFile(url: string, formData) {
        return this.http.post(url, formData);
    }

    getFile(url: string): Observable<Blob> {
        return this.http.get(url, {responseType: 'blob'});
    }

    commitDocument(key: string) {
        const httpOptions = this.createHttpOptions();
        return this.http.post(this.getApiUrl() + '/documents/commit', {key: key}, httpOptions);
    }

    getDocument(id: string, docId: number) {
        const httpOptions = this.createHttpOptions();
        return this.http.get<DocumentResponse>(this.getApiUrl() + '/people/' + id + '/documents/' + docId, httpOptions);
    }

    documentUrl(personIdParam: string, docId: number, type: string, fileName: string) {
        return this.getApiUrl() + '/people/' + personIdParam + '/documents/' + docId + '/content/' + fileName;
    }

    downloadDocument(personIdParam: string, docId: number, type: string, fileName: string) {
        this.getFile(this.documentUrl(personIdParam, docId, type, fileName)).subscribe(
            dataFile => {
                const blob = new Blob([dataFile], {type: type});
                saveAs(blob, fileName);
            },
            () => this.toastr.error('Could get file from storage: ' + fileName, 'Error!')
        );

    }

    listDocuments(id: string, docType: number, q: string, base: boolean, page: number, pageSize: number, lastId: number,
                  queryFilters: QueryFilter[] = []) {
        const httpHeaders = this.createHttpHeaders();

        let params = new HttpParams();
        if (page !== undefined && page !== null) {
            params = params.append('page', page.toString());
        }
        if (pageSize !== undefined && pageSize !== null) {
            params = params.append('pageSize', pageSize.toString());
        }
        if (lastId !== undefined && lastId !== null) {
            params = params.append('lastId', lastId.toString());
        }
        if (docType !== undefined && docType !== null) {
            params = params.append('docType', docType.toString());
        }
        if (q !== undefined && q !== null) {
            params = params.append('q', q.toString());
        }
        if (base !== undefined && base !== null) {
            params = params.append('base', base.toString());
        }
        for (const queryFilter of queryFilters) {
            params = params.append(queryFilter.name, String(queryFilter.value));
        }

        return this.http.get<DocumentListResponse>(this.getApiUrl() + '/people/' + id + '/documents', (params.keys().length > 0) ? {
            withCredentials: true,
            headers: httpHeaders,
            params: params
        } : {withCredentials: true, headers: httpHeaders});
    }

    updateDocument(personIdParam: string, doc: DocumentHolder) {
        const httpOptions = this.createHttpOptions();
        return this.http.put(this.getApiUrl() + '/people/' + personIdParam + '/documents/' + doc.id, doc, httpOptions);
    }

    deleteDocument(id: number) {
        const httpOptions = this.createHttpOptions();
        return this.http.delete(this.getApiUrl() + '/documents/' + id, httpOptions);
    }

    insertIntoS3A(doc, file, that, callback) {
        const url = doc.url;
        const key = doc.key;

        const formData: FormData = new FormData();
        formData.append('x-amz-server-side-encryption', 'AES256');
        formData.append('key', key);

        for (const k of Object.keys(doc.fields)) {
            console.log(k + ': ' + doc.fields[k]);
            formData.append(k, doc.fields[k]);
        }
        formData.append('file', file);

        this.postFile(url, formData).subscribe(
            resp => {
                console.log('Successfully inserted to S3: ' + key);
                console.log('Success: ' + resp);

                this.commitDocument(key).subscribe(
                    () => {
                        console.log('Successfully commit: ' + key);
                        callback(null, doc.name, this.toastr, that);
                    },
                    () => callback('Could not commit', doc.name, that)
                );
            },
            err => callback('Could not insert into S3 ' + err, doc.name, that)
        );
    }

    onDownload(personIdParam: string, key: string, type: string, fileName: string) {
        this.createSignedGetUrl(personIdParam, {key: key})
            .subscribe(
                (data: SignedUrlResponse) => {
                    const url = data.result.url;
                    console.log('Url: ' + url);
                    this.getFile(url).subscribe(
                        dataF => {
                            console.log('Successfully downloaded file from S3: ' + key);
                            const blob = new Blob([dataF], {type: type});
                            saveAs(blob, fileName);
                        },
                        err => this.toastr.error('Could get file from storage: ' + fileName, 'Error!')
                    );
                },
                err => this.toastr.error('Could not obtain signature for file: ' + fileName, 'Error!')
            );
    }

    private resolveStaticHost() {
        // 'https://s3.eu-central-1.amazonaws.com/medakte.static.test'
        return environment.aws.s3.staticUrl;

        // if (location.host.lastIndexOf('localhost') > -1) {
        //   return 'https://s3.eu-central-1.amazonaws.com/medakte.static.test';
        // } else {
        //   return location.host + '/medakte.static.test';
        // }
    }

    resolveStaticFileUrl(key: string): string {
        if (!key) {
            return '';
        }
        return this.resolveStaticHost() + '/' + key;
    }

    resolvePhotoFileUrl(key: string): string {
        if (!key) {
            return '';
        }

        return this.getApiUrl() + '/profilePhotos/' + key + '.jpg';
    }

    public downloadFile(url: string): any {
        return this.http.get(url, {responseType: 'blob'})
            .pipe(
                map((result: any) => {
                    return result;
                })
            );
    }

    onView(personId, docId: number, docType: number, type: string, fileName: string) {
        if (docType === DocumentTypeEnum.X_RAYS_CT_MRI) {
            this.createDicomDocumentView(personId, {docId: docId})
                .subscribe(
                    (data: DicomViewResponse) => {

                        const dicomView = data.result;

                        this.router.navigate(['/documents/dicom-viewer/'], {
                            queryParams: {
                                sessionId: dicomView.sessionId,
                                personId: personId,
                                docId: docId,
                                docType: docType,
                                type: type,
                                fileName: fileName
                            }
                        });
                    },
                    err => this.toastr.error('Could not obtain DICOM document session', 'Error!')
                );
            return;
        } else if (type && (type === 'application/pdf' || type.startsWith('image'))) {
            this.router.navigate(['/documents/viewer/'], {
                queryParams: {
                    personId: personId,
                    docId: docId,
                    type: type,
                    fileName: fileName
                }
            });
            return;
        } else {
            this.toastr.warning('Could not display document. Pls download it.', 'Warning');
        }
    }

    createDocumentFileFormData(file, doc) {
        const formData: FormData = new FormData();
        formData.append('file', file); // binary content
        formData.append('metadata', JSON.stringify(doc));
        return formData;
    }

    async createDocumentBulk(personIdParam: string, documents: DocumentHolder[]): Promise<string[]>  {
        const allDocumentUploads = [];
        documents.forEach(doc => {
                const formData = this.createDocumentFileFormData(doc.file, doc);
                const docUpload = this.createDocument(personIdParam, formData).toPromise();
                allDocumentUploads.push(docUpload);
            }
        );

        const uploads = await Promise.all(allDocumentUploads);
        const uploadedDocsId = uploads.map(u => u.result.id);

        return new Promise((resolve, reject) => {
            resolve(uploadedDocsId);
        });
    }

    resolveEmergencyCardDocumentDirectLink(documentId: number, fileName: string, medakteId: number, code: string) {
        return this.getApiUrl() + '/emergencyCardDocuments/' + documentId + '/content/' + fileName + '?medakteId=' + medakteId + '&code=' + code;
    }
}
