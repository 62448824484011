import {Country} from '../location/country.model';

export class Address {
  id: number;
  type: number;
  streetLine1: string;
  streetLine2: string;
  zip: string;
  city: string;
  note: string;
  country: Country;
  cityRef: {
    id: number;
    name: string;
  };

  // for creation
  countryCode: string;
  cityId: number;

  constructor() {
    this.streetLine1 = '';
    this.streetLine2 = '';
    this.zip = '';
    this.city = '';
    this.country = {code: '', name: ''};
    this.cityRef = {id: null, name: ''};
  }
}
