import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MedicationService} from '../../../../core/health/medication.service';
import {Medication, MedicationCode} from '../../../../core/health/medication.model';
import {NgbActiveModal, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {fromNgbDateStructToDate, toNgbDateStruct} from '../../../../../lib/dates';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {catchError, debounceTime, distinctUntilChanged, map, switchMap, tap} from 'rxjs/operators';
import {concat, Observable, of, Subject} from 'rxjs';
import {MedicationCodeListResponse} from '../../../../core/response/response.model';
import {NgbDateDynamicParserFormatter} from '../../../../../lib/ngb-date-dynamic-parser-formatter';
import {BaseComponent} from '../../../../../base/base-component';
import {LocaleResolverService} from '../../../../core/locale/locale-resolver.service';
import {Operation} from '../../../../core/health/operation.model';
import {DocumentHolder} from '../../../documents/document.model';
import {Disease} from '../../../../core/health/disease.model';
import {EntityLinksComponent} from '../../entity-links/entity-links.component';
import * as moment from 'moment-timezone';
import {CustomValidators} from 'ngx-custom-validators';
import {medicationValidator} from './medication-validator.directive';
import {ProfileService} from '../../../../core/profile/profile.service';
import {MedicationTypeEnum} from '../../../../shared/enums/medication-type.enum';
import {MedicationWhenEnum} from '../../../../shared/enums/medication-when.enum';
import {TranslateService} from '@ngx-translate/core';
import {TranslatedToastrService} from '../../../../core/translated-toastr/translated-toastr.service';

@Component({
  selector: 'app-medication-edit',
  templateUrl: './medication-edit.component.html',
  styleUrls: ['./medication-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {provide: NgbDateParserFormatter, useClass: NgbDateDynamicParserFormatter}
  ]
})
export class MedicationEditComponent extends BaseComponent implements OnInit {
  @Input() medication: Medication;
  @Input() personIdParam: string;
  @Input() readOnly = false;
  @ViewChild('entityLinks') entityLinks: EntityLinksComponent;
  end: NgbDateStruct;
  isSubmitted = false;
  isValid = true;
  loadedDiseases: Disease[] = [];
  loadedDocuments: DocumentHolder[] = [];
  loadedOperations: Operation[] = [];
  medicationFormGroup: FormGroup;
  medicationsCodes$: Observable<MedicationCode[]>;
  medicationsCodesInput$ = new Subject<string>();
  medicationsCodesLoading = false;
  medicationTypes = [
    {label: '', value: MedicationTypeEnum.TABLET_CAPSULE_DRAGEE},
    {label: '', value: MedicationTypeEnum.SUPPOSITORY},
    {label: '', value: MedicationTypeEnum.SYRINGE_INFUSION},
    {label: '', value: MedicationTypeEnum.OINTMENT_TINCTURE},
    {label: '', value: MedicationTypeEnum.PLASTER},
    {label: '', value: MedicationTypeEnum.SPRAY},
    {label: '', value: MedicationTypeEnum.DROPS},
    {label: '', value: MedicationTypeEnum.OTHER}
  ];
  medicationTypesWithDayTimes = [
    MedicationTypeEnum.TABLET_CAPSULE_DRAGEE,
    MedicationTypeEnum.SUPPOSITORY,
    MedicationTypeEnum.SYRINGE_INFUSION,
    MedicationTypeEnum.PLASTER,
    MedicationTypeEnum.SPRAY,
    MedicationTypeEnum.DROPS
  ];
  medicationWhens = [
    {label: '', value: MedicationWhenEnum.NO_INSTRUCTIONS},
    {label: '', value: MedicationWhenEnum.BEFORE_THE_MEAL},
    {label: '', value: MedicationWhenEnum.WITH_THE_MEAL},
    {label: '', value: MedicationWhenEnum.AFTER_THE_MEAL},
    {label: '', value: MedicationWhenEnum.WHEN_NEEDED},
    {label: '', value: MedicationWhenEnum.ACCORDING_TO_INSTRUCTIONS}
  ];
  otherMedication = false;
  selectedDiseases: Disease[] = [];
  selectedDocuments: DocumentHolder[] = [];
  selectedOperations: Operation[] = [];
  start: NgbDateStruct;
  tillNow = false;

  constructor(
    private activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private medicationService: MedicationService,
    private translatedToastrService: TranslatedToastrService,
    private translateService: TranslateService,
    protected profileService: ProfileService,
    protected localeResolverService: LocaleResolverService,
    protected ref: ChangeDetectorRef,
  ) {
    super(profileService, localeResolverService, ref);
  }

  ngOnInit() {
    super.ngOnInit();

    this.medicationTypes.forEach(
      medicationType => medicationType.label = this.translateService.instant('codes.medication.type.' + medicationType.value)
    );
    this.medicationWhens.forEach(
      medicationWhen => medicationWhen.label = this.translateService.instant('codes.medication.when.' + medicationWhen.value)
    );

    // init form group
    this.medicationFormGroup = this.fb.group({
      id: [null],
      emergencyCardVisible: [null],
      end: [''],
      evening: [null],
      favourite: [null],
      drug: [''],
      lunch: [null],
      medicationCode: [null],
      morning: [null],
      night: [null],
      notes: [''],
      otherType: [''],
      prescribedBy: [''],
      start: ['', Validators.compose([Validators.required, CustomValidators.date])],
      strength: [''],
      title: [''],
      type: [null],
      visible: [null],
      when: [this.medication ? this.medication.when : 1],
    }, {validators: medicationValidator});

    this.initDate();

    if (!this.medication) {
      this.medication = new Medication();
      this.medicationFormGroup.controls['start'].setValue(fromNgbDateStructToDate(this.start));
      this.ref.markForCheck();
    } else {
      if (this.medication.drug) {
        this.otherMedication = true;
      }

      this.medication.morning = this.medication.morning ? parseFloat(this.medication.morning + '') : null;
      this.medication.lunch = this.medication.lunch ? parseFloat(this.medication.lunch + '') : null;
      this.medication.evening = this.medication.evening ? parseFloat(this.medication.evening + '') : null;
      this.medication.night = this.medication.night ? parseFloat(this.medication.night + '') : null;

      this.loadedDiseases = this.medication.diseases;
      this.loadedDocuments = this.medication.documents;
      this.loadedOperations = this.medication.operations;

      this.selectedDiseases = this.medication.diseases;
      this.selectedDocuments = this.medication.documents;
      this.selectedOperations = this.medication.operations;

      this.initForm();
    }

    this.loadMedicationsCodes();
  }

  close(saved: boolean) {
    this.activeModal.close(saved);
  }

  diseasesPopulated(diseases: Disease[]) {
    this.selectedDiseases = diseases;
  }

  documentsPopulated(documents: DocumentHolder[]) {
    this.selectedDocuments = documents;
  }

  getMedicationUnit(): string {
    let unit = '';
    switch (+this.medicationFormGroup.controls['type'].value) {
      case MedicationTypeEnum.TABLET_CAPSULE_DRAGEE:
      case MedicationTypeEnum.SUPPOSITORY:
      case MedicationTypeEnum.PLASTER:
        unit = this.translateService.instant('codes.medication.unit.piece');
        break;

      case MedicationTypeEnum.SYRINGE_INFUSION:
        unit = this.translateService.instant('codes.medication.unit.milliliter');
        break;

      case MedicationTypeEnum.SPRAY:
        unit = this.translateService.instant('codes.medication.unit.strokes');
        break;

      case MedicationTypeEnum.DROPS:
        unit = this.translateService.instant('codes.medication.unit.drops');
        break;
    }
    return unit;
  }

  keys(obj): string[] {
    return Object.keys(obj);
  }

  onEmergencyCardVisibleChange(emergencyCardVisible: boolean) {
    this.medicationFormGroup.controls['emergencyCardVisible'].setValue(emergencyCardVisible);
  }

  onEndChange(end: NgbDateStruct) {
    this.end = end;
    this.medicationFormGroup.controls['end'].setValue(fromNgbDateStructToDate(this.end));
    this.ref.markForCheck();
  }

  onFavouriteChange(favourite: boolean) {
    this.medicationFormGroup.controls['favourite'].setValue(favourite);
  }

  onMedicationCodeChange() {
    this.ref.markForCheck();
  }

  onMedicationCodeClick() {
    // trigger load
    this.medicationsCodesInput$.next('');
  }

  onMedicationTypeChange() {
    this.ref.markForCheck();
  }

  onStartChange(start: NgbDateStruct) {
    this.start = start;
    this.medicationFormGroup.controls['start'].setValue(fromNgbDateStructToDate(this.start));
    this.ref.markForCheck();
  }

  onTillNowChange(tillNow: boolean) {
    this.tillNow = tillNow;
    this.medicationFormGroup.controls['end'].setValue(this.tillNow ? null : fromNgbDateStructToDate(this.end));
    this.ref.markForCheck();
  }

  onVisibleChange(visible: boolean) {
    this.medicationFormGroup.controls['visible'].setValue(visible);
  }

  operationsPopulated(operations: Operation[]) {
    this.selectedOperations = operations;
  }

  save() {
    this.isSubmitted = true;
    this.medicationFormGroup.controls['start'].setValue(fromNgbDateStructToDate(this.start));
    this.medicationFormGroup.controls['end'].setValue(this.tillNow ? null : fromNgbDateStructToDate(this.end));

    if (this.medicationFormGroup.valid && this.isValid) {
      this.medication = this.medicationFormGroup.value;

      this.medication.diseaseIds = this.selectedDiseases.map(e => e.id);
      this.medication.documentIds = this.selectedDocuments.map(e => e.id);
      this.medication.operationIds = this.selectedOperations.map(e => e.id);
      if (this.medication.id) {
        // updating existing medication
        this.medicationService.updateMedication(this.medication.id, this.medication).subscribe(() => {
            this.isSubmitted = false;

            this.translatedToastrService.success('message.success.medication.update');
            this.close(true);
          },
          () => this.translatedToastrService.error('message.error.medication.update')
        );

      } else {
        // create new medication
        this.medicationService
          .createMedication(this.personIdParam, this.medication)
          .subscribe(() => {
            this.entityLinks.resetSelects();
            this.isSubmitted = false;
            this.resetForm();

            this.translatedToastrService.error('message.success.medication.update');
            this.close(true);
          },
            () => this.translatedToastrService.error('message.error.medication.update')
          );
      }
    }
  }

  showDayTimes(): boolean {
    return this.medicationFormGroup.controls['type'].value && this.medicationTypesWithDayTimes.some(
      drugType => drugType === +this.medicationFormGroup.controls['type'].value
    );
  }

  private initDate() {
    const now = moment();
    this.start = {day: now.date(), month: (now.month() + 1), year: now.year()};
  }

  private initForm() {
    this.start = toNgbDateStruct(this.medication.start);
    this.end = toNgbDateStruct(this.medication.end);
    this.tillNow = !this.medication.end;
    this.medicationFormGroup.patchValue(this.medication);
    if (this.readOnly) {
      this.medicationFormGroup.disable();
    }
    this.ref.markForCheck();
  }

  private loadMedicationsCodes() {
    this.medicationsCodes$ = concat(
      of([]), // default items
      this.medicationsCodesInput$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => this.medicationsCodesLoading = true),
        switchMap(term => this.medicationService.listMedicationsCodes(term).pipe(
          catchError(() => of([])),
          map((res: MedicationCodeListResponse) => {
            const diseasesCodes = res.results;
            return diseasesCodes.map(d => {
              return {
                id: d.id,
                atcCode: d.atcCode,
                text: d.text + ' (' + d.atcCode + ')'
              };
            });
          }),
          tap(() => this.medicationsCodesLoading = false)
        ))
      )
    );
  }

  private resetForm() {
    this.medicationFormGroup.reset();
    this.initDate();
    this.end = null;
    this.tillNow = null;
    this.medication = new Medication();
    this.ref.markForCheck();
  }
}
