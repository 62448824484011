import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {TimelineService} from '../../core/timeline/timeline.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {TimelineItem} from '../../core/timeline/timeline-item.model';
import {formatDayDate} from '../../../lib/formats';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineComponent implements OnDestroy, OnInit {

  timelineItems: TimelineItem[] = [];

  personIdParam: string;

  page = 1;
  pageSize = 20;
  moreResults = true;

  private sub: any;

  constructor(
    private timelineService: TimelineService,
    private translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private ref: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.personIdParam = params['id'];
      this.loadTimeline();
    });
  }

  isMe() {
    return this.personIdParam === 'me';
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  loadTimeline() {
    this.timelineService.listTimeline(this.personIdParam, this.page, this.pageSize)
      .subscribe(resp => {
        if (resp.results.length < this.pageSize) { this.moreResults = false; }
        this.timelineItems = this.timelineItems.concat(resp.results);
        this.page++;

        this.timelineItems.forEach(m => {
          m.localized = {
            created_at: '',
            start: '',
            end: '',
            medType: '',
            allCategory: '',
            allSeverity: '',
            immTaken: '',
            fdRelative: ''
          };
          m.localized.created_at = formatDayDate(m.created_at);
          m.localized.start = formatDayDate(m.start);
          m.localized.end = formatDayDate(m.end);

          if (m.doctype === 102) { // medication
            m.localized.medType = this.translateService.instant('codes.medication.type.' + m.intcode);
          }

          if (m.doctype === 103) { // allergy
            m.localized.allCategory = this.translateService.instant('codes.allergy.category.' + m.intcode);
            m.localized.allSeverity = this.translateService.instant('codes.allergy.severity.' + m.intcode);
          }

          if (m.doctype === 104) { // immunisation
            m.localized.immTaken =  this.translateService.instant('codes.immunisation.taken.' + m.intcode);
          }

          if (m.doctype === 105) { // family diseases
            m.localized.fdRelative = this.translateService.instant('codes.relative.' + m.intcode);
          }

        });

        this.ref.markForCheck();
      }, e => {
        console.log(e);
        this.toastr.error('Could not load timeline data', 'Error!');
      });
  }

  goToItem(docType, id) {
    let path = '';
    if (docType === 100) { path = '/diseases/'; }
    if (docType === 101) { path = '/operations/'; }
    if (docType === 102) { path = '/medications/'; }
    if (docType === 103) { path = '/allergies/'; }
    if (docType === 104) { path = '/immunisations/'; }
    if (docType === 105) { path = '/family-diseases/'; }

    this.router.navigate(['/people/' + this.personIdParam + path + id]);
  }

}
