import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../../core/authentication/authentication.service';
import {PersonTypeEnum} from '../../enums/person-type.enum';
import {TranslatedToastrService} from '../../../core/translated-toastr/translated-toastr.service';

@Component({
    selector: 'app-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
    isSubmitted: boolean;
    personType: PersonTypeEnum;
    signUpForm: FormGroup;

    constructor(
        private authenticationService: AuthenticationService,
        private formBuilder: FormBuilder,
        private router: Router,
        private translatedToastrService: TranslatedToastrService,
    ) {
        this.signUpForm = this.formBuilder.group({
            confirmPassword: '',
            email: '',
            password: '',
            tosAccepted: false,
        });
    }

    ngOnInit() {
        this.personType = this.resolveSignupType();
    }

    onSignup({email, password, confirmPassword, tosAccepted}:
                 { email: string, password: string, confirmPassword: string, tosAccepted: boolean }) {
        this.isSubmitted = true;

        if (email == null || password == null || !confirmPassword) {
            this.translatedToastrService.error('authentication.errors.EmailPasswordMandatoryError');
            return;
        }

        if (!tosAccepted) {
            this.translatedToastrService.error('authentication.errors.TosNotAccepted');
            return;
        }

        if (password !== confirmPassword) {
            this.translatedToastrService.error('authentication.errors.PasswordsNotMatched');
            return;
        }

        this.authenticationService.signUp(email, password, {type: this.personType}).subscribe(
          () => this.onSignUpSuccess(),
          err => this.onSignUpError(err)
        );
    }

    private onSignUpError(err: any) {
        if (err && err.error && err.error.error) {
            const errCode = err.error.error.code;
            const translationCode = 'authentication.errors.' + errCode;
            this.translatedToastrService.error(translationCode);
        }
    }

    private onSignUpSuccess() {
        this.authenticationService.setCredentials(this.signUpForm.value.email, this.signUpForm.value.password);
        this.router.navigate(['/confirm-registration/', this.signUpForm.value.email]);
    }

    private resolveSignupType() {
        if (location.host.startsWith('pat')) {
            return PersonTypeEnum.PATIENT;
        }

        if (location.host.startsWith('doc')) {
            return PersonTypeEnum.DOCTOR;
        }

        if (location.host.startsWith('care')) {
            return PersonTypeEnum.MEDICAL_INSTITUTION;
        }

        return PersonTypeEnum.PATIENT;
    }
}
