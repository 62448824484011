import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Allergy} from '../../../core/health/allergy.model';
import {formatDate} from '../../../../lib/formats';

@Component({
  selector: 'app-emergency-card-allergies',
  templateUrl: './emergency-card-allergies.component.html',
  styleUrls: ['./emergency-card-allergies.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmergencyCardAllergiesComponent implements OnChanges, OnInit {
  @Input() allergies: Allergy[] = [];
  @Input() personIdParam: string;

  constructor(protected ref: ChangeDetectorRef) {
  }

  ngOnInit() {
    if (this.allergies.length) {
      this.allergies.forEach(allergy => this.localizeAllergy(allergy));
      this.ref.markForCheck();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const change = changes.allergies || undefined;
    if (change) {
      this.allergies.forEach(allergy => this.localizeAllergy(allergy));
      this.ref.markForCheck();
    }
  }

  private localizeAllergy(allergy: Allergy) {
    allergy.localized = {
      category: '',
      severity: '',
      end: '',
      start: '',
      created_at: ''
    };
    allergy.localized.start = formatDate(allergy.start);
  }
}
