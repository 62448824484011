import {Component, OnInit} from '@angular/core';
import {take} from 'rxjs/operators';
import {forkJoin} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {TranslatedToastrService} from '../../../core/translated-toastr/translated-toastr.service';
import {AuthenticationService} from '../../../core/authentication/authentication.service';
import {NavigationService} from '../../../core/navigation/navigation.service';

@Component({
  selector: 'app-patient-registration-confirmation',
  templateUrl: './patient-registration-confirmation.component.html',
  styleUrls: ['./patient-registration-confirmation.component.scss']
})
export class PatientRegistrationConfirmationComponent implements OnInit {
  private code: string;
  private email: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private navigationService: NavigationService,
    private translatedToastrService: TranslatedToastrService,
  ) { }

  ngOnInit() {
    const routeParams$ = this.activatedRoute.params.pipe(take(1));
    const queryParams$ = this.activatedRoute.queryParams.pipe(take(1));

    forkJoin([routeParams$, queryParams$]).subscribe(respList => {
      this.email = respList[0]['email'];
      this.code = respList[1]['code'];

      if (this.code) {
        this.authenticationService.confirmAccount(this.email, this.code).subscribe(
          () => this.onConfirmAccountSuccess(),
          err => this.onConfirmAccountError(err)
        );
      } else {
        this.translatedToastrService.error('authentication.errors.CodeMandatoryError');
      }
    });
  }

  onClickBack() {
    this.navigationService.navigateToLogin();
  }

  private onConfirmAccountError(err) {
    if (err && err.error && err.error.error) {
      const errCode = err.error.error.code;
      const translationCode = 'authentication.errors.' + errCode;
      this.translatedToastrService.error(translationCode);

      switch (errCode) {
        case 'CodeExpired':
        case 'VerificationCodeValidationError':
          this.navigationService.navigateToRegistrationConfirmationInvalid(this.email);
          break;

        default:
          this.navigationService.navigateToLogin();
          break;
      }
    }
  }

  private onConfirmAccountSuccess() {
    this.navigationService.navigateToRegistrationSuccess();
  }
}
