import {Injectable} from '@angular/core';
import {BackendService} from '../backend/backend.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {CitiesResponse} from '../response/response.model';
import {LocaleResolverService} from '../locale/locale-resolver.service';

@Injectable()
export class CityService extends BackendService {

  constructor(
      protected http: HttpClient,
    protected localeResolverService: LocaleResolverService,
  ) {
    super(http, localeResolverService);
  }

  listCities(name: string, countryCode: string) {
    const httpHeaders = this.createHttpHeaders();
    let params = new HttpParams();
    params = params.append('name', name);
    params = params.append('countryCode', countryCode);

    return this.http.get<CitiesResponse>(this.getApiUrl() + '/cities', { headers: httpHeaders, params: params });
  }
}
