import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DocumentHolder} from '../../documents/document.model';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ImageViewerComponent} from 'ng2-image-viewer';
import {EmergencyPassService} from '../emergency-pass.service';
import {DocumentTypeEnum} from '../../documents/document-type.enum';
import {DicomViewResponse} from '../../../core/response/response.model';
import {DocumentService} from '../../documents/document.service';
import {environment} from '../../../../../environments/environment';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-emergency-card-document-viewer',
  templateUrl: './emergency-card-document-viewer.component.html',
  styleUrls: ['./emergency-card-document-viewer.component.scss']
})
export class EmergencyCardDocumentViewerComponent implements OnDestroy, OnInit {
  @Input() code: string;
  @Input() document: DocumentHolder;
  @ViewChild('viewer') viewer: ImageViewerComponent;
  // Dicom
  dicomSessionId: string;
  dicomUrl: SafeResourceUrl;
  isDicomLoaded = false;
  // Document
  isDocumentLoaded = false;
  resources = [];

  constructor(
    private activeModal: NgbActiveModal,
    private documentService: DocumentService,
    private domSanitizer: DomSanitizer,
    private emergencyPassService: EmergencyPassService
    ) { }

  ngOnInit() {
    if (this.document.docType === DocumentTypeEnum.X_RAYS_CT_MRI) {
      this.emergencyPassService.getDicomDocumentViewSessionId({docId: this.document.id}, this.code)
        .subscribe(
          (data: DicomViewResponse) => {
            const dicomView = data.result;
            this.dicomSessionId = dicomView.sessionId;
            this.dicomUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(environment.dicom.url + this.dicomSessionId);
            this.isDicomLoaded = true;
          },
          err => console.log(err)
        );

    } else if (this.document.type && (this.document.type === 'application/pdf' || this.document.type.startsWith('image'))) {
      this.emergencyPassService.getEmergencyPassDocumentUrl(this.document, this.code).subscribe(
        (dataFile) => {
            const that = this;
            const reader = new FileReader();
            reader.readAsDataURL(dataFile);
            reader.onloadend = function () {
              const base64data = reader.result;
              const base64result = (base64data as string).split(',')[1];
              that.resources = [base64result];
              that.isDocumentLoaded = true;
            };
        },
        err => console.log(err)
      );
    } else {
      // this.toastr.warning('Could not display document. Pls download it.', 'Warning');
    }
  }

  ngOnDestroy() {
    if (this.dicomSessionId) {
      this.emergencyPassService.releaseDicomDocumentView(this.dicomSessionId, this.code).subscribe();
    }
  }

  close() {
    this.activeModal.close();
  }
}
