import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS} from '@angular/common/http';

import {CookieService} from 'ngx-cookie-service';

import {SharedModule} from '../shared/shared.module';
import {ProfileService} from './profile/profile.service';
import {SubscriptionService} from './subscription/subscription.service';
import {DocumentService} from '../patient/documents/document.service';
import {MedicalService} from './medical/medical.service';
import {GoogleService} from './google/google.service';
import {ChatService} from '../../chat/chat.service';
import {InboxService} from '../../inbox/inbox.service';
import {CalendarService} from './calendar/calendar.service';
import {DiseaseService} from './health/disease.service';
import {OperationService} from './health/operation.service';
import {MedicationService} from './health/medication.service';
import {AllergyService} from './health/allergy.service';
import {ImmunisationService} from './health/immunisation.service';
import {FamilyDiseaseService} from './health/family-disease.service';
import {HeartRateService} from './measurements/heart-rate.service';
import {BloodPressureService} from './measurements/blood-pressure.service';
import {BloodGlucoseService} from './measurements/blood-glucose.service';
import {FeelingService} from './measurements/feeling.service';
import {SleepService} from './measurements/sleep.service';
import {StressService} from './measurements/stress.service';
import {PainService} from './measurements/pain.service';
import {Spo2Service} from './measurements/spo2.service';
import {BodyTemperatureService} from './measurements/body-temperature.service';
import {WeightService} from './measurements/weight.service';
import {TimelineService} from './timeline/timeline.service';
import {EmergencyPassService} from '../patient/emergency-card/emergency-pass.service';
import {BillingService} from './billing/billing.service';
import {AdminService} from './admin/admin.service';
import {IssueService} from './issue/issue.service';
import {UsageService} from './usage/usage.service';
import {OnboardingService} from './onboarding/onboarding.service';
import {ActivityLogService} from './activity-log/activity-log.service';
import {ResponsiveService} from './responsive/responsive.service';
import {HttpConfigInterceptor} from './interceptors/http-config.interceptor';
import {AuthenticationService} from './authentication/authentication.service';
import {CountryService} from './location/country.service';
import {FavouriteService} from './favourite/favourite/favourite.service';
import {StripeService} from './stripe/stripe.service';
import {RefreshTokenService} from '../shared/auth/refresh-token.service';
import {LanguageService} from './language/language.service';
import {LocaleResolverService} from './locale/locale-resolver.service';
import {AddressService} from './address/address.service';
import {PersonService} from './person/person.service';
import {CityService} from './location/city.service';
import {FileSizePipe} from 'ngx-filesize';
import {JournalService} from './measurements/journal.service';
import {DatePickerService} from './date-picker/date-picker.service';
import {NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import {ImageHelperService} from './image-helper/image-helper.service';
import {SubsPlanResolverService} from './subs-plan/subs-plan-resolver.service';
import {TranslatedToastrService} from './translated-toastr/translated-toastr.service';
import {NavigationService} from './navigation/navigation.service';
import {MedicGuardService} from './medic/medic-guard.service';
import {AdminGuardService} from './admin/admin-guard.service';
import {SortService} from './helper/sort.service';
import {MedicSpecialtyService} from './medic/medic-specialty/medic-specialty.service';

@NgModule({
  imports: [
    // Modules
    SharedModule
  ],
  providers: [
    // Services
    ActivityLogService,
    AddressService,
    AdminGuardService,
    AdminService,
    AllergyService,
    AuthenticationService,
    BillingService,
    BloodGlucoseService,
    BloodPressureService,
    BodyTemperatureService,
    CalendarService,
    ChatService,
    CityService,
    CookieService,
    CountryService,
    DatePickerService,
    DiseaseService,
    DocumentService,
    EmergencyPassService,
    FamilyDiseaseService,
    FavouriteService,
    FeelingService,
    GoogleService,
    HeartRateService,
    ImageHelperService,
    ImmunisationService,
    InboxService,
    IssueService,
    JournalService,
    LanguageService,
    LocaleResolverService,
    MedicalService,
    MedicationService,
    MedicSpecialtyService,
    MedicGuardService,
    NavigationService,
    OnboardingService,
    OperationService,
    PainService,
    PersonService,
    ProfileService,
    RefreshTokenService,
    ResponsiveService,
    SleepService,
    SortService,
    Spo2Service,
    StressService,
    StripeService,
    SubscriptionService,
    SubsPlanResolverService,
    TimelineService,
    TranslatedToastrService,
    UsageService,
    WeightService,
    // Pipe Services
    FileSizePipe,
    // Interceptors
    {provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true},
    {provide: NgbDatepickerI18n, useClass: DatePickerService},
  ]
})
// The CORE module provides all Services, Interceptors and Impure AsyncPipes of the APP.
export class CoreModule {
}
