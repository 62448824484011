import {Component, Input, OnInit} from '@angular/core';
import {Payment} from '../../../core/payment/payment.model';
import {formatDateTime} from '../../../../lib/formats';
import {MedakteIconTypes} from '../../../shared/icon/medakte-icon-types';
import {MedakteIconColors} from '../../../shared/icon/medakte-icon-colors';
import {SubsPlan} from '../../../core/subs-plan/subs-plan.model';
import {SubscriptionService} from '../../../core/subscription/subscription.service';

@Component({
  selector: 'app-patient-settings-payments',
  templateUrl: './payment-settings-payments.component.html',
  styleUrls: ['./payment-settings-payments.component.scss']
})
export class PaymentSettingsPaymentsComponent implements OnInit {
  @Input() subsPlans: SubsPlan[] = [];
  formatDateTime = formatDateTime;
  medakteIconColors = MedakteIconColors;
  medakteIconTypes = MedakteIconTypes;
  pageNumber = 1;
  pageSize = 30;
  payments: Payment[] = [];
  total = 0;

  constructor(
    private subscriptionService: SubscriptionService,
  ) { }

  ngOnInit() {
    if (!this.subsPlans.length) {
      this.getSubscriptionPlans();
    }

    this.getPayments();
  }

  getPaymentDescription(payment: Payment) {
    let paymentDescription = '';
    const planCode = payment.planCode;
    if (planCode) {
      const subscriptionPlan = this.subsPlans.find(subsPlan => subsPlan.code === planCode);
      paymentDescription = subscriptionPlan ? 'subscriptionPlan.plan.' + subscriptionPlan.name : '';
    }

    return paymentDescription;
  }

  private getPayments() {
    this.subscriptionService.listPayments(this.pageNumber, this.pageSize)
      .subscribe(res => {
        this.payments = res.results;
        this.total = res.count;
      });
  }

  private getSubscriptionPlans() {
    this.subscriptionService.listSubscriptionPlans().subscribe(data => {
        this.subsPlans = data.results;
      }
    );
  }
}
