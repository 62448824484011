import {Country} from './country.model';

export class Location {
  country: Country;
  city: string;
  cityRef: {
    id: number;
    name: string;
  };
}
