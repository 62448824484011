import {Component, OnInit} from '@angular/core';
import {Profile} from '../../../core/profile/profile.model';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ProfileService} from '../../../core/profile/profile.service';
import {TranslatedToastrService} from '../../../core/translated-toastr/translated-toastr.service';

@Component({
  selector: 'app-patient-settings-profile',
  templateUrl: './patient-settings-profile.component.html',
  styleUrls: ['./patient-settings-profile.component.scss']
})
export class PatientSettingsProfileComponent implements OnInit {
  isSubmitted: boolean;
  profile: Profile;
  profileFormGroup: FormGroup;

  constructor(
    private fb: FormBuilder,
    private profileService: ProfileService,
    private translatedToastrService: TranslatedToastrService
  ) {}

  ngOnInit() {
    this.profileFormGroup = this.fb.group({
      emergencyContact: [''],
      height: [''],
      specialCharact: [''],
      weight: [''],
    });

    this.profileService.getMyPersonProfile().subscribe(
      resp => {
        this.profile = resp.result;
        this.profileFormGroup.patchValue(this.profile);
      },
      () => this.translatedToastrService.error('Could not load data')
    );
  }

  save() {
    this.isSubmitted = true;
    if (this.profileFormGroup.valid) {
      // this.profile = this.profileFormGroup.value;
      this.profileService.updateMyPersonProfile(this.profile).subscribe(
        () => this.translatedToastrService.success('Saved'),
        () => this.translatedToastrService.error('Could not save')
      );
    } else {
      this.translatedToastrService.error('Please provide all mandatory information', 'Validation error');
    }
  }
}
