import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivityLogService} from '../../core/activity-log/activity-log.service';
import {formatDate, formatMonthDayYear} from '../../../lib/formats';
import {ActivityLog} from '../../core/activity-log/activity-log.model';
import {activityLogResourceTranslationKey} from '../../core/activity-log/activity-log-resource.enum';
import {ACTIVITY_LOG_ACTION_TRANSLATION_KEY_PREFIX} from '../../core/activity-log/activity-log-action.enum';
import {ProfileService} from '../../core/profile/profile.service';
import {Person} from '../../core/person/person.model';

@Component({
    selector: 'app-activity-logs',
    templateUrl: './activity-logs.component.html',
    styleUrls: ['./activity-logs.component.scss']
})
export class ActivityLogsComponent implements OnInit {
    ACTIVITY_LOG_ACTION_TRANSLATION_KEY_PREFIX = ACTIVITY_LOG_ACTION_TRANSLATION_KEY_PREFIX;
    activityLogResourceTranslationKey = activityLogResourceTranslationKey;
    activityLogs: ActivityLog[];
    count: number;
    formatDate = formatDate;
    formatMonthDayYear = formatMonthDayYear;
    me: Person;
    pageNumber: number;
    pageSize = 30;

    constructor(
        private activityLogService: ActivityLogService,
        private profileService: ProfileService,
        private ref: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        this.setPage({offset: 0});

        const me$ = this.profileService.getMyProfile();
        me$.subscribe(r => {
            this.me = r.result;
        });
    }

    setPage(pageInfo) {
        this.pageNumber = pageInfo.offset;
        this.activityLogService.listMyActivityLogs(this.pageNumber + 1, this.pageSize)
            .subscribe(r => {
                this.activityLogs = r.results;
                this.count = r.count;
                this.ref.markForCheck();
            });
    }
}
