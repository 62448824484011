import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {CalendarView} from 'angular-calendar';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {BloodPressureService} from '../../../../core/measurements/blood-pressure.service';
import {startEndDates} from '../../../../../lib/dates';
import * as moment from 'moment-timezone';
import * as chartConfig from '../../../../shared/charts/chart.config';
import {ActivatedRoute} from '@angular/router';
import {take} from 'rxjs/operators';
import {LocaleResolverService} from '../../../../core/locale/locale-resolver.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-blood-pressure-chart',
  templateUrl: './blood-pressure-chart.component.html',
  styleUrls: ['./blood-pressure-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BloodPressureChartComponent implements OnInit {

  personIdParam: string;
  locale: string;

  view: string = CalendarView.Month;
  viewDate: Date = new Date();

  start: any;
  end: any;

  stats: any;

  dataSystolic: any[];
  dataDiastolic: any[];

  translations: {};

  chartColors = chartConfig.chartMultipleColors;
  chartData: ChartDataSets[] = [{ data: [] }];
  chartOptions: ChartOptions;
  chartType: ChartType = 'line';

  constructor(
    private bloodPressureService: BloodPressureService,
    private route: ActivatedRoute,
    private localeResolver: LocaleResolverService,
    private ref: ChangeDetectorRef,
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.locale = this.localeResolver.obtainLocale();
    this.localeResolver.localeSubject.subscribe(v => {
      if (v) {
        this.locale = v;
      }
    });

    this.translateService.get(['journal.bloodPressure.systolic', 'journal.bloodPressure.diastolic'])
      .subscribe(resp => {
        this.translations = resp;
        this.ref.markForCheck();
      });

    const routeParams$ = this.route.params.pipe(take(1));
    routeParams$.subscribe(r => {
      this.personIdParam = r['id'];
      this.fetchValues();
    });
  }

  fetchValues() {
    const dates = startEndDates('month', this.viewDate);
    this.start = dates.start;
    this.end = dates.end;

    this.setChartOption(this.start, this.end, this);

    this.bloodPressureService.listBloodPressures(this.personIdParam, null, null, null, this.start, this.end)
      .subscribe(r => {
        const values = r.results;

        this.dataSystolic = values.map(e => {
          return { x: e.date, y: e.systolic };
        });

        this.dataDiastolic = values.map(e => {
          return { x: e.date, y: e.diastolic };
        });

        this.populateChartData(this.dataSystolic, this.dataDiastolic);
        this.ref.markForCheck();

        // const systolic = calcStats(values, 'systolic');
        // const diastolic = calcStats(values, 'diastolic');
        //
        // this.stats = {
        //   systolic: systolic,
        //   diastolic: diastolic
        // };

        this.ref.markForCheck();
      });
  }

  private populateChartData(dataSystolic, dataDiastolic) {
    this.chartData = [
      {
        data: dataSystolic,
        label: `${this.translations['journal.bloodPressure.systolic']} mmHg`,
        pointRadius: 7,
      },
      {
        data: dataDiastolic,
        label: `${this.translations['journal.bloodPressure.diastolic']} mmHg`,
        pointRadius: 7,
      }
    ];
  }

  private setChartOption(min, max, ref) {
    this.chartOptions = {
      animation: {
        duration: 1000,
        easing: 'easeOutBack'
      },
      hover: {
        animationDuration: 1000,
      },
      responsiveAnimationDuration: 1000,
      responsive: true,

      scales: {
        xAxes: [{
          type: 'time',
          distribution: 'series',
          position: 'bottom',
          time: {
            unit: 'week',
            tooltipFormat: 'LLL',
            displayFormats: {
              day: 'dd'
            },
            min: min,
            max: max
          },
          scaleLabel: {
            display: true,
          }
        }],
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      },

      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            let text = '';
            if (tooltipItem.datasetIndex === 0) {
              text = ' Systolic, ';
            } else {
              text = ' Diastolic, ';
            }

            const syst = ref.dataSystolic[tooltipItem.index].y;
            const disa = ref.dataDiastolic[tooltipItem.index].y;

            return tooltipItem.yLabel + text + ' ' + syst + '/' + disa + ' mmHg';
          }
        }
      }
    };
  }

  addDate(start, addDays) {
    return moment(start).add(addDays, 'days').format('LL');
  }

}
