import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {concat, Observable, of, Subject} from 'rxjs';
import {Disease} from '../../../core/health/disease.model';
import {DiseaseService} from '../../../core/health/disease.service';
import {catchError, debounceTime, distinctUntilChanged, map, switchMap, take, tap} from 'rxjs/operators';
import {DiseaseListResponse, DocumentListResponse, MedicationListResponse, OperationListResponse} from '../../../core/response/response.model';
import {OperationService} from '../../../core/health/operation.service';
import {MedicationService} from '../../../core/health/medication.service';
import {DocumentService} from '../../documents/document.service';
import {Operation} from '../../../core/health/operation.model';
import {Medication} from '../../../core/health/medication.model';
import {DocumentHolder} from '../../documents/document.model';
import {ActivatedRoute} from '@angular/router';
import {BaseComponent} from '../../../../base/base-component';
import {ProfileService} from '../../../core/profile/profile.service';
import {LocaleResolverService} from '../../../core/locale/locale-resolver.service';

@Component({
    selector: 'app-entity-links',
    templateUrl: './entity-links.component.html',
    styleUrls: ['./entity-links.component.scss']
})
export class EntityLinksComponent extends BaseComponent implements OnInit {

    @Input() readonly: boolean;
    @Input() requestedPerson: string;
    @Input() hasDiseases = false;
    @Input() hasOperations = false;
    @Input() hasMedications = false;
    @Input() hasDocuments = false;

    @Input() diseases: Disease[];
    @Output() selectedDiseases = new EventEmitter<Disease[]>();
    diseasesFormGroup: FormGroup;
    diseases$: Observable<Disease[]>;
    diseasesLoading = false;
    diseasesInput$ = new Subject<string>();

    @Input() operations: Operation[];
    @Output() selectedOperations = new EventEmitter<Operation[]>();
    operationsFormGroup: FormGroup;
    operations$: Observable<Operation[]>;
    operationsLoading = false;
    operationsInput$ = new Subject<string>();

    @Input() medications: Medication[];
    @Output() selectedMedications = new EventEmitter<Medication[]>();
    medicationsFormGroup: FormGroup;
    medications$: Observable<Medication[]>;
    medicationsLoading = false;
    medicationsInput$ = new Subject<string>();

    @Input() documents: DocumentHolder[];
    @Output() selectedDocuments = new EventEmitter<DocumentHolder[]>();
    documentsFormGroup: FormGroup;
    documents$: Observable<DocumentHolder[]>;
    documentsLoading = false;
    documentsInput$ = new Subject<string>();

    personIdParam: string;
    private debounce = 500;
    validate: boolean;

    constructor(
        private activatedRoute: ActivatedRoute,
        private diseaseService: DiseaseService,
        private documentService: DocumentService,
        private medicationService: MedicationService,
        private operationService: OperationService,
        private fb: FormBuilder,
        protected profileService: ProfileService,
        protected localeResolverService: LocaleResolverService,
        protected ref: ChangeDetectorRef
    ) {
        super(profileService, localeResolverService, ref);
    }

    ngOnInit() {
        this.activatedRoute.params.pipe(take(1)).subscribe(routeParams => this.personIdParam = routeParams['id']);

        this.diseases = !this.diseases ? [] : this.diseases;
        this.diseases.forEach(disease => {
            disease['search'] = disease.title ? disease.title : (
              disease.diseaseCodeText ? disease.diseaseCodeText : ''
            );
        });
        this.diseasesFormGroup = this.fb.group({
            diseases: [[], Validators.required],
        });
        this.onDiseaseChanges();
        this.loadDisease();
        this.diseasesFormGroup.controls['diseases'].setValue(this.diseases);

        this.operations = !this.operations ? [] : this.operations;
        this.operationsFormGroup = this.fb.group({
            operations: [[], Validators.required],
        });
        this.onOperationChanges();
        this.loadOperation();
        this.operationsFormGroup.controls['operations'].setValue(this.operations);

        this.medications = !this.medications ? [] : this.medications;
        this.medications.forEach(medication => {
            medication['search'] = medication.title ? (
              medication.title + (medication.strength ? ' ' + medication.strength : '')
            ) : (
              medication.medicationCode.text ? medication.medicationCode.text : ''
            );
        });
        this.medicationsFormGroup = this.fb.group({
            medications: [[], Validators.required],
        });
        this.onMedicationChanges();
        this.loadMedication();
        this.medicationsFormGroup.controls['medications'].setValue(this.medications);

        this.documents = !this.documents ? [] : this.documents;
        this.documentsFormGroup = this.fb.group({
            documents: [[], Validators.required],
        });
        this.onDocumentChanges();
        this.loadDocument();
        this.documentsFormGroup.controls['documents'].setValue(this.documents);
    }

    private onDiseaseChanges() {
        this.diseasesFormGroup.valueChanges.subscribe(val => {
            if (this.diseasesFormGroup.valid) {
                this.selectedDiseases.emit(this.diseasesFormGroup.get('diseases').value);
            } else {
                this.selectedDiseases.emit([]);
            }
        });
    }

    private loadDisease() {
        this.diseases$ = concat(
            of([]), // default items
            this.diseasesInput$.pipe(
                debounceTime(this.debounce),
                distinctUntilChanged(),
                tap(() => this.diseasesLoading = true),
                switchMap(term => this.diseaseService
                    .listDiseases(this.requestedPerson, term, true, 1, 100, null)
                    .pipe(
                        catchError(() => of([])),
                        map((res: DiseaseListResponse) => {
                            if (res.results.length) {
                                res.results.forEach(disease => {
                                    disease['search'] = disease.title ? disease.title : (
                                      disease.diseaseCodeText ? disease.diseaseCodeText : ''
                                    );
                                });
                            }
                            return res.results;
                        }),
                        tap(() => this.diseasesLoading = false)
                    ))
            )
        );
    }

    clickDiseases(event) {
        this.diseasesFormGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});
        // trigger load
        this.diseasesInput$.next('');
    }

    private onOperationChanges() {
        this.operationsFormGroup.valueChanges.subscribe(val => {
            if (this.operationsFormGroup.valid) {
                this.selectedOperations.emit(this.operationsFormGroup.get('operations').value);
            } else {
                this.selectedOperations.emit([]);
            }
        });
    }

    private loadOperation() {
        this.operations$ = concat(
            of([]), // default items
            this.operationsInput$.pipe(
                debounceTime(this.debounce),
                distinctUntilChanged(),
                tap(() => this.operationsLoading = true),
                switchMap(term => this.operationService
                    .listOperations(this.requestedPerson, term, true, 1, 100, null)
                    .pipe(
                        catchError(() => of([])),
                        map((res: OperationListResponse) => {
                            return res.results;
                        }),
                        tap(() => this.operationsLoading = false)
                    ))
            )
        );
    }

    clickOperations(event) {
        this.operationsFormGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});
        // trigger load
        this.operationsInput$.next('');
    }

    private onMedicationChanges() {
        this.medicationsFormGroup.valueChanges.subscribe(val => {
            if (this.medicationsFormGroup.valid) {
                this.selectedMedications.emit(this.medicationsFormGroup.get('medications').value);
            } else {
                this.selectedMedications.emit([]);
            }
        });
    }

    private loadMedication() {
        this.medications$ = concat(
            of([]), // default items
            this.medicationsInput$.pipe(
                debounceTime(this.debounce),
                distinctUntilChanged(),
                tap(() => this.medicationsLoading = true),
                switchMap(term => this.medicationService
                    .listMedications(this.requestedPerson, term, true, 1, 100, null)
                    .pipe(
                        catchError(() => of([])),
                        map((res: MedicationListResponse) => {
                            if (res.results.length) {
                                res.results.forEach(medication => {
                                    medication['search'] = medication.title ? (
                                      medication.title + (medication.strength ? ' ' + medication.strength : '')
                                    ) : (
                                      medication.medicationCode.text ? medication.medicationCode.text : ''
                                    );
                                });
                            }
                            return res.results;
                        }),
                        tap(() => this.medicationsLoading = false)
                    ))
            )
        );
    }

    clickMedications(event) {
        this.medicationsFormGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});
        // trigger load
        this.medicationsInput$.next('');
    }

    private onDocumentChanges() {
        this.documentsFormGroup.valueChanges.subscribe(val => {
            if (this.documentsFormGroup.valid) {
                this.selectedDocuments.emit(this.documentsFormGroup.get('documents').value);
            } else {
                this.selectedDocuments.emit([]);
            }
        });
    }

    private loadDocument() {
        this.documents$ = concat(
            of([]), // default items
            this.documentsInput$.pipe(
                debounceTime(this.debounce),
                distinctUntilChanged(),
                tap(() => this.documentsLoading = true),
                switchMap(term => this.documentService
                    .listDocuments(this.requestedPerson, null, term, true, 1, 100, null)
                    .pipe(
                        catchError(() => of([])),
                        map((res: DocumentListResponse) => {
                            return res.results;
                        }),
                        tap(() => this.documentsLoading = false)
                    ))
            )
        );
    }

    clickDocuments(event) {
        this.documentsFormGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});
        // trigger load
        this.documentsInput$.next('');
    }

    onViewDocument(document: DocumentHolder) {
        this.documentService.onView(this.requestedPerson, document.id, document.docType, document.type, document.name);
    }

    downloadDocument(document) {
        this.documentService.downloadDocument(this.requestedPerson, document.id, document.type, document.fileName);
    }

    getLinkedObjectUrl(id: number, entity: string, action = null) {
        return ((this.personIdParam === 'me') ? '/people/' : '/medic/patients/') +
          this.personIdParam +
          (entity === 'documents' ? '/' : '/health/') + entity + '/' +
          id +
          (action ? '/' + action : '');
    }

    resetSelects() {
        this.diseasesFormGroup.controls['diseases'].setValue([]);
        this.operationsFormGroup.controls['operations'].setValue([]);
        this.medicationsFormGroup.controls['medications'].setValue([]);
        this.documentsFormGroup.controls['documents'].setValue([]);

        this.diseasesFormGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});
        this.operationsFormGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});
        this.medicationsFormGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});
        this.documentsFormGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});
    }
}
