import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Allergy} from '../../../core/health/allergy.model';
import {AllergyService} from '../../../core/health/allergy.service';
import {BaseComponent} from '../../../../base/base-component';
import {LocaleResolverService} from '../../../core/locale/locale-resolver.service';
import {ProfileService} from '../../../core/profile/profile.service';
import {Router} from '@angular/router';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {AllergyEditComponent} from '../../health/allergies/allergy-edit/allergy-edit.component';
import {UsageService} from '../../../core/usage/usage.service';
import {UsageTypeEnum} from '../../../shared/enums/usage-type.enum';
import {hasValue} from '../../../../lib/validator';
import {UsageItem} from '../../../core/usage/usage.model';

@Component({
  selector: 'app-patient-dashboard-allergies',
  templateUrl: './patient-dashboard-allergies.component.html',
  styleUrls: ['./patient-dashboard-allergies.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatientDashboardAllergiesComponent extends BaseComponent implements OnInit {
  @Input() isVisible = true;
  @Input() personIdParam: string;
  allergies: Allergy[] = [];
  currentModal: NgbModalRef;
  isAddActive = true;
  titleLink = '';
  total = 0;
  usageItem: UsageItem;
  private pageSize = 3;

  constructor(private allergyService: AllergyService,
              private modal: NgbModal,
              private router: Router,
              private usageService: UsageService,
              protected profileService: ProfileService,
              protected localeResolverService: LocaleResolverService,
              protected ref: ChangeDetectorRef) {
    super(profileService, localeResolverService, ref);
  }

  ngOnInit() {
    super.ngOnInit();
    this.titleLink = ((this.personIdParam === 'me') ? '/people/' : '/medic/patients/') + this.personIdParam + '/health/allergies';
    this.loadAllergies();
    this.loadAllergyLimits();
  }

  onAddChange() {
    this.showAddModal();
  }

  onClick(id: number) {
    const url = this.titleLink + '/' + id + '/view';
    this.router.navigate([url]);
  }

  private handleSaved(saved: boolean) {
    if (saved === true) {
      this.loadAllergies();
      this.loadAllergyLimits();
    }
  }

  private loadAllergies() {
    this.allergyService.listAllergies(this.personIdParam, null, null, 1, this.pageSize, null).subscribe(
      resp => {
        this.total = resp.count;
        this.allergies = resp.results;
        this.allergies.forEach(m => {
          m.localized = {
            category: '',
            severity: '',
            end: '',
            start: '',
            created_at: ''
          };
          m.localized.start = this.formatDate(m.start);
        });
        this.ref.markForCheck();
      },
      e => {
        console.log(e);
      }
    );
  }

  private loadAllergyLimits() {
    this.usageService.getUsageItem(this.personIdParam, UsageTypeEnum.ALLERGIES).subscribe(res => {
      this.usageItem = res.result;
      if (this.usageItem) {
        // Limit Reached
        if (hasValue(this.usageItem.value) && hasValue(this.usageItem.limit) && this.usageItem.value >= this.usageItem.limit) {
          this.isAddActive = false;
        }

        // Insufficient Storage
        if (hasValue(this.usageItem.quota) && hasValue(this.usageItem.sumSize) && this.usageItem.sumSize >= this.usageItem.quota) {
          this.isAddActive = false;
        }

        this.ref.markForCheck();
      }
    });
  }

  private showAddModal() {
    setTimeout(() => {
      this.currentModal = this.modal.open(AllergyEditComponent, {size: 'lg'});
      this.currentModal.componentInstance.personIdParam = this.personIdParam;
      this.currentModal.componentInstance.requester = this.requester;
      this.currentModal.result.then(saved => this.handleSaved(saved)).catch();
    }, 100);
  }
}
