import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ResponsiveService} from '../../../core/responsive/responsive.service';
import {RouteInfo} from '../../../core/routes/route-info.model';
import {PATIENT_ROUTE_INFOS} from '../../../core/routes/patient-route-infos';
import {Person} from '../../../core/person/person.model';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';
import {ProfileService} from '../../../core/profile/profile.service';

@Component({
  selector: 'app-patient-toolbar',
  templateUrl: './patient-toolbar.component.html',
  styleUrls: ['./patient-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientToolbarComponent implements OnDestroy, OnInit {
  activeMenuItem: RouteInfo;
  activeFirstLevelMenuItem: RouteInfo;
  activeSecondLevelMenuItem: RouteInfo;
  menuItems: RouteInfo[] = [];
  person: Person;
  private subscriptions: Subscription[] = [];

  constructor(
    private profileService: ProfileService,
    private ref: ChangeDetectorRef,
    private router: Router,
    public responsiveService: ResponsiveService,
  ) { }

  ngOnInit() {
    this.responsiveService.responsiveSizeChange.subscribe(() => this.ref.markForCheck());

    this.subscriptions.push(
      this.profileService.myProfileSubject.subscribe(person => {
        this.person = person;
        this.initMenuItems();
      }),
      this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => this.initMenuItems()),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private initMenuItems() {
    this.menuItems = [];
    if (this.person && this.person.onboardingStage === 1) {
      // deep copy
      this.menuItems = JSON.parse(JSON.stringify(PATIENT_ROUTE_INFOS));
    }
    this.setActiveMenuItemsFromRoute();
    this.ref.markForCheck();
  }

  private setActiveMenuItemsFromRoute() {
    this.activeFirstLevelMenuItem = undefined;
    this.activeSecondLevelMenuItem = undefined;
    this.activeMenuItem = undefined;

    const currentRoute = this.router.url;
    const firstLevelMenuItem = this.menuItems.find(item => currentRoute.startsWith(item.path));
    if (firstLevelMenuItem) {
      this.activeFirstLevelMenuItem = firstLevelMenuItem;
      this.activeMenuItem = firstLevelMenuItem;

      const secondLevelMenuItem = firstLevelMenuItem.submenu.find(item => currentRoute.startsWith(item.path));
      if (secondLevelMenuItem) {
        this.activeSecondLevelMenuItem = secondLevelMenuItem;
        this.activeMenuItem = secondLevelMenuItem;
      }
    }
  }
}
