import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Operation} from '../../../core/health/operation.model';
import {OperationService} from '../../../core/health/operation.service';
import {LocaleResolverService} from '../../../core/locale/locale-resolver.service';
import {BaseComponent} from '../../../../base/base-component';
import {ProfileService} from '../../../core/profile/profile.service';
import {Router} from '@angular/router';
import {UsageTypeEnum} from '../../../shared/enums/usage-type.enum';
import {hasValue} from '../../../../lib/validator';
import {OperationEditComponent} from '../../health/operations/operation-edit/operation-edit.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {UsageService} from '../../../core/usage/usage.service';
import {UsageItem} from '../../../core/usage/usage.model';

@Component({
  selector: 'app-patient-dashboard-operations',
  templateUrl: './patient-dashboard-operations.component.html',
  styleUrls: ['./patient-dashboard-operations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
  export class PatientDashboardOperationsComponent extends BaseComponent implements OnInit {
  @Input() isVisible = true;
  @Input() personIdParam: string;
  currentModal: NgbModalRef;
  isAddActive = true;
  operations: Operation[] = [];
  titleLink = '';
  total = 0;
  usageItem: UsageItem;
  private pageSize = 3;

  constructor(private modal: NgbModal,
              private operationService: OperationService,
              private router: Router,
              private usageService: UsageService,
              protected profileService: ProfileService,
              protected localeResolverService: LocaleResolverService,
              protected ref: ChangeDetectorRef) {
    super(profileService, localeResolverService, ref);
  }

  ngOnInit() {
    super.ngOnInit();
    this.titleLink = ((this.personIdParam === 'me') ? '/people/' : '/medic/patients/') + this.personIdParam + '/health/operations';
    this.loadOperations();
    this.loadOperationLimits();
  }

  onAddChange() {
    this.showAddModal();
  }

  onClick(id: number) {
    const url = this.titleLink + '/' + id + '/view';
    this.router.navigate([url]);
  }

  private handleSaved(saved: boolean) {
    if (saved === true) {
      this.loadOperations();
      this.loadOperationLimits();
    }
  }

  private loadOperations() {
    this.operationService
      .listOperations(this.personIdParam, null, null, 1, this.pageSize, null)
      .subscribe(
        resp => {
          this.total = resp.count;
          this.operations = resp.results;
          this.operations.forEach(operation => {
            operation.localized = { date: this.formatDate(operation.date), end: this.formatDate(operation.end) };
          });
          this.ref.markForCheck();
        },
        e => {
          console.log(e);
        }
      );
  }

  private loadOperationLimits() {
    this.usageService.getUsageItem(this.personIdParam, UsageTypeEnum.OPERATIONS).subscribe(res => {
      this.usageItem = res.result;
      if (this.usageItem) {
        // Limit Reached
        if (hasValue(this.usageItem.value) && hasValue(this.usageItem.limit) && this.usageItem.value >= this.usageItem.limit) {
          this.isAddActive = false;
        }

        // Insufficient Storage
        if (hasValue(this.usageItem.quota) && hasValue(this.usageItem.sumSize) && this.usageItem.sumSize >= this.usageItem.quota) {
          this.isAddActive = false;
        }

        this.ref.markForCheck();
      }
    });
  }

  private showAddModal() {
    setTimeout(() => {
      this.currentModal = this.modal.open(OperationEditComponent, {size: 'lg'});
      this.currentModal.componentInstance.personIdParam = this.personIdParam;
      this.currentModal.componentInstance.requester = this.requester;
      this.currentModal.result.then(saved => this.handleSaved(saved)).catch();
    }, 100);
  }
}
