import {Injectable} from '@angular/core';
import {BackendService} from '../backend/backend.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {CalendarEventListResponse, GeneralResponse} from '../response/response.model';
import {LocaleResolverService} from '../locale/locale-resolver.service';

@Injectable()
export class CalendarService extends BackendService {

  constructor(
      protected http: HttpClient,
    protected localeResolverService: LocaleResolverService,
  ) {
    super(http, localeResolverService);
  }

  listMyEvents(start: string, end: string) {

    const httpHeaders = this.buildBaseHttpHeaders();
    const options: any = {
      headers: httpHeaders,
    };

    let params;
    params = new HttpParams();
    params = params.append('start', start);
    params = params.append('end', end);
    options.params = params;

    return this.http.get<CalendarEventListResponse>(this.getApiUrl() + '/people/me/events', {
      headers: httpHeaders,
      params: params
    });
  }

  listMedicEventsTimeslots(id: string, start: string, end: string) {

    const httpHeaders = this.buildBaseHttpHeaders();
    const options: any = {
      headers: httpHeaders,
    };

    let params;
    params = new HttpParams();
    params = params.append('start', encodeURIComponent(start));
    params = params.append('end', encodeURIComponent(end));
    options.params = params;

    return this.http.get<CalendarEventListResponse>(this.getApiUrl() + '/medics/' + id + '/events/timeslots', {
      headers: httpHeaders,
      params: params
    });
  }

  findFreeTimeslots(id: string, date: string) {

    const httpHeaders = this.buildBaseHttpHeaders();
    const options: any = {
      headers: httpHeaders,
    };

    let params;
    params = new HttpParams();
    params = params.append('date', date);
    options.params = params;

    return this.http.get<GeneralResponse>(this.getApiUrl() + '/medics/' + id + '/appointment/freeTimeslots', {
      headers: httpHeaders,
      params: params
    });
  }

  makeAnAppointment(id: string, req: any) {
    const httpOptions = this.createHttpOptions();
    return this.http.post(this.getApiUrl() + '/medics/' + id + '/appointments', req, httpOptions);
  }

  createEvent(req: any) {
    const httpOptions = this.createHttpOptions();
    return this.http.post(this.getApiUrl() + '/medics/me/events', req, httpOptions);
  }

  updateEvent(id, req: any) {
    const httpOptions = this.createHttpOptions();
    return this.http.put(this.getApiUrl() + '/medics/me/events/' + id, req, httpOptions);
  }

  deleteEvent(id: number | string) {
    const httpOptions = this.createHttpOptions();
    return this.http.delete(this.getApiUrl() + '/events/' + id, httpOptions);
  }
}
