import {DocumentHolder} from '../../patient/documents/document.model';
import {Creator} from '../creator/creator.model';
import {Medication} from './medication.model';
import {Operation} from './operation.model';

export class Disease {
  id: number;
  code: string;
  created_at: Date;
  creator: Creator;
  diseaseCode: DiseaseCode;
  diseaseCodeText: string;
  emergencyCardVisible: boolean;
  end: Date;
  favourite: boolean;
  notes: string;
  search: string; // CLIENT ONLY
  start: Date;
  tillNow: boolean;
  title: string;
  visible: boolean;

  documentIds: number[];
  medicationIds: number[];
  operationIds: number[];

  documents: DocumentHolder[];
  medications: Medication[];
  operations: Operation[];

  localized: {
    end: string;
    start: string;
  };
}

export class DiseaseCode {
  code: string;
  text: string;
}
