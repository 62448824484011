import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import * as $ from 'jquery';
import {StripeService} from '../../../../core/stripe/stripe.service';
import {environment} from '../../../../../../environments/environment';

declare var Stripe;

@Component({
    selector: 'app-stripe-card',
    templateUrl: './stripe-card.component.html',
    styleUrls: ['./stripe-card.component.scss']
})
export class StripeCardComponent implements OnInit, AfterViewInit {
    @Input() amount: number;
    @Input() description: string;
    @Input() embedded: boolean;
    @Output() cardCompleted = new EventEmitter<boolean>();
    @Output() cardSource = new EventEmitter<any>();
    @ViewChild('cardElement', {static: true}) cardElement: ElementRef;
    card;
    cardErrors;
    elements;
    isLoading = true;
    stripe;
    stripeCard;
    stripeCustomer: any;
    stripeCustomerSource: any;
    validCardEntered = false;

    constructor(
        private stripeService: StripeService
    ) {
    }

    ngOnInit() {
    }

    async ngAfterViewInit() {
        await $.getScript('https://checkout.stripe.com/checkout.js');
        await $.getScript('https://js.stripe.com/v3/');

        const publishableKey = environment.stripe.key;

        this.stripe = Stripe(publishableKey);
        this.elements = this.stripe.elements();

        const stripeCustomerResponse = await this.stripeService.getStripeCustomer().toPromise();
        this.stripeCustomer = stripeCustomerResponse.result;

        // console.log(this.stripeCustomer);

        if (this.stripeCustomer['default_source']) {
            const sourceId = this.stripeCustomer['default_source'];
            this.stripeCustomerSource = this.stripeCustomer.sources.data.filter(s => s.id === sourceId).pop();
            this.stripeCard = this.stripeCustomerSource.card;

            // TODO load source
            this.cardCompleted.emit(true); // valid source
            this.isLoading = false;
        } else {
            this.createCardElement();
            this.isLoading = false;
        }
    }

    getStripeCardExpire() {
        let expiryMonth = this.stripeCard.exp_month + '';
        if (expiryMonth.length !== 2) {
            expiryMonth = '0'  + expiryMonth;
        }
        let expiryYear = this.stripeCard.exp_year + '';
        if (expiryYear.length !== 2) {
            expiryYear = expiryYear.substring(2, 4);
        }
        return expiryMonth + '/' + expiryYear;
    }

    async handleForm(e) {
        e.preventDefault();

        const {source, error} = await this.stripe
            .createSource(this.card)
            .catch(err => console.log(err));

        if (error) {
            const cardErrors = error.message;
        }
    }

    async obtainSource(): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {

            if (this.stripeCustomerSource) { // obtained saved source
                resolve(this.stripeCustomerSource);
            } else {
                try {
                    if (this.validCardEntered) {
                        const {source, error} = await this.stripe.createSource(this.card);
                        if (!error) {
                            const existingSource = this.stripeCustomer.sources.data.filter(s => s.id === source.id).pop();
                            if (existingSource) {
                                resolve(existingSource);
                            } else {
                                await this.stripeService.attachSource(source).toPromise();
                                resolve(source);
                            }
                        } else {
                            this.cardErrors = error.message; // display error to user
                            reject(null); // could not create source via stripe service
                        }
                    } else {
                        reject(null); // no valid credit card data provided
                    }

                } catch (e) {
                    reject(null);
                    console.log(e);
                }
            }
        }).catch(e => {
            console.log(e);
        });
    }

    removeSource() {
        this.stripeService.removeSource(this.stripeCustomerSource.id).subscribe(r => {
            this.stripeCustomerSource = null;
            this.createCardElement();
        }, e => {
            console.log('error');
        });
    }

    private createCardElement() {
        const style = {
            base: {
                color: '#31325F',
                lineHeight: '24px',
                fontFamily: 'monospace',
                fontSmoothing: 'antialiased',
                fontSize: '19px',
                '::placeholder': {
                    color: '#CFD7E0'
                }
            }
        };

        this.card = this.elements.create('card', {hidePostalCode: false, style: style});
        this.card.mount(this.cardElement.nativeElement);
        this.card.addEventListener('change', (event) => {
            this.validCardEntered = event.complete;

            if (event.complete === true) {
                this.cardCompleted.emit(event.complete);
            }

            this.cardErrors = event.error && event.error.message;
        });
    }
}
