import {Creator} from '../creator/creator.model';

export class Allergy {
  id: number;
  category: number;
  created_at: Date;
  creator: Creator;
  doctor: string;
  emergencyCardVisible: boolean;
  end: Date;
  favourite: boolean;
  notes: string;
  severity: number;
  title: string;
  start: Date;
  visible: boolean;

  localized: {
    category: string;
    severity: string;
    end: string;
    start: string;
    created_at: string;
  };

  constructor() {
    this.localized = {
      category: '',
      severity: '',
      end: '',
      start: '',
      created_at: ''
    };
  }
}
