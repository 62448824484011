import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {Feeling} from '../../../core/measurements/feeling.model';
import {NgbDateParserFormatter, NgbDateStruct, NgbModal, NgbModalRef, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import {FeelingService} from '../../../core/measurements/feeling.service';
import * as moment from 'moment-timezone';
import {Person} from '../../../core/person/person.model';
import {ActivatedRoute} from '@angular/router';
import {ProfileService} from '../../../core/profile/profile.service';
import {take} from 'rxjs/operators';
import {forkJoin} from 'rxjs';
import {FeelingChartComponent} from './feeling-chart/feeling-chart.component';
import {LocaleResolverService} from '../../../core/locale/locale-resolver.service';
import {BaseComponent} from '../../../../base/base-component';
import {NgbDateDynamicParserFormatter} from '../../../../lib/ngb-date-dynamic-parser-formatter';
import {FeelingEnum} from '../../../shared/enums/feeling.enum';
import {UsageAlertComponent} from '../../../shared/usage/usage-alert/usage-alert.component';
import {UsageTypeEnum} from '../../../shared/enums/usage-type.enum';
import {EmergencyPassConfig} from '../../emergency-card/emergency-card-config.model';
import {JournalConfig} from '../../../core/measurements/journal-config.model';
import {JournalService} from '../../../core/measurements/journal.service';
import {EmergencyPassService} from '../../emergency-card/emergency-pass.service';
import {DeleteModalComponent} from '../../../shared/delete-modal/delete-modal.component';
import {TranslateService} from '@ngx-translate/core';
import {TranslatedToastrService} from '../../../core/translated-toastr/translated-toastr.service';

@Component({
    selector: 'app-feeling',
    templateUrl: './feeling.component.html',
    styleUrls: ['./feeling.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateDynamicParserFormatter }
    ]
})
export class FeelingComponent extends BaseComponent implements OnInit {
    @ViewChild('chart', { static: true }) chart: FeelingChartComponent;
    @ViewChild('usageAlert', { static: true }) usageAlert: UsageAlertComponent;

    count: number;
    currentModal: NgbModalRef;
    date: NgbDateStruct;
    emergencyCardConfig: EmergencyPassConfig;
    feeling: Feeling;
    feelings: Feeling[];
    feelingKeys = [
        {label: 'codes.feeling.' + FeelingEnum.EXCELLENT, value: FeelingEnum.EXCELLENT},
        {label: 'codes.feeling.' + FeelingEnum.VERY_GOOD, value: FeelingEnum.VERY_GOOD},
        {label: 'codes.feeling.' + FeelingEnum.GOOD, value: FeelingEnum.GOOD},
        {label: 'codes.feeling.' + FeelingEnum.SLIGHTLY_GOOD, value: FeelingEnum.SLIGHTLY_GOOD},
        {label: 'codes.feeling.' + FeelingEnum.ABOVE_AVERAGE, value: FeelingEnum.ABOVE_AVERAGE},
        {label: 'codes.feeling.' + FeelingEnum.AVERAGE, value: FeelingEnum.AVERAGE},
        {label: 'codes.feeling.' + FeelingEnum.BELOW_AVERAGE, value: FeelingEnum.BELOW_AVERAGE},
        {label: 'codes.feeling.' + FeelingEnum.SLIGHTLY_BAD, value: FeelingEnum.SLIGHTLY_BAD},
        {label: 'codes.feeling.' + FeelingEnum.BAD, value: FeelingEnum.BAD},
        {label: 'codes.feeling.' + FeelingEnum.VERY_BAD, value: FeelingEnum.VERY_BAD},
        {label: 'codes.feeling.' + FeelingEnum.TERRIBLE, value: FeelingEnum.TERRIBLE},
    ];
    journalConfig: JournalConfig;
    pageNumber: number;
    pageSize = 30;
    personIdParam: string;
    requestedPerson: Person;
    readonly = false;
    submitted = false;
    time: NgbTimeStruct;
    UsageTypeEnum = UsageTypeEnum;
    valid = true;

    constructor(
        private feelingService: FeelingService,
        private emergencyPassService: EmergencyPassService,
        private journalService: JournalService,
        private modal: NgbModal,
        private route: ActivatedRoute,
        private translatedToastrService: TranslatedToastrService,
        private translateService: TranslateService,
        protected profileService: ProfileService,
        protected localeResolverService: LocaleResolverService,
        protected ref: ChangeDetectorRef
    ) {
        super(profileService, localeResolverService, ref);
    }

    ngOnInit() {
        super.ngOnInit();
        this.feeling = new Feeling();
        this.initDate();

        const routeParams$ = this.route.params.pipe(take(1));

        forkJoin([routeParams$]).subscribe(respList => {
            this.personIdParam = respList[0]['id'];
            this.profileService.setCurrentPersonIdParam(this.personIdParam);

            if (this.personIdParam === 'me') {
                this.emergencyPassService.getMyEmergencyPassConfig().subscribe(r => {
                    this.emergencyCardConfig = r.result;
                    this.ref.markForCheck();
                });
                this.journalService.getMyJournalConfig().subscribe(r => {
                    this.journalConfig = r.result;
                    this.ref.markForCheck();
                });
            }

            this.loadData();

            if (this.personIdParam !== 'me') {
                this.profileService
                    .getProfile(this.personIdParam)
                    .subscribe(resp => {
                        this.requestedPerson = resp.result;
                        this.ref.markForCheck();
                    });
            }
        });
    }

    private loadData() {
        this.initDate();
        this.setPage({ offset: 0 });
    }

    private initDate() {
        const now = moment();
        this.time = { hour: now.hour(), minute: now.minute(), second: 0 };
        this.date = { day: now.date(), month: (now.month() + 1), year: now.year() };
    }

    askToRemove(index: number, feeling: Feeling) {
        this.showDeleteModal(index, feeling);
    }

    // https://github.com/swimlane/ngx-datatable/issues/721
    onActive(event) {
        if (event.type === 'click') {
            event.cellElement.blur();
        }
    }

    onEmergencyCardVisibleChange() {
        this.emergencyPassService.updateMyEmergencyPassConfig(this.emergencyCardConfig).subscribe(
          () => this.translatedToastrService.success('message.success.save'),
          () => this.translatedToastrService.error('message.error.save')
        );
    }

    onVisibleChange() {
        this.journalService.updateMyJournalConfig(this.journalConfig).subscribe(
          () => this.translatedToastrService.success('message.success.save'),
          () => this.translatedToastrService.error('message.error.save')
        );
    }

    validValues() {
        return this.feeling.value != null;
    }

    save() {
        this.submitted = true;
        if (this.valid && this.validValues()) {
            const date = moment(
                this.date.year + '-' +
                this.date.month + '-' +
                this.date.day + ' ' +
                this.time.hour + ':' +
                this.time.minute
                , 'YYYY-MM-DD HH:mm');

            this.feeling.date = date.toDate();
            this.feelingService.create(this.feeling)
                .subscribe(() => {
                    this.translatedToastrService.success('message.success.save');
                    this.feeling = new Feeling();

                    this.loadData();
                    this.chart.fetchValues();
                    this.usageAlert.loadsUsage();
                },
                    () => this.translatedToastrService.error('message.error.save')
                );
        }
    }

    setPage(pageInfo) {
        this.pageNumber = pageInfo.offset;
        this.feelingService.listFeelings(this.personIdParam, this.pageNumber + 1, this.pageSize, null, null, null, 'desc')
            .subscribe(r => {
                this.feelings = r.results;
                this.count = r.count;
                this.ref.markForCheck();
            });
    }

    showDeleteModal(index: number, feeling: Feeling) {
        setTimeout(() => {
            this.currentModal = this.modal.open(DeleteModalComponent, {size: 'lg'});
            this.currentModal.componentInstance.itemHeader = this.formatMonthDayYear(feeling.date);
            this.currentModal.componentInstance.itemType = this.translateService.instant('journal.feeling.title');
            this.currentModal.componentInstance.itemValue = this.translateService.instant('codes.feeling.' + feeling.value);
            this.currentModal.result.then(isDeleted => {
                if (isDeleted) {
                    this.removeRow(index, feeling);
                }
            });
        }, 100);
    }

    removeRow(index: number, feeling: Feeling) {
        this.feelingService.delete(feeling.id).subscribe(() => {
            this.feelings.splice(index, 1);
            this.feelings = [...this.feelings];
            this.chart.fetchValues();
            this.usageAlert.loadsUsage();
            this.ref.markForCheck();
        }, () =>
            this.translatedToastrService.error('message.error.delete'));
    }

    isDisabled() {
        return !this.feeling || this.feeling.value === null || false;
    }

    handleValid(valid) {
        this.valid = valid;
    }

    onIsLimitQuotaReached(limitQuotaReached) {
        this.readonly = limitQuotaReached;
        this.ref.markForCheck();
    }
}
