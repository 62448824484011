export class BloodGlucose {
  id: number;
  date: Date;
  value: number;
  unit: number;
  mealTime: number;

  min: number;
  max: number;
  avg: number;
}

