import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../../../../base/base-component';
import {LocaleResolverService} from '../../../core/locale/locale-resolver.service';
import {EmergencyPass} from '../emergency-card.model';
import {NgbActiveModal, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Disease} from '../../../core/health/disease.model';
import {concat, Observable, of, Subject} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, map, switchMap, tap} from 'rxjs/operators';
import {
  AllergyListResponse,
  DiseaseListResponse,
  DocumentListResponse, FamilyDiseaseListResponse, ImmunisationListResponse,
  MedicationListResponse,
  OperationListResponse
} from '../../../core/response/response.model';
import {DiseaseService} from '../../../core/health/disease.service';
import {DocumentHolder} from '../../documents/document.model';
import {Allergy} from '../../../core/health/allergy.model';
import {FamilyDisease} from '../../../core/health/family-disease.model';
import {Immunisation} from '../../../core/health/immunisation.model';
import {Medication} from '../../../core/health/medication.model';
import {Operation} from '../../../core/health/operation.model';
import {OperationService} from '../../../core/health/operation.service';
import {AllergyService} from '../../../core/health/allergy.service';
import {DocumentService} from '../../documents/document.service';
import {FamilyDiseaseService} from '../../../core/health/family-disease.service';
import {ImmunisationService} from '../../../core/health/immunisation.service';
import {MedicationService} from '../../../core/health/medication.service';
import {ProfileService} from '../../../core/profile/profile.service';
import {EmergencyPassConfig} from '../emergency-card-config.model';
import {EmergencyPassService} from '../emergency-pass.service';
import {SelectedItem} from '../../../core/selected-item/selected-item';
import {TranslatedToastrService} from '../../../core/translated-toastr/translated-toastr.service';

@Component({
  selector: 'app-emergency-card-edit',
  templateUrl: './emergency-card-edit.component.html',
  styleUrls: ['./emergency-card-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmergencyCardEditComponent extends BaseComponent implements OnInit {
  @Input() personIdParam: string;
  @Input() emergencyCard: EmergencyPass;
  @Input() emergencyCardConfig: EmergencyPassConfig;

  minDate: NgbDateStruct = {day: 1, month: 9, year: 2022};
  maxDate: NgbDateStruct = {day: 1, month: 1, year: 2122};

  validTo: NgbDateStruct;

  editEmergencyCardFormGroup: FormGroup;

  allergies: Allergy[] = [];
  allergies$: Observable<Allergy[]>;
  allergiesFormGroup: FormGroup;
  allergiesInput$ = new Subject<string>();
  allergiesLoading = false;

  diseases: Disease[] = [];
  diseases$: Observable<Disease[]>;
  diseasesFormGroup: FormGroup;
  diseasesInput$ = new Subject<string>();
  diseasesLoading = false;

  documents: DocumentHolder[] = [];
  documents$: Observable<DocumentHolder[]>;
  documentsFormGroup: FormGroup;
  documentsInput$ = new Subject<string>();
  documentsLoading = false;

  familyDiseases: FamilyDisease[] = [];
  familyDiseases$: Observable<FamilyDisease[]>;
  familyDiseasesFormGroup: FormGroup;
  familyDiseasesInput$ = new Subject<string>();
  familyDiseasesLoading = false;

  immunisations: Immunisation[] = [];
  immunisations$: Observable<Immunisation[]>;
  immunisationsFormGroup: FormGroup;
  immunisationsInput$ = new Subject<string>();
  immunisationsLoading = false;

  medications: Medication[] = [];
  medications$: Observable<Medication[]>;
  medicationsFormGroup: FormGroup;
  medicationsInput$ = new Subject<string>();
  medicationsLoading = false;

  operations: Operation[] = [];
  operations$: Observable<Operation[]>;
  operationsFormGroup: FormGroup;
  operationsInput$ = new Subject<string>();
  operationsLoading = false;

  validate: boolean;

  private debounce = 500;

  constructor(
      private activeModal: NgbActiveModal,
      private allergyService: AllergyService,
      private diseaseService: DiseaseService,
      private documentService: DocumentService,
      private emergencyPassService: EmergencyPassService,
      private familyDiseaseService: FamilyDiseaseService,
      private fb: FormBuilder,
      private immunisationService: ImmunisationService,
      private medicationService: MedicationService,
      private operationService: OperationService,
      private translatedToastrService: TranslatedToastrService,
      protected profileService: ProfileService,
      protected localeResolverService: LocaleResolverService,
      protected ref: ChangeDetectorRef,
  ) {
    super(profileService, localeResolverService, ref);
  }

  ngOnInit() {
    super.ngOnInit();

    this.editEmergencyCardFormGroup = this.fb.group({});

    if (!this.emergencyCardConfig && this.personIdParam === 'me') {
      this.emergencyPassService.getMyEmergencyPassConfig().subscribe(r => this.emergencyCardConfig = r.result);
    }

    // ALLERGIES
    this.allergies = this.emergencyCard.allergies;
    this.allergiesFormGroup = this.fb.group({
      allergies: [[], Validators.required],
    });
    this.onAllergiesChanges();
    this.loadAllergies();
    this.allergiesFormGroup.controls['allergies'].setValue(this.allergies);

    // DISEASES
    this.diseases = this.emergencyCard.diseases;
    this.diseases.forEach(disease => {
      disease['search'] = disease.title ? disease.title : (
        disease.diseaseCodeText ? disease.diseaseCodeText : ''
      );
    });
    this.diseasesFormGroup = this.fb.group({
      diseases: [[], Validators.required],
    });
    this.onDiseasesChanges();
    this.loadDiseases();
    this.diseasesFormGroup.controls['diseases'].setValue(this.diseases);

    // DOCUMENTS
    this.documents = this.emergencyCard.documents;
    this.documentsFormGroup = this.fb.group({
      documents: [[], Validators.required],
    });
    this.onDocumentsChanges();
    this.loadDocuments();
    this.documentsFormGroup.controls['documents'].setValue(this.documents);

    // FAMILY DISEASES
    this.familyDiseases = this.emergencyCard.familyDiseases;
    this.familyDiseases.forEach(familyDisease => {
      familyDisease['search'] = familyDisease.title ? familyDisease.title : (
        familyDisease.diseaseCodeText ? familyDisease.diseaseCodeText : ''
      );
    });
    this.familyDiseasesFormGroup = this.fb.group({
      familyDiseases: [[], Validators.required],
    });
    this.onFamilyDiseasesChanges();
    this.loadFamilyDiseases();
    this.familyDiseasesFormGroup.controls['familyDiseases'].setValue(this.familyDiseases);

    // IMMUNISATIONS
    this.immunisations = this.emergencyCard.immunisations;
    this.immunisationsFormGroup = this.fb.group({
      immunisations: [[], Validators.required],
    });
    this.onImmunisationsChanges();
    this.loadImmunisations();
    this.immunisationsFormGroup.controls['immunisations'].setValue(this.immunisations);

    // MEDICATIONS
    this.medications = this.emergencyCard.medications;
    this.medications.forEach(medication => {
      medication['search'] = medication.title ? (
        medication.title + (medication.strength ? ' ' + medication.strength : '')
      ) : (
        medication.medicationCode.text ? medication.medicationCode.text : ''
      );
    });
    this.medicationsFormGroup = this.fb.group({
      medications: [[], Validators.required],
    });
    this.onMedicationsChanges();
    this.loadMedications();
    this.medicationsFormGroup.controls['medications'].setValue(this.medications);

    // OPERATIONS
    this.operations = this.emergencyCard.operations;
    this.operationsFormGroup = this.fb.group({
      operations: [[], Validators.required],
    });
    this.onOperationsChanges();
    this.loadOperations();
    this.operationsFormGroup.controls['operations'].setValue(this.operations);

    this.ref.markForCheck();
  }

  isEqual(a: any, b: any): boolean {
    return a && b && a.id === b.id;
  }

  onAddAllergy(allergy: Allergy) {
    allergy.emergencyCardVisible = true;
    this.allergyService.updateAllergy(allergy.id, allergy).subscribe(
      () => {},
      () => this.translatedToastrService.error('message.error.save')
    );
  }

  onAddDisease(disease: Disease) {
    disease.emergencyCardVisible = true;
    this.diseaseService.updateDisease(disease.id, disease).subscribe(
      () => {},
      () => this.translatedToastrService.error('message.error.save')
    );
  }

  onAddDocument(documentHolder: DocumentHolder) {
    documentHolder.emergencyCardVisible = true;
    this.documentService.updateDocument(this.personIdParam, documentHolder).subscribe(
      () => {},
      () => this.translatedToastrService.error('message.error.save')
    );
  }

  onAddFamilyDisease(familyDisease: FamilyDisease) {
    familyDisease.emergencyCardVisible = true;
    this.familyDiseaseService.updateFamilyDisease(familyDisease.id, familyDisease).subscribe(
      () => {},
      () => this.translatedToastrService.error('message.error.save')
    );
  }

  onAddImmunisation(immunisation: Immunisation) {
    immunisation.emergencyCardVisible = true;
    this.immunisationService.updateImmunisation(immunisation.id, immunisation).subscribe(
      () => {},
      () => this.translatedToastrService.error('message.error.save')
    );
  }

  onAddMedication(medication: Medication) {
    medication.emergencyCardVisible = true;
    this.medicationService.updateMedication(medication.id, medication).subscribe(
      () => {},
      () => this.translatedToastrService.error('message.error.save')
    );
  }

  onAddOperation(operation: Operation) {
    operation.emergencyCardVisible = true;
    this.operationService.updateOperation(operation.id, operation).subscribe(
      () => {},
      () => this.translatedToastrService.error('message.error.save')
    );
  }

  onClickAllergies(event) {
    this.allergiesFormGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});
    // trigger load
    this.allergiesInput$.next('');
  }

  onClickDiseases(event) {
    this.diseasesFormGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});
    // trigger load
    this.diseasesInput$.next('');
  }

  onClickDocuments(event) {
    this.documentsFormGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});
    // trigger load
    this.documentsInput$.next('');
  }

  onClickFamilyDiseases(event) {
    this.familyDiseasesFormGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});
    // trigger load
    this.familyDiseasesInput$.next('');
  }

  onClickImmunisations(event) {
    this.immunisationsFormGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});
    // trigger load
    this.immunisationsInput$.next('');
  }

  onClickMedications(event) {
    this.medicationsFormGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});
    // trigger load
    this.medicationsInput$.next('');
  }

  onClickOperations(event) {
    this.operationsFormGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});
    // trigger load
    this.operationsInput$.next('');
  }

  onEmergencyCardConfigChange() {
    this.emergencyPassService.updateMyEmergencyPassConfig(this.emergencyCardConfig).subscribe(
      () => this.translatedToastrService.success('message.success.save'),
      () => this.translatedToastrService.error('message.error.save')
    );
  }

  onClearAllergies() {
    for (const allergy of this.emergencyCard.allergies) {
      this.removeAllergy(allergy);
    }
  }

  onClearDiseases() {
    for (const disease of this.emergencyCard.diseases) {
      this.removeDisease(disease);
    }
  }

  onClearDocuments() {
    for (const document of this.emergencyCard.documents) {
      this.removeDocument(document);
    }
  }

  onClearFamilyDiseases() {
    for (const familyDisease of this.emergencyCard.familyDiseases) {
      this.removeFamilyDisease(familyDisease);
    }
  }

  onClearImmunisations() {
    for (const immunisation of this.emergencyCard.immunisations) {
      this.removeImmunisation(immunisation);
    }
  }

  onClearMedications() {
    for (const medication of this.emergencyCard.medications) {
      this.removeMedication(medication);
    }
  }

  onClearOperations() {
    for (const operation of this.emergencyCard.operations) {
      this.removeOperation(operation);
    }
  }

  onRemoveAllergy(entry: SelectedItem<Allergy>) {
    const allergy = entry.value;
    const idx = this.allergies.findIndex(d => d.id === allergy.id);
    if (idx >= 0) {
      this.allergies.splice(idx, 1);
    }

    this.allergiesFormGroup.get('allergies').setValue(this.allergies);
    this.allergiesFormGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});

    allergy.emergencyCardVisible = false;
    this.allergyService.updateAllergy(allergy.id, allergy).subscribe(
      () => {},
      () => this.translatedToastrService.error('message.error.save')
    );
  }

  onRemoveDisease(entry: SelectedItem<Disease>) {
    const disease = entry.value;
    const idx = this.diseases.findIndex(d => d.id === disease.id);
    if (idx >= 0) {
      this.diseases.splice(idx, 1);
    }

    this.diseasesFormGroup.get('diseases').setValue(this.diseases);
    this.diseasesFormGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});

    disease.emergencyCardVisible = false;
    this.diseaseService.updateDisease(disease.id, disease).subscribe(
      () => {},
      () => this.translatedToastrService.error('message.error.save')
    );
  }

  onRemoveDocument(entry: SelectedItem<DocumentHolder>) {
    const document = entry.value;
    const idx = this.documents.findIndex(d => d.id === document.id);
    if (idx >= 0) {
      this.documents.splice(idx, 1);
    }

    this.documentsFormGroup.get('documents').setValue(this.documents);
    this.documentsFormGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});

    document.emergencyCardVisible = false;
    this.documentService.updateDocument(this.personIdParam, document).subscribe(
      () => {},
      () => this.translatedToastrService.error('message.error.save')
    );
  }

  onRemoveFamilyDisease(entry: SelectedItem<FamilyDisease>) {
    const familyDisease = entry.value;
    const idx = this.familyDiseases.findIndex(d => d.id === familyDisease.id);
    if (idx >= 0) {
      this.familyDiseases.splice(idx, 1);
    }

    this.familyDiseasesFormGroup.get('familyDiseases').setValue(this.familyDiseases);
    this.familyDiseasesFormGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});

    familyDisease.emergencyCardVisible = false;
    this.familyDiseaseService.updateFamilyDisease(familyDisease.id, familyDisease).subscribe(
      () => {},
      () => this.translatedToastrService.error('message.error.save')
    );
  }

  onRemoveImmunisation(entry: SelectedItem<Immunisation>) {
    const immunisation = entry.value;
    const idx = this.immunisations.findIndex(d => d.id === immunisation.id);
    if (idx >= 0) {
      this.immunisations.splice(idx, 1);
    }

    this.immunisationsFormGroup.get('immunisations').setValue(this.immunisations);
    this.immunisationsFormGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});

    immunisation.emergencyCardVisible = false;
    this.immunisationService.updateImmunisation(immunisation.id, immunisation).subscribe(
      () => {},
      () => this.translatedToastrService.error('message.error.save')
    );
  }

  onRemoveMedication(entry: SelectedItem<Medication>) {
    const medication = entry.value;
    const idx = this.medications.findIndex(d => d.id === medication.id);
    if (idx >= 0) {
      this.medications.splice(idx, 1);
    }

    this.medicationsFormGroup.get('medications').setValue(this.medications);
    this.medicationsFormGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});

    medication.emergencyCardVisible = false;
    this.medicationService.updateMedication(medication.id, medication).subscribe(
      () => {},
      () => this.translatedToastrService.error('message.error.save')
    );
  }

  onRemoveOperation(entry: SelectedItem<Operation>) {
    const operation = entry.value;
    const idx = this.operations.findIndex(d => d.id === operation.id);
    if (idx >= 0) {
      this.operations.splice(idx, 1);
    }

    this.operationsFormGroup.get('operations').setValue(this.operations);
    this.operationsFormGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});

    operation.emergencyCardVisible = false;
    this.operationService.updateOperation(operation.id, operation).subscribe(
      () => {},
      () => this.translatedToastrService.error('message.error.save')
    );
  }

  close() {
    // TODO if the settings should only be made if the save button is clicked:
    // update Emergency Card Config
    // update Health Entries
    this.activeModal.close();
  }

  removeAllergy(allergy: Allergy) {
    const idx = this.allergies.findIndex(d => d.id === allergy.id);
    if (idx >= 0) {
      this.allergies.splice(idx, 1);
    }

    this.allergiesFormGroup.get('allergies').setValue(this.allergies);
    this.allergiesFormGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});

    allergy.emergencyCardVisible = false;
    this.allergyService.updateAllergy(allergy.id, allergy).subscribe(
      () => {},
      () => this.translatedToastrService.error('message.error.save')
    );
  }

  removeDisease(disease: Disease) {
    const idx = this.diseases.findIndex(d => d.id === disease.id);
    if (idx >= 0) {
      this.diseases.splice(idx, 1);
    }

    this.diseasesFormGroup.get('diseases').setValue(this.diseases);
    this.diseasesFormGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});

    disease.emergencyCardVisible = false;
    this.diseaseService.updateDisease(disease.id, disease).subscribe(
      () => {},
      () => this.translatedToastrService.error('message.error.save')
    );
  }

  removeDocument(document: DocumentHolder) {
    const idx = this.documents.findIndex(d => d.id === document.id);
    if (idx >= 0) {
      this.documents.splice(idx, 1);
    }

    this.documentsFormGroup.get('documents').setValue(this.documents);
    this.documentsFormGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});

    document.emergencyCardVisible = false;
    this.documentService.updateDocument(this.personIdParam, document).subscribe(
      () => {},
      () => this.translatedToastrService.error('message.error.save')
    );
  }

  removeFamilyDisease(familyDisease: FamilyDisease) {
    const idx = this.familyDiseases.findIndex(d => d.id === familyDisease.id);
    if (idx >= 0) {
      this.familyDiseases.splice(idx, 1);
    }

    this.familyDiseasesFormGroup.get('familyDiseases').setValue(this.familyDiseases);
    this.familyDiseasesFormGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});

    familyDisease.emergencyCardVisible = false;
    this.familyDiseaseService.updateFamilyDisease(familyDisease.id, familyDisease).subscribe(
      () => {},
      () => this.translatedToastrService.error('message.error.save')
    );
  }

  removeImmunisation(immunisation: Immunisation) {
    const idx = this.immunisations.findIndex(d => d.id === immunisation.id);
    if (idx >= 0) {
      this.immunisations.splice(idx, 1);
    }

    this.immunisationsFormGroup.get('immunisations').setValue(this.immunisations);
    this.immunisationsFormGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});

    immunisation.emergencyCardVisible = false;
    this.immunisationService.updateImmunisation(immunisation.id, immunisation).subscribe(
      () => {},
      () => this.translatedToastrService.error('message.error.save')
    );
  }

  removeMedication(medication: Medication) {
    const idx = this.medications.findIndex(d => d.id === medication.id);
    if (idx >= 0) {
      this.medications.splice(idx, 1);
    }

    this.medicationsFormGroup.get('medications').setValue(this.medications);
    this.medicationsFormGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});

    medication.emergencyCardVisible = false;
    this.medicationService.updateMedication(medication.id, medication).subscribe(
      () => {},
      () => this.translatedToastrService.error('message.error.save')
    );
  }

  removeOperation(operation: Operation) {
    const idx = this.operations.findIndex(d => d.id === operation.id);
    if (idx >= 0) {
      this.operations.splice(idx, 1);
    }

    this.operationsFormGroup.get('operations').setValue(this.operations);
    this.operationsFormGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});

    operation.emergencyCardVisible = false;
    this.operationService.updateOperation(operation.id, operation).subscribe(
      () => {},
      () => this.translatedToastrService.error('message.error.save')
    );
  }

  save() {
    this.activeModal.close(true);
  }

  private loadAllergies() {
    this.allergies$ = concat(
      of([]), // default items
      this.allergiesInput$.pipe(
        debounceTime(this.debounce),
        distinctUntilChanged(),
        tap(() => this.allergiesLoading = true),
        switchMap(term => this.allergyService
          .listAllergies(this.personIdParam, term, true, 1, 100, null)
          .pipe(
            catchError(() => of([])),
            map((res: AllergyListResponse) => {
              return res.results;
            }),
            tap(() => this.allergiesLoading = false)
          ))
      )
    );
  }

  private loadDiseases() {
    this.diseases$ = concat(
      of([]), // default items
      this.diseasesInput$.pipe(
        debounceTime(this.debounce),
        distinctUntilChanged(),
        tap(() => this.diseasesLoading = true),
        switchMap(term => this.diseaseService
          .listDiseases(this.personIdParam, term, true, 1, 100, null)
          .pipe(
            catchError(() => of([])),
            map((res: DiseaseListResponse) => {
              res.results.forEach(disease => {
                disease['search'] = disease.title ? disease.title : (
                  disease.diseaseCodeText ? disease.diseaseCodeText : ''
                );
              });
              return res.results;
            }),
            tap(() => this.diseasesLoading = false)
          ))
      )
    );
  }

  private loadDocuments() {
    this.documents$ = concat(
      of([]), // default items
      this.documentsInput$.pipe(
        debounceTime(this.debounce),
        distinctUntilChanged(),
        tap(() => this.documentsLoading = true),
        switchMap(term => this.documentService
          .listDocuments(this.personIdParam, null, term, true, 1, 100, null)
          .pipe(
            catchError(() => of([])),
            map((res: DocumentListResponse) => {
              return res.results;
            }),
            tap(() => this.documentsLoading = false)
          ))
      )
    );
  }

  private loadFamilyDiseases() {
    this.familyDiseases$ = concat(
      of([]), // default items
      this.familyDiseasesInput$.pipe(
        debounceTime(this.debounce),
        distinctUntilChanged(),
        tap(() => this.familyDiseasesLoading = true),
        switchMap(term => this.familyDiseaseService
          .listFamilyDiseases(this.personIdParam, term, true, 1, 100, null)
          .pipe(
            catchError(() => of([])),
            map((res: FamilyDiseaseListResponse) => {
              res.results.forEach(familyDisease => {
                familyDisease['search'] = familyDisease.title ? familyDisease.title : (
                  familyDisease.diseaseCodeText ? familyDisease.diseaseCodeText : ''
                );
              });
              return res.results;
            }),
            tap(() => this.familyDiseasesLoading = false)
          ))
      )
    );
  }

  private loadImmunisations() {
    this.immunisations$ = concat(
      of([]), // default items
      this.immunisationsInput$.pipe(
        debounceTime(this.debounce),
        distinctUntilChanged(),
        tap(() => this.immunisationsLoading = true),
        switchMap(term => this.immunisationService
          .listImmunisations(this.personIdParam, term, true, 1, 100, null)
          .pipe(
            catchError(() => of([])),
            map((res: ImmunisationListResponse) => {
              return res.results;
            }),
            tap(() => this.immunisationsLoading = false)
          ))
      )
    );
  }

  private loadMedications() {
    this.medications$ = concat(
      of([]), // default items
      this.medicationsInput$.pipe(
        debounceTime(this.debounce),
        distinctUntilChanged(),
        tap(() => this.medicationsLoading = true),
        switchMap(term => this.medicationService
          .listMedications(this.personIdParam, term, true, 1, 100, null)
          .pipe(
            catchError(() => of([])),
            map((res: MedicationListResponse) => {
              res.results.forEach(medication => {
                medication['search'] = medication.title ? (
                  medication.title + (medication.strength ? ' ' + medication.strength : '')
                ) : (
                  medication.medicationCode.text ? medication.medicationCode.text : ''
                );
              });
              return res.results;
            }),
            tap(() => this.medicationsLoading = false)
          ))
      )
    );
  }

  private loadOperations() {
    this.operations$ = concat(
      of([]), // default items
      this.operationsInput$.pipe(
        debounceTime(this.debounce),
        distinctUntilChanged(),
        tap(() => this.operationsLoading = true),
        switchMap(term => this.operationService
          .listOperations(this.personIdParam, term, true, 1, 100, null)
          .pipe(
            catchError(() => of([])),
            map((res: OperationListResponse) => {
              return res.results;
            }),
            tap(() => this.operationsLoading = false)
          ))
      )
    );
  }

  private onAllergiesChanges() {
    this.allergiesFormGroup.valueChanges.subscribe(val => {
      if (this.allergiesFormGroup.valid) {
        this.allergies = this.allergiesFormGroup.get('allergies').value;
      } else {
        this.allergies = [];
      }
    });
  }

  private onDiseasesChanges() {
    this.diseasesFormGroup.valueChanges.subscribe(val => {
      if (this.diseasesFormGroup.valid) {
        this.diseases = this.diseasesFormGroup.get('diseases').value;
      } else {
        this.diseases = [];
      }
    });
  }

  private onDocumentsChanges() {
    this.documentsFormGroup.valueChanges.subscribe(val => {
      if (this.documentsFormGroup.valid) {
        this.documents = this.documentsFormGroup.get('documents').value;
      } else {
        this.documents = [];
      }
    });
  }

  private onFamilyDiseasesChanges() {
    this.familyDiseasesFormGroup.valueChanges.subscribe(val => {
      if (this.familyDiseasesFormGroup.valid) {
        this.familyDiseases = this.familyDiseasesFormGroup.get('familyDiseases').value;
      } else {
        this.familyDiseases = [];
      }
    });
  }

  private onImmunisationsChanges() {
    this.immunisationsFormGroup.valueChanges.subscribe(val => {
      if (this.immunisationsFormGroup.valid) {
        this.immunisations = this.immunisationsFormGroup.get('immunisations').value;
      } else {
        this.immunisations = [];
      }
    });
  }

  private onMedicationsChanges() {
    this.medicationsFormGroup.valueChanges.subscribe(val => {
      if (this.medicationsFormGroup.valid) {
        this.medications = this.medicationsFormGroup.get('medications').value;
      } else {
        this.medications = [];
      }
    });
  }

  private onOperationsChanges() {
    this.operationsFormGroup.valueChanges.subscribe(val => {
      if (this.operationsFormGroup.valid) {
        this.operations = this.operationsFormGroup.get('operations').value;
      } else {
        this.operations = [];
      }
    });
  }
}
