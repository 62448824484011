import {Component, OnInit} from '@angular/core';
import {Person} from '../../../core/person/person.model';
import {PersonService} from '../../../core/person/person.service';
import {TranslatedToastrService} from '../../../core/translated-toastr/translated-toastr.service';

@Component({
  selector: 'app-patient-settings-person',
  templateUrl: './payment-settings-person.component.html',
  styleUrls: ['./payment-settings-person.component.scss']
})
export class PaymentSettingsPersonComponent implements OnInit {
  isLoaded = false;
  person: Person;

  constructor(
    private peopleService: PersonService,
    private translatedToastrService: TranslatedToastrService,
  ) {
  }

  ngOnInit() {
    this.peopleService
      .getPerson()
      .subscribe(r => {
        this.person = r.result;
        this.isLoaded = true;
      },
        () => this.translatedToastrService.error('Could not get person'));
  }
}
