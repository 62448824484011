import {Component, OnInit} from '@angular/core';
import {OnboardingService} from '../../../core/onboarding/onboarding.service';
import {Router} from '@angular/router';
import {OnboardingStages} from '../../../core/onboarding/onboarding-stages';
import {NavigationService} from '../../../core/navigation/navigation.service';

@Component({
  selector: 'app-patient-onboarding-payment-success',
  templateUrl: './patient-onboarding-payment-success.component.html',
  styleUrls: ['./patient-onboarding-payment-success.component.scss']
})
export class PatientOnboardingPaymentSuccessComponent implements OnInit {
  constructor(
    private navigationService: NavigationService,
    private onboardingService: OnboardingService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.checkOnboarding();
  }

  onClickContinue() {
    this.navigationService.navigateToPatientOnboardingHealth();
  }

  private checkOnboarding() {
    this.onboardingService.getPatientOnboardingStage().subscribe(
      data => {
        const onboardingUrl = '/onboarding/' + data.result.onboardingStage;
        // SUBSCRIPTION should be DONE
        if (OnboardingStages.HEALTH !== data.result.onboardingStage) {
          this.router.navigate([onboardingUrl]);
        }
      }
    );
  }
}
