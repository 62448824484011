export enum PainEnum {
    EXCELLENT = 0,
    VERY_GOOD = 1,
    GOOD = 2,
    SLIGHTLY_GOOD = 3,
    ABOVE_AVERAGE = 4,
    AVERAGE = 5,
    BELOW_AVERAGE = 6,
    SLIGHTLY_BAD = 7,
    BAD = 8,
    VERY_BAD = 9,
    TERRIBLE = 10
}
