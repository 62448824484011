import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {enumNumericValues} from '../../../lib/utils';
import {DietEnum} from '../../shared/enums/diet.enum';
import {BloodGroupEnum} from '../../shared/enums/blood-group.enum';
import {AlcoholConsumptionEnum} from '../../shared/enums/alcohol-consumption.enum';
import {SpectacleEnum} from '../../shared/enums/spectacle.enum';
import {SportEnum} from '../../shared/enums/sport.enum';
import {YesNoEnum} from '../../shared/enums/yesno.enum';
import {Profile} from '../../core/profile/profile.model';

@Component({
  selector: 'pat-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  @Input() profile: Profile;
  @Input() showHeaders = true;
  @Output() profileChange = new EventEmitter<Profile>();

  alcohols = [];
  bloodGroups = [];
  diets = [];
  hearingAids = [];
  organDonors = [];
  smokers = [];
  selectedAlcohol;
  selectedBloodGroup;
  selectedDiet;
  selectedHearingAid;
  selectedOrganDonor;
  selectedSmoker;
  selectedSpectacle;
  selectedSport;
  spectacles = [];
  sports = [];

  isLoaded = false;

  ngOnInit() {
    this.initAlcoholConsumptions();
    this.initBloodGroups();
    this.initDiets();
    this.initHearingAids();
    this.initOrganDonors();
    this.initSmokers();
    this.initSpectacles();
    this.initSports();

    this.isLoaded = true;
  }

  isEqualId(a: any, b: any): boolean {
    return a && b && a.id === b.id;
  }

  onProfileChange(fieldName: string, fieldValue: any) {
    this.profile[fieldName] = fieldValue ? fieldValue.value : null;

    this.profileChange.emit(this.profile);
  }

  private initAlcoholConsumptions() {
    this.alcohols = [{id: 1, label: 'noAnswer', value: null}];
    for (const value of enumNumericValues(AlcoholConsumptionEnum)) {
      this.alcohols.push({id: (this.alcohols.length + 1), label: 'codes.alcohol.' + value, value: +value});
    }

    if (this.profile.alcohol === null) {
      this.selectedAlcohol = this.alcohols[0];
    } else {
      this.selectedAlcohol = this.alcohols.find(alcohol => alcohol.value === this.profile.alcohol);
    }
  }

  private initBloodGroups() {
    this.bloodGroups = [{id: 1, label: 'noAnswer', value: null}];
    for (const value of enumNumericValues(BloodGroupEnum)) {
      this.bloodGroups.push({id: (this.bloodGroups.length + 1), label: 'codes.bloodGroup.' + value, value: +value});
    }

    if (this.profile.bloodGroup === null) {
      this.selectedBloodGroup = this.bloodGroups[0];
    } else {
      this.selectedBloodGroup = this.bloodGroups.find(bloodGroup => bloodGroup.value === this.profile.bloodGroup);
    }
  }

  private initDiets() {
    this.diets = [{id: 1, label: 'noAnswer', value: null}];
    for (const value of enumNumericValues(DietEnum)) {
      this.diets.push({id: (this.diets.length + 1), label: 'codes.diet.' + value, value: +value});
    }

    if (this.profile.diet === null) {
      this.selectedDiet = this.diets[0];
    } else {
      this.selectedDiet = this.diets.find(diet => diet.value === this.profile.diet);
    }
  }

  private initHearingAids() {
    this.hearingAids = [{id: 1, label: 'noAnswer', value: null}];
    for (const value of enumNumericValues(YesNoEnum)) {
      this.hearingAids.push({id: (this.hearingAids.length + 1), label: 'codes.yesno.' + value, value: +value});
    }

    if (this.profile.hearingAid === null) {
      this.selectedHearingAid = this.hearingAids[0];
    } else {
      this.selectedHearingAid = this.hearingAids.find(hearingAid => hearingAid.value === this.profile.hearingAid);
    }
  }

  private initOrganDonors() {
    this.organDonors = [{id: 1, label: 'noAnswer', value: null}];
    for (const value of enumNumericValues(YesNoEnum)) {
      this.organDonors.push({id: (this.organDonors.length + 1), label: 'codes.yesno.' + value, value: +value});
    }

    if (this.profile.organDonor === null) {
      this.selectedOrganDonor = this.organDonors[0];
    } else {
      this.selectedOrganDonor = this.organDonors.find(organDonor => organDonor.value === this.profile.organDonor);
    }
  }

  private initSmokers() {
    this.smokers = [{id: 1, label: 'noAnswer', value: null}];
    for (const value of enumNumericValues(YesNoEnum)) {
      this.smokers.push({id: (this.smokers.length + 1), label: 'codes.yesno.' + value, value: +value});
    }

    if (this.profile.smoker === null) {
      this.selectedSmoker = this.smokers[0];
    } else {
      this.selectedSmoker = this.smokers.find(smoker => smoker.value === this.profile.smoker);
    }
  }

  private initSpectacles() {
    this.spectacles = [{id: 1, label: 'noAnswer', value: null}];
    for (const value of enumNumericValues(SpectacleEnum)) {
      this.spectacles.push({id: (this.spectacles.length + 1), label: 'codes.spectacle.' + value, value: +value});
    }

    if (this.profile.spectacle === null) {
      this.selectedSpectacle = this.spectacles[0];
    } else {
      this.selectedSpectacle = this.spectacles.find(spectacle => spectacle.value === this.profile.spectacle);
    }
  }

  private initSports() {
    this.sports = [{id: 1, label: 'noAnswer', value: null}];
    for (const value of enumNumericValues(SportEnum)) {
      this.sports.push({id: (this.sports.length + 1), label: 'codes.sport.' + value, value: +value});
    }

    if (this.profile.sport === null) {
      this.selectedSport = this.sports[0];
    } else {
      this.selectedSport = this.sports.find(sport => sport.value === this.profile.sport);
    }
  }
}
