import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DocumentService} from '../document.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DocumentHolder} from '../document.model';
import {Operation} from '../../../core/health/operation.model';
import {Medication} from '../../../core/health/medication.model';
import {Disease} from '../../../core/health/disease.model';
import {EntityLinksComponent} from '../../health/entity-links/entity-links.component';
import {Usage} from '../../../core/usage/usage.model';
import {UsageTypeEnum} from '../../../shared/enums/usage-type.enum';
import {UsageAlertComponent} from '../../../shared/usage/usage-alert/usage-alert.component';
import {NgbActiveModal, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {hasValue} from '../../../../lib/validator';
import {DocumentTypeEnum} from '../document-type.enum';
import {BaseComponent} from '../../../../base/base-component';
import {ProfileService} from '../../../core/profile/profile.service';
import {LocaleResolverService} from '../../../core/locale/locale-resolver.service';
import {fromNgbDateStructToDate, toNgbDateStruct} from '../../../../lib/dates';
import * as moment from 'moment-timezone';
import {NgbDateDynamicParserFormatter} from '../../../../lib/ngb-date-dynamic-parser-formatter';
import {SubscriptionService} from '../../../core/subscription/subscription.service';
import {SubscriptionPlanCodeEnum} from '../../../shared/enums/subscription-plan-code.enum';
import {TranslatedToastrService} from '../../../core/translated-toastr/translated-toastr.service';

@Component({
    selector: 'app-document-edit',
    templateUrl: './document-edit.component.html',
    styleUrls: ['./document-edit.component.scss'],
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateDynamicParserFormatter }
    ]
})

export class DocumentEditComponent extends BaseComponent implements OnInit {
    @Input() document: DocumentHolder;
    @Input() documentTypes = [
        {label: 'codes.documentType.' + DocumentTypeEnum.DOCTORS_LETTER_OR_DISCHARGE_LETTER, value: DocumentTypeEnum.DOCTORS_LETTER_OR_DISCHARGE_LETTER},
        {label: 'codes.documentType.' + DocumentTypeEnum.LABORATORY_RESULTS, value: DocumentTypeEnum.LABORATORY_RESULTS},
        {label: 'codes.documentType.' + DocumentTypeEnum.EXAMINATION_FINDINGS, value: DocumentTypeEnum.EXAMINATION_FINDINGS},
        {label: 'codes.documentType.' + DocumentTypeEnum.PHOTO, value: DocumentTypeEnum.PHOTO},
        {label: 'codes.documentType.' + DocumentTypeEnum.X_RAYS_CT_MRI, value: DocumentTypeEnum.X_RAYS_CT_MRI},
        {label: 'codes.documentType.' + DocumentTypeEnum.BLOOD_GROUP_DETECTION, value: DocumentTypeEnum.BLOOD_GROUP_DETECTION},
        {label: 'codes.documentType.' + DocumentTypeEnum.ORGAN_DONATION_CARD, value: DocumentTypeEnum.ORGAN_DONATION_CARD},
        {label: 'codes.documentType.' + DocumentTypeEnum.VACCINATION_CARD, value: DocumentTypeEnum.VACCINATION_CARD},
        {label: 'codes.documentType.' + DocumentTypeEnum.LIVING_WILL, value: DocumentTypeEnum.LIVING_WILL},
        {label: 'codes.documentType.' + DocumentTypeEnum.ALLERGY_PASSPORT, value: DocumentTypeEnum.ALLERGY_PASSPORT},
        {label: 'codes.documentType.' + DocumentTypeEnum.IMPLANT_CARD, value: DocumentTypeEnum.IMPLANT_CARD},
        {label: 'codes.documentType.' + DocumentTypeEnum.GLASSES_OR_CONTACT_LENS_PASSPORT, value: DocumentTypeEnum.GLASSES_OR_CONTACT_LENS_PASSPORT},
        {label: 'codes.documentType.' + DocumentTypeEnum.PASSPORT, value: DocumentTypeEnum.PASSPORT},
        {label: 'codes.documentType.' + DocumentTypeEnum.COVID_19_TEST, value: DocumentTypeEnum.COVID_19_TEST},
        {label: 'codes.documentType.' + DocumentTypeEnum.IMMUNISATION, value: DocumentTypeEnum.IMMUNISATION},
        {label: 'codes.documentType.' + DocumentTypeEnum.OTHER, value: DocumentTypeEnum.OTHER}
    ];
    @Input() personIdParam: string;
    @Input() readOnly = false;
    @Output() documentSaved = new EventEmitter<boolean>();
    @ViewChild('entityLinks', {static: true}) entityLinks: EntityLinksComponent;
    @ViewChild('usageAlert') usageAlert: UsageAlertComponent;
    date: NgbDateStruct;
    docType: number;
    file: any;
    fModel: any;
    isSubmitted = false;
    loadedDiseases: Disease[] = [];
    loadedMedications: Medication[] = [];
    loadedOperations: Operation[] = [];
    loading = false;
    selectedDiseases: Disease[] = [];
    selectedMedications: Medication[] = [];
    selectedOperations: Operation[] = [];
    title: string;
    valid = true;
    usage: Usage;
    UsageTypeEnum = UsageTypeEnum;

    constructor(
      private activeModal: NgbActiveModal,
      protected docService: DocumentService,
      protected route: ActivatedRoute,
      protected router: Router,
      protected translatedToastrService: TranslatedToastrService,
      protected ref: ChangeDetectorRef,
      protected profileService: ProfileService,
      protected localeResolverService: LocaleResolverService,
      private subscriptionService: SubscriptionService,
    ) {
        super(profileService, localeResolverService, ref);
    }

    onFileChange(event) {
        if (event.target.files.length > 0) {
            this.file = event.target.files[0];
        }
    }

    ngOnInit() {
        // disable MRT for non PREMIUM Users
        this.subscriptionService.getMySubscription().subscribe(
          res => {
              const subscription = res.result;
              if (subscription && subscription.planCode !== SubscriptionPlanCodeEnum.PREMIUM) {
                  this.documentTypes = this.documentTypes.filter(documentType => documentType.value !== DocumentTypeEnum.X_RAYS_CT_MRI);
                  this.ref.markForCheck();
              }
          }
        );

        if (!this.document) {
            this.document = new DocumentHolder();
            const now = moment();
            this.date = { day: now.date(), month: (now.month() + 1), year: now.year() };
        } else {
            this.loadedDiseases = this.document.diseases;
            this.loadedMedications = this.document.medications;
            this.loadedOperations = this.document.operations;

            this.selectedDiseases = this.document.diseases;
            this.selectedMedications = this.document.medications;
            this.selectedOperations = this.document.operations;

            this.date = toNgbDateStruct(this.document.date);
        }
        this.ref.markForCheck();
    }

    onDateChange(date: NgbDateStruct) {
        this.date = date;
    }

    private successSave() {
        this.translatedToastrService.info('message.success.document.update');
        this.resetLinks();
        this.entityLinks.resetSelects();
        this.close(true);
    }

    private errorSave() {
        this.translatedToastrService.error('message.error.document.update');
        this.resetLinks();
        this.entityLinks.resetSelects();
        this.close(false);
    }

    onSubmitDocument() {

        this.isSubmitted = true;

        if (this.document.title && (this.document.id || this.file) && this.valid) {

            this.document.date = fromNgbDateStructToDate(this.date);

            const docHolder = new DocumentHolder();
            docHolder.docType = this.document.docType;
            docHolder.date = this.document.date;
            docHolder.title = this.document.title;
            docHolder.visible = this.document.visible;
            docHolder.favourite = this.document.favourite;
            docHolder.emergencyCardVisible = this.document.emergencyCardVisible;
            docHolder.diseaseIds = this.selectedDiseases.map(e => e.id);
            docHolder.operationIds = this.selectedOperations.map(e => e.id);
            docHolder.medicationIds = this.selectedMedications.map(e => e.id);

            if (!hasValue(this.document.id)) {

                const formData: FormData = new FormData();
                formData.append('file', this.file); // binary content
                formData.append('metadata', JSON.stringify(docHolder));

                this.docService.createDocument(this.personIdParam, formData).subscribe(resp => {
                    this.successSave();
                }, err => {
                    this.errorSave();
                });
            } else {

                docHolder.id = this.document.id;
                this.docService.updateDocument(this.personIdParam, docHolder).subscribe(resp => {
                    this.successSave();
                }, err => {
                    this.errorSave();
                });
            }
        }
    }

    public close(saved: boolean) {
        this.documentSaved.emit(saved);
        this.activeModal.close(saved);
    }

    resetLinks() {
        this.selectedDiseases = [];
        this.selectedOperations = [];
        this.selectedMedications = [];
        this.loadedDiseases = [];
        this.loadedOperations = [];
        this.loadedMedications = [];
    }

    diseasesPopulated(diseases) {
        this.selectedDiseases = diseases;
    }

    onEmergencyCardVisibleChange(emergencyCardVisible: boolean) {
        this.document.emergencyCardVisible = emergencyCardVisible;
    }

    onFavouriteChange(favourite: boolean) {
        this.document.favourite = favourite;
    }

    onVisibleChange(visible: boolean) {
        this.document.visible = visible;
    }

    operationsPopulated(operations) {
        this.selectedOperations = operations;
    }

    medicationsPopulated(medications) {
        this.selectedMedications = medications;
    }

    setValid(valid) {
        this.valid = valid;
        this.ref.markForCheck();
    }

    setReadonly(readonly) {
        this.readOnly = readonly;
        this.ref.markForCheck();
    }

    resetUsageAlert() {
        this.usageAlert.loadsUsage();
    }
}
