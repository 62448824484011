import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CityService} from '../../../core/location/city.service';
import {Address} from '../../../core/address/address.model';
import {Location} from '../../../core/location/location.model';
import {LocationComponent} from '../../location/location.component';

@Component({
  selector: 'app-address-edit',
  templateUrl: './address-edit.component.html',
  styleUrls: ['./address-edit.component.scss']
})
export class AddressEditComponent implements OnInit {

  @Input() address: Address;
  @Output() addressChange = new EventEmitter<Address>();
  @ViewChild('appLocation', { static: true }) locationComponent: LocationComponent;
  addressGroup: FormGroup;
  location: Location;
  locationValid: boolean;
  validate = false;

  constructor(
    private cityService: CityService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    const isNewAddress = !this.address;
    this.address = (isNewAddress ? new Address() : this.address);
    const location = new Location();
    location.city = (this.address && this.address.city) ? this.address.city : undefined;
    location.country = (this.address && this.address.country) ? this.address.country : undefined;
    this.location = location;

    this.addressGroup = this.fb.group({
      streetLine1: ['', Validators.required],
      streetLine2: [''],
      zip: ['', Validators.required],
    });

    this.addressGroup.patchValue(this.address);

    this.onAddressChanges();
  }

  handleLocationSelect(location: Location) {
    this.location = location;
  }

  handleLocationValid(valid: boolean) {
    this.locationValid = valid;
    this.addressGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});
  }

  hasAddressChanged(): boolean {
    return (
      this.address.city !== this.location.city ||
      this.address.countryCode !== this.location.country.code ||
      this.address.streetLine1 !== this.addressGroup.get('streetLine1').value ||
      this.address.streetLine2 !== this.addressGroup.get('streetLine2').value ||
      this.address.zip !== this.addressGroup.get('zip').value
    );
  }

  private populateAddress() {
    this.address.streetLine1 = this.addressGroup.get('streetLine1').value;
    this.address.streetLine2 = this.addressGroup.get('streetLine2').value;
    this.address.zip = this.addressGroup.get('zip').value;

    // from location component
    this.address.country = this.location.country;
    this.address.countryCode = this.location.country.code;
    this.address.city = this.location.city;
  }

  private onAddressChanges() {
    this.addressGroup.valueChanges.subscribe(val => {
      if (this.addressGroup.valid && this.locationValid) {
        if (this.hasAddressChanged()) {
          this.populateAddress();
          this.addressChange.emit(this.address);
        }
      }
    });
  }

  checkValidity() {
    this.validate = true;
    this.addressGroup.get('streetLine1').markAsTouched({ onlySelf: true });
    this.addressGroup.get('zip').markAsTouched({ onlySelf: true });

    this.locationComponent.checkValidity();
  }
}
