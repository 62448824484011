import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {BloodGlucoseService} from '../../../../core/measurements/blood-glucose.service';
import * as moment from 'moment-timezone';
import {CalendarView} from 'angular-calendar';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import * as chartConfig from '../../../../shared/charts/chart.config';
import {startEndDates} from '../../../../../lib/dates';
import {take} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {LocaleResolverService} from '../../../../core/locale/locale-resolver.service';
import {hasValue} from '../../../../../lib/validator';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-blood-glucose-chart',
    templateUrl: './blood-glucose-chart.component.html',
    styleUrls: ['./blood-glucose-chart.component.scss']
})
export class BloodGlucoseChartComponent implements OnInit {
    personIdParam: string;
    locale: string;

    view: string = CalendarView.Month;
    viewDate: Date = new Date();

    start: any;
    end: any;

    stats: any;

    unit: number;
    unitLabel: string;

    values: any[];
    minValues: any[];
    maxValues: any[];

    translatedLabel = '-';

    chartColors = chartConfig.chartColors;
    chartData: ChartDataSets[] = [{ data: [] }];
    chartOptions: ChartOptions;
    chartType: ChartType = 'line';

    constructor(
        private bloodGlucoseService: BloodGlucoseService,
        private route: ActivatedRoute,
        private ref: ChangeDetectorRef,
        private translateService: TranslateService,
        private localeResolver: LocaleResolverService
    ) {
    }

    ngOnInit() {
        this.locale = this.localeResolver.obtainLocale();
        this.localeResolver.localeSubject.subscribe(v => {
            if (v) {
                this.locale = v;
            }
        });

        this.translatedLabel = this.translateService.instant('journal.bloodGlucose.title');
        this.ref.markForCheck();

        const routeParams$ = this.route.params.pipe(take(1));
        routeParams$.subscribe(r => {
            this.personIdParam = r['id'];
            this.fetchValues();
        });
    }

    fetchValues() {
        if (!hasValue(this.personIdParam)) {
            return;
        }

        const dates = startEndDates('month', this.viewDate);
        this.start = dates.start;
        this.end = dates.end;

        this.setChartOption(this.start, this.end, this);


        this.bloodGlucoseService.listBloodGlucoses(this.personIdParam, this.unit, null, null, null, this.start, this.end, 'asc')
            .subscribe(r => {

                const values = r.results;

                this.values = values.map(e => {
                    return { x: e.date, y: e.value };
                });

                this.populateChartData(this.values);
                this.ref.markForCheck();
            });
    }

    markForCheck() {
        this.fetchValues();
        this.ref.markForCheck();
    }

    private populateChartData(val) {
        this.chartData = [
            {
                data: val,
                label: this.translatedLabel,
                pointRadius: 7,
            }
        ];
    }

    /*private populateChartData(max, min) {
        this.chartData = [
            {
                data: max,
                label: 'Max ' + this.unitLabel,
                pointRadius: 7,
            },
            {
                data: min,
                label: 'Min ' + this.unitLabel,
                pointRadius: 7,
            }
        ];
    }*/

    private setChartOption(min, max, ref) {
        this.chartOptions = {
            animation: {
                duration: 1000,
                easing: 'easeOutBack'
            },
            hover: {
                animationDuration: 1000,
            },
            responsiveAnimationDuration: 1000,
            responsive: true,

            scales: {
                xAxes: [{
                    type: 'time',
                    distribution: 'series',
                    position: 'bottom',
                    time: {
                        unit: 'week',
                        tooltipFormat: 'LLL',
                        displayFormats: {
                            day: 'dd'
                        },
                        min: min,
                        max: max
                    },
                    scaleLabel: {
                        display: true,
                    }
                }],
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }]
            },

            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) {
                        return Number(tooltipItem.yLabel).toFixed(1) + ' ' + ref.unitLabel;
                    }
                }
            }
        };
    }

    addDate(start, addDays) {
        return moment(start).add(addDays, 'days').format('LL');
    }
}
