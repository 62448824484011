import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {OperationService} from '../../../../core/health/operation.service';
import {DocumentHolder} from '../../../documents/document.model';
import {Operation} from '../../../../core/health/operation.model';
import {NgbActiveModal, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {Person} from '../../../../core/person/person.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {fromNgbDateStructToDate, toNgbDateStruct} from '../../../../../lib/dates';
import {LocaleResolverService} from '../../../../core/locale/locale-resolver.service';
import {BaseComponent} from '../../../../../base/base-component';
import {NgbDateDynamicParserFormatter} from '../../../../../lib/ngb-date-dynamic-parser-formatter';
import {Medication} from '../../../../core/health/medication.model';
import {Disease} from '../../../../core/health/disease.model';
import {EntityLinksComponent} from '../../entity-links/entity-links.component';
import {UsageAlertComponent} from '../../../../shared/usage/usage-alert/usage-alert.component';
import {UsageTypeEnum} from '../../../../shared/enums/usage-type.enum';
import {CustomValidators} from 'ngx-custom-validators';
import * as moment from 'moment-timezone';
import {ProfileService} from '../../../../core/profile/profile.service';
import {OperationTypeEnum} from '../../../../shared/enums/operation-type.enum';
import {TranslatedToastrService} from '../../../../core/translated-toastr/translated-toastr.service';

@Component({
  selector: 'app-operation-edit',
  templateUrl: './operation-edit.component.html',
  styleUrls: ['./operation-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateDynamicParserFormatter }
  ]
})
export class OperationEditComponent extends BaseComponent implements OnInit {
  @Input() operation: Operation;
  @Input() personIdParam: string;
  @Input() readOnly = false;
  @Output() saved = new EventEmitter<boolean>();
  @ViewChild('entityLinks') entityLinks: EntityLinksComponent;
  @ViewChild('docUsageAlert') docUsageAlert: UsageAlertComponent;
  date: NgbDateStruct;
  end: NgbDateStruct;
  isSubmitted = false;
  isValid = true;
  loadedDiseases: Disease[] = [];
  loadedDocuments: DocumentHolder[] = [];
  loadedMedications: Medication[] = [];
  operationFormGroup: FormGroup;
  requester: Person;
  selectedDiseases: Disease[] = [];
  selectedDocuments: DocumentHolder[] = [];
  selectedMedications: Medication[] = [];
  tillNow = false;
  types = [
    {label: 'codes.operation.' + OperationTypeEnum.ORDINATION, value: OperationTypeEnum.ORDINATION},
    {label: 'codes.operation.' + OperationTypeEnum.HOSPITAL, value: OperationTypeEnum.HOSPITAL},
    {label: 'codes.operation.' + OperationTypeEnum.STATIONARY, value: OperationTypeEnum.STATIONARY},
    {label: 'codes.operation.' + OperationTypeEnum.REHABILITATION_SPA, value: OperationTypeEnum.REHABILITATION_SPA},
    {label: 'codes.operation.' + OperationTypeEnum.EMERGENCY, value: OperationTypeEnum.EMERGENCY},
    {label: 'codes.operation.' + OperationTypeEnum.HOME_CARE_VISIT, value: OperationTypeEnum.HOME_CARE_VISIT},
    {label: 'codes.operation.' + OperationTypeEnum.OTHER, value: OperationTypeEnum.OTHER},
  ];
  UsageTypeEnum = UsageTypeEnum;

  constructor(
    public activeModal: NgbActiveModal,
    public translatedToastrService: TranslatedToastrService,
    private fb: FormBuilder,
    private operationService: OperationService,
    protected profileService: ProfileService,
    protected localeResolverService: LocaleResolverService,
    protected ref: ChangeDetectorRef,

  ) {
    super(profileService, localeResolverService, ref);
  }

  ngOnInit() {
    super.ngOnInit();

    // init form group
    this.operationFormGroup = this.fb.group({
      id: [null],
      clinic: [''],
      date: ['', Validators.compose([Validators.required, CustomValidators.date])],
      doctor: [null],
      end: [null, CustomValidators.date],
      emergencyCardVisible: [null],
      favourite: [null],
      notes: [''],
      title: ['', Validators.required],
      type: [null],
      visible: [null],
    });

    this.initDate();

    if (!this.operation) {
      this.operation = new Operation();
      this.ref.markForCheck();
    } else {
      this.loadedDiseases = this.operation.diseases;
      this.loadedDocuments = this.operation.documents;
      this.loadedMedications = this.operation.medications;

      this.selectedDiseases = this.operation.diseases;
      this.selectedDocuments = this.operation.documents;
      this.selectedMedications = this.operation.medications;

      this.initForm();
    }
  }

  close(saved: boolean) {
    this.saved.emit(saved);
    this.activeModal.close(saved);
  }

  diseasesPopulated(diseases) {
    this.selectedDiseases = diseases;
  }

  documentsPopulated(documents) {
    this.selectedDocuments = documents;
  }

  medicationsPopulated(medications) {
    this.selectedMedications = medications;
  }

  onDateChange(date: NgbDateStruct) {
    this.date = date;
    this.operationFormGroup.controls['date'].setValue(fromNgbDateStructToDate(this.date));
    this.ref.markForCheck();
  }

  onEmergencyCardVisibleChange(emergencyCardVisible: boolean) {
    this.operationFormGroup.controls['emergencyCardVisible'].setValue(emergencyCardVisible);
  }

  onEndChange(end: NgbDateStruct) {
    this.end = end;
    this.operationFormGroup.controls['end'].setValue(fromNgbDateStructToDate(this.end));
    this.ref.markForCheck();
  }

  onFavouriteChange(favourite: boolean) {
    this.operationFormGroup.controls['favourite'].setValue(favourite);
  }

  onTillNowChange(tillNow: boolean) {
    this.tillNow = tillNow;
    this.operationFormGroup.controls['end'].setValue(this.tillNow ? null : fromNgbDateStructToDate(this.end));
    this.ref.markForCheck();
  }

  onTypeChange() {
    this.ref.markForCheck();
  }

  onVisibleChange(visible: boolean) {
    this.operationFormGroup.controls['visible'].setValue(visible);
  }

  save() {
    this.isSubmitted = true;
    this.operationFormGroup.controls['date'].setValue(fromNgbDateStructToDate(this.date));
    this.operationFormGroup.controls['end'].setValue(this.tillNow ? null : fromNgbDateStructToDate(this.end));

    if (this.operationFormGroup.valid && this.isValid) {
      this.operation = this.operationFormGroup.value;

      this.operation.diseaseIds = this.selectedDiseases.map(e => e.id);
      this.operation.documentIds = this.selectedDocuments.map(e => e.id);
      this.operation.medicationIds = this.selectedMedications.map(e => e.id);

      if (this.operation.id) {
        // updating existing operation
        this.operationService
          .updateOperation(this.operation.id, this.operation)
          .subscribe(
            res => {
              this.isSubmitted = false;
              this.saved.emit(true);

              this.translatedToastrService.success('message.success.operation.update');
              this.close(true);
            },
            () => this.translatedToastrService.error('message.error.operation.update')
          );
      } else {
        // create new operation
        this.operationService
          .createOperation(this.personIdParam, this.operation)
          .subscribe(res => {
              this.entityLinks.resetSelects();
              this.isSubmitted = false;
              this.resetForm();
              this.saved.emit(true);

              this.translatedToastrService.success('message.success.operation.update');
              this.close(true);
            },
            () => this.translatedToastrService.error('message.error.operation.update')
          );
      }
    }
  }

  private initDate() {
    const now = moment();
    this.date = { day: now.date(), month: (now.month() + 1), year: now.year() };
  }

  private initForm() {
    this.date = toNgbDateStruct(this.operation.date);
    this.end = toNgbDateStruct(this.operation.end);
    this.tillNow = !this.operation.end;
    this.operationFormGroup.patchValue(this.operation);
    if (this.readOnly) {
      this.operationFormGroup.disable();
    }
    this.ref.markForCheck();
  }

  private resetForm() {
    this.operationFormGroup.reset();
    this.initDate();
    this.end = null;
    this.tillNow = null;
    this.operation = new Operation();
    this.ref.markForCheck();
  }
}
