import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DocumentTypeEnum} from '../document-type.enum';
import {Person} from '../../../core/person/person.model';
import {DocumentService} from '../document.service';
import {ProfileService} from '../../../core/profile/profile.service';
import {ToastrService} from 'ngx-toastr';
import {DocumentListResponse} from '../../../core/response/response.model';
import {DocumentHolder} from '../document.model';
import {hasValue} from '../../../../lib/validator';
import {enumKeys} from '../../../../lib/utils';

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss']
})
export class DocumentUploadComponent implements OnInit {
  // TODO check if we need this component else remove
  @Input() documentType: DocumentTypeEnum;
  @Input() isRequired = false;
  @Input() readonly = false;
  @Input() showButtons = true;
  @Output() documentChange = new EventEmitter<DocumentHolder>();
  documents: DocumentHolder[] = [];
  document: DocumentHolder;
  personIdParam = 'me';
  requester: Person;

  enumKeys = enumKeys;
  DocumentTypeEnum = DocumentTypeEnum;

  file: any;
  fileModel: any;

  constructor(
    private documentService: DocumentService,
    private profileService: ProfileService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.profileService.getMyProfile().subscribe(r => {
      this.requester = r.result;
      this.loadDocuments();
    });
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
    }
  }

  remove() {
    this.documentService.deleteDocument(this.document.id).subscribe(
      () => {
        this.toastr.success('Document deleted', 'Success');

        this.documents = this.documents.filter(d => d.id !== this.document.id);
        this.documents = [...this.documents];

        this.setDocument();
      },
      () => this.toastr.error('Could not delete', 'Error')
    );
  }

  save() {
    if (this.document.title && (this.document.id || this.file)) {
      const document = new DocumentHolder();
      document.docType = this.document.docType;
      document.title = this.document.title;
      document.visible = this.document.visible;
      document.favourite = this.document.favourite;
      document.emergencyCardVisible = this.document.emergencyCardVisible;
      // document.diseaseIds = this.selectedDiseases.map(e => e.id);
      // document.operationIds = this.selectedOperations.map(e => e.id);
      // document.medicationIds = this.selectedMedications.map(e => e.id);

      if (!hasValue(this.document.id)) {
        const formData: FormData = new FormData();
        formData.append('file', this.file); // binary content
        formData.append('metadata', JSON.stringify(document));

        this.documentService.createDocument(this.personIdParam, formData).subscribe(
          () => this.loadDocuments(),
          () => this.toastr.error('Could not save', 'Error'));

      } else {
        document.id = this.document.id;
        this.documentService.updateDocument(this.personIdParam, document).subscribe(
          () => {},
          () => this.toastr.error('Could not save', 'Error')
        );
      }
    }
  }

  private loadDocuments() {
    this.documentService.listDocuments(this.personIdParam, this.documentType, null, null, null, null, null).subscribe(
      (documentList: DocumentListResponse) => {
          this.documents = documentList.results;

          this.setDocument();
        },
      () => {}
    );
  }

  private setDocument() {
    // select the first document
    if (this.documents.length) {
      this.document = this.documents[0];
    } else {
      this.document = new DocumentHolder();
      this.document.docType = this.documentType;
      this.document.file = null;
      this.document.title = '';
    }

    this.documentChange.emit(this.document);
  }
}
