import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PersonTypeEnum} from 'src/app/+modules/shared/enums/person-type.enum';
import {enumKeys} from 'src/app/lib/utils';

@Component({
  selector: 'app-person-type-selection',
  templateUrl: './person-type-selection.component.html',
  styleUrls: ['./person-type-selection.component.scss']
})
export class PersonTypeSelectionComponent implements OnInit {

  enumKeys = enumKeys;
  PersonTypeEnum = PersonTypeEnum;

  personTypeFormGroup: FormGroup;


  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.personTypeFormGroup = this.fb.group({
      personType: [null, [Validators.required]]
    });
  }

  save() {
    if (this.personTypeFormGroup.valid) {
      this.activeModal.close(parseInt(this.personTypeFormGroup.value.personType, 10));
    }
  }
  close() {
    this.activeModal.close(false);
    // go back
  }
}
