import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DocumentService} from '../document.service';

@Component({
    selector: 'app-document-viewer',
    templateUrl: './document-viewer.component.html',
    styleUrls: ['./document-viewer.component.scss']
})
export class DocumentViewerComponent implements OnInit {
    isLoaded: boolean;
    resources = [];
    private url: string;

    constructor(
        private docService: DocumentService,
        private route: ActivatedRoute,
    ) {
    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            const docId = params['docId'];
            const personId = params['personId'];
            const type = params['type'];
            const fileName = params['fileName'];

            this.url = this.docService.documentUrl(personId, docId, type, fileName);
            this.docService.downloadFile(this.url).subscribe(
                (res) => {
                    const that = this;
                    const reader = new FileReader();
                    reader.readAsDataURL(res);
                    reader.onloadend = function () {
                        const base64data = reader.result;
                        const base64result = (base64data as string).split(',')[1];
                        that.resources = [base64result];
                        that.isLoaded = true;
                    };
                }
            );
        });
    }

    onClickBack() {
        window.history.back();
    }
}
