export enum DocumentTypeEnum {
    DOCTORS_LETTER_OR_DISCHARGE_LETTER = 1,
    LABORATORY_RESULTS = 2,
    EXAMINATION_FINDINGS = 3,
    PHOTO = 4,
    X_RAYS_CT_MRI = 5,
    BLOOD_GROUP_DETECTION = 6,
    ORGAN_DONATION_CARD = 7,
    VACCINATION_CARD = 8,
    LIVING_WILL = 9,
    ALLERGY_PASSPORT = 10,
    IMPLANT_CARD = 11,
    GLASSES_OR_CONTACT_LENS_PASSPORT = 12,
    PASSPORT = 13,
    COVID_19_TEST = 14,
    IMMUNISATION = 34,
    OTHER = 99,
}
