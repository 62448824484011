import {BackendService} from '../backend/backend.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
  ExpertiseResponse,
  GeneralResponse,
  MedicListResponse,
  MedicOverviewResponse,
  PatientListResponse,
  PhotoResponse,
  SpecialtyListResponse
} from '../response/response.model';
import {createHttpParams} from '../../../lib/http-params';
import {Medical} from './medical.model';
import {LocaleResolverService} from '../locale/locale-resolver.service';
import {QueryFilter} from '../filters/query-filter.model';
import {ConnectionStatus} from './connection-status';
import {hasValue} from '../../../lib/validator';
import {BehaviorSubject} from 'rxjs';
import {Connection} from '../patient/patient.model';

@Injectable()
export class MedicalService extends BackendService {
  patientConnection: Connection;
  patientConnectionSubject: BehaviorSubject<Connection> = new BehaviorSubject<Connection>(null);

  constructor(
      protected http: HttpClient,
    protected localeResolverService: LocaleResolverService,
  ) {
    super(http, localeResolverService);
  }

  searchMedicsFulltext(personIdParam: string,
                       q: string,
                       lastId: number,
                       page: number,
                       pageSize: number,
                       connected: boolean = false,
                       queryFilters: QueryFilter[] = []) {
    return this.searchMedics(personIdParam, q, undefined, undefined, undefined, undefined, undefined, q,
      (connected ? 1 : null), lastId, page, pageSize, queryFilters
    );
  }

  searchMedics(personIdParam: string,
               name: string,
               street: string,
               city: string,
               zip: string,
               country: string,
               expertise: string,
               expdescription: string,
               status: number,
               lastId: number,
               page: number,
               pageSize: number,
               queryFilters: QueryFilter[] = []) {
    const httpHeaders = this.createHttpHeaders();

    let params: HttpParams = createHttpParams({
      name: name,
      street: street,
      city: city,
      zip: zip,
      country: country,
      expertise: expertise,
      expdescription: expdescription,
      status: status,
      lastId: lastId,
      page: page,
      pageSize: pageSize
    });

    for (const queryFilter of queryFilters) {
      params = params.append(queryFilter.name, String(queryFilter.value));
    }

    return this.http.get<MedicListResponse>(this.getApiUrl() + '/people/' + personIdParam + '/medics',
      (!params) ? {headers: httpHeaders} : {
      headers: httpHeaders,
      params: params
    });
  }

  getMedicSearchOverview(personIdParam: string, medicIdParam: string) {
    const httpHeaders = this.createHttpHeaders();
    return this.http.get<MedicOverviewResponse>(this.getApiUrl() + '/people/' + personIdParam + '/medics/' + medicIdParam + '/overview',
      {headers: httpHeaders});
  }

  listExpertise(q: string) {
    const httpHeaders = this.createHttpHeaders();

    let params = new HttpParams();
    if (q !== undefined && q !== null) {
      params = params.append('q', q.toString());
    }

    return this.http.get<ExpertiseResponse>(
        this.getApiUrl() + '/expertise', (params.keys().length > 0) ? { headers: httpHeaders, params: params } : { headers: httpHeaders });
  }

  requestConnection(medId: number) {
    const httpOptions = this.createHttpOptions();
    return this.http.post(this.getApiUrl() + '/connections', {dataVisible: true, medId: medId}, httpOptions);
  }

  changeConnection(connectionId: number, status: ConnectionStatus, dataVisible?: boolean) {
    const params = {
      status: status,
    };
    if (typeof dataVisible !== 'undefined') {
      params['dataVisible'] = dataVisible;
    }

    const httpOptions = this.createHttpOptions();
    return this.http.put(this.getApiUrl() + '/connections/' + connectionId, params, httpOptions);
  }

  markConnectionAsVerified(id: number) {
    const httpOptions = this.createHttpOptions();
    return this.http.post(this.getApiUrl() + '/connections/' + id + '/verified', {}, httpOptions);
  }

  getMedical(personId: number) {
    const httpHeaders = this.createHttpHeaders();
    return this.http.get<GeneralResponse>(this.getApiUrl() + '/people/' + personId + '/medical', {headers: httpHeaders});
  }

  getMedic() {
    const httpHeaders = this.createHttpHeaders();
    return this.http.get<GeneralResponse>(this.getApiUrl() + '/people/me/medic', {headers: httpHeaders});
  }

  saveMedic(medical: Medical) {
    const httpHeaders = this.createHttpHeaders();
    return this.http.put(this.getApiUrl() + '/people/me/medic', medical, {headers: httpHeaders});
  }

  saveMedical(personId: number, medical: Medical) {
    const httpHeaders = this.createHttpHeaders();
    return this.http.put(this.getApiUrl() + '/people/' + personId + '/medical', medical, {headers: httpHeaders});
  }

  getMedicOverview(personIdParam: string) {
    const httpHeaders = this.createHttpHeaders();
    return this.http.get<MedicOverviewResponse>(this.getApiUrl() + '/medics/' + personIdParam + '/overview', {headers: httpHeaders});
  }

  getMyExpertise() {
    const httpHeaders = this.createHttpHeaders();
    return this.http.get<ExpertiseResponse>(this.getApiUrl() + '/medics/me/expertise', {headers: httpHeaders});
  }

  getMySpecialties() {
    const httpHeaders = this.createHttpHeaders();
    return this.http.get<SpecialtyListResponse>(this.getApiUrl() + '/medics/me/specialties', {headers: httpHeaders});
  }

  saveMySpecialties(body) {
    const httpHeaders = this.createHttpHeaders();
    return this.http.put(this.getApiUrl() + '/medics/me/specialties', body, {headers: httpHeaders});
  }

  saveProfilePhoto(formData) {
    return this.http.put(
        this.getApiUrl() + '/medics/me/profilePhoto', formData, {withCredentials: true});
  }

  getMyPhoto() {
    const httpOptions = this.createHttpOptions();
    return this.http.get<PhotoResponse>(this.getApiUrl() + '/medics/me/photo', httpOptions);
  }

  deleteMedicPhoto() {
    const httpOptions = this.createHttpOptions();
    return this.http.delete(this.getApiUrl() + '/medics/me/profilePhoto', httpOptions);
  }

  createMedicPhotoPresignedPost(body) {
    const httpOptions = this.createHttpOptions();
    return this.http.post(this.getApiUrl() + '/medics/me/photo/presignedpost', body, httpOptions);
  }

  getMyPatients(id: string, connected: boolean, q: string, page: number, pageSize: number) {
    const httpHeaders = this.createHttpHeaders();

    let params = new HttpParams();
    if (id !== undefined && id !== null) {
      params = params.append('id', id.toString());
    }

    if (q !== undefined && q !== null) {
      params = params.append('q', q.toString());
    }

    if (page !== undefined && page !== null) {
      params = params.append('page', page.toString());
    }
    if (pageSize !== undefined && pageSize !== null) {
      params = params.append('pageSize', pageSize.toString());
    }
    if (connected !== undefined && connected !== null) {
      params = params.append('connected', connected.toString());
    }

    return this.http.get<PatientListResponse>(this.getApiUrl() + '/patients',
      (params.keys().length > 0) ? { headers: httpHeaders, params: params } : { headers: httpHeaders });
  }

  /**
   * Get the medics for the patient
   * @param personIdParam | Patient ID
   * @param status        | 0 ... pending, 1 ... connected
   * @param queryFilters  | dynamic filters
   */
  getPatientMedics(personIdParam: string, status: number = null, queryFilters: QueryFilter[] = []) {
    const httpHeaders = this.createHttpHeaders();
    let params = createHttpParams({status: status});

    for (const queryFilter of queryFilters) {
      if (hasValue(queryFilter.value)) {
        params = params.append(queryFilter.name, String(queryFilter.value));
      }
    }

    return this.http.get<MedicListResponse>(this.getApiUrl() + '/people/' + personIdParam + '/medics',
      (!params) ? {headers: httpHeaders} : {
        headers: httpHeaders,
        params: params
      });
  }

  setPatientConnection(connection: Connection) {
    this.patientConnection = connection;
    this.patientConnectionSubject.next(this.patientConnection);
  }

  unsetPatientConnection() {
    this.patientConnection = undefined;
    this.patientConnectionSubject.next(this.patientConnection);
  }
}
