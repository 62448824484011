import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../+modules/core/authentication/authentication.service';

@Component({
    selector: 'app-side-menu',
    templateUrl: './side-menu.component.html',
    styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

    isLoggedIn: boolean;

    constructor(
        private authService: AuthenticationService
    ) {
        this.authService.isLoggedIn.subscribe(v => {
            if (!this.isLoggedIn && v) {
            }
            this.isLoggedIn = v;
        });
    }

    ngOnInit() {
    }

}
