import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {CalendarView} from 'angular-calendar';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import * as chartConfig from '../../../../shared/charts/chart.config';
import {WeightService} from '../../../../core/measurements/weight.service';
import {startEndDates} from '../../../../../lib/dates';
import * as moment from 'moment-timezone';
import {ActivatedRoute} from '@angular/router';
import {take} from 'rxjs/operators';
import {LocaleResolverService} from '../../../../core/locale/locale-resolver.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-weight-chart',
    templateUrl: './weight-chart.component.html',
    styleUrls: ['./weight-chart.component.scss']
})
export class WeightChartComponent implements OnInit {
    @Input() maxWeight = 600;
    @Input() minWeight = 1;
    chartColors = chartConfig.chartColors;
    chartData: ChartDataSets[] = [{data: []}];
    chartOptions: ChartOptions;
    chartType: ChartType = 'line';
    end: any;
    locale: string;
    personIdParam: string;
    start: any;
    stats: any;
    translatedLabel = '-';
    values: any[];
    view: string = CalendarView.Month;
    viewDate: Date = new Date();

    constructor(
        private weightService: WeightService,
        private route: ActivatedRoute,
        private ref: ChangeDetectorRef,
        private localeResolver: LocaleResolverService,
        private translateService: TranslateService
    ) {
    }

    ngOnInit() {
        this.locale = this.localeResolver.obtainLocale();
        this.localeResolver.localeSubject.subscribe(v => {
            if (v) {
                this.locale = v;
            }
        });

        this.translatedLabel = this.translateService.instant('journal.weight.title');
        this.ref.markForCheck();

        const routeParams$ = this.route.params.pipe(take(1));
        routeParams$.subscribe(r => {
            this.personIdParam = r['id'];
            this.fetchValues();
        });
    }

    fetchValues() {
        const dates = startEndDates('month', this.viewDate);
        this.start = dates.start;
        this.end = dates.end;

        this.setChartOption(this.start, this.end, this);

        this.weightService.listWeights(this.personIdParam, null, null, null, this.start, this.end, 'asc')
            .subscribe(r => {
                const values = r.results;

                this.values = values.map(e => {
                    return {x: e.date, y: e.value};
                });

                this.populateChartData(this.values);
                this.ref.markForCheck();

            });
    }

    private populateChartData(val) {
        this.chartData = [
            {
                data: val,
                label: this.translatedLabel,
                pointRadius: 7,
            }
        ];
    }

    private setChartOption(min, max, ref) {
        this.chartOptions = {
            animation: {
                duration: 1000,
                easing: 'easeOutBack'
            },
            hover: {
                animationDuration: 1000,
            },
            responsiveAnimationDuration: 1000,
            responsive: true,

            scales: {
                xAxes: [{
                    type: 'time',
                    distribution: 'series',
                    position: 'bottom',
                    time: {
                        unit: 'week',
                        tooltipFormat: 'LLL',
                        displayFormats: {
                            day: 'dd'
                        },
                        min: min,
                        max: max
                    },
                    scaleLabel: {
                        display: true,
                    }
                }],
                yAxes: [{
                    ticks: {
                        // max: this.maxWeight,
                        // min: this.minWeight
                    }
                }]
            },

            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) {
                        return tooltipItem.yLabel + 'kg';
                    }
                }
            }
        };
    }

    addDate(start, addDays) {
        return moment(start).add(addDays, 'days').format('LL');
    }

}
