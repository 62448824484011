import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {MedakteIconTypes} from '../../icon/medakte-icon-types';
import {fromEvent} from 'rxjs';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss']
})
export class SearchFilterComponent implements AfterViewInit {
  @Input() isFullWidth = false;
  @Input() placeholder: string;
  @Input() search: string;
  @Output() searchChange = new EventEmitter<string>();
  @ViewChild('searchInput', {static: false}) searchInput: ElementRef;
  medakteIconTypes = MedakteIconTypes;
  // minSearchLength = 3;

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.searchInput) {
        if (this.search) {
          this.searchInput.nativeElement.value = this.search;
        }
      }

      fromEvent(this.searchInput.nativeElement, 'keyup')
        .pipe(
          debounceTime(300),
          distinctUntilChanged(),
          tap(() => {
            if (this.search !== this.searchInput.nativeElement.value.trim().toLowerCase()) {
              this.applySearch(this.searchInput.nativeElement.value);
            }
          })
        )
        .subscribe();
    }, 1);
  }

  onClickResetSearch() {
    this.searchInput.nativeElement.value = '';
    this.applySearch('');
  }

  applySearch(search: string) {
    this.search = search.trim().toLowerCase();
    this.searchChange.emit(this.search);

    // apply search after min length
    /*if (this.search.trim().length >= this.minSearchLength) {
      this.searchChange.emit(this.search);
    }*/
  }
}
