import {Injectable} from '@angular/core';
import {BackendService} from '../backend/backend.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {MessageRecipientListResponse, PersonResponse} from '../response/response.model';
import {Person} from './person.model';
import {LocaleResolverService} from '../locale/locale-resolver.service';
import {Observable} from 'rxjs';

@Injectable()
export class PersonService extends BackendService {

  constructor(
    protected http: HttpClient,
    protected localeResolverService: LocaleResolverService,
  ) {
    super(http, localeResolverService);
  }

  listMessageRecipients(query: string): Observable<MessageRecipientListResponse> {
    const httpHeaders = this.createHttpHeaders();
    const options: any = {
      headers: httpHeaders,
    };

    let params;
    if (query !== undefined && query !== null) {
      params = new HttpParams();
      params = params.append('q', query.toString());
      options.params = params;
    }
    return this.http.get<MessageRecipientListResponse>(
      this.getApiUrl() + '/people/me/messages/recipients',
      (!params) ? {headers: httpHeaders} : {headers: httpHeaders, params: params}
      );
  }

  getPerson(): Observable<PersonResponse> {
    const httpOptions = this.createHttpOptions();
    return this.http.get<PersonResponse>(this.getApiUrl() + '/people/me', httpOptions);
  }

  updatePerson(person: Person) {
    const httpOptions = this.createHttpOptions();
    return this.http.put(this.getApiUrl() + '/people/me', person, httpOptions);
  }
}
