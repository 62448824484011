import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Immunisation} from '../../../core/health/immunisation.model';
import {formatDate} from '../../../../lib/formats';

@Component({
  selector: 'app-emergency-card-immunisations',
  templateUrl: './emergency-card-immunisations.component.html',
  styleUrls: ['./emergency-card-immunisations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmergencyCardImmunisationsComponent implements OnChanges, OnInit {
  @Input() immunisations: Immunisation[] = [];
  @Input() personIdParam: string;

  constructor(protected ref: ChangeDetectorRef) {
  }

  ngOnInit() {
    if (this.immunisations.length) {
      this.immunisations.forEach(immunisation => this.localizeImmunisation(immunisation));
      this.ref.markForCheck();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const change = changes.immunisations || undefined;
    if (change) {
      this.immunisations.forEach(immunisation => this.localizeImmunisation(immunisation));
      this.ref.markForCheck();
    }
  }
  private localizeImmunisation(immunisation: Immunisation) {
    immunisation.localized = {
      taken: '',
      created_at: '',
      date: ''
    };
    immunisation.localized.date = formatDate(immunisation.date);
  }
}
