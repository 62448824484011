import {DocumentHolder} from '../../patient/documents/document.model';
import {Creator} from '../creator/creator.model';
import {Disease} from './disease.model';
import {Medication} from './medication.model';

export class Operation {
  id: number;
  clinic: string;
  created_at: Date;
  creator: Creator;
  date: Date;
  doctor: string;
  emergencyCardVisible: boolean;
  end: Date;
  favourite: boolean;
  notes: string;
  title: string;
  type: number;
  visible: boolean;

  diseaseIds: number[];
  documentIds: number[];
  medicationIds: number[];

  diseases: Disease[];
  documents: DocumentHolder[];
  medications: Medication[];

  localized: {
    date: string;
    end: string;
  };
}
