import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Operation} from '../../../core/health/operation.model';
import {formatDate} from '../../../../lib/formats';

@Component({
  selector: 'app-emergency-card-operations',
  templateUrl: './emergency-card-operations.component.html',
  styleUrls: ['./emergency-card-operations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmergencyCardOperationsComponent implements OnChanges, OnInit {
  @Input() operations: Operation[] = [];
  @Input() personIdParam: string;

  constructor(protected ref: ChangeDetectorRef) {
  }

  ngOnInit() {
    if (this.operations.length) {
      this.operations.forEach(operation => this.localizeOperation(operation));
      this.ref.markForCheck();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const change = changes.operations || undefined;
    if (change) {
      this.operations.forEach(operation => this.localizeOperation(operation));
      this.ref.markForCheck();
    }
  }

  private localizeOperation(operation: Operation) {
    operation.localized = { date: formatDate(operation.date), end: formatDate(operation.end) };
  }
}
