import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Address} from './address.model';
import {LocaleResolverService} from '../locale/locale-resolver.service';
import {AddressListResponse} from '../response/response.model';
import {BackendService} from '../backend/backend.service';

@Injectable()
export class AddressService extends BackendService {

    constructor(
        protected http: HttpClient,
    protected localeResolverService: LocaleResolverService,
    ) {
        super(http, localeResolverService);
    }

    getWorkAddress() {
        const httpOptions = this.initHttpOptions();
        return this.http.get<AddressListResponse>(this.getApiUrl() + '/people/me/addresses?type=1', httpOptions);
    }

    getPermanentAddress(id: string) {
        const httpOptions = this.initHttpOptions();
        return this.http.get<AddressListResponse>(this.getApiUrl() + '/people/' + id + '/addresses?type=0', httpOptions);
    }

    updateAddress(address: Address) {
        const httpOptions = this.initHttpOptions();
        return this.http.put(this.getApiUrl() + '/people/me/addresses/' + address.id, address, httpOptions);
    }
}
