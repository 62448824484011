import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Profile} from '../../../core/profile/profile.model';
import {Router} from '@angular/router';
import {formatDateTime} from '../../../../lib/formats';
import {BloodPressure} from '../../../core/measurements/blood-pressure.model';
import {BloodPressureService} from '../../../core/measurements/blood-pressure.service';

@Component({
  selector: 'app-profile-blood-pressure',
  templateUrl: './profile-blood-pressure.component.html',
  styleUrls: ['./profile-blood-pressure.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileBloodPressureComponent implements OnInit {
  @Input() isVisible = true;
  @Input() latestBloodPressure: BloodPressure | undefined;
  @Input() personIdParam = 'me';
  @Input() profile: Profile;
  @Input() readOnly = false;
  unit = 'mmHg';

  constructor(private router: Router,
              private bloodPressureService: BloodPressureService,
              protected ref: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.latestBloodPressure === undefined) {
      this.bloodPressureService.loadLatestBloodPressure(this.personIdParam).subscribe(
        r => {
          this.latestBloodPressure = r.results.length > 0 ? r.results[0] : null;
          this.ref.markForCheck();
        },
        () => {
        }
      );
    }
  }

  getSubLabel(): string {
    let subLabel = '-';
    if (this.latestBloodPressure) {
      subLabel = formatDateTime(this.latestBloodPressure.date);
    }
    return subLabel;
  }

  onClick() {
    if (!this.readOnly && this.isVisible) {
      const url = ((this.personIdParam === 'me') ? '/people/' : '/medic/patients/') + this.personIdParam + '/measurements/blood-pressure';
      this.router.navigate([url]);
    }
  }
}
