import {Pipe, PipeTransform} from '@angular/core';
import {formatDate} from '../../../lib/formats';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {
  transform(value: Date, args?: any): any {
    return formatDate(value);
  }
}
