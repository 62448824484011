import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Disease} from '../../../core/health/disease.model';
import {DiseaseService} from '../../../core/health/disease.service';
import {LocaleResolverService} from '../../../core/locale/locale-resolver.service';
import {BaseComponent} from '../../../../base/base-component';
import {ProfileService} from '../../../core/profile/profile.service';
import {Router} from '@angular/router';
import {UsageTypeEnum} from '../../../shared/enums/usage-type.enum';
import {hasValue} from '../../../../lib/validator';
import {DiseaseEditComponent} from '../../health/diseases/disease-edit/disease-edit.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {UsageService} from '../../../core/usage/usage.service';
import {UsageItem} from '../../../core/usage/usage.model';

@Component({
  selector: 'app-patient-dashboard-diseases',
  templateUrl: './patient-dashboard-diseases.component.html',
  styleUrls: ['./patient-dashboard-diseases.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatientDashboardDiseasesComponent extends BaseComponent implements OnInit {
  @Input() isVisible = true;
  @Input() personIdParam: string;
  currentModal: NgbModalRef;
  diseases: Disease[] = [];
  isAddActive = true;
  titleLink = '';
  total = 0;
  usageItem: UsageItem;
  private pageSize = 3;

  constructor(private diseaseService: DiseaseService,
              private modal: NgbModal,
              private router: Router,
              private usageService: UsageService,
              protected profileService: ProfileService,
              protected localeResolverService: LocaleResolverService,
              protected ref: ChangeDetectorRef) {
    super(profileService, localeResolverService, ref);
  }

  ngOnInit() {
    super.ngOnInit();
    this.titleLink = ((this.personIdParam === 'me') ? '/people/' : '/medic/patients/') + this.personIdParam + '/health/diseases';
    this.loadDiseases();
    this.loadDiseaseLimits();
  }

  onAddChange() {
    this.showAddModal();
  }

  onClick(id: number) {
    const url = this.titleLink + '/' + id + '/view';
    this.router.navigate([url]);
  }

  private handleSaved(saved: boolean) {
    if (saved === true) {
      this.loadDiseases();
      this.loadDiseaseLimits();
    }
  }

  private loadDiseases() {
    this.diseaseService
      .listDiseases(this.personIdParam, null, null, 1, this.pageSize, null)
      .subscribe(
        resp => {
          this.total = resp.count;
          this.diseases = resp.results;
          this.diseases.forEach(disease => {
            disease.localized = { end: this.formatDate(disease.end), start: this.formatDate(disease.start) };
          });
          this.ref.markForCheck();
        },
        e => {
          console.log(e);
        }
      );
  }

  private loadDiseaseLimits() {
    this.usageService.getUsageItem(this.personIdParam, UsageTypeEnum.DISEASES).subscribe(res => {
      this.usageItem = res.result;
      if (this.usageItem) {
        // Limit Reached
        if (hasValue(this.usageItem.value) && hasValue(this.usageItem.limit) && this.usageItem.value >= this.usageItem.limit) {
          this.isAddActive = false;
        }

        // Insufficient Storage
        if (hasValue(this.usageItem.quota) && hasValue(this.usageItem.sumSize) && this.usageItem.sumSize >= this.usageItem.quota) {
          this.isAddActive = false;
        }

        this.ref.markForCheck();
      }
    });
  }

  private showAddModal() {
    setTimeout(() => {
      this.currentModal = this.modal.open(DiseaseEditComponent, {size: 'lg'});
      this.currentModal.componentInstance.personIdParam = this.personIdParam;
      this.currentModal.componentInstance.requester = this.requester;
      this.currentModal.result.then(saved => this.handleSaved(saved)).catch();
    }, 100);
  }
}
