import {Component, OnDestroy, OnInit} from '@angular/core';
import {RouteInfo} from '../../../core/routes/route-info.model';
import {NavigationEnd, Router} from '@angular/router';
import {PATIENT_ROUTE_INFOS} from '../../../core/routes/patient-route-infos';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-patient-side-navigation',
  templateUrl: './patient-side-navigation.component.html',
  styleUrls: ['./patient-side-navigation.component.scss']
})
export class PatientSideNavigationComponent implements OnDestroy, OnInit {
  activeMenuItem: RouteInfo;
  activeFirstLevelMenuItem: RouteInfo;
  activeSecondLevelMenuItem: RouteInfo;
  menuItems: RouteInfo[] = [];
  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    ) { }

  ngOnInit() {
    // deep copy
    this.menuItems = JSON.parse(JSON.stringify(PATIENT_ROUTE_INFOS));

    this.subscriptions.push(
      this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => this.setActiveMenuItemsFromRoute()),
    );

    this.setActiveMenuItemsFromRoute();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  hasSecondLevel(firstLevelMenuItem: RouteInfo): boolean {
    return firstLevelMenuItem.submenu.length > 0;
  }

  isShowSecondLevelMenuItems(firstLevelMenuItem: RouteInfo): boolean {
    return firstLevelMenuItem.submenu.length > 0 && (this.activeFirstLevelMenuItem === firstLevelMenuItem);
  }

  private setActiveMenuItemsFromRoute() {
    this.activeFirstLevelMenuItem = undefined;
    this.activeSecondLevelMenuItem = undefined;
    this.activeMenuItem = undefined;

    const currentRoute = this.router.url;
    const firstLevelMenuItem = this.menuItems.find(item => currentRoute.startsWith(item.path));
    if (firstLevelMenuItem) {
      this.activeFirstLevelMenuItem = firstLevelMenuItem;
      this.activeMenuItem = firstLevelMenuItem;

      const secondLevelMenuItem = firstLevelMenuItem.submenu.find(item => currentRoute.startsWith(item.path));
      if (secondLevelMenuItem) {
        this.activeSecondLevelMenuItem = secondLevelMenuItem;
        this.activeMenuItem = secondLevelMenuItem;
      }
    }
  }
}
