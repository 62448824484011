import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Profile} from '../../../core/profile/profile.model';
import {Router} from '@angular/router';
import {formatDateTime} from '../../../../lib/formats';
import {BodyTemperature} from '../../../core/measurements/body-temperature.model';
import {BodyTemperatureService} from '../../../core/measurements/body-temperature.service';

@Component({
  selector: 'app-profile-body-temperature',
  templateUrl: './profile-body-temperature.component.html',
  styleUrls: ['./profile-body-temperature.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileBodyTemperatureComponent implements OnInit {
  @Input() isVisible = true;
  @Input() latestBodyTemperature: BodyTemperature | undefined;
  @Input() personIdParam = 'me';
  @Input() profile: Profile;
  @Input() readOnly = false;
  unit = '°C';

  constructor(private router: Router,
              private bodyTemperatureService: BodyTemperatureService,
              protected ref: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.latestBodyTemperature === undefined) {
      this.bodyTemperatureService.loadLatestBodyTemperature(this.personIdParam).subscribe(
        r => {
          this.latestBodyTemperature = r.results.length > 0 ? r.results[0] : null;
          this.ref.markForCheck();
        },
        () => {
        }
      );
    }
  }

  getSubLabel(): string {
    let subLabel = '-';
    if (this.latestBodyTemperature) {
      subLabel = formatDateTime(this.latestBodyTemperature.date);
    }
    return subLabel;
  }

  onClick() {
    if (!this.readOnly && this.isVisible) {
      const url = ((this.personIdParam === 'me') ? '/people/' : '/medic/patients/') + this.personIdParam + '/measurements/body-temperature';
      this.router.navigate([url]);
    }
  }
}
