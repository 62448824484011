import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {BackendService} from '../+modules/core/backend/backend.service';
import {ConversationListResponse, GeneralResponse, MessageListResponse} from '../+modules/core/response/response.model';
import {ConversationMessage} from './conversation-message.model';
import {LocaleResolverService} from '../+modules/core/locale/locale-resolver.service';
import {AuthenticationService} from '../+modules/core/authentication/authentication.service';

const uuidv4 = require('uuid/v4');

@Injectable()
export class ChatService extends BackendService {

    messages: Subject<any>;
    isLoggedIn: boolean;

    constructor(
        private authService: AuthenticationService,
        protected http: HttpClient,

        protected localeResolverService: LocaleResolverService
    ) {
        super(http, localeResolverService);

        this.authService.isLoggedIn
            .subscribe(v => {
                if (this.isLoggedIn !== v && v === true) {
                    this.initSubject();
                }
                this.isLoggedIn = v;
            });
    }

    initSubject() {
        // this.messages = <Subject<any>>this.wsService
        //   .getWebsocketSubject()
        //   .pipe(map((response: any): any => {
        //     return response;
        //   }));
    }

    /**
     * Sends message by websocket
     *
     * @param msg: string
     */
    sendMessage(msg: any) {
        if (this.isLoggedIn) {
            this.messages.next(msg);
        }
    }

    /**
     * Factory method for chat message
     *
     * @param recipientId: number
     * @param body: string
     * @param bodyType: number = 0
     */
    newChatMessage(recipientId: number, body: string, bodyType = 0): ConversationMessage {

        const message = new ConversationMessage();
        message.uid = uuidv4();
        message.bodyType = bodyType;
        message.body = body;
        message.created_at = new Date();
        message.recipient = recipientId;

        return message;
    }

    loadConversations() {
        const httpOptions = this.createHttpOptions();
        return this.http.get<ConversationListResponse>(this.getApiUrl() + '/people/me/conversations', httpOptions);
    }

    getUnseenChatMessages() {
        const httpOptions = this.createHttpOptions();
        return this.http.get<GeneralResponse>(this.getApiUrl() + '/people/me/chat/messages/unseen', httpOptions);
    }

    loadChatConversations() {
        const httpOptions = this.createHttpOptions();
        return this.http.get<ConversationListResponse>(this.getApiUrl() + '/people/me/chat', httpOptions);
    }

    loadChatConversationsOverview() {
        const httpOptions = this.createHttpOptions();
        return this.http.get<ConversationListResponse>(this.getApiUrl() + '/people/me/chat/overview', httpOptions);
    }

    loadChatMessages(conversationId: number) {
        const httpOptions = this.createHttpOptions();
        return this.http.get<MessageListResponse>(this.getApiUrl() + '/people/me/chat/' + conversationId + '/messages', httpOptions);
    }

    createChatMessage(recipientId: number, msg: ConversationMessage) {
        const httpOptions = this.createHttpOptions();
        return this.http.post(this.getApiUrl() + '/people/' + recipientId + '/chat/messages', msg, httpOptions);
    }

    markConversationAsSeen(conversationId: number) {
        const httpOptions = this.createHttpOptions();
        return this.http.post(this.getApiUrl() + '/people/me/chat/' + conversationId + '/seen', {}, httpOptions);
    }
}
