import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PersonTypeEnum} from '../../../shared/enums/person-type.enum';
import {AuthenticationService} from '../../../core/authentication/authentication.service';
import {TranslatedToastrService} from '../../../core/translated-toastr/translated-toastr.service';
import {NavigationService} from '../../../core/navigation/navigation.service';
import {ResponsiveService} from '../../../core/responsive/responsive.service';

@Component({
  selector: 'app-patient-registration-start',
  templateUrl: './patient-registration-start.component.html',
  styleUrls: ['./patient-registration-start.component.scss']
})
export class PatientRegistrationStartComponent implements OnInit {
  isPasswordConfirmVisible = false;
  isPasswordVisible = false;
  isSubmitted = false;
  personType = PersonTypeEnum.PATIENT;
  urlSignIn = '/signin';
  urlRequestMed = 'mailto:mail@medakte.com';
  urlTermsOfService = 'https://medakte.com/terms-of-service';
  userFormGroup: FormGroup;

  constructor(
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private navigationService: NavigationService,
    private translatedToastrService: TranslatedToastrService,
    public responsizeService: ResponsiveService,
  ) { }

  ngOnInit() {
    this.initFormGroup();
  }

  onClickCancel() {
    this.navigationService.navigateToLogin();
  }

  onClickContinue() {
    this.isSubmitted = true;
    if (this.checkUserFormGroupIsValid() && this.userFormGroup.valid) {
      const email = this.userFormGroup.get('email').value;
      const password = this.userFormGroup.get('password').value;
      const recaptcha = this.userFormGroup.get('recaptcha').value;
      this.authenticationService.signUp(email, password, {type: this.personType, recaptcha: recaptcha}).subscribe(
        () => this.navigationService.navigateToRegistrationConfirmationSent(email),
        err => this.onSignUpError(err)
      );
    }
  }

  private checkUserFormGroupIsValid(): boolean {
    const email = this.userFormGroup.get('email').value;
    const password = this.userFormGroup.get('password').value;
    const passwordConfirm = this.userFormGroup.get('passwordConfirm').value;
    const termsOfService = this.userFormGroup.get('termsOfService').value;
    const recaptcha = this.userFormGroup.get('recaptcha').value;

    if (!email || !password || !passwordConfirm) {
      this.translatedToastrService.error('authentication.errors.EmailPasswordMandatoryError');
      return false;
    }

    if (!termsOfService) {
      this.translatedToastrService.error('authentication.errors.TosNotAccepted');
      return false;
    }

    if (password !== passwordConfirm) {
      this.translatedToastrService.error('authentication.errors.PasswordsNotMatched');
      return false;
    }

    if (!recaptcha) {
      this.translatedToastrService.error('authentication.errors.MissingRecaptcha');
      return false;
    }

    return true;
  }

  private onSignUpError(err: any) {
    if (err && err.error && err.error.error) {
      const errCode = err.error.error.code;
      const translationCode = 'authentication.errors.' + errCode;
      this.translatedToastrService.error(translationCode);
    }
  }

  private initFormGroup() {
    this.userFormGroup = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      passwordConfirm: ['', Validators.required],
      recaptcha: ['', Validators.required],
      termsOfService: [false, Validators.required],
    });
  }

}
