import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {CalendarView} from 'angular-calendar';
import {Chart, ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import * as chartConfig from '../../../../shared/charts/chart.config';
import {startEndDates} from '../../../../../lib/dates';
import * as moment from 'moment-timezone';
import {FeelingService} from '../../../../core/measurements/feeling.service';
import {Label} from 'ng2-charts';
import {ActivatedRoute} from '@angular/router';
import {take} from 'rxjs/operators';
import {LocaleResolverService} from '../../../../core/locale/locale-resolver.service';
import {TranslateService} from '@ngx-translate/core';
import * as ChartAnnotation from 'chartjs-plugin-annotation';

@Component({
    selector: 'app-feeling-chart',
    templateUrl: './feeling-chart.component.html',
    styleUrls: ['./feeling-chart.component.scss']
})
export class FeelingChartComponent implements OnInit {

    personIdParam: string;
    locale: string;

    view: CalendarView = CalendarView.Month;
    viewDate: Date = new Date();

    start: any;
    end: any;

    minValues: any[];
    maxValues: any[];

    chartColors = chartConfig.chartColors;
    chartData: ChartDataSets[] = [{ data: [] }];
    chartOptions: ChartOptions;
    chartType: ChartType = 'line';

    public barChartLabels: Label[] = [];
    public translations = {};
    translatedLabel = '-';

    constructor(
        private feelingService: FeelingService,
        private route: ActivatedRoute,
        private localeResolver: LocaleResolverService,
        private translateService: TranslateService,
        private ref: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        this.locale = this.localeResolver.obtainLocale();
        this.localeResolver.localeSubject.subscribe(v => {
            if (v) {
                this.locale = v;
            }
        });

        this.translatedLabel = this.translateService.instant('journal.feeling.title');
        for (let i = -5; i < 6; i++) {
            const translation = this.translateService.instant('codes.feeling.' + i);
            this.barChartLabels.push(translation);
            this.translations[i] = translation;
        }
        this.ref.markForCheck();

        const routeParams$ = this.route.params.pipe(take(1));
        routeParams$.subscribe(r => {
            this.personIdParam = r['id'];
            this.fetchValues();
        });

        const namedChartAnnotation = ChartAnnotation;
        namedChartAnnotation['id'] = 'annotation';
        Chart.pluginService.register(namedChartAnnotation);
    }

    fetchValues() {
        const dates = startEndDates('month', this.viewDate);
        this.start = dates.start;
        this.end = dates.end;

        this.setChartOption(this.start, this.end, this);

        this.feelingService.listFeelings(this.personIdParam, null, null, null, this.start, this.end)
            .subscribe(r => {
                const results = r.results;

                const data = results.map(e => {
                    return { x: e.date, y: e.value };
                });

                this.populateChartData(data);
                this.ref.markForCheck();
            });
    }

    private populateChartData(val) {
        this.chartData = [
            {
                data: val,
                label: this.translatedLabel,
                pointRadius: 7,
            }
        ];
    }

    private setChartOption(min, max, ref) {
        this.chartOptions = {
            animation: {
                duration: 1000,
                easing: 'easeOutBack'
            },
            hover: {
                animationDuration: 1000,
            },
            responsiveAnimationDuration: 1000,
            responsive: true,

            scales: {
                xAxes: [{
                    type: 'time',
                    distribution: 'series',
                    position: 'bottom',
                    time: {
                        unit: 'week',
                        tooltipFormat: 'LLL',
                        displayFormats: {
                            day: 'dd'
                        },
                        min: min,
                        max: max
                    },
                    scaleLabel: {
                        display: true,
                    }
                }],
                yAxes: [{
                    ticks: {
                        max: 5,
                        min: -5,
                        stepSize: 1
                    }
                }]
            },

            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) {
                        return ref.translations[tooltipItem.yLabel];
                    }
                }
            }
        };

        // prevent typescript unknown annotation in chartOptions
        const x = 'annotation';
        this.chartOptions[x] = {
            annotations: [
                {
                    type: 'line',
                    mode: 'horizontal',
                    scaleID: 'y-axis-0',
                    value: 0,
                    borderColor: 'rgba(255, 50, 50, 0.5)',
                    borderWidth: 2,
                    label: {
                        enabled: false,
                        content: 0
                    }
                }]
        };
    }

    addDate(start, addDays) {
        return moment(start).add(addDays, 'days').format('LL');
    }
}
