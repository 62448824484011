var BMIComponent = (function () {

  var int;
  var rot = 0;
  var bmitext = 0;

  var showValue = function (value) {
    // console.log(value);

    var targetAngel = 0;

    if (!value) { targetAngel = 0; value = 0; };
    if (value <= 10) targetAngel = 0;
    if (value >= 40) targetAngel = 180;
    if (value && value > 10 && value < 40) targetAngel = Math.round((value - 10) * 6);

    int = setInterval(function () {

      if (!document.getElementById("bmitext") || !document.getElementById("bmiline")) {
        clearInterval(int);

        return;
      }

      document.getElementById("bmitext").innerHTML = bmitext.toFixed(1);
      document.getElementById("bmiline").setAttribute('transform', "rotate(" + rot + ", 21, 21)");

      var textCompleted = (bmitext >= value);
      var lineCompleted = (rot >= targetAngel);

      if (!textCompleted) bmitext = bmitext + 0.1;
      if (!lineCompleted) rot += 0.5;

      if (textCompleted && lineCompleted) clearInterval(int);
    }, 1);
  };

  return {
    showValue: showValue
  };

})();
