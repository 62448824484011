import {Component, OnInit} from '@angular/core';
import {NavigationService} from '../../../core/navigation/navigation.service';
import {take} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {AuthenticationService} from '../../../core/authentication/authentication.service';
import {TranslatedToastrService} from '../../../core/translated-toastr/translated-toastr.service';

@Component({
  selector: 'app-patient-registration-confirmation-sent',
  templateUrl: './patient-registration-confirmation-sent.component.html',
  styleUrls: ['./patient-registration-confirmation-sent.component.scss']
})
export class PatientRegistrationConfirmationSentComponent implements OnInit {
  private email: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private navigationService: NavigationService,
    private translatedToastrService: TranslatedToastrService,
  ) { }

  ngOnInit() {
    this.activatedRoute.params.pipe(take(1)).subscribe(
      res => {
        this.email = res['email'];
      }
    );
  }

  onClickBack() {
    this.navigationService.navigateToLogin();
  }

  onClickSendConfirmationAgain(event: any) {
    event.preventDefault();
    this.authenticationService.resendVerificationCode(this.email).subscribe(
      () => this.translatedToastrService.success('message.success.resendVerificationCode'),
      err => this.onResendVerificationCodeError(err)
    );
  }

  private onResendVerificationCodeError(err) {
    if (err && err.error && err.error.error) {
      const errCode = err.error.error.code;
      const translationCode = 'authentication.errors.' + errCode;
      this.translatedToastrService.error(translationCode);
    }
  }
}
