import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {concat, Observable, of, Subject} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, map, switchMap, tap} from 'rxjs/operators';
import {Language} from '../../core/language/language.model';
import {LanguageService} from '../../core/language/language.service';
import {LanguageListResponse} from '../../core/response/response.model';

@Component({
  selector: 'app-profile-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit {

  @Input() languages: Language[];
  @Input() isRequired = false;
  @Output() selectedLanguages = new EventEmitter<Language[]>();
  languagesFormGroup: FormGroup;
  private debounce = 500;
  languages$: Observable<Language[]>;
  languagesLoading = false;
  languagesInput$ = new Subject<string>();
  validate: boolean;

  constructor(
    private languageService: LanguageService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.languages = !this.languages ? [] : this.languages;

    this.languagesFormGroup = this.fb.group({
      languages: [[], Validators.required],
    });

    // console.log(this.languages);

    this.onLanguageChanges();
    this.loadLanguage();

    this.languagesFormGroup.controls['languages'].setValue(this.languages);
  }

  private onLanguageChanges() {
    this.languagesFormGroup.valueChanges.subscribe(val => {
      if (this.languagesFormGroup.valid) {
        // console.log(this.languagesFormGroup.get('languages').value);
        this.selectedLanguages.emit(this.languagesFormGroup.get('languages').value);
      } else {
        this.selectedLanguages.emit([]);
      }
    });
  }

  private loadLanguage() {
    this.languages$ = concat(
      of([]), // default items
      this.languagesInput$.pipe(
        debounceTime(this.debounce),
        distinctUntilChanged(),
        tap(() => this.languagesLoading = true),
        switchMap(term => this.languageService
          .listLanguages(term)
          .pipe(
            catchError(() => of([])),
            map((res: LanguageListResponse) => {
              return res.result;
            }),
            tap(() => this.languagesLoading = false)
          ))
      )
    );
  }

  click(event) {
    this.languagesInput$.next('');

    this.languagesFormGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});
  }

  checkValidity() {
    this.validate = true;
    this.languagesFormGroup.get('languages').markAsTouched({ onlySelf: true });
  }
}
