import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Profile} from '../../../core/profile/profile.model';
import {Router} from '@angular/router';
import {formatDateTime} from '../../../../lib/formats';
import {Feeling} from '../../../core/measurements/feeling.model';
import {FeelingService} from '../../../core/measurements/feeling.service';

@Component({
  selector: 'app-profile-feeling',
  templateUrl: './profile-feeling.component.html',
  styleUrls: ['./profile-feeling.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileFeelingComponent implements OnInit {
  @Input() isVisible = true;
  @Input() latestFeeling: Feeling | undefined;
  @Input() personIdParam = 'me';
  @Input() profile: Profile;
  @Input() readOnly = false;

  constructor(private router: Router,
              private feelingService: FeelingService,
              protected ref: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.latestFeeling === undefined) {
      this.feelingService.loadLatestFeeling(this.personIdParam).subscribe(
        r => {
          this.latestFeeling = r.results.length > 0 ? r.results[0] : null;
          this.ref.markForCheck();
        },
        () => {
        }
      );
    }
  }

  getSubLabel(): string {
    let subLabel = '-';
    if (this.latestFeeling) {
      subLabel = formatDateTime(this.latestFeeling.date);
    }
    return subLabel;
  }

  onClick() {
    if (!this.readOnly && this.isVisible) {
      const url = ((this.personIdParam === 'me') ? '/people/' : '/medic/patients/') + this.personIdParam + '/measurements/feeling';
      this.router.navigate([url]);
    }
  }
}
