import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {CalendarView} from 'angular-calendar';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import * as chartConfig from '../../../../shared/charts/chart.config';
import {startEndDates} from '../../../../../lib/dates';
import * as moment from 'moment-timezone';
import {Spo2Service} from '../../../../core/measurements/spo2.service';
import {Label} from 'ng2-charts';
import {ActivatedRoute} from '@angular/router';
import {take} from 'rxjs/operators';
import {LocaleResolverService} from '../../../../core/locale/locale-resolver.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-spo2-chart',
  templateUrl: './spo2-chart.component.html',
  styleUrls: ['./spo2-chart.component.scss']
})
export class Spo2ChartComponent implements OnInit {
  @Input() maxSpo2 = 100;
  @Input() minSpo2 = 60;

  personIdParam: string;
  locale: string;

  view: string = CalendarView.Month;
  viewDate: Date = new Date();

  start: any;
  end: any;

  minValues: any[];
  maxValues: any[];

  translatedLabel = '-';

  chartColors = chartConfig.chartColors;
  chartData: ChartDataSets[] = [{ data: [] }];
  chartOptions: ChartOptions;
  chartType: ChartType = 'line';

  public barChartLabels: Label[] = [];

  constructor(
    private spo2Service: Spo2Service,
    private route: ActivatedRoute,
    private localeResolver: LocaleResolverService,
    private translateService: TranslateService,
    private ref: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.locale = this.localeResolver.obtainLocale();
    this.localeResolver.localeSubject.subscribe(v => {
      if (v) {
        this.locale = v;
      }
    });

    this.translatedLabel = this.translateService.instant('journal.spo2.title');
    this.ref.markForCheck();

    const routeParams$ = this.route.params.pipe(take(1));
    routeParams$.subscribe(r => {
      this.personIdParam = r['id'];
      this.fetchValues();
    });
  }

  fetchValues() {
    const dates = startEndDates('month', this.viewDate);
    this.start = dates.start;
    this.end = dates.end;

    this.setChartOption(this.start, this.end, this);

    this.spo2Service.listSpo2(this.personIdParam, null, null, null, this.start, this.end)
      .subscribe(r => {
        const results = r.results || [];

        const data = results.map(e => {
          return { x: e.date, y: e.value };
        });

        this.populateChartData(data);
        this.ref.markForCheck();
      });
  }

  private populateChartData(val) {
    this.chartData = [
      {
        data: val,
        label: this.translatedLabel,
        pointRadius: 7,
      }
    ];
  }

  private setChartOption(min, max, ref) {
    this.chartOptions = {
      animation: {
        duration: 1000,
        easing: 'easeOutBack'
      },
      hover: {
        animationDuration: 1000,
      },
      responsiveAnimationDuration: 1000,
      responsive: true,

      scales: {
        xAxes: [{
          type: 'time',
          distribution: 'series',
          position: 'bottom',
          time: {
            unit: 'week',
            tooltipFormat: 'LLL',
            displayFormats: {
              day: 'dd'
            },
            min: min,
            max: max
          },
          scaleLabel: {
            display: true,
          }
        }],
        yAxes: [{
          ticks: {
            max: this.maxSpo2,
            min: this.minSpo2
          }
        }]
      },

      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            return tooltipItem.yLabel + ' %';
          }
        }
      }
    };
  }

  addDate(start, addDays) {
    return moment(start).add(addDays, 'days').format('LL');
  }
}

