import {AfterViewInit, Component} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {PersonTypeSelectionComponent} from '../person-type-selection/person-type-selection.component';
import {OnboardingProfileModalComponent} from '../profile/onboarding-profile-modal.component';
import {AuthenticationService} from '../../+modules/core/authentication/authentication.service';
import {PersonTypeEnum} from '../../+modules/shared/enums/person-type.enum';
import {NavigationService} from '../../+modules/core/navigation/navigation.service';

@Component({
  selector: 'app-patient-onboarding-start',
  templateUrl: './onboarding-start.component.html',
  styleUrls: ['./onboarding-start.component.scss']
})
export class OnboardingStartComponent implements AfterViewInit {
  currentModal: NgbModalRef;

  constructor(
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private navigationService: NavigationService,
    private router: Router) { }

  ngAfterViewInit() {
    this.openInitialModal();
  }

  openInitialModal() {
    if (this.currentModal) { this.currentModal.close(); }
    this.currentModal = this.modalService.open(PersonTypeSelectionComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false
    });
    this.currentModal.result.then((personType) => {
      this.openOnboardingModal(personType);
    });
  }

  openOnboardingModal(personType: number) {
    this.currentModal.close();
    let modalComponent;
    switch (personType) {
      case PersonTypeEnum.PATIENT:
        modalComponent = OnboardingProfileModalComponent;
        break;
      case PersonTypeEnum.DOCTOR:
      case PersonTypeEnum.MEDICAL_INSTITUTION:
        // TODO
        // modalComponent = MedicOnboardingComponent;
        break;
      default:
        // logout
        this.authenticationService.signOut().subscribe(
          () => {
            this.authenticationService.setLoggedOut();
            this.navigationService.navigateToLogin();
          },
          () => {
            this.authenticationService.setLoggedOut();
            this.navigationService.navigateToLogin();
          }
        );
        this.router.navigate(['/']);
        return;
        // TODO throw error?
    }
    this.currentModal = this.modalService.open(modalComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false
    });
    if (personType === PersonTypeEnum.DOCTOR || personType === PersonTypeEnum.MEDICAL_INSTITUTION) {
      this.currentModal.componentInstance.personType = personType;
    }

    this.currentModal.result.then((success) => {
      if (success) {
        if (personType === PersonTypeEnum.PATIENT) {
          this.router.navigate(['/dashboard']);

        } else {
          this.router.navigate(['/medic/dashboard']);
        }
      } else {
        this.openInitialModal();
      }
    });
  }
}
