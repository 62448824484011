import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BackendService} from '../backend/backend.service';
import {PersonResponse, ProfileResponse} from '../response/response.model';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Profile} from './profile.model';
import {Person} from '../person/person.model';
import {LocaleResolverService} from '../locale/locale-resolver.service';

@Injectable()
export class ProfileService extends BackendService {
    myProfile: Person;
    myProfileSubject: BehaviorSubject<Person> = new BehaviorSubject<Person>(null);
    personIdParam: string;
    personIdParamSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private profileCache = {};

    constructor(
        protected http: HttpClient,
        protected localeResolverService: LocaleResolverService
    ) {
        super(http, localeResolverService);
    }

    clearProfileCache() {
        for (const personIdParam in this.profileCache) {
            if (this.profileCache.hasOwnProperty(personIdParam) && personIdParam !== 'me') {
                delete this.profileCache[personIdParam];
            }
        }
    }

    deleteAccount() {
        const httpOptions = this.initHttpOptions();
        return this.http.delete(this.getApiUrl() + '/people/me', httpOptions);
    }

    deleteProfileDataFromCache(personIdParam: string) {
        if (this.profileCache.hasOwnProperty(personIdParam)) {
            delete this.profileCache[personIdParam];
        }
    }

    deleteProfilePhoto() {
        const httpOptions = this.initHttpOptions();
        return this.http.delete(this.getApiUrl() + '/people/me/profilePhoto', httpOptions);
    }

    getMyProfile(): Observable<PersonResponse> {
        return this.getProfile('me');
    }

    getProfile(personIdParam: string): Observable<PersonResponse> {
        console.log('loading profile <' + personIdParam + '> from cache: ' + this.profileCache.hasOwnProperty(personIdParam));
        if (personIdParam === 'me') {
            return this.profileCache.hasOwnProperty(personIdParam)
              ? this.getProfileDataFromCache(personIdParam)
              : this.getProfileData(personIdParam).pipe(tap(data => {
                  this.profileCache[personIdParam] = data.result;

                  if (this.myProfile !== data.result) {
                      this.myProfile = data.result;
                      this.myProfileSubject.next(this.myProfile);
                      this.notifyCurrentPersonIdParam();
                  }
                }));
        } else {
            return this.profileCache.hasOwnProperty(personIdParam)
                ? this.getProfileDataFromCache(personIdParam)
                : this.getProfileData(personIdParam)
                    .pipe(tap(data => {
                        this.profileCache[personIdParam] = data.result;
                    }));
        }
    }

    getMyOnboardingStatus() {
        const httpOptions = this.initHttpOptions();
        return this.http.get<any>(this.getApiUrl() + '/me/onboarding/status', httpOptions);
    }

    getMyPersonProfile() {
        const httpOptions = this.initHttpOptions();
        return this.http.get<ProfileResponse>(this.getApiUrl() + '/people/me/profile', httpOptions);
    }

    notifyCurrentPersonIdParam() {
        this.personIdParamSubject.next(this.personIdParam);
    }

    setCurrentPersonIdParam(personIdParam: string) {
        this.personIdParam = personIdParam;
        this.notifyCurrentPersonIdParam();
    }

    updateMyPersonProfile(profile: Profile) {
        const httpOptions = this.initHttpOptions();
        return this.http.put(this.getApiUrl() + '/people/me/profile', profile, httpOptions);
    }

    private getProfileData(personIdParam: string): Observable<PersonResponse> {
        const httpOptions = this.initHttpOptions();
        // console.log(httpOptions);
        const uri = (personIdParam === 'me' ? '/' : '/people/') + personIdParam;
        return this.http.get<PersonResponse>(this.getApiUrl() + uri, httpOptions);
    }

    private getProfileDataFromCache(personIdParam: string): Observable<PersonResponse> {
        const personResponse: PersonResponse = {
            messages: [],
            result: this.profileCache[personIdParam],
            status: 200
        };

        if (personIdParam === 'me' && this.myProfile !== personResponse.result) {
            this.myProfile = personResponse.result;
            this.myProfileSubject.next(this.myProfile);
        }

        return of<PersonResponse>(personResponse);
    }
}

