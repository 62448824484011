import * as moment from 'moment-timezone';
import {endOfDay, endOfMonth, endOfWeek, format, startOfDay, startOfMonth, startOfWeek} from 'date-fns';
import {DATE_WITH_TZ_FORMAT_END, DATE_WITH_TZ_FORMAT_START} from './formats';
import {NgbDateStruct, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';

export function startEndDates(view, viewDate) {

  const getStart: any = {
    month: startOfMonth,
    week: startOfWeek,
    day: startOfDay
  }[view];

  const getEnd: any = {
    month: endOfMonth,
    week: endOfWeek,
    day: endOfDay
  }[view];

  const startDate = format(getStart(viewDate, {weekStartsOn: 1}), 'YYYY-MM-DD');
  const endDate = format(getEnd(viewDate, {weekStartsOn: 1}), 'YYYY-MM-DD');

  const start = moment(startDate).format(DATE_WITH_TZ_FORMAT_START);
  const end = moment(endDate).format(DATE_WITH_TZ_FORMAT_END);

  return {
    start: start,
    end: end
  };
}

export function toNgbDateStruct(d: Date) {

  if (!d) { return null; }

  const m = moment(d);
  return {day: m.date(), month: (m.month() + 1), year: m.year()};
}

export function fromNgbDateStructToDate(d: NgbDateStruct) {

  if (!d) { return null; }

  const m = moment(d.year + '-' + d.month + '-' + d.day, 'YYYY-MM-DD');
  return m.toDate();
}

export function toNgbTimeStruct(d: Date) {

  if (!d) { return null; }

  const m = moment(d);
  return {hour: m.hour(), minute: m.minute()};
}

export function fromNgbDateTimeStructToDate(d: NgbDateStruct, t: NgbTimeStruct) {

  if (!d) { return null; }

  const m = moment(d.year + '-' + d.month + '-' + d.day + ' ' +
    (t ? t.hour : '00') + ':' +
    (t ? t.minute : '00'), 'YYYY-MM-DD HH:mm');

  return m.toDate();
}

export function fromNgbDateWithEndDateTimeStructToDate(d: NgbDateStruct) {

  if (!d) { return null; }

  const m =  moment(d.year + '-' + d.month + '-' + d.day + ' ' + '23:59:59', 'YYYY-MM-DD HH:mm:ss');

  return m.toDate();
}

