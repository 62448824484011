import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {EmergencyPassService} from './emergency-pass.service';
import {EmergencyPass} from './emergency-card.model';
import {DocumentService} from '../documents/document.service';
import {take} from 'rxjs/operators';
import {forkJoin} from 'rxjs';
import {EmergencyPassResponse} from '../../core/response/response.model';
import '../../../../assets/js/bmi-component.js';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {EmergencyCardEditComponent} from './emergency-card-edit/emergency-card-edit.component';
import {EmergencyCardShareComponent} from './emergency-card-share/emergency-card-share.component';
import {EmergencyPassConfig} from './emergency-card-config.model';
import {formatCalendarDateTime} from '../../../lib/formats';

@Component({
    selector: 'app-emergency-card',
    templateUrl: './emergency-card.component.html',
    styleUrls: ['./emergency-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmergencyCardComponent implements OnInit {
    bmi: string;
    currentModal: NgbModalRef;
    code: string;

    emergencyPass: EmergencyPass;
    emergencyPassConfig: EmergencyPassConfig;
    isEmergencyPassActive = true;
    formatCalendarDateTime = formatCalendarDateTime;
    medakteId: string;
    personIdParam: string;
    photo: string;

    public = true;

    constructor(
        private documentService: DocumentService,
        private emergencyPassService: EmergencyPassService,
        private modal: NgbModal,
        private route: ActivatedRoute,
        protected ref: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        const routeParams$ = this.route.params.pipe(take(1));
        const queryParams$ = this.route.queryParams.pipe(take(1));

        forkJoin([routeParams$, queryParams$]).subscribe(respList => {
            this.personIdParam = respList[0]['id'];

            if (this.personIdParam) {
                if (this.personIdParam === 'me') {
                    this.public = false;
                    this.emergencyPassService.getMyEmergencyPass()
                      .subscribe(
                        resp => this.afterGetEmergencyCard(resp),
                        () => this.afterEmergencyCardError()
                      );
                } else {
                    this.emergencyPassService.getPatientEmergencyPass(this.personIdParam)
                      .subscribe(
                        resp => this.afterGetEmergencyCard(resp),
                        () => this.afterEmergencyCardError()
                      );
                }
            } else {
                this.code = respList[1]['code'];
                if (this.code) {
                    this.emergencyPassService.getEmergencyPass(this.code)
                        .subscribe(
                            resp => this.afterGetEmergencyCard(resp),
                            () => this.afterEmergencyCardError()
                        );
                }
            }
        });
    }

    isMe() {
        return this.personIdParam === 'me';
    }

    edit() {
        this.showEditModal();
    }

    share() {
        this.showShareModal();
    }

    showEditModal() {
        this.currentModal = this.modal.open(EmergencyCardEditComponent, {size: 'lg', windowClass: 'edit_ec_card'});

        this.currentModal.componentInstance.personIdParam = this.personIdParam;
        this.currentModal.componentInstance.emergencyCard = this.emergencyPass;
        this.currentModal.componentInstance.emergencyCardConfig = this.emergencyPassConfig;

        this.currentModal.result.then(saved => this.handleDialogDataChanged(saved)).catch(e => {
        });
    }

    showShareModal() {
        this.currentModal = this.modal.open(EmergencyCardShareComponent, {size: 'lg', windowClass: 'share_ec_card'});

        this.currentModal.componentInstance.emergencyCard = this.emergencyPass;

        this.currentModal.result.then(changed => this.handleDialogDataChanged(changed)).catch(e => {
        });
    }

    private handleDialogDataChanged(changed: boolean) {
        if (changed) {
            // reload emergencyCard
            if (this.personIdParam === 'me') {
                this.emergencyPassService.getMyEmergencyPass()
                    .subscribe(
                        resp => this.afterGetEmergencyCard(resp),
                        () => this.afterEmergencyCardError()
                    );
            } else {
                if (this.code) {
                    this.emergencyPassService.getEmergencyPass(this.code)
                        .subscribe(
                            resp => this.afterGetEmergencyCard(resp),
                            () => this.afterEmergencyCardError()
                        );
                } else {
                    this.emergencyPassService.getPatientEmergencyPass(this.personIdParam)
                        .subscribe(
                            resp => this.afterGetEmergencyCard(resp),
                            () => this.afterEmergencyCardError()
                        );
                }
            }
        } else {
            this.ref.markForCheck();
        }
    }

    private afterGetEmergencyCard(emergencyCardResponse: EmergencyPassResponse) {
        this.emergencyPass = emergencyCardResponse.result;

        this.isEmergencyPassActive = !!this.emergencyPass;

        this.code = this.emergencyPass.config.code;
        this.medakteId = this.emergencyPass.person.id + '';

        if (this.public) {
            this.photo = this.emergencyPassService.getEmergencyCardProfilePhotoUrl(
                this.emergencyPass.person.photoKey, this.medakteId, this.code
            );
        } else {
            if (this.emergencyPass.person.photoKey) {
                this.photo = this.documentService.resolvePhotoFileUrl(this.emergencyPass.person.photoKey);
            }
        }

        this.emergencyPassConfig = this.emergencyPass.config;
        this.ref.markForCheck();
    }

    private afterEmergencyCardError() {
        this.isEmergencyPassActive = false;
        this.ref.markForCheck();
    }
}
