import {Injectable, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class BaseAuthComponent implements OnInit {

    translations: any;

    constructor(
        protected translateService: TranslateService,
    ) {
    }

    ngOnInit() {
        this.translateService.get([
            'success',
            'error',
            'validation.allMandatory',
            'authentication.errors.PasswordPolicyError',
            'authentication.errors.EmailPasswordMandatoryError',
            'authentication.errors.CodeMandatoryError',
            'authentication.errors.TosNotAccepted',
            'authentication.errors.PasswordsNotMatched',
            'authentication.errors.TokenValidationError',
            'authentication.errors.EnrollmentCodeNotValid',
            'authentication.AccountConfirmationCodeSent',
            'authentication.passwordChanged'
        ]).subscribe(resp => this.translations = resp);
    }
}
