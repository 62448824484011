import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Profile} from '../../../core/profile/profile.model';
import {Router} from '@angular/router';
import {formatDateTime} from '../../../../lib/formats';
import {BloodGlucose} from '../../../core/measurements/blood-glucose.model';
import {BloodGlucoseService} from '../../../core/measurements/blood-glucose.service';
import {BloodGlucoseUnitEnum} from '../../../shared/enums/blood-glucose-unit.enum';

@Component({
  selector: 'app-profile-blood-glucose',
  templateUrl: './profile-blood-glucose.component.html',
  styleUrls: ['./profile-blood-glucose.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileBloodGlucoseComponent implements OnInit {
  @Input() isVisible = true;
  @Input() latestBloodGlucose: BloodGlucose | undefined;
  @Input() personIdParam = 'me';
  @Input() profile: Profile;
  @Input() readOnly = false;
  unit = 'mg/dL';

  constructor(private router: Router,
              private bloodGlucoseService: BloodGlucoseService,
              protected ref: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.latestBloodGlucose === undefined) {
      this.bloodGlucoseService.loadLatestBloodGlucose(this.personIdParam).subscribe(
        r => {
          this.latestBloodGlucose = r.results.length > 0 ? r.results[0] : null;
          if (this.latestBloodGlucose) {
            this.unit = (this.latestBloodGlucose.unit === BloodGlucoseUnitEnum.MG_DL) ? 'mg/dl' : 'mmol/l';
          }
          this.ref.markForCheck();
        },
        () => {
        }
      );
    } else {
      if (this.latestBloodGlucose) {
        this.unit = (this.latestBloodGlucose.unit === BloodGlucoseUnitEnum.MG_DL) ? 'mg/dL' : 'mmol/L';
      }
      this.ref.markForCheck();
    }
  }

  getSubLabel(): string {
    let subLabel = '-';
    if (this.latestBloodGlucose) {
      subLabel = formatDateTime(this.latestBloodGlucose.date);
    }
    return subLabel;
  }

  onClick() {
    if (!this.readOnly && this.isVisible) {
      const url = ((this.personIdParam === 'me') ? '/people/' : '/medic/patients/') + this.personIdParam + '/measurements/blood-glucose';
      this.router.navigate([url]);
    }
  }
}
