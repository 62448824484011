import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Profile} from '../../../core/profile/profile.model';
import {Router} from '@angular/router';
import {formatDateTime} from '../../../../lib/formats';
import {HeartRate} from '../../../core/measurements/heart-rate.model';
import {HeartRateService} from '../../../core/measurements/heart-rate.service';

@Component({
  selector: 'app-profile-heart-rate',
  templateUrl: './profile-heart-rate.component.html',
  styleUrls: ['./profile-heart-rate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileHeartRateComponent implements OnInit {
  @Input() isVisible = true;
  @Input() latestHeartRate: HeartRate | undefined;
  @Input() personIdParam = 'me';
  @Input() profile: Profile;
  @Input() readOnly = false;
  unit = 'HF';

  constructor(private router: Router,
              private heartRateService: HeartRateService,
              protected ref: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.latestHeartRate === undefined) {
      this.heartRateService.loadLatestHeartRate(this.personIdParam).subscribe(
        r => {
          this.latestHeartRate = r.results.length > 0 ? r.results[0] : null;
          this.ref.markForCheck();
        },
        () => {
        }
      );
    }
  }

  getSubLabel(): string {
    let subLabel = '-';
    if (this.latestHeartRate) {
      subLabel = formatDateTime(this.latestHeartRate.date);
    }
    return subLabel;
  }

  onClick() {
    if (!this.readOnly && this.isVisible) {
      const url = ((this.personIdParam === 'me') ? '/people/' : '/medic/patients/') + this.personIdParam + '/measurements/heart-rate';
      this.router.navigate([url]);
    }
  }
}
