import {Injectable} from '@angular/core';
import {BackendService} from '../backend/backend.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {TimelineListResponse} from '../response/response.model';
import {LocaleResolverService} from '../locale/locale-resolver.service';

@Injectable()
export class TimelineService extends BackendService {

  constructor(
      protected http: HttpClient,
    protected localeResolverService: LocaleResolverService,
  ) {
    super(http, localeResolverService);
  }

  listTimeline(id: string, page: number, pageSize: number) {
    const httpHeaders = this.createHttpHeaders();

    let params = new HttpParams();
    if (page !== undefined && page !== null) {
      params = params.append('page', page.toString());
    }
    if (pageSize !== undefined && pageSize !== null) {
      params = params.append('pageSize', pageSize.toString());
    }

    return this.http.get<TimelineListResponse>(this.getApiUrl() + '/people/' + id + '/timeline', (params.keys().length > 0) ? { headers: httpHeaders, params: params } : { headers: httpHeaders });
  }
}
