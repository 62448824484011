import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

import {Router} from '@angular/router';
import * as moment from 'moment-timezone';
import {AddressEditComponent} from '../../shared/address/address-edit/address-edit.component';
import {Address} from '../../core/address/address.model';
import {PersonGenderEnum} from '../../shared/enums/gender.enum';
import {Person} from '../../core/person/person.model';
import {TranslatedToastrService} from '../../core/translated-toastr/translated-toastr.service';
import {ImageHelperService} from '../../core/image-helper/image-helper.service';
import {fromNgbDateStructToDate, toNgbDateStruct} from '../../../lib/dates';
import {ImageTypes} from '../../core/image-helper/image-types';
import {ImageResizeTypes} from '../../core/image-helper/image-resize-types';
import {DATE_WITH_TZ_FORMAT_START} from '../../../lib/formats';
import {PersonService} from '../../core/person/person.service';
import {DocumentService} from '../documents/document.service';
import {AddressService} from '../../core/address/address.service';

@Component({
  selector: 'pat-person',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.scss']
})
export class PersonComponent implements OnInit {
  @Input() showHeaders = true;
  @Input() person: Person;
  @ViewChild('editAddress') editAddress: AddressEditComponent;
  @ViewChild('uploadPhoto', {read: ElementRef, static: false}) uploadPhoto: ElementRef;
  address: Address;
  genders = [
    {label: 'codes.gender.' + PersonGenderEnum.MALE, value: PersonGenderEnum.MALE},
    {label: 'codes.gender.' + PersonGenderEnum.FEMALE, value: PersonGenderEnum.FEMALE},
    {label: 'codes.gender.' + PersonGenderEnum.OTHER, value: PersonGenderEnum.OTHER}
  ];
  isAddressChanged = false;
  isLoaded = false;
  isSubmitted = false;
  personFormGroup: FormGroup;
  photoName: string;
  photoSrc: string;
  photoSize = {
    height: 240,
    width: 240
  };
  dateOfBirth: NgbDateStruct;

  constructor(
    private addressService: AddressService,
    private documentService: DocumentService,
    private fb: FormBuilder,
    private peopleService: PersonService,
    private router: Router,
    private translatedToastrService: TranslatedToastrService,
  ) {
  }

  ngOnInit() {
    // init form
    this.personFormGroup = this.fb.group({
      dateOfBirth: ['', Validators.required],
      firstName: ['', Validators.required],
      gender: [undefined, Validators.required],
      lastName: ['', Validators.required],
      phone: [''],
      title: ['']
    });

    this.personFormGroup.patchValue(this.person);

    if (this.person.firstName) {
      this.personFormGroup.get('firstName').disable({emitEvent: false});
    }
    if (this.person.lastName) {
      this.personFormGroup.get('lastName').disable({emitEvent: false});
    }

    this.dateOfBirth = toNgbDateStruct(this.person.dateOfBirth);

    this.setPhotoSrc();

    this.addressService.getPermanentAddress('me').subscribe(r => {
        const addresses = r.result;
        this.address = addresses.length > 0 ? addresses[0] : null; // TODO
        this.isLoaded = true;
    });
  }

  getPhotoPath(): string {
    return this.photoSrc ? this.photoSrc : 'assets/img/svg/black/medakte_icon_profil_picture.svg';
  }

  onAddressChange(address: Address) {
    this.address = address;
    this.isAddressChanged = true;
  }

  onClickPhoto() {
    // trigger click on input element
    if (this.uploadPhoto) {
      this.uploadPhoto.nativeElement.click();
    }
  }

  onDateOfBirthChange(dateOfBirth: NgbDateStruct) {
    this.dateOfBirth = dateOfBirth;
    this.personFormGroup.controls['dateOfBirth'].setValue(fromNgbDateStructToDate(dateOfBirth));
  }

  onPhotoFileChange($event: any) {
    const file: File = $event.target.files[0];
    ImageHelperService.resizeImage(
      file, this.photoSize.height, this.photoSize.width, ImageTypes.JPEG, ImageResizeTypes.CONTAIN,
      (fileName, fileDataUrl) => this.afterPhotoResize(fileName, fileDataUrl)
    );
  }

  onSavePerson() {
    this.isSubmitted = true;
    this.editAddress.checkValidity();

    if (this.personFormGroup.valid && this.address) {
      this.person.addresses = [this.address];

      this.person.dateOfBirth = this.dateOfBirth ?
        moment(this.dateOfBirth.year + '-' + this.dateOfBirth.month + '-' + this.dateOfBirth.day, DATE_WITH_TZ_FORMAT_START).toDate() :
        null;
      this.person.firstName = this.personFormGroup.get('firstName').value;
      this.person.gender = this.personFormGroup.get('gender').value;
      this.person.lastName = this.personFormGroup.get('lastName').value;
      this.person.phone = this.personFormGroup.get('phone').value;
      this.person.title = this.personFormGroup.get('title').value;

      this.peopleService.updatePerson(this.person).subscribe(
        () => {
          this.translatedToastrService.success('message.success.profile');
          this.savePhoto();
          this.saveAddress();
        },
        () => this.translatedToastrService.error('message.error.profile')
      );
    }
  }

  private afterPhotoResize(fileName: string, fileDataUrl: string) {
    this.photoName = fileName;
    this.photoSrc = fileDataUrl;
  }

  private saveAddress() {
    if (this.isAddressChanged && this.address) {
      this.addressService
        .updateAddress(this.address)
        .subscribe(
          () => {
            this.translatedToastrService.success('message.success.address');
            this.isAddressChanged = false;
          },
          () => this.translatedToastrService.error('message.error.address')
        );
    }
  }

  private savePhoto() {
    if (this.photoName && this.photoSrc) {
      const formData: FormData = new FormData();
      formData.append('profilePhoto', ImageHelperService.getFileFromDataURL(this.photoName, this.photoSrc), this.photoName);

      this.documentService.saveMyProfilePhoto(formData).subscribe(
        () => this.translatedToastrService.success('message.success.profilePhoto'),
        () => this.translatedToastrService.error('message.error.profilePhoto')
      );
    }
  }

  private setPhotoSrc() {
    if (this.person.photoKey) {
      this.photoSrc = this.documentService.resolvePhotoFileUrl(this.person.photoKey);
    }
  }
}
