export enum BloodGroupEnum {
  APOS = 0,
  ANEG = 1,
  BPOS = 2,
  BNEG = 3,
  ZEROPOS = 4,
  ZERONEG = 5,
  ABPOS = 6,
  ABNEG = 7
}
