import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class TranslatedToastrService {
  constructor(
    private toastrService: ToastrService,
    private translateService: TranslateService,
    ) {}

  error(message: string, title: string = 'message.error.title') {
    if (message.length > 0) {
      message = this.translateService.instant(message);
      title = this.translateService.instant(title);
      this.toastrService.error(message, title);
    }
  }

  info(message: string, title: string = 'message.info.title') {
    if (message.length > 0) {
      message = this.translateService.instant(message);
      title = this.translateService.instant(title);
      this.toastrService.info(message, title);
    }
  }

  success(message: string, title: string = 'message.success.title') {
    if (message.length > 0) {
      message = this.translateService.instant(message);
      title = this.translateService.instant(title);
      this.toastrService.success(message, title);
    }
  }

  show(message: string, title: string = '') {
    if (message.length > 0) {
      message = this.translateService.instant(message);
      title = this.translateService.instant(title);
      this.toastrService.show(message, title);
    }
  }

  warning(message: string, title: string = 'message.warning.title') {
    if (message.length > 0) {
      message = this.translateService.instant(message);
      title = this.translateService.instant(title);
      this.toastrService.warning(message, title);
    }
  }
}
