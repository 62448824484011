import {Injectable} from '@angular/core';
import {BackendService} from '../backend/backend.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {GeneralResponse, OperationListResponse, OperationResponse} from '../response/response.model';
import {Operation} from './operation.model';
import {LocaleResolverService} from '../locale/locale-resolver.service';
import {QueryFilter} from '../filters/query-filter.model';

@Injectable()
export class OperationService extends BackendService {

  constructor(
      protected http: HttpClient,
    protected localeResolverService: LocaleResolverService,
  ) {
    super(http, localeResolverService);
  }

  createOperation(id, body) {
    const httpOptions = this.createHttpOptions();
    return this.http.post<GeneralResponse>(this.getApiUrl() + '/people/' + id + '/operations', body, httpOptions);
  }

  updateOperation(id: number, operation: Operation) {
    const httpOptions = this.createHttpOptions();
    return this.http.put(this.getApiUrl() + '/operations/' + operation.id, operation, httpOptions);
  }

  getOperation(personIdParam: string, id: number) {
    const httpOptions = this.createHttpOptions();
    return this.http.get<OperationResponse>(this.getApiUrl() + '/people/' + personIdParam +  '/operations/' + id, httpOptions);
  }

  listOperations(id: string, q: string, base: boolean, page: number, pageSize: number, lastId: number, queryFilters: QueryFilter[] = []) {
    const httpHeaders = this.buildBaseHttpHeaders();

    let params = new HttpParams();
    if (page !== undefined && page !== null) {
      params = params.append('page', page.toString());
    }
    if (pageSize !== undefined && pageSize !== null) {
      params = params.append('pageSize', pageSize.toString());
    }
    if (lastId !== undefined && lastId !== null) {
      params = params.append('lastId', lastId.toString());
    }
    if (base !== undefined && base !== null) {
      params = params.append('base', base.toString());
    }
    if (q !== undefined && q !== null) {
      params = params.append('q', q.toString());
    }
    for (const queryFilter of queryFilters) {
      params = params.append(queryFilter.name, String(queryFilter.value));
    }

    return this.http.get<OperationListResponse>(
        this.getApiUrl() + '/people/' + id + '/operations',
        (params.keys().length > 0) ? { headers: httpHeaders, params: params } : { headers: httpHeaders });
  }

  deleteOperation(id: number) {
    const httpOptions = this.createHttpOptions();
    return this.http.delete(this.getApiUrl() + '/operations/' + id, httpOptions);
  }
}
