import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UsageService} from '../../../core/usage/usage.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UsageTypeEnum} from '../../enums/usage-type.enum';
import {UsageItem} from '../../../core/usage/usage.model';
import {hasValue} from '../../../../lib/validator';
import {FileSizePipe} from 'ngx-filesize';

@Component({
    selector: 'app-usage-alert',
    templateUrl: './usage-alert.component.html',
    styleUrls: ['./usage-alert.component.scss']
})
export class UsageAlertComponent implements OnInit {
    @Input() personIdParam: string;
    @Input() usageType: UsageTypeEnum;
    /** User already have reached limit or quota */
    @Output() isLimitQuotaReached = new EventEmitter<boolean>();
    /** New resources cannot be created due to limits or quotas */
    @Output() isValidChange = new EventEmitter<boolean>();
    freeStorageText: string | string[];
    isInsufficientStorage = false;
    isLimitReached = false;
    isQuotaReached = false;
    isValid = true;
    quotaText: string | string[];
    urlSubscription = 'patient/settings/subscription';
    usageItem: UsageItem;

    constructor(
        protected activatedRoute: ActivatedRoute,
        protected router: Router,
        protected usageService: UsageService,
        protected fileSizePipe: FileSizePipe,
        protected ref: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        if (!this.personIdParam) {
            this.activatedRoute.params.subscribe(params => {
                this.personIdParam = params['id'];
                this.personIdParam = !this.personIdParam ? 'me' : this.personIdParam;
                this.loadsUsage();
            });
        }
    }

    loadsUsage() {
        this.usageService.getUsageItem(this.personIdParam, this.usageType).subscribe(res => {
            this.usageItem = res.result;

            if (hasValue(this.usageItem.quota) &&
                hasValue(this.usageItem.sumSize)) {
                this.freeStorageText = this.fileSizePipe.transform(this.usageItem.quota - this.usageItem.sumSize);
                this.quotaText = this.fileSizePipe.transform(this.usageItem.quota);
            }

            this.validatesQuotasLimits();
        });
    }

    validatesQuotasLimits(size = 0) {
        if (this.usageItem) {
            // Limit Reached
            if (hasValue(this.usageItem.value) &&
                hasValue(this.usageItem.limit) &&
                this.usageItem.value >= this.usageItem.limit) {

                this.isLimitReached = true;
                this.isLimitQuotaReached.emit(true);

                this.isValid = false;
                this.isValidChange.emit(false);

                this.ref.markForCheck();
                return;
            }

            // Insufficient Storage
            if (hasValue(this.usageItem.quota) &&
                hasValue(this.usageItem.sumSize)) {

                if (size && ((this.usageItem.sumSize + size) > this.usageItem.quota)) {
                    this.isInsufficientStorage = true;
                    this.isLimitQuotaReached.emit(true);

                    this.isValid = false;
                    this.isValidChange.emit(false);

                    this.ref.markForCheck();
                    return;
                }

                // Quota Reached
                if ((this.usageItem.sumSize) >= this.usageItem.quota) {
                    this.isQuotaReached = true;
                    this.isLimitQuotaReached.emit(true);

                    this.isValid = false;
                    this.isValidChange.emit(false);

                    this.ref.markForCheck();
                    return;
                }
            }
        }

        this.isInsufficientStorage = false;
        this.isLimitReached = false;
        this.isQuotaReached = false;
        this.isLimitQuotaReached.emit(false);

        this.isValid = true;
        this.isValidChange.emit(true);

        this.ref.markForCheck();
    }
}
