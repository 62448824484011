import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-terms-ru',
  templateUrl: './terms-ru.component.html',
  styleUrls: ['./terms-ru.component.scss']
})
export class TermsRuComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
