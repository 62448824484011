import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Profile} from '../../../core/profile/profile.model';
import {Router} from '@angular/router';
import {formatDateTime} from '../../../../lib/formats';
import {Spo2} from '../../../core/measurements/spo2.model';
import {Spo2Service} from '../../../core/measurements/spo2.service';

@Component({
  selector: 'app-profile-spo2',
  templateUrl: './profile-spo2.component.html',
  styleUrls: ['./profile-spo2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileSpo2Component implements OnInit {
  @Input() isVisible = true;
  @Input() latestSpo2: Spo2 | undefined;
  @Input() personIdParam = 'me';
  @Input() profile: Profile;
  @Input() readOnly = false;
  unit = '%';

  constructor(private router: Router,
              private spo2Service: Spo2Service,
              protected ref: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.latestSpo2 === undefined) {
      this.spo2Service.loadLatestSpo2(this.personIdParam).subscribe(
        r => {
          this.latestSpo2 = r.results.length > 0 ? r.results[0] : null;
          this.ref.markForCheck();
        },
        () => {
        }
      );
    }
  }

  getSubLabel(): string {
    let subLabel = '-';
    if (this.latestSpo2) {
      subLabel = formatDateTime(this.latestSpo2.date);
    }
    return subLabel;
  }

  onClick() {
    if (!this.readOnly && this.isVisible) {
      const url = ((this.personIdParam === 'me') ? '/people/' : '/medic/patients/') + this.personIdParam + '/measurements/spo2';
      this.router.navigate([url]);
    }
  }
}
