import {Component} from '@angular/core';
import {ProfileService} from '../+modules/core/profile/profile.service';
import {AuthenticationService} from '../+modules/core/authentication/authentication.service';
import {NavigationService} from '../+modules/core/navigation/navigation.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent {
    constructor(
        private authService: AuthenticationService,
        private navigationService: NavigationService,
        private profileService: ProfileService,
    ) {
        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            if (isLoggedIn) {
                this.loadMyProfile();
            } else {
                this.navigationService.navigateToLogin();
            }
        });
    }

    private loadMyProfile() {
        this.profileService.getMyProfile().subscribe(r => {
            const person = r.result;
            if (person.type === 1 || person.type === 2) {
                this.navigationService.navigateToMedicDashboard();
            } else {
                this.navigationService.navigateToPatientDashboard();
            }
        });
    }
}
