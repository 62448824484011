import {Person} from '../../core/person/person.model';
import {Disease} from '../../core/health/disease.model';
import {Medication} from '../../core/health/medication.model';
import {Operation} from '../../core/health/operation.model';

export class DocumentHolder {
    id: number;
    creator: Person;
    created_at: Date;
    date: Date;
    docType: number;
    emergencyCardVisible: boolean;
    favourite: boolean;
    file: File;
    name: string;
    key: string;
    size: number;
    title: string;
    type: string;
    visible: boolean;

    diseaseIds: number[];
    medicationIds: number[];
    operationIds: number[];

    diseases: Disease[];
    medications: Medication[];
    operations: Operation[];

    localized: {
        docType: string;
        created_at: string;
        date: string;
    };
}

