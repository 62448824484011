import {Injectable} from '@angular/core';
import {BackendService} from '../../backend/backend.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {FavouriteListResponse} from '../../response/response.model';
import {LocaleResolverService} from '../../locale/locale-resolver.service';

@Injectable()
export class FavouriteService extends BackendService {

    constructor(
        protected http: HttpClient,
    protected localeResolverService: LocaleResolverService,
    ) {
        super(http, localeResolverService);
    }

    /**
     * Returns list of favourites.
     *
     * Items marked as favourite from my health and documents
     *
     * @param id person id
     * @param page index of page
     * @param pageSize size of page
     */
    listFavourites(id: string, page: number, pageSize: number) {
        const httpHeaders = this.buildBaseHttpHeaders();

        let params = new HttpParams();
        if (page !== undefined && page !== null) {
            params = params.append('page', page.toString());
        }
        if (pageSize !== undefined && pageSize !== null) {
            params = params.append('pageSize', pageSize.toString());
        }

        return this.http.get<FavouriteListResponse>(this.getApiUrl() + '/people/' + id + '/favourites', (params.keys().length > 0) ? {
            headers: httpHeaders,
            params: params
        } : {headers: httpHeaders});
    }
}
