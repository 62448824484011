import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Profile} from '../../../core/profile/profile.model';
import {Router} from '@angular/router';
import {formatDateTime} from '../../../../lib/formats';
import {Pain} from '../../../core/measurements/pain.model';
import {PainService} from '../../../core/measurements/pain.service';

@Component({
  selector: 'app-profile-pain',
  templateUrl: './profile-pain.component.html',
  styleUrls: ['./profile-pain.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfilePainComponent implements OnInit {
  @Input() isVisible = true;
  @Input() latestPain: Pain | undefined;
  @Input() personIdParam = 'me';
  @Input() profile: Profile;
  @Input() readOnly = false;

  constructor(private router: Router,
              private painService: PainService,
              protected ref: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.latestPain === undefined) {
      this.painService.loadLatestPain(this.personIdParam).subscribe(
        r => {
          this.latestPain = r.results.length > 0 ? r.results[0] : null;
          this.ref.markForCheck();
        },
        () => {
        }
      );
    }
  }

  getSubLabel(): string {
    let subLabel = '-';
    if (this.latestPain) {
      subLabel = formatDateTime(this.latestPain.date);
    }
    return subLabel;
  }

  onClick() {
    if (!this.readOnly && this.isVisible) {
      const url = ((this.personIdParam === 'me') ? '/people/' : '/medic/patients/') + this.personIdParam + '/measurements/pain';
      this.router.navigate([url]);
    }
  }
}
