import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AddressEditComponent} from '../../../shared/address/address-edit/address-edit.component';
import {Address} from '../../../core/address/address.model';
import {PersonGenderEnum} from '../../../shared/enums/gender.enum';
import {Person} from '../../../core/person/person.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {OnboardingService} from '../../../core/onboarding/onboarding.service';
import {PersonService} from '../../../core/person/person.service';
import {Router} from '@angular/router';
import * as moment from 'moment-timezone';
import {ImageHelperService} from '../../../core/image-helper/image-helper.service';
import {ImageTypes} from '../../../core/image-helper/image-types';
import {ImageResizeTypes} from '../../../core/image-helper/image-resize-types';
import {fromNgbDateStructToDate} from '../../../../lib/dates';
import {DATE_WITH_TZ_FORMAT_START} from '../../../../lib/formats';
import {GeneralResponse} from '../../../core/response/response.model';
import {TranslatedToastrService} from '../../../core/translated-toastr/translated-toastr.service';
import {NavigationService} from '../../../core/navigation/navigation.service';

@Component({
  selector: 'app-patient-onboarding-start',
  templateUrl: './patient-onboarding-start.component.html',
  styleUrls: ['./patient-onboarding-start.component.scss']
})
export class PatientOnboardingStartComponent implements OnInit {
  @ViewChild('editAddress') editAddress: AddressEditComponent;
  @ViewChild('uploadPhoto', {read: ElementRef, static: false}) uploadPhoto: ElementRef;
  address: Address;
  genders = [
    {label: 'codes.gender.' + PersonGenderEnum.MALE, value: PersonGenderEnum.MALE},
    {label: 'codes.gender.' + PersonGenderEnum.FEMALE, value: PersonGenderEnum.FEMALE},
    {label: 'codes.gender.' + PersonGenderEnum.OTHER, value: PersonGenderEnum.OTHER}
  ];
  isLoaded = false;
  person: Person;
  personFormGroup: FormGroup;
  photoName: string;
  photoSrc: string;
  photoSize = {
    height: 240,
    width: 240
  };
  dateOfBirth: NgbDateStruct;
  submitted = false;

  constructor(
    private fb: FormBuilder,
    private onboardingService: OnboardingService,
    private navigationService: NavigationService,
    private peopleService: PersonService,
    private router: Router,
    private translatedToastrService: TranslatedToastrService,
  ) {
  }

  ngOnInit() {
    this.checkOnboarding();
  }

  getPhotoPath(): string {
    return this.photoSrc ? this.photoSrc : 'assets/img/svg/black/medakte_icon_profil_picture.svg';
  }

  onAddressChange(address: Address) {
    this.address = address;
  }

  onClickPhoto() {
    // trigger click on input element
    if (this.uploadPhoto) {
      this.uploadPhoto.nativeElement.click();
    }
  }

  onClickSavePerson() {
    this.submitted = true;
    this.editAddress.checkValidity();

    if (this.personFormGroup.valid && this.address) {
      this.person.addresses = [this.address];

      this.person.dateOfBirth = this.dateOfBirth ?
        moment(this.dateOfBirth.year + '-' + this.dateOfBirth.month + '-' + this.dateOfBirth.day, DATE_WITH_TZ_FORMAT_START).toDate() :
        null;
      this.person.firstName = this.personFormGroup.get('firstName').value;
      this.person.gender = this.personFormGroup.get('gender').value;
      this.person.lastName = this.personFormGroup.get('lastName').value;
      this.person.phone = this.personFormGroup.get('phone').value;
      this.person.title = this.personFormGroup.get('title').value;

      this.onboardingService.postOnboardingProfile(this.person).subscribe(
        (res: GeneralResponse) => {
          this.translatedToastrService.success('message.success.profile');

          if (this.photoName && this.photoSrc) {
            this.savePhoto();
          } else {
            this.navigationService.navigateToPatientOnboardingSubscription();
          }
        },
        (err: GeneralResponse) =>
          this.translatedToastrService.error('message.error.profile')
      );
    }
  }

  onDateOfBirthChange(dateOfBirth: NgbDateStruct) {
    this.dateOfBirth = dateOfBirth;
    this.personFormGroup.controls['dateOfBirth'].setValue(fromNgbDateStructToDate(dateOfBirth));
  }

  onPhotoFileChange($event: any) {
    const file: File = $event.target.files[0];
    ImageHelperService.resizeImage(
      file, this.photoSize.height, this.photoSize.width, ImageTypes.JPEG, ImageResizeTypes.CONTAIN,
      (fileName, fileDataUrl) => this.afterPhotoResize(fileName, fileDataUrl)
    );
  }

  private afterPhotoResize(fileName: string, fileDataUrl: string) {
    this.photoName = fileName;
    this.photoSrc = fileDataUrl;
  }

  private checkOnboarding() {
    this.onboardingService.getPatientOnboardingStage().subscribe(
      data => {
        const currentOnboardingUrl = this.router.routerState.snapshot;
        const onboardingUrl = '/onboarding/' + data.result.onboardingStage;
        if (currentOnboardingUrl.url !== onboardingUrl) {
          this.router.navigate([onboardingUrl]);
        } else {
          this.initOnboardingStart();
        }
      }
    );
  }

  private initOnboardingStart() {
    // init form
    this.personFormGroup = this.fb.group({
      dateOfBirth: ['', Validators.required],
      firstName: ['', Validators.required],
      gender: [undefined, Validators.required],
      lastName: ['', Validators.required],
      phone: [''],
      title: ['']
    });

    this.person = new Person();
    this.person.gender = null;

    // const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.person.timezone = moment.tz.guess();

    this.address = new Address();

    this.isLoaded = true;
  }

  private savePhoto() {
    if (this.photoName && this.photoSrc) {
      const formData: FormData = new FormData();
      formData.append(
        'profilePhoto', ImageHelperService.getFileFromDataURL(this.photoName, this.photoSrc), this.photoName
      );

      this.onboardingService.postOnboardingProfilePhoto(formData).subscribe(
        () => {
          this.translatedToastrService.success('message.success.profilePhoto');
          this.navigationService.navigateToPatientOnboardingSubscription();
        },
        () => this.translatedToastrService.error('message.error.profilePhoto')
      );
    }
  }
}
