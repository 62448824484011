import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {DocumentHolder} from '../../documents/document.model';
import {DocumentService} from '../../documents/document.service';
import {LocaleResolverService} from '../../../core/locale/locale-resolver.service';
import {BaseComponent} from '../../../../base/base-component';
import {ProfileService} from '../../../core/profile/profile.service';
import {Router} from '@angular/router';
import {SortService} from '../../../core/helper/sort.service';
import {UsageTypeEnum} from '../../../shared/enums/usage-type.enum';
import {hasValue} from '../../../../lib/validator';
import {DocumentEditComponent} from '../../documents/document-edit/document-edit.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {UsageService} from '../../../core/usage/usage.service';
import {UsageItem} from '../../../core/usage/usage.model';

@Component({
  selector: 'app-patient-dashboard-documents',
  templateUrl: './patient-dashboard-documents.component.html',
  styleUrls: ['./patient-dashboard-documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatientDashboardDocumentsComponent extends BaseComponent implements OnInit {
  @Input() isVisible = true;
  @Input() personIdParam: string;
  currentModal: NgbModalRef;
  documents: DocumentHolder[] = [];
  isAddActive = true;
  titleLink = '';
  total = 0;
  usageItem: UsageItem;
  private pageSize = 3;

  constructor(private documentService: DocumentService,
              private modal: NgbModal,
              private router: Router,
              private usageService: UsageService,
              protected profileService: ProfileService,
              protected localeResolverService: LocaleResolverService,
              protected ref: ChangeDetectorRef) {
    super(profileService, localeResolverService, ref);
  }

  ngOnInit() {
    super.ngOnInit();
    this.titleLink = ((this.personIdParam === 'me') ? '/people/' : '/medic/patients/') + this.personIdParam + '/documents';
    this.loadDocuments();
    this.loadDocumentLimits();
  }

  onAddChange() {
    this.showAddModal();
  }

  onClick(id: number) {
    const url = this.titleLink + '/' + id + '/view';
    this.router.navigate([url]);
  }

  private handleSaved(saved: boolean) {
    if (saved === true) {
      this.loadDocuments();
      this.loadDocumentLimits();
    }
  }

  private loadDocuments() {
    this.documentService
      .listDocuments(this.personIdParam, null, null, null, 1, this.pageSize, null)
      .subscribe(
        resp => {
          this.total = resp.count;
          this.documents = resp.results;
          SortService.sortList(this.documents, 'date', false);
          this.documents.forEach(document => {
            document.localized = { docType: '', created_at: this.formatDate(document.created_at), date: this.formatDate(document.date) };
          });
          this.ref.markForCheck();
        },
        e => {
          console.log(e);
        }
      );
  }

  private loadDocumentLimits() {
    this.usageService.getUsageItem(this.personIdParam, UsageTypeEnum.DOCUMENTS).subscribe(res => {
      this.usageItem = res.result;
      if (this.usageItem) {
        // Limit Reached
        if (hasValue(this.usageItem.value) && hasValue(this.usageItem.limit) && this.usageItem.value >= this.usageItem.limit) {
          this.isAddActive = false;
        }

        // Insufficient Storage
        if (hasValue(this.usageItem.quota) && hasValue(this.usageItem.sumSize) && this.usageItem.sumSize >= this.usageItem.quota) {
          this.isAddActive = false;
        }

        this.ref.markForCheck();
      }
    });
  }

  private showAddModal() {
    setTimeout(() => {
      this.currentModal = this.modal.open(DocumentEditComponent, {size: 'lg'});
      this.currentModal.componentInstance.personIdParam = this.personIdParam;
      this.currentModal.componentInstance.requester = this.requester;
      this.currentModal.result.then(saved => this.handleSaved(saved)).catch();
    }, 100);
  }
}
