import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {take} from 'rxjs/operators';
import {AuthDone, AuthenticationService, AuthError} from '../../../core/authentication/authentication.service';
import {BaseAuthComponent} from '../base-auth.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-forgot-password-email',
    templateUrl: './forgot-password-email.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordEmailComponent extends BaseAuthComponent implements OnInit, AuthDone {

    email: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthenticationService,
        protected translateService: TranslateService,
        private toastr: ToastrService
    ) {
        super(translateService);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    onNext() {
        if (this.email) {
            this.authService.requestPasswordReset(this.email, this);
        }
    }

    done(error: AuthError, result: any): void {
        if (error != null) {
            this.toastr.error(error.message, this.translations['error']);
            return;
        } else {
            this.router.navigate(['/forgot-password/', this.email]);
        }
    }
}

@Component({
    selector: 'app-forgot-password-new-password',
    templateUrl: './forgot-password-new-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordNewPasswordComponent extends BaseAuthComponent implements OnInit {

    email: string;
    code: string;
    password: string;
    confirmPassword: string;
    tosAccepted: boolean;
    submitted: boolean;

    errorMessage: string;

    pwdPolicy: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthenticationService,
        protected translateService: TranslateService,
        private toastr: ToastrService,
    ) {
        super(translateService);
    }

    ngOnInit() {
        super.ngOnInit();
        const routeParams$ = this.route.params.pipe(take(1));
        routeParams$.subscribe(p => {
            this.email = p['email'];
        });
    }

    onNext() {
        this.submitted = true;

        const errTitle = this.translations['error'];
        this.pwdPolicy = this.translations['authentication.errors.PasswordPolicyError'];


        if (!this.email || !this.password || !this.confirmPassword) {
            this.errorMessage = this.translations['authentication.errors.EmailPasswordMandatoryError'];
            this.toastr.error(this.errorMessage, errTitle);
            return;
        }

        if (!this.code) {
            this.errorMessage = this.translations['authentication.errors.CodeMandatoryError'];
            this.toastr.error(this.errorMessage, errTitle);
            return;
        }

        if (!this.tosAccepted) {
            this.errorMessage = this.translations['authentication.errors.TosNotAccepted'];
            this.toastr.error(this.errorMessage, errTitle);
            return;
        }

        if (this.password !== this.confirmPassword) {
            this.errorMessage = this.translations['authentication.errors.PasswordsNotMatched'];
            this.toastr.error(this.errorMessage, errTitle);
            return;
        }

        this.authService.passwordReset(this.email, this.password, this.code).subscribe(
          () => {
              this.toastr.success(this.translations['passwordChanged'], this.translations['success']);
              this.router.navigate(['/signin']);
          },
          err => {
              const errCode = err.error.error.code;
              this.toastr.error(this.translations['authentication.errors.' + errCode], this.translations['error']);
          }
        );
    }
}
