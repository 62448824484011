import {ChangeDetectorRef, Injectable, OnDestroy, OnInit} from '@angular/core';
import {LocaleResolverService} from '../+modules/core/locale/locale-resolver.service';
import {formatBytes, formatDate, formatDayDate, formatMonthDayYear} from '../lib/formats';
import {canEdit} from '../lib/utils';
import {ProfileService} from '../+modules/core/profile/profile.service';
import {Person} from '../+modules/core/person/person.model';
import {PersonResponse} from '../+modules/core/response/response.model';
import {MedakteIconTypes} from '../+modules/shared/icon/medakte-icon-types';
import {MedakteIconColors} from '../+modules/shared/icon/medakte-icon-colors';
import {Subscription} from 'rxjs';

@Injectable()
export class BaseComponent implements OnDestroy, OnInit {
  canEdit = canEdit;
  dateFormat: string;
  formatBytes = formatBytes;
  formatDayDate = formatDayDate;
  formatDate = formatDate;
  formatMonthDayYear = formatMonthDayYear;
  medakteIconColors = MedakteIconColors;
  medakteIconTypes = MedakteIconTypes;
  requester: Person;
  private subscriptions: Subscription[] = [];

  constructor(protected profileService: ProfileService,
              protected localeResolverService: LocaleResolverService,
              protected ref: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.dateFormat = this.localeResolverService.getDateFormat();

    this.subscriptions.push(
      this.localeResolverService.localeSubject.subscribe(locale => this.afterLocaleChange(locale)),
      this.profileService.getMyProfile().subscribe((personResponse: PersonResponse) => this.requester = personResponse.result)
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private afterLocaleChange(locale: string) {
    if (locale) {
      this.dateFormat = this.localeResolverService.getDateFormat();
      this.ref.markForCheck();
    }
  }
}
