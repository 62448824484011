import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ProfileService} from '../../core/profile/profile.service';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../core/authentication/authentication.service';
import {TranslatedToastrService} from '../../core/translated-toastr/translated-toastr.service';

@Component({
    selector: 'app-account-deletion',
    templateUrl: './account-deletion.component.html',
    styleUrls: ['./account-deletion.component.scss']
})
export class AccountDeletionComponent implements OnInit {
    @ViewChild('modalContent', {static: true}) modalContent: TemplateRef<any>;
    confirmationCount = 0;
    currentModal: NgbModalRef;
    translations: any;

    constructor(
      private authenticationService: AuthenticationService,
      private modal: NgbModal,
      private profileService: ProfileService,
      private router: Router,
      private translateService: TranslateService,
      private translatedToastrService: TranslatedToastrService,
    ) {
    }

    ngOnInit() {
        this.translateService.get(['error', 'profile.accountDeletion.error']).subscribe(r => {
            this.translations = r;
        });
    }

    removeAccount() {

        this.confirmationCount++;

        if (this.confirmationCount < 2) {
            this.currentModal.close();
            this.currentModal = this.modal.open(this.modalContent, {size: 'lg'});
        } else {
            this.profileService.deleteAccount().subscribe(r => {
                this.currentModal.close();
                // logout
                this.authenticationService.setLoggedOut();
                // remove profile
                this.profileService.myProfileSubject.next(null);
                this.router.navigate(['/signin']);
            }, e => {
                this.currentModal.close();
                this.translatedToastrService.error('profile.accountDeletion.error');
            });
        }
    }

    askToRemove() {
        this.currentModal = this.modal.open(this.modalContent, {size: 'lg'});
    }
}
