import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Disease} from '../../../core/health/disease.model';
import {formatDate} from '../../../../lib/formats';

@Component({
  selector: 'app-emergency-card-diseases',
  templateUrl: './emergency-card-diseases.component.html',
  styleUrls: ['./emergency-card-diseases.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmergencyCardDiseasesComponent implements OnChanges, OnInit {
  @Input() diseases: Disease[] = [];
  @Input() personIdParam: string;

  constructor(protected ref: ChangeDetectorRef) {
  }

  ngOnInit() {
    if (this.diseases.length) {
      this.diseases.forEach(disease => this.localizeDisease(disease));
      this.ref.markForCheck();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const change = changes.diseases || undefined;
    if (change) {
      this.diseases.forEach(disease => this.localizeDisease(disease));
      this.ref.markForCheck();
    }
  }

  private localizeDisease(disease: Disease) {
    disease.localized = { end: formatDate(disease.end), start: formatDate(disease.start) };
  }
}
