import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../core/authentication/authentication.service';
import {NavigationService} from '../../core/navigation/navigation.service';
import {TranslatedToastrService} from '../../core/translated-toastr/translated-toastr.service';
import {take} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {forkJoin} from 'rxjs';

@Component({
  selector: 'pat-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  isLoaded = false;
  isPasswordConfirmVisible = false;
  isPasswordVisible = false;
  isSubmitted = false;
  userFormGroup: FormGroup;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private navigationService: NavigationService,
    private translatedToastrService: TranslatedToastrService,
  ) {
  }

  ngOnInit() {
    this.initFormGroup();

    const routeParams$ = this.activatedRoute.params.pipe(take(1));
    const queryParams$ = this.activatedRoute.queryParams.pipe(take(1));

    forkJoin([routeParams$, queryParams$]).subscribe(respList => {
      if (respList[0]['email']) {
        this.userFormGroup.controls['email'].setValue(respList[0]['email']);
      }
      if (respList[1]['code']) {
        this.userFormGroup.controls['code'].setValue(respList[1]['code']);
      }
      this.isLoaded = true;
    });
  }

  onClickConfirm() {
    this.isSubmitted = true;
    if (this.checkUserFormGroupIsValid() && this.userFormGroup.valid) {
      const code = this.userFormGroup.get('code').value;
      const email = this.userFormGroup.get('email').value;
      const password = this.userFormGroup.get('password').value;
      this.authenticationService.passwordReset(email, password, code).subscribe(
        () => this.onPasswordResetSuccess(),
        err => this.onPasswordResetError(err)
      );
    }
  }

  private checkUserFormGroupIsValid(): boolean {
    const code = this.userFormGroup.get('code').value;
    const email = this.userFormGroup.get('email').value;
    const password = this.userFormGroup.get('password').value;
    const passwordConfirm = this.userFormGroup.get('passwordConfirm').value;

    if (!code || !email || !password || !passwordConfirm) {
      this.translatedToastrService.error('authentication.errors.EmailPasswordMandatoryError');
      return false;
    }

    if (password !== passwordConfirm) {
      this.translatedToastrService.error('authentication.errors.PasswordsNotMatched');
      return false;
    }

    return true;
  }

  private initFormGroup() {
    this.userFormGroup = this.formBuilder.group({
      code: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', Validators.required],
      passwordConfirm: ['', Validators.required],
    });
  }

  private onPasswordResetError(err: any) {
    const errCode = err.error.error.code;
    this.translatedToastrService.error('authentication.errors.' + errCode);
  }

  private onPasswordResetSuccess() {
    this.translatedToastrService.success('authentication.passwordChanged');
    this.navigationService.navigateToLogin();
  }
}
