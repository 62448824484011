import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MedakteIconTypes} from '../../icon/medakte-icon-types';

@Component({
  selector: 'app-privacy-filter',
  templateUrl: './privacy-filter.component.html',
  styleUrls: ['./privacy-filter.component.scss']
})
export class PrivacyFilterComponent {
  @Input() emergencyCardVisible = false;
  @Input() favourite = false;
  @Input() visible = false;
  @Output() emergencyCardVisibleChange = new EventEmitter<boolean>();
  @Output() favouriteChange = new EventEmitter<boolean>();
  @Output() visibleChange = new EventEmitter<boolean>();
  medakteIconTypes = MedakteIconTypes;

  onToggleEmergencyCardVisible() {
    this.emergencyCardVisible = !this.emergencyCardVisible;
    this.emergencyCardVisibleChange.emit(this.emergencyCardVisible ? this.emergencyCardVisible : undefined);
  }

  onToggleFavourite() {
    this.favourite = !this.favourite;
    this.favouriteChange.emit(this.favourite ? this.favourite : undefined);
  }

  onToggleVisible() {
    this.visible = !this.visible;
    this.visibleChange.emit(this.visible ? this.visible : undefined);
  }
}
