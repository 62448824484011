import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-completion',
  templateUrl: './onboarding-completion.component.html',
  styleUrls: ['./onboarding-completion.component.scss']
})
export class OnboardingCompletionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
