export class Profile {
  id: number;
  alcohol: number;
  bloodGroup: number;
  bloodGroupId: number;
  createdAt: Date;
  diet: number;
  emergencyContact: string;
  hearingAid: number;
  height: number;
  organDonor: number;
  sleep: number;
  smoker: boolean;
  specialCharact: string;
  spectacle: number;
  sport: number;
  stress: number;
  updatedAt: Date;
  weight: number;
}
