import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {HeartRate} from '../../../core/measurements/heart-rate.model';
import {NgbDateParserFormatter, NgbDateStruct, NgbModal, NgbModalRef, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment-timezone';
import {HeartRateService} from '../../../core/measurements/heart-rate.service';
import {Person} from '../../../core/person/person.model';
import {take} from 'rxjs/operators';
import {forkJoin} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {ProfileService} from '../../../core/profile/profile.service';
import {HearthRateChartComponent} from './hearth-rate-chart/hearth-rate-chart.component';
import {BaseComponent} from '../../../../base/base-component';
import {LocaleResolverService} from '../../../core/locale/locale-resolver.service';
import {NgbDateDynamicParserFormatter} from '../../../../lib/ngb-date-dynamic-parser-formatter';
import {UsageTypeEnum} from '../../../shared/enums/usage-type.enum';
import {UsageAlertComponent} from '../../../shared/usage/usage-alert/usage-alert.component';
import {EmergencyPassConfig} from '../../emergency-card/emergency-card-config.model';
import {JournalConfig} from '../../../core/measurements/journal-config.model';
import {EmergencyPassService} from '../../emergency-card/emergency-pass.service';
import {JournalService} from '../../../core/measurements/journal.service';
import {DeleteModalComponent} from '../../../shared/delete-modal/delete-modal.component';
import {formatNumber} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {TranslatedToastrService} from '../../../core/translated-toastr/translated-toastr.service';

@Component({
    selector: 'app-heart-rate',
    templateUrl: './heart-rate.component.html',
    styleUrls: ['./heart-rate.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateDynamicParserFormatter }
    ]
})
export class HeartRateComponent extends BaseComponent implements OnInit {
    @ViewChild('chart', { static: true }) chart: HearthRateChartComponent;
    @ViewChild('usageAlert', { static: true }) usageAlert: UsageAlertComponent;

    count: number;
    currentModal: NgbModalRef;
    date: NgbDateStruct;
    emergencyCardConfig: EmergencyPassConfig;
    heartRate: HeartRate;
    heartRates: HeartRate[];
    journalConfig: JournalConfig;
    maxHearthRate = 250;
    minHearthRate = 0;
    pageNumber: number;
    pageSize = 30;
    personIdParam: string;
    readonly = false;
    requestedPerson: Person;
    submitted = false;
    time: NgbTimeStruct;
    unitLabel = 'HF';
    UsageTypeEnum = UsageTypeEnum;
    valid = true;

    constructor(
      private emergencyPassService: EmergencyPassService,
      private heartRateService: HeartRateService,
      private journalService: JournalService,
      private modal: NgbModal,
      private route: ActivatedRoute,
      private translatedToastrService: TranslatedToastrService,
      private translateService: TranslateService,
      protected profileService: ProfileService,
      protected localeResolverService: LocaleResolverService,
      protected ref: ChangeDetectorRef
    ) {
        super(profileService, localeResolverService, ref);
    }

    ngOnInit() {
        super.ngOnInit();

        this.heartRate = new HeartRate();
        this.initDate();

        const routeParams$ = this.route.params.pipe(take(1));

        forkJoin([routeParams$]).subscribe(respList => {
            this.personIdParam = respList[0]['id'];
            this.profileService.setCurrentPersonIdParam(this.personIdParam);

            if (this.personIdParam === 'me') {
                this.emergencyPassService.getMyEmergencyPassConfig().subscribe(r => {
                    this.emergencyCardConfig = r.result;
                    this.ref.markForCheck();
                });
                this.journalService.getMyJournalConfig().subscribe(r => {
                    this.journalConfig = r.result;
                    this.ref.markForCheck();
                });
            }

            this.loadData();

            if (this.personIdParam !== 'me') {
                this.profileService
                    .getProfile(this.personIdParam)
                    .subscribe(resp => {
                        this.requestedPerson = resp.result;
                        this.ref.markForCheck();
                    });
            }
        });
    }

    private loadData() {
        this.initDate();
        this.setPage({ offset: 0 });
    }

    private initDate() {
        const now = moment();
        this.time = { hour: now.hour(), minute: now.minute(), second: 0 };
        this.date = { day: now.date(), month: (now.month() + 1), year: now.year() };
    }

    askToRemove(index: number, heartRate: HeartRate) {
        this.showDeleteModal(index, heartRate);
    }

    // https://github.com/swimlane/ngx-datatable/issues/721
    onActive(event) {
        if (event.type === 'click') {
            event.cellElement.blur();
        }
    }

    onEmergencyCardVisibleChange() {
        this.emergencyPassService.updateMyEmergencyPassConfig(this.emergencyCardConfig).subscribe(
          () => this.translatedToastrService.success('message.success.save'),
          () => this.translatedToastrService.error('message.error.save')
        );
    }

    onVisibleChange() {
        this.journalService.updateMyJournalConfig(this.journalConfig).subscribe(
          () => this.translatedToastrService.success('message.success.save'),
          () => this.translatedToastrService.error('message.error.save')
        );
    }

    validValues() {
        return this.heartRate.bpm != null && this.heartRate.bpm >= this.minHearthRate && this.heartRate.bpm <= this.maxHearthRate;
    }

    save() {
        this.submitted = true;
        if (this.valid && this.validValues()) {
            const date = moment(
                this.date.year + '-' +
                this.date.month + '-' +
                this.date.day + ' ' +
                this.time.hour + ':' +
                this.time.minute
                , 'YYYY-MM-DD HH:mm');

            this.heartRate.date = date.toDate();
            this.heartRateService.create(this.heartRate)
                .subscribe(() => {
                    this.translatedToastrService.success('message.success.save');
                    this.heartRate = new HeartRate();

                    this.loadData();
                    this.chart.fetchValues();
                    this.usageAlert.loadsUsage();
                },
                    () => this.translatedToastrService.error('message.error.save'));
        }
    }

    setPage(pageInfo) {
        this.pageNumber = pageInfo.offset;
        this.heartRateService.listHeartRates(this.personIdParam, this.pageNumber + 1, this.pageSize, null, null, null, 'desc')
            .subscribe(r => {
                this.heartRates = r.results;
                this.count = r.count;
                this.ref.markForCheck();
            });
    }

    showDeleteModal(index: number, heartRate: HeartRate) {
        setTimeout(() => {
            this.currentModal = this.modal.open(DeleteModalComponent, {size: 'lg'});
            this.currentModal.componentInstance.itemHeader = this.formatMonthDayYear(heartRate.date);
            this.currentModal.componentInstance.itemType = this.translateService.instant('journal.heartRate.title');
            this.currentModal.componentInstance.itemValue = formatNumber(heartRate.bpm, this.localeResolverService.locale, '1.0-0') +
              ' ' + this.unitLabel;
            this.currentModal.result.then(isDeleted => {
                if (isDeleted) {
                    this.removeRow(index, heartRate);
                }
            });
        }, 100);
    }

    removeRow(index: number, heartRate: HeartRate) {
        this.heartRateService.deleteHeartRate(heartRate.id).subscribe(() => {
            this.heartRates.splice(index, 1);
            this.heartRates = [...this.heartRates];
            this.chart.fetchValues();
            this.usageAlert.loadsUsage();
            this.ref.markForCheck();
        }, () =>
            this.translatedToastrService.error('message.error.delete'));
    }

    handleValid(valid) {
        this.valid = valid;
    }

    onIsLimitQuotaReached(limitQuotaReached) {
        this.readonly = limitQuotaReached;
        this.ref.markForCheck();
    }
}
