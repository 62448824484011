import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {Immunisation} from '../../../core/health/immunisation.model';
import {NgbDateParserFormatter, NgbDateStruct, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ImmunisationService} from '../../../core/health/immunisation.service';
import {ActivatedRoute} from '@angular/router';
import {Person} from '../../../core/person/person.model';
import {ProfileService} from '../../../core/profile/profile.service';
import {take} from 'rxjs/operators';
import {forkJoin} from 'rxjs';
import {canEdit} from '../../../../lib/utils';
import {NgbDateDynamicParserFormatter} from '../../../../lib/ngb-date-dynamic-parser-formatter';
import {UsageAlertComponent} from '../../../shared/usage/usage-alert/usage-alert.component';
import {UsageTypeEnum} from '../../../shared/enums/usage-type.enum';
import {ImmunisationEditComponent} from './immunisation-edit/immunisation-edit.component';
import {BaseComponent} from '../../../../base/base-component';
import {LocaleResolverService} from '../../../core/locale/locale-resolver.service';
import {QueryFilter} from '../../../core/filters/query-filter.model';
import {fromNgbDateStructToDate} from '../../../../lib/dates';
import {DeleteModalComponent} from '../../../shared/delete-modal/delete-modal.component';
import {TranslateService} from '@ngx-translate/core';
import {TranslatedToastrService} from '../../../core/translated-toastr/translated-toastr.service';
import {SelectionType} from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-immunisations',
  templateUrl: './immunisations.component.html',
  styleUrls: ['./immunisations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateDynamicParserFormatter }
  ]
})
export class ImmunisationsComponent extends BaseComponent implements OnInit {
  @ViewChild('usageAlert', { static: true }) usageAlert: UsageAlertComponent;
  currentModal: NgbModalRef;
  filterDate: NgbDateStruct;
  immunisationId: number;
  immunisations: Immunisation[];
  isLoadingImmunisationDetail = false;
  lastId: number;
  pageSize = 30;
  personIdParam: string;
  query: string;
  queryFilters: QueryFilter[] = [];
  quotaReached = false;
  requestedPerson: Person;
  selectedImmunisation: Immunisation;
  UsageTypeEnum = UsageTypeEnum;
  selected = [];
  SelectionType = SelectionType;

  constructor(
    private activatedRoute: ActivatedRoute,
    private immunisationService: ImmunisationService,
    private modal: NgbModal,
    private translatedToastrService: TranslatedToastrService,
    private translateService: TranslateService,
    protected profileService: ProfileService,
    protected localeResolverService: LocaleResolverService,
    protected ref: ChangeDetectorRef
  ) {
    super(profileService, localeResolverService, ref);
  }

  ngOnInit() {
    super.ngOnInit();

    const routeParams$ = this.activatedRoute.params.pipe(take(1));

    forkJoin([routeParams$]).subscribe(respList => {
      this.personIdParam = respList[0]['id'];
      if ('immunisationId' in respList[0]) {
        this.immunisationId = +(respList[0]['immunisationId']);
      }
      this.loadImmunisations();

      this.profileService.setCurrentPersonIdParam(this.personIdParam);

      if (this.personIdParam !== 'me') {
        this.profileService.getProfile(this.personIdParam).subscribe(resp => {
          this.requestedPerson = resp.result;
          this.ref.markForCheck();
        });
      }
    });
  }

  addNew() {
    this.showAddEditModal();
  }

  askToRemove(event, firstChild) {
    event.stopPropagation();
    firstChild.parentElement.parentElement.blur();
    this.showDeleteModal();
  }

  editItem(event, firstChild) {
    event.stopPropagation();
    firstChild.parentElement.parentElement.blur();
    this.showAddEditModal(this.selectedImmunisation);
  }

  onIsLimitQuotaReached(limitQuotaReached) {
    this.quotaReached = limitQuotaReached;
  }

  loadImmunisationDetail(immId) {
    if (this.selectedImmunisation && this.selectedImmunisation.id === immId) {
      this.selectedImmunisation = null;
      this.selected = [];
    } else {
      this.selectedImmunisation = null;
      this.isLoadingImmunisationDetail = true;
      this.immunisationService.getImmunisation(this.personIdParam, immId).subscribe(r => {
        this.selectedImmunisation = r.result;
        this.isLoadingImmunisationDetail = false;
        this.ref.markForCheck();
      }, () => {
        this.selected = [];
        this.translatedToastrService.error('message.error.immunisation.detail');
      });
    }
  }

  // https://github.com/swimlane/ngx-datatable/issues/721
  onActive(event) {
    if (event.type === 'click') {
      event.cellElement.blur();
      this.loadImmunisationDetail(event.row.id);
    }
  }

  onDateFilterChange(selectedDate: NgbDateStruct, filterName: string) {
    let filterValue;
    if (filterName === 'date') {
      this.filterDate = selectedDate;
      if (this.filterDate) {
        const date = fromNgbDateStructToDate(this.filterDate);
        // check if date is valid
        if (!isNaN(date.getTime())) {
          date.setHours(0, 0, 0, 0);
          filterValue = date.toISOString();
        }
      }
    }

    this.onFilterChange(filterName, filterValue);
  }

  onFilterChange(filterName: string, filterValue: any) {
    const filter = this.queryFilters.find(queryFilter => queryFilter.name === filterName);

    if (filter && filterValue) {
      // update value
      filter.value = filterValue;
    } else if (filter) {
      // unset value
      this.queryFilters.splice(this.queryFilters.lastIndexOf(filter), 1);
    } else if (filterValue) {
      // new value
      this.queryFilters.push({name: filterName, value: filterValue});
    }
    // reload the list
    this.lastId = undefined;
    this.loadImmunisations();
  }

  onSearchChange(search: string) {
    this.query = search;
    this.lastId = null;
    this.loadImmunisations();
  }

  toggleEmergencyCardVisible(event, immunisation?: Immunisation) {
    event.stopPropagation();
    if (immunisation) {
      immunisation.emergencyCardVisible = !immunisation.emergencyCardVisible;

      this.updateImmunisation(immunisation);
    } else if (this.selectedImmunisation) {
      this.selectedImmunisation.emergencyCardVisible = !this.selectedImmunisation.emergencyCardVisible;
      const diseaseIndex = this.immunisations.findIndex(d => d.id === this.selectedImmunisation.id);
      this.immunisations[diseaseIndex].emergencyCardVisible = this.selectedImmunisation.emergencyCardVisible;

      this.updateSelectedImmunisation();
    }
  }

  toggleFavourite(immunisation?: Immunisation) {
    if (immunisation) {
      immunisation.favourite = !immunisation.favourite;

      this.updateImmunisation(immunisation);
    } else if (this.selectedImmunisation) {
      this.selectedImmunisation.favourite = !this.selectedImmunisation.favourite;
      const idx = this.immunisations.findIndex(d => d.id === this.selectedImmunisation.id);
      this.immunisations[idx].favourite = this.selectedImmunisation.favourite;

      this.updateSelectedImmunisation();
    }
  }

  toggleVisibility(event, immunisation?: Immunisation) {
    event.stopPropagation();
    if (immunisation) {
      immunisation.visible = !immunisation.visible;

      this.updateImmunisation(immunisation);
    } else if (this.selectedImmunisation) {
      this.selectedImmunisation.visible = !this.selectedImmunisation.visible;
      const idx = this.immunisations.findIndex(d => d.id === this.selectedImmunisation.id);
      this.immunisations[idx].visible = this.selectedImmunisation.visible;
      this.updateSelectedImmunisation();
    }
  }

  private handleSaved(saved: boolean) {
    if (saved) {
      this.lastId = null;
      this.usageAlert.loadsUsage();
      this.loadImmunisations();
      this.loadImmunisationDetail(this.selectedImmunisation.id);
    }
  }

  private loadImmunisations() {
    this.immunisationService
      .listImmunisations(
        this.personIdParam,
        this.query,
        null,
        this.lastId ? null : 1,
        this.pageSize,
        this.lastId,
        this.queryFilters
      )
      .subscribe(
        resp => {
          this.immunisations = this.lastId ? this.immunisations.concat(resp.results) : resp.results;
          this.immunisations.forEach(m => {
            m.localized = {
              created_at: '',
              date: '',
              taken: ''
            };
            m.localized.created_at = this.formatDayDate(m.created_at);
            m.localized.date = this.formatDayDate(m.date);
          });

          this.lastId = resp.lastId;
          this.immunisations = [...this.immunisations];

          if (this.immunisationId) {
            this.loadImmunisationDetail(this.immunisationId);
            this.immunisationId = undefined;
          }

          this.ref.markForCheck(); // trigger refresh
        },
        e => {
          console.log(e);
          this.translatedToastrService.error('message.error.immunisation.list');
        }
      );
  }

  private removeItem() {
    this.immunisationService
      .deleteImmunisation(this.selectedImmunisation.id)
      .subscribe(
        () => {
          this.translatedToastrService.success('message.success.immunisation.delete');

          this.immunisations = this.immunisations.filter(i => i.id !== this.selectedImmunisation.id);
          // this is a workaround - see https://stackoverflow.com/questions/51704888/remove-rows-with-ngx-datatable
          this.immunisations = [...this.immunisations];

          this.selectedImmunisation = null;
          this.currentModal.close();
          this.usageAlert.loadsUsage();
          this.ref.markForCheck(); // trigger refresh
        },
        () => this.translatedToastrService.error('message.error.immunisation.delete')
      );
  }

  private showAddEditModal(immunisation?: Immunisation) {
    setTimeout(() => {
      this.currentModal = this.modal.open(ImmunisationEditComponent, {size: 'lg'});
      this.currentModal.componentInstance.personIdParam = this.personIdParam;
      this.currentModal.componentInstance.requester = this.requester;
      this.currentModal.componentInstance.immunisation = immunisation;
      if (immunisation) {
        this.currentModal.componentInstance.readonly = !canEdit(this.personIdParam, this.requester.id, null);
      }
      this.currentModal.result.then(saved => this.handleSaved(saved)).catch(e => {
      });
    }, 100);
  }

  private showDeleteModal() {
    setTimeout(() => {
      this.currentModal = this.modal.open(DeleteModalComponent, {size: 'lg'});
      this.currentModal.componentInstance.itemDescription = this.selectedImmunisation.notes;
      this.currentModal.componentInstance.itemHeader = this.selectedImmunisation.title;
      this.currentModal.componentInstance.itemType = this.translateService.instant('immunisation');
      this.currentModal.result.then(isDeleted => {
        if (isDeleted) {
          this.removeItem();
        }
      });
    }, 100);
  }

  private updateImmunisation(immunisation: Immunisation) {
    if (immunisation) {
      this.immunisationService.updateImmunisation(immunisation.id, immunisation).subscribe(
        () => this.translatedToastrService.success('message.success.immunisation.update'),
        () => this.translatedToastrService.error('message.error.immunisation.update')
      );
    }
  }

  private updateSelectedImmunisation() {
    if (this.selectedImmunisation) {
      this.immunisationService.updateImmunisation(this.selectedImmunisation.id, this.selectedImmunisation).subscribe(
        () => this.translatedToastrService.success('message.success.immunisation.update'),
        () => this.translatedToastrService.error('message.error.immunisation.update')
      );
    }
  }
}
