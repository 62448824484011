export class EmergencyPassConfig {
  id: number;
  created_at: Date;
  code: string;
  validTo: Date;
  visibility: {
    emergency_card: boolean;
    person: {
      id: boolean;
      age: boolean;
      gender: boolean;
      phone: boolean;
    },
    profile: {
      alcohol: boolean;
      bloodGroup: boolean;
      bmi: boolean;
      diet: boolean;
      hearingAid: boolean;
      height: boolean;
      organDonor: boolean;
      smoker: boolean;
      specialCharact: boolean;
      spectacle: boolean;
      sport: boolean;
    },
    journal: {
      bloodGlucoses: boolean;
      bloodPressures: boolean;
      bodyTemperatures: boolean;
      feelings: boolean;
      heartRates: boolean;
      pains: boolean;
      sleeps: boolean;
      spo2: boolean;
      stress: boolean;
      weights: boolean;
    }
  };
  person_id: number;
  updated_at: Date;
}
