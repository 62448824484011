import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Profile} from '../../../core/profile/profile.model';
import {formatDateTime} from '../../../../lib/formats';

@Component({
  selector: 'app-profile-height',
  templateUrl: './profile-height.component.html',
  styleUrls: ['./profile-height.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileHeightComponent {
  @Input() isVisible = true;
  @Input() profile: Profile;
  unit = 'cm';

  getSubLabel(): string {
    let subLabel = '-';
    if (this.profile?.height) {
      subLabel = formatDateTime(this.profile.createdAt);
    }
    return subLabel;
  }
}
