import {ConversationMessage} from './conversation-message.model';

export class Conversation {
  public id: number;
  public type: number;
  public recipient: number;
  public recipients: string;
  public photo: string;
  public unseen: number;

  public messages: ConversationMessage[];
}
