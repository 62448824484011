import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {AuthenticationService} from '../../core/authentication/authentication.service';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss'],
})
export class SideNavigationComponent implements OnDestroy, OnInit {
  @Input() isMedic = false;
  @Input() isPatient = false;
  isLoggedIn = false;
  isSideNavigationOpened = false;
  private subscriptions: Subscription[] = [];

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.subscriptions.push(
      this.authenticationService.isLoggedIn.subscribe(isLoggedIn => {
        this.isLoggedIn = isLoggedIn;
      }),
      this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => this.onNavigationEnd()),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  isDefaultSideNavigationVisible(): boolean {
    return !this.isLoggedIn;
  }

  isOnboardingSideNavigationVisible(): boolean {
    return this.isLoggedIn && !this.isPatient && !this.isMedic;
  }

  isMedicSideNavigationVisible(): boolean {
    return this.isLoggedIn && this.isMedic;
  }

  isPatientSideNavigationVisible(): boolean {
    return this.isLoggedIn && this.isPatient;
  }

  onClickSideNavigationBackground() {
    this.onClickToggleSideNavigation();
  }

  onClickToggleSideNavigation() {
    this.isSideNavigationOpened = !this.isSideNavigationOpened;
    this.toggleScrolling();
  }

  private onNavigationEnd() {
    this.isSideNavigationOpened = false;
    this.toggleScrolling();
  }

  private toggleScrolling() {
    const body = document.body;
    const mainPanels = document.getElementsByClassName('main-panel');
    switch (this.isSideNavigationOpened) {
      case true:
        if (!body.classList.contains('overflow-hidden')) {
          body.classList.add('overflow-hidden');

          for (let i = 0; i < mainPanels.length; i++) {
            if (!mainPanels[i].classList.contains('overflow-hidden')) {
              mainPanels[i].classList.add('overflow-hidden');
            }
          }
        }
        break;

      case false:
        body.classList.remove('overflow-hidden');

        for (let i = 0; i < mainPanels.length; i++) {
          mainPanels[i].classList.remove('overflow-hidden');
        }
        break;
    }
  }
}
