import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {DocumentService} from './document.service';
import {DocumentListResponse} from '../../core/response/response.model';
import {DocumentHolder} from './document.model';
import {ActivatedRoute, Router} from '@angular/router';
import {ProfileService} from '../../core/profile/profile.service';
import {NgbDateParserFormatter, NgbDateStruct, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {formatBytes, formatDate, formatDayDate} from '../../../lib/formats';
import {Person} from '../../core/person/person.model';
import {take} from 'rxjs/operators';
import {forkJoin} from 'rxjs';
import {canEdit} from '../../../lib/utils';
import {DocumentEditComponent} from './document-edit/document-edit.component';
import {UsageTypeEnum} from '../../shared/enums/usage-type.enum';
import {UsageAlertComponent} from '../../shared/usage/usage-alert/usage-alert.component';
import {MedakteIconTypes} from '../../shared/icon/medakte-icon-types';
import {MedakteIconColors} from '../../shared/icon/medakte-icon-colors';
import {fromNgbDateStructToDate} from '../../../lib/dates';
import {QueryFilter} from '../../core/filters/query-filter.model';
import {DeleteModalComponent} from '../../shared/delete-modal/delete-modal.component';
import {TranslateService} from '@ngx-translate/core';
import {NgbDateDynamicParserFormatter} from '../../../lib/ngb-date-dynamic-parser-formatter';
import {TranslatedToastrService} from '../../core/translated-toastr/translated-toastr.service';
import {DocumentTypeEnum} from './document-type.enum';
import {SelectionType} from '@swimlane/ngx-datatable';

@Component({
    selector: 'app-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{provide: NgbDateParserFormatter, useClass: NgbDateDynamicParserFormatter}]
})
export class DocumentsComponent implements OnInit {
    @ViewChild('addDocument') addDocument: DocumentEditComponent;
    @ViewChild('usageAlert', {static: true}) usageAlert: UsageAlertComponent;
    canEdit = canEdit;
    currentModal: NgbModalRef;
    documentId: number;
    documents: DocumentHolder[];
    filterDate: NgbDateStruct;
    formatBytes = formatBytes;
    formatDate = formatDate;
    isLoadingDocumentDetails = false;
    lastId: number;
    medakteIconColors = MedakteIconColors;
    medakteIconTypes = MedakteIconTypes;
    pageNumber = 1;
    pageSize = 30;
    personIdParam: string;
    query: string;
    queryFilters: QueryFilter[] = [];
    quotaReached = false;
    requestedPerson: Person;
    requester: Person;
    selectedDocument: DocumentHolder;
    UsageTypeEnum = UsageTypeEnum;
    DocumentTypeEnum = DocumentTypeEnum;
    selected = [];
    SelectionType = SelectionType;

    constructor(
        private activatedRoute: ActivatedRoute,
        private docService: DocumentService,
        private modal: NgbModal,
        private profileService: ProfileService,
        private ref: ChangeDetectorRef,
        private router: Router,
        private translatedToastrService: TranslatedToastrService,
        private translateService: TranslateService,
    ) {
    }

    ngOnInit() {
        const routeParams$ = this.activatedRoute.params.pipe(take(1));
        const queryParams$ = this.activatedRoute.queryParams.pipe(take(1));
        const me$ = this.profileService.getMyProfile();

        forkJoin([routeParams$, queryParams$, me$]).subscribe(respList => {
            this.personIdParam = respList[0]['id'];
            if ('documentId' in respList[0]) {
                this.documentId = +(respList[0]['documentId']);
            }
            this.requester = respList[2].result;

            this.profileService.setCurrentPersonIdParam(this.personIdParam);

            if (this.personIdParam !== 'me') {
                this.profileService
                    .getProfile(this.personIdParam)
                    .subscribe(resp => {
                        this.requestedPerson = resp.result;
                        this.ref.markForCheck();
                    });
            }

            this.loadDocuments();
        });
    }

    onView() {
        this.docService.onView(
            this.personIdParam, this.selectedDocument.id,
          this.selectedDocument.docType,
          this.selectedDocument.type, this.selectedDocument.name
        );
    }

    addNew() {
        this.showAddEditModal();
    }

    askToRemove(event, firstChild) {
        event.stopPropagation();
        firstChild.parentElement.parentElement.blur();
        this.showDeleteModal();
    }

    editItem(event, firstChild)  {
        event.stopPropagation();
        firstChild.parentElement.parentElement.blur();
        this.showAddEditModal(this.selectedDocument);
    }

    onIsLimitQuotaReached(limitQuotaReached) {
        this.quotaReached = limitQuotaReached;
    }

    loadDocumentDetail(documentId: number) {
        if (this.selectedDocument && this.selectedDocument.id === documentId) {
            this.selectedDocument = null;
            this.selected = [];
        } else {
            this.selectedDocument = null;
            this.isLoadingDocumentDetails = true;
            this.docService.getDocument(this.personIdParam, documentId).subscribe(r => {
                this.selectedDocument = r.result;
                this.isLoadingDocumentDetails = false;
                this.ref.markForCheck();
            }, () => {
                this.selected = [];
                this.translatedToastrService.error('message.error.document.detail');
            });
        }
    }

    // https://github.com/swimlane/ngx-datatable/issues/721
    onActive(event) {
        if (event.type === 'click') {
            event.cellElement.blur();
            this.loadDocumentDetail(event.row.id);
        }
    }

    onDateFilterChange(selectedDate: NgbDateStruct, filterName: string) {
        let filterValue;
        if (filterName === 'date') {
            this.filterDate = selectedDate;
            if (this.filterDate) {
                const date = fromNgbDateStructToDate(this.filterDate);
                // check if date is valid
                if (!isNaN(date.getTime())) {
                    date.setHours(0, 0, 0, 0);
                    filterValue = date.toISOString();
                }
            }
        }

        this.onFilterChange(filterName, filterValue);
    }

    onDownload(event) {
        event.stopPropagation();
        this.docService.downloadDocument(this.personIdParam,
          this.selectedDocument.id,
          this.selectedDocument.type, this.selectedDocument.name);
    }

    onFilterChange(filterName: string, filterValue: any) {
        const filter = this.queryFilters.find(queryFilter => queryFilter.name === filterName);

        if (filter && filterValue) {
            // update value
            filter.value = filterValue;
        } else if (filter) {
            // unset value
            this.queryFilters.splice(this.queryFilters.lastIndexOf(filter), 1);
        } else if (filterValue) {
            // new value
            this.queryFilters.push({name: filterName, value: filterValue});
        }
        // reload the list
        this.lastId = undefined;
        this.loadDocuments();
    }

    onSearchChange(search: string) {
        this.query = search;
        this.lastId = null;
        this.loadDocuments();
    }

    setPage(pageInfo) {
        this.pageNumber = pageInfo.offset;
        this.loadDocuments();
    }

    toggleEmergencyCardVisible(event, document?: DocumentHolder) {
        event.stopPropagation();
        if (document) {
            document.emergencyCardVisible = !document.emergencyCardVisible;

            this.updateDocument(document);
        } else if (this.selectedDocument) {
            this.selectedDocument.emergencyCardVisible = !this.selectedDocument.emergencyCardVisible;
            const diseaseIndex = this.documents.findIndex(d => d.id === this.selectedDocument.id);
            this.documents[diseaseIndex].emergencyCardVisible = this.selectedDocument.emergencyCardVisible;

            this.updateSelectedDocument();
        }
    }

    toggleFavourite(document?: DocumentHolder) {
        if (document) {
            document.favourite = !document.favourite;

            this.updateDocument(document);
        } else if (this.selectedDocument) {
            this.selectedDocument.favourite = !this.selectedDocument.favourite;
            const idx = this.documents.findIndex(d => d.id === this.selectedDocument.id);
            this.documents[idx].favourite = this.selectedDocument.favourite;

            this.updateSelectedDocument();
        }
    }

    toggleVisibility(event, document?: DocumentHolder) {
        event.stopPropagation();
        if (document) {
            document.visible = !document.visible;

            this.updateDocument(document);
        } else if (this.selectedDocument) {
            this.selectedDocument.visible = !this.selectedDocument.visible;
            const idx = this.documents.findIndex(d => d.id === this.selectedDocument.id);
            this.documents[idx].visible = this.selectedDocument.visible;

            this.updateSelectedDocument();
        }
    }

    emergencyCardDocumentLink(documentId: number, fileName: string) {
        return this.docService.resolveEmergencyCardDocumentDirectLink(documentId, fileName, this.requester.id, this.requester.code);
    }

    private documentSearchPostprocessing(lastId) {
        this.documents.forEach(d => {
            d.localized = {
                docType: '',
                created_at: '',
                date: '',
            };
            d.localized.created_at = formatDayDate(d.created_at);
            d.localized.date = formatDayDate(d.date);
        });
        this.lastId = lastId;
        this.ref.markForCheck();
    }

    private loadDocuments() {
        this.docService.listDocuments(
          this.personIdParam,
          null,
          this.query,
          null,
          (this.lastId ? null : 1),
          this.pageSize,
          this.lastId,
          this.queryFilters
        )
          .subscribe(
            (resp: DocumentListResponse) => {
                this.documents = this.lastId ? this.documents.concat(resp.results) : resp.results;
                this.documents = [...this.documents];

                if (this.documentId) {
                    this.loadDocumentDetail(this.documentId);
                    this.documentId = undefined;
                }

                this.documentSearchPostprocessing(resp.lastId);
            },
            e => {
                console.log(e);
                this.translatedToastrService.error('message.error.document.list');
            }
          );
    }

    private handleDocumentSaved(saved: boolean) {
        if (saved) {
            this.lastId = null;
            this.usageAlert.loadsUsage();
            this.loadDocuments();
            this.loadDocumentDetail(this.selectedDocument.id);
        }
    }

    private removeDocument() {
        this.docService.deleteDocument(this.selectedDocument.id).subscribe(
          () => {
              this.translatedToastrService.success('message.success.document.delete');

              this.documents = this.documents.filter(d => d.id !== this.selectedDocument.id);
              this.documents = [...this.documents];

              this.selectedDocument = null;
              this.loadDocuments();
              if (this.addDocument) {
                  this.addDocument.resetUsageAlert();
              }
              this.currentModal.close();
              this.ref.markForCheck();
          },
          () => this.translatedToastrService.error('message.error.document.delete')
        );
    }

    private showAddEditModal(document?: DocumentHolder) {
        setTimeout(() => {
            this.currentModal = this.modal.open(DocumentEditComponent, {size: 'lg'});
            this.currentModal.componentInstance.personIdParam = this.personIdParam;
            this.currentModal.componentInstance.requester = this.requester;
            this.currentModal.componentInstance.document = document;
            if (document) {
                this.currentModal.componentInstance.readonly = !canEdit(this.personIdParam, this.requester.id, document.creator.id);
            }
            this.currentModal.result.then(saved => this.handleDocumentSaved(saved)).catch(e => {
            });
        }, 100);
    }

    private showDeleteModal() {
        setTimeout(() => {
            this.currentModal = this.modal.open(DeleteModalComponent, {size: 'lg'});
            this.currentModal.componentInstance.itemDescription = this.selectedDocument.name  + ' / ' +
              formatBytes(this.selectedDocument.size, 1);
            this.currentModal.componentInstance.itemHeader = this.selectedDocument.title;
            this.currentModal.componentInstance.itemType = this.translateService.instant('documents.titleSingular');
            this.currentModal.result.then(isDeleted => {
                if (isDeleted) {
                    this.removeDocument();
                }
            });
        }, 100);
    }

    private updateDocument(document: DocumentHolder) {
        if (document) {
            this.docService.updateDocument(this.personIdParam, document).subscribe(
              () => this.translatedToastrService.success('message.success.document.update'),
              () => this.translatedToastrService.error('message.error.document.update')
            );
        }
    }

    private updateSelectedDocument() {
        if (this.selectedDocument) {
            this.docService.updateDocument(this.personIdParam, this.selectedDocument).subscribe(
              () => this.translatedToastrService.success('message.success.document.update'),
              () => this.translatedToastrService.error('message.error.document.update')
            );
        }
    }
}
