import {Injectable} from '@angular/core';
import {BackendService} from '../backend/backend.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {LocaleResolverService} from '../locale/locale-resolver.service';
import {BodyTemperature} from './body-temperature.model';
import {BodyTemperatureListResponse} from '../response/response.model';

@Injectable()
export class BodyTemperatureService extends BackendService {

    constructor(
        protected http: HttpClient,
        protected localeResolverService: LocaleResolverService,
    ) {
        super(http, localeResolverService);
    }

    create(bodyTemperature: BodyTemperature) {
        const httpOptions = this.createHttpOptions();
        return this.http.post(this.getApiUrl() + '/measurements/bodyTemperatures', bodyTemperature, httpOptions);
    }

    listBodyTemperatures(id: string, page: number, pageSize: number, lastId: number, start: string, end: string, sort = 'asc') {
        const httpHeaders = this.buildBaseHttpHeaders();

        let params = new HttpParams();
        if (page !== undefined && page !== null) {
            params = params.append('page', page.toString());
        }
        if (pageSize !== undefined && pageSize !== null) {
            params = params.append('pageSize', pageSize.toString());
        }
        if (lastId !== undefined && lastId !== null) {
            params = params.append('lastId', lastId.toString());
        }
        if (start !== undefined && start !== null) {
            params = params.append('start', encodeURIComponent(start.toString()));
        }
        if (end !== undefined && end !== null) {
            params = params.append('end', encodeURIComponent(end.toString()));
        }
        if (sort !== undefined && sort !== null) {
            params = params.append('sort', encodeURIComponent(sort.toString()));
        }

        return this.http.get<BodyTemperatureListResponse>(
          this.getApiUrl() + '/people/' + id + '/measurements/bodyTemperatures', (params.keys().length > 0) ? {
            headers: httpHeaders,
            params: params
        } : {headers: httpHeaders});
    }

    loadLatestBodyTemperature(personIdParam: string) {
        return this.listBodyTemperatures(personIdParam, 1, 1, null, null, null, 'desc');
    }

    listBodyTemperatureStats(id: string, start: string, end: string) {
        const httpHeaders = this.createHttpHeaders();

        let params = new HttpParams();
        if (start !== undefined && start !== null) {
            params = params.append('start', encodeURIComponent(start.toString()));
        }
        if (end !== undefined && end !== null) {
            params = params.append('end', encodeURIComponent(end.toString()));
        }

        return this.http.get<BodyTemperatureListResponse>(
          this.getApiUrl() + '/people/' + id + '/measurements/bodyTemperatureStats', (params.keys().length > 0) ? {
            headers: httpHeaders,
            params: params
        } : {headers: httpHeaders});
    }

    delete(id: number) {
        const httpOptions = this.createHttpOptions();
        return this.http.delete(this.getApiUrl() + '/measurements/bodyTemperatures/' + id, httpOptions);
    }
}
