import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../../../../base/base-component';
import {LocaleResolverService} from '../../../core/locale/locale-resolver.service';
import {NgbActiveModal, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {EmergencyPass} from '../emergency-card.model';
import {ProfileService} from '../../../core/profile/profile.service';
import {TranslateService} from '@ngx-translate/core';
import {EmergencyPassService} from '../emergency-pass.service';
import * as moment from 'moment-timezone';
import {fromNgbDateWithEndDateTimeStructToDate, toNgbDateStruct} from '../../../../lib/dates';


@Component({
  selector: 'app-emergency-card-share',
  templateUrl: './emergency-card-share.component.html',
  styleUrls: ['./emergency-card-share.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmergencyCardShareComponent extends BaseComponent implements OnInit {
  @Input() emergencyCard: EmergencyPass;

  shareFormGroup: FormGroup;
  emailBody = '';
  emailSubject = '';
  url: string = null;

  minDate: NgbDateStruct = {day: 1, month: 9, year: 2022};
  maxDate: NgbDateStruct = {day: 1, month: 1, year: 2122};

  validTo: NgbDateStruct;

  changed = false as boolean;

  constructor(protected profileService: ProfileService,
              protected localeResolverService: LocaleResolverService,
              protected ref: ChangeDetectorRef,
              private activeModal: NgbActiveModal,
              private fb: FormBuilder,
              private translateService: TranslateService,
              private emergencyPassService: EmergencyPassService
  ) {
    super(profileService, localeResolverService, ref);
  }

  ngOnInit() {
    super.ngOnInit();

    const now = moment();
    this.minDate = { day: now.date(), month: (now.month() + 1), year: now.year() };

    this.url = this.emergencyCard ? this.emergencyCard.url : '';
    this.emailBody = encodeURIComponent(this.translateService.instant('emergencyPass.share.emailBody', {url: this.url}));
    this.emailSubject = this.translateService.instant('emergencyPass.share.emailSubject');
    this.initValidTo();

    this.shareFormGroup = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      validTo: [null, (fc: FormControl) => {
        return fc.value == null || this.validateValidTo(fc.value) ? null : {wrongValidTo: true};
      }],
    });

    this.shareFormGroup.patchValue({email: null, validTo: this.emergencyCard.config.validTo});

    this.ref.markForCheck();
  }

  initValidTo() {
    if (this.emergencyCard.config.validTo) {
      this.validTo = toNgbDateStruct(this.emergencyCard.config.validTo);
    } else {
      this.validTo = null;
    }
  }

  close() {
    this.activeModal.close(this.changed);
  }

  copy(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }

  send() {
    if (this.shareFormGroup.controls['email'].valid) {
      const mailTo = 'mailto:' + this.shareFormGroup.controls['email'].value + '?subject=' + this.emailSubject + '&body=' + this.emailBody;
      window.location.href = mailTo;
    }
  }

  downloadQrCode() {
    const qrCodeLink =  document.createElement('a');
    qrCodeLink.download = 'emergency_pass_qr.png';
    qrCodeLink.href =  document.getElementsByTagName('canvas')[0].toDataURL();
    qrCodeLink.click();
  }

  renewCode() {
    this.emergencyPassService.renewCode()
        .subscribe(r => {
          // @ts-ignore
          this.emergencyCard.config.code = r.result.code;
            this.handleUpdate();
        });
  }

  validateValidTo(date) {
      if (date != null) {
          return moment(date).isAfter(moment());
      }
      return true;
  }

  onValidToChange(validToDateStruct: NgbDateStruct) {
    const validTo = fromNgbDateWithEndDateTimeStructToDate(validToDateStruct);
    this.shareFormGroup.controls['validTo'].setValue(validTo);
    this.ref.markForCheck();

    this.emergencyPassService.updateValidity(validTo)
        .subscribe(r => {
          // @ts-ignore
          this.handleUpdate();
        });
  }

  handleUpdate() {
      this.changed = true;
      this.emergencyPassService.getEmergencyPass(this.emergencyCard.config.code).subscribe(resp => {
          this.emergencyCard = resp.result;
          this.url = this.emergencyCard ? this.emergencyCard.url : '';
          this.ref.markForCheck();
      });
  }
}
