import {Injectable} from '@angular/core';
import {NgbDatepickerI18n, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {LocaleResolverService} from '../locale/locale-resolver.service';

@Injectable()
export class DatePickerService extends NgbDatepickerI18n {
  constructor(protected localeResolverService: LocaleResolverService) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    const date = new Date(Date.UTC(1900, 0, weekday, 3, 1, 0 ));
    const options = {weekday: 'short'};
    return date.toLocaleDateString(this.localeResolverService.locale, options);
  }

  getMonthShortName(month: number): string {
    const date = new Date(Date.UTC(1900, month - 1, 1, 3, 1, 0 ));
    const options = {month: 'short'};
    return date.toLocaleDateString(this.localeResolverService.locale, options);
  }

  getMonthFullName(month: number): string {
    const date = new Date(Date.UTC(1900, month - 1, 1, 3, 1, 0 ));
    const options = {month: 'long'};
    return date.toLocaleDateString(this.localeResolverService.locale, options);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    // const dateFormat = this.localeResolverService.getDateFormat();
    // const date = fromNgbDateStructToDate(dateStruct);
    return `${date.day}-${date.month}-${date.year}`;
  }
}
