import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-label-value',
  templateUrl: './label-value.component.html',
  styleUrls: ['./label-value.component.scss']
})
export class LabelValueComponent {
  @Input() isVisible = true;
  @Input() label: string;
  @Input() subLabel: string;
}
