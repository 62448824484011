import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {Allergy} from '../../../core/health/allergy.model';
import {NgbDateParserFormatter, NgbDateStruct, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {AllergyService} from '../../../core/health/allergy.service';
import {ActivatedRoute} from '@angular/router';
import {canEdit} from '../../../../lib/utils';
import {take} from 'rxjs/operators';
import {forkJoin} from 'rxjs';
import {ProfileService} from '../../../core/profile/profile.service';
import {Person} from '../../../core/person/person.model';
import {UsageAlertComponent} from '../../../shared/usage/usage-alert/usage-alert.component';
import {UsageTypeEnum} from '../../../shared/enums/usage-type.enum';
import {AllergyEditComponent} from './allergy-edit/allergy-edit.component';
import {BaseComponent} from '../../../../base/base-component';
import {LocaleResolverService} from '../../../core/locale/locale-resolver.service';
import {QueryFilter} from '../../../core/filters/query-filter.model';
import {fromNgbDateStructToDate} from '../../../../lib/dates';
import {NgbDateDynamicParserFormatter} from '../../../../lib/ngb-date-dynamic-parser-formatter';
import {DeleteModalComponent} from '../../../shared/delete-modal/delete-modal.component';
import {TranslateService} from '@ngx-translate/core';
import {TranslatedToastrService} from '../../../core/translated-toastr/translated-toastr.service';
import {SelectionType} from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-allergies',
  templateUrl: './allergies.component.html',
  styleUrls: ['./allergies.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{provide: NgbDateParserFormatter, useClass: NgbDateDynamicParserFormatter}]
})
export class AllergiesComponent extends BaseComponent implements OnInit {
  @ViewChild('usageAlert', { static: true }) usageAlert: UsageAlertComponent;
  allergies: Allergy[];
  allergyId: number;
  currentModal: NgbModalRef;
  filterEnd: NgbDateStruct;
  filterStart: NgbDateStruct;
  isLoadingAllergyDetail = false;
  lastId: number;
  pageSize = 30;
  personIdParam: string;
  query: string;
  queryFilters: QueryFilter[] = [];
  quotaReached = false;
  requestedPerson: Person;
  selectedAllergy: Allergy;
  UsageTypeEnum = UsageTypeEnum;
  selected = [];
  SelectionType = SelectionType;

  constructor(
    private activatedRoute: ActivatedRoute,
    private allergyService: AllergyService,
    private modal: NgbModal,
    private translatedToastrService: TranslatedToastrService,
    private translateService: TranslateService,
    protected profileService: ProfileService,
    protected localeResolverService: LocaleResolverService,
    protected ref: ChangeDetectorRef
  ) {
    super(profileService, localeResolverService, ref);
  }

  ngOnInit() {
    super.ngOnInit();

    const routeParams$ = this.activatedRoute.params.pipe(take(1));

    forkJoin([routeParams$]).subscribe(respList => {
      this.personIdParam = respList[0]['id'];
      if ('allergyId' in respList[0]) {
        this.allergyId = +(respList[0]['allergyId']);
      }
      this.queryFilters = [];
      this.loadAllergies();

      this.profileService.setCurrentPersonIdParam(this.personIdParam);

      if (this.personIdParam !== 'me') {
        this.profileService.getProfile(this.personIdParam).subscribe(resp => {
          this.requestedPerson = resp.result;
          this.ref.markForCheck();
        });
      }
    });
  }

  addNew() {
    this.showAddEditModal();
  }

  askToRemove(event, firstChild) {
    event.stopPropagation();
    firstChild.parentElement.parentElement.blur();
    this.showDeleteModal();
  }

  editItem(event, firstChild) {
    event.stopPropagation();
    firstChild.parentElement.parentElement.blur();
    this.showAddEditModal(this.selectedAllergy);
  }

  onIsLimitQuotaReached(limitQuotaReached) {
    this.quotaReached = limitQuotaReached;
  }

  loadAllergyDetail(allergyId) {
    if (this.selectedAllergy && this.selectedAllergy.id === allergyId) {
      this.selectedAllergy = null;
      this.selected = [];
    } else {
      this.selectedAllergy = null;
      this.isLoadingAllergyDetail = true;
      this.allergyService.getAllergy(this.personIdParam, allergyId).subscribe(r => {
        this.selectedAllergy = r.result;
        this.isLoadingAllergyDetail = false;
        this.ref.markForCheck();
      }, () => {
        this.selected = [];
        this.translatedToastrService.error('message.error.allergy.detail');
      });
    }
  }

  // https://github.com/swimlane/ngx-datatable/issues/721
  onActive(event) {
    if (event.type === 'click') {
      this.loadAllergyDetail(event.row.id);
      event.cellElement.blur();
    }
  }
  onDateFilterChange(selectedDate: NgbDateStruct, filterName: string) {
    let filterValue;
    switch (filterName) {
      case 'end':
        this.filterEnd = selectedDate;
        if (this.filterEnd) {
          const endDate = fromNgbDateStructToDate(this.filterEnd);
          // check if date is valid
          if (!isNaN(endDate.getTime())) {
            endDate.setHours(23, 59, 59, 59);
            filterValue = endDate.toISOString();
          }
        }
        break;

      case 'start':
        this.filterStart = selectedDate;
        if (this.filterStart) {
          const startDate = fromNgbDateStructToDate(this.filterStart);
          // check if date is valid
          if (!isNaN(startDate.getTime())) {
            startDate.setHours(0, 0, 0, 0);
            filterValue = startDate.toISOString();
          }
        }
        break;
    }

    this.onFilterChange(filterName, filterValue);
  }

  onFilterChange(filterName: string, filterValue: any) {
    const filter = this.queryFilters.find(queryFilter => queryFilter.name === filterName);

    if (filter && filterValue) {
      // update value
      filter.value = filterValue;
    } else if (filter) {
      // unset value
      this.queryFilters.splice(this.queryFilters.lastIndexOf(filter), 1);
    } else if (filterValue) {
      // new value
      this.queryFilters.push({name: filterName, value: filterValue});
    }
    // reload the list
    this.lastId = undefined;
    this.loadAllergies();
  }

  onSearchChange(search: string) {
    this.query = search;
    this.lastId = null;
    this.loadAllergies();
  }

  toggleEmergencyCardVisible(event, allergy?: Allergy) {
    event.stopPropagation();
    if (allergy) {
      allergy.emergencyCardVisible = !allergy.emergencyCardVisible;

      this.updateAllergy(allergy);
    } else if (this.selectedAllergy) {
      this.selectedAllergy.emergencyCardVisible = !this.selectedAllergy.emergencyCardVisible;
      const diseaseIndex = this.allergies.findIndex(d => d.id === this.selectedAllergy.id);
      this.allergies[diseaseIndex].emergencyCardVisible = this.selectedAllergy.emergencyCardVisible;

      this.updateSelectedAllergy();
    }
  }

  toggleFavourite(allergy?: Allergy) {
    if (allergy) {
      allergy.favourite = !allergy.favourite;

      this.updateAllergy(allergy);
    } else if (this.selectedAllergy) {
      this.selectedAllergy.favourite = !this.selectedAllergy.favourite;
      const idx = this.allergies.findIndex(d => d.id === this.selectedAllergy.id);
      this.allergies[idx].favourite = this.selectedAllergy.favourite;

      this.updateSelectedAllergy();
    }
  }

  toggleVisibility(event, allergy?: Allergy) {
    event.stopPropagation();
    if (allergy) {
      allergy.visible = !allergy.visible;

      this.updateAllergy(allergy);
    } else if (this.selectedAllergy) {
      this.selectedAllergy.visible = !this.selectedAllergy.visible;
      const diseaseIndex = this.allergies.findIndex(d => d.id === this.selectedAllergy.id);
      this.allergies[diseaseIndex].visible = this.selectedAllergy.visible;
      this.updateSelectedAllergy();
    }
  }

  private handleSaved(saved: boolean) {
    if (saved === true) {
      this.lastId = null;
      this.usageAlert.loadsUsage();
      this.loadAllergies();
      this.loadAllergyDetail(this.selectedAllergy.id);
    }
  }

  private loadAllergies() {
    this.allergyService
      .listAllergies(
        this.personIdParam,
        this.query,
        null,
        this.lastId ? null : 1,
        this.pageSize,
        this.lastId,
        this.queryFilters
      )
      .subscribe(
        resp => {
          this.allergies = this.lastId ? this.allergies.concat(resp.results) : resp.results;
          this.allergies.forEach(m => {
            m.localized = {
              category: '',
              end: '',
              severity: '',
              start: '',
              created_at: ''
            };
            m.localized.created_at = this.formatDayDate(m.created_at);
            m.localized.start = this.formatDayDate(m.start);
          });

          this.lastId = resp.lastId;
          this.allergies = [...this.allergies];

          if (this.allergyId) {
            this.loadAllergyDetail(this.allergyId);
            this.allergyId = undefined;
          }

          this.ref.markForCheck(); // trigger refresh
        },
        e => {
          console.log(e);
          this.translatedToastrService.error('message.error.allergy.list');
        }
      );
  }

  private removeItem() {
    this.allergyService.deleteAllergy(this.selectedAllergy.id).subscribe(
      () => {
        this.translatedToastrService.success('message.success.allergy.delete');

        this.allergies = this.allergies.filter(d => d.id !== this.selectedAllergy.id);
        // this is a workaround - see https://stackoverflow.com/questions/51704888/remove-rows-with-ngx-datatable
        this.allergies = [...this.allergies];

        this.selectedAllergy = null;
        this.currentModal.close();
        this.usageAlert.loadsUsage();
        this.ref.markForCheck(); // trigger refresh
      },
      () => this.translatedToastrService.error('message.error.allergy.delete')
    );
  }

  private showAddEditModal(allergy?: Allergy) {
    setTimeout(() => {
      this.currentModal = this.modal.open(AllergyEditComponent, {size: 'lg'});
      this.currentModal.componentInstance.personIdParam = this.personIdParam;
      this.currentModal.componentInstance.requester = this.requester;
      this.currentModal.componentInstance.allergy = allergy;
      if (allergy) {
        this.currentModal.componentInstance.readonly = !canEdit(this.personIdParam, this.requester.id, null);
      }
      this.currentModal.result.then(saved => this.handleSaved(saved)).catch(e => {
      });
    }, 100);
  }

  private showDeleteModal() {
    setTimeout(() => {
      this.currentModal = this.modal.open(DeleteModalComponent, {size: 'lg'});
      this.currentModal.componentInstance.itemDescription = this.selectedAllergy.notes;
      this.currentModal.componentInstance.itemHeader = this.selectedAllergy.title;
      this.currentModal.componentInstance.itemType = this.translateService.instant('allergy');
      this.currentModal.result.then(isDeleted => {
        if (isDeleted) {
          this.removeItem();
        }
      });
    }, 100);
  }

  private updateAllergy(allergy: Allergy) {
    if (allergy) {
      this.allergyService.updateAllergy(allergy.id, allergy).subscribe(
        () => this.translatedToastrService.success('message.success.allergy.update'),
        () => this.translatedToastrService.error('message.error.allergy.update')
      );
    }
  }

  private updateSelectedAllergy() {
    if (this.selectedAllergy) {
      this.allergyService.updateAllergy(this.selectedAllergy.id, this.selectedAllergy).subscribe(
        () => this.translatedToastrService.success('message.success.allergy.update'),
        () => this.translatedToastrService.error('message.error.allergy.update')
      );
    }
  }

}
