import {Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {CookieService} from 'ngx-cookie-service';
import {AuthDone, AuthenticationService, AuthError} from '../../core/authentication/authentication.service';
import {Router} from '@angular/router';
import {Location} from '@angular/common';

@Injectable()
export class RefreshTokenService implements AuthDone {

    private running = false;
    private interval;

    private offsetSeconds = (60 * 7);

    constructor(
        private router: Router,
        private authService: AuthenticationService,
        private cookieService: CookieService,
        private location: Location
    ) {
    }

    start() {
        if (!this.running) {
            this.verifyTokenExpiration();
            this.interval = setInterval(() => {
                this.verifyTokenExpiration();
            }, 1000 * 60);

            this.running = true;
        }
    }

    stop() {
        this.running = false;
        clearInterval(this.interval);
    }

    private async verifyTokenExpiration() {

        const idToken = this.cookieService.get('idToken');
        if (!idToken) {
            return;
        }

        const helper = new JwtHelperService();
        const expirationDate = helper.getTokenExpirationDate(idToken);

        this.logTrace(expirationDate);

        if (helper.isTokenExpired(idToken, this.offsetSeconds)) {
            console.log('Refreshing token ...');
            this.authService.refreshTokens(this);
        }
    }

    done(error: AuthError, result: any): void {
        if (error) {

            if (error.code && error.code === 'MissingRefreshToken') {
                this.stop();
            }

            if (!this.authService.isNotProtectedLocation()) {
                this.router.navigate(['/signin']);
            }
        }
    }

    private logTrace(expirationDate) {
        if (expirationDate) {
            const expMillis = (expirationDate.getTime() - new Date().getTime());

            console.log('Expiration date: ' + expirationDate);
            console.log('Token expires in minutes: ' + (expMillis / (1000 * 60)));
        }
    }
}
