import {Injectable} from '@angular/core';
import {Specialty} from '../../+medic/medic-specialty/specialty.model';

@Injectable()
export class SortService {
  static sortList(list: any[], sortKey: string, asc = true) {
    if (list && list.length > 1) {
      // sort list by sortKey and asc or desc
      list.sort((itemA: any, itemB: any) => {
        if (asc) {
          // ascending sort
          return itemA[sortKey] > itemB[sortKey]
            ? 1
            : itemA[sortKey] < itemB[sortKey]
              ? -1
              : 0;
        } else {
          // descending sort
          return itemA[sortKey] < itemB[sortKey]
            ? 1
            : itemA[sortKey] > itemB[sortKey]
              ? -1
              : 0;
        }
      });
    }
  }

  static sortSpecialties(specialties: Specialty[]) {
    specialties.sort((specialtyA: Specialty, specialtyB: Specialty) => {
      // ascending sort
      const specialtyCodesA = specialtyA.code.split('.');
      const specialtyCodesB = specialtyB.code.split('.');
      for (let idx = 0; idx < Math.max(specialtyCodesA.length, specialtyCodesB.length); idx++) {
        if (+specialtyCodesA[idx] > +specialtyCodesB[idx]) { return 1; }
        if (+specialtyCodesA[idx] < +specialtyCodesB[idx]) { return -1; }
      }
      return 0;
    });
  }
}
