export class SubsPlan {
  annual: number;
  code: number;
  name: string;
  price: number;
  stripeMonthlyPlan: string;
  stripeYearlyPlan: string;
  title: string;

  plan: {
    code: number;
    modules: string[],
    limits: {
      health: {
        diseases: {
          limit: number;
        },
        operations: {
          limit: number;
        },
        medications: {
          limit: number;
        },
        allergies: {
          limit: number;
        },
        immunisations: {
          limit: number;
        },
        familyDiseases: {
          limit: number;
        },
      },
      journal: {
        bloodPressures: {
          limit: number;
        },
        heartRates: {
          limit: number;
        },
        pains: {
          limit: number;
        },
        bloodGlucoses: {
          limit: number;
        },
        spo2: {
          limit: number;
        },
        feelings: {
          limit: number;
        },
        weights: {
          limit: number;
        },
        sleep: {
          limit: number;
        },
        stress: {
          limit: number;
        },
        bodyTemperatures: {
          limit: number;
        }
      },
      documents: {
        quota: number;
      }
    }
  };
}
