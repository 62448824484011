import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {GoogleService} from '../../+modules/core/google/google.service';

import * as moment from 'moment-timezone';

// import * as moment from 'moment';
// import 'moment-timezone';

@Component({
  selector: 'app-google',
  templateUrl: './google.component.html',
  styleUrls: ['./google.component.scss']
})
export class GoogleComponent implements OnInit {

  googleAuthUrl: string;
  timezone: string;
  currentDate: string;

  constructor(private router: Router, private googleService: GoogleService) { }

  ngOnInit() {
    this.googleService.getGoogleAuthUrl().subscribe(res => {
      this.googleAuthUrl = res.result.url;
    });


    this.timezone = moment.tz.guess();

    this.currentDate = moment.tz(new Date(), this.timezone).format();

  }

}
