import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LocaleResolverService} from '../locale/locale-resolver.service';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {JwtHelperService} from '@auth0/angular-jwt';
import {CookieService} from 'ngx-cookie-service';
import {BackendService} from '../backend/backend.service';
import {GeneralResponse, MfaResponse} from '../response/response.model';
import {environment} from '../../../../environments/environment';
import {Location} from '@angular/common';
import {map} from 'rxjs/operators';
import {ProfileService} from '../profile/profile.service';

export interface AuthDone {
    done(error: AuthError, result: any): void;
}

export interface AuthError {
    code: string;
    title: string;
    message: string;
}

@Injectable()
export class AuthenticationService extends BackendService {
    isAdminSupport: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    isMedic: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    password: string;
    userName: string;

    constructor(
        private profileService: ProfileService,
        protected http: HttpClient,
        protected translateService: TranslateService,
        protected cookieService: CookieService,
        protected localeResolverService: LocaleResolverService,
        protected location: Location
    ) {
        super(http, localeResolverService);
    }

    changePassword(oldPassword: string, password: string, authDone: AuthDone) {
        const httpOptions = this.initOptions();
        return this.http
          .put<GeneralResponse>(this.getApiUrl() + '/auth/password', {oldPassword: oldPassword, password: password}, httpOptions)
          .subscribe(resp => authDone.done(null, resp.result),
            err => this.handleError(err, authDone));
    }

    clearCredentials() {
        this.userName = null;
        this.password = null;
    }

    confirmAccount(userName: string, code: string): Observable<GeneralResponse> {
        const httpOptions = this.initOptions();
        const body = {
            code: code,
            userName: userName,
        };
        return this.http.post<GeneralResponse>(this.getApiUrl() + '/auth/account/confirmation', body, httpOptions);
    }

    createTimeBasedOneTimePassword(): Observable<GeneralResponse> {
        const httpOptions = this.initOptions();
        return this.http.post<GeneralResponse>(this.getApiUrl() + '/auth/totp', {}, httpOptions);
    }

    getTimeBasedOneTimePassword(): Observable<MfaResponse> {
        const httpOptions = this.initOptions();
        return this.http.get<MfaResponse>(this.getApiUrl() + '/auth/totp', httpOptions);
    }

    isAuthenticated() {
        const httpOptions = this.initOptions();
        return this.http.get(this.getApiUrl() + '/auth', httpOptions);
    }

    isNotProtectedLocation(): boolean {
        if (this.location.path().startsWith('/registration') ||
          this.location.path().startsWith('/signup') ||
          this.location.path().startsWith('/confirm-registration') ||
          this.location.path().startsWith('/login/reset-password') ||
          this.location.path().startsWith('/emergencyPasses')) {
            return true;
        }
    }

    notifyIsAdminSupport() {
        const idToken = this.cookieService.get('idToken');
        if (!idToken) {
            this.isAdminSupport.next(false);
            return;
        }

        const helper = new JwtHelperService();
        const token = helper.decodeToken(idToken);

        if (token &&
          Array.isArray(token.groups) &&
          token.groups.indexOf(environment.auth.adminSupportGroup) >= 0) {
            this.isAdminSupport.next(true);
        } else {
            this.isAdminSupport.next(false);
        }
    }

    passwordReset(userName: string, password: string, code: string): Observable<GeneralResponse> {
        const httpOptions = this.initOptions();
        const body = {
            code: code,
            password: password,
            userName: userName,
        };
        return this.http.post<GeneralResponse>(this.getApiUrl() + '/auth/password/reset', body, httpOptions);
    }

    refreshTokens(authDone: AuthDone) {
        const httpOptions = this.initOptions();
        return this.http
          .post<GeneralResponse>(this.getApiUrl() + '/auth/tokens/refresh', {}, httpOptions)
          .subscribe(resp => authDone.done(null, resp.result),
            err => this.handleError(err, authDone));
    }

    removeTimeBasedOneTimePassword(): Observable<GeneralResponse> {
        const httpOptions = this.initOptions();
        return this.http.delete<GeneralResponse>(this.getApiUrl() + '/auth/totp', httpOptions);
    }

    requestPasswordReset(userName: string, authDone: AuthDone) {
        const httpOptions = this.initOptions();
        return this.http
          .post<GeneralResponse>(this.getApiUrl() + '/auth/password/reset-request', {userName: userName}, httpOptions)
          .subscribe(resp => authDone.done(null, resp.result),
            err => this.handleError(err, authDone));
    }

    resendVerificationCode(userName: string): Observable<GeneralResponse> {
        const httpOptions = this.initOptions();
        return this.http.post<GeneralResponse>(this.getApiUrl() + '/auth/code/reset', {
            userName: userName
        }, httpOptions);
    }

    setCredentials(userName: string, password: string) {
        this.userName = userName;
        this.password = password;
    }

    setMedic() {
        this.isMedic.next(true);
    }

    setLoggedIn() {
        this.isLoggedIn.next(true);
        this.notifyIsAdminSupport();
    }

    setLoggedOut() {
        this.isAdminSupport.next(false);
        this.isLoggedIn.next(false);
        this.isMedic.next(false);

        this.profileService.clearProfileCache();
        this.profileService.deleteProfileDataFromCache('me');
    }

    signIn(userName: string, password: string, token: string, keepMeLoggedIn: boolean): Observable<GeneralResponse> {
        const httpOptions = this.initOptions();
        return this.http
          .post<GeneralResponse>(this.getApiUrl() + '/auth/signin', {
              userName: userName,
              password: password,
              token: token,
              keepMeLoggedIn: keepMeLoggedIn
          }, httpOptions);
    }

    signOut() {
        const httpOptions = this.initOptions();
        return this.http
          .post<GeneralResponse>(this.getApiUrl() + '/auth/signout', {}, httpOptions);
          /*.subscribe(resp => {
                this.setLoggedOut();
                if (authDone) {
                    authDone.done(null, resp.result);
                }
            },
            err => {
                this.setLoggedOut();
                this.handleError(err, authDone);
            }
          );*/
    }

    signUp(userName: string, password: string, attrs: any): Observable<GeneralResponse> {
        const httpOptions = this.initOptions();
        const body = {
            attrs: attrs,
            password: password,
            userName: userName,
        };
        return this.http.post<GeneralResponse>(this.getApiUrl() + '/auth/signup', body, httpOptions);
    }

    verifyTimeBasedOneTimePassword(token): Observable<GeneralResponse> {
        const httpOptions = this.initOptions();
        return this.http.post<GeneralResponse>(this.getApiUrl() + '/auth/totp/verification', {token: token}, httpOptions);
    }

    private handleError(err, authDone: AuthDone) {
        if (err &&
          err.error &&
          err.error.error) {
            const errCode = err.error.error.code;
            const transId = 'authentication.errors.' + errCode;

            this.translateService.get(['error', transId]).subscribe(r => {
                console.log(errCode); // TODO
                authDone.done({code: errCode, title: r['error'], message: r[transId]}, null);
            });
        } else {
            console.log('Error'); // TODO
            authDone.done({code: 'AuthenticationError', title: 'Error', message: 'Error'}, null);
        }
    }
}
