import {Component, OnInit} from '@angular/core';
import {MedicalService} from '../../core/medical/medical.service';
import {MedicView} from '../../core/medic/medic-view.model';
import {DocumentService} from '../documents/document.service';
import {MedicOverview} from '../../core/medic/medic-overview.model';
import {formatNumber} from '@angular/common';
import {LocaleResolverService} from '../../core/locale/locale-resolver.service';
import {MedakteIconTypes} from '../../shared/icon/medakte-icon-types';
import {MedakteIconColors} from '../../shared/icon/medakte-icon-colors';
import {take} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {QueryFilter} from '../../core/filters/query-filter.model';
import {ConnectionStatus} from '../../core/medical/connection-status';
import {hasValue} from '../../../lib/validator';
import {DeleteModalComponent} from '../../shared/delete-modal/delete-modal.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ProfileService} from '../../core/profile/profile.service';
import {SelectionType} from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-patient-medics',
  templateUrl: './patient-medics.component.html',
  styleUrls: ['./patient-medics.component.scss']
})
export class PatientMedicsComponent implements OnInit {
  currentModal: NgbModalRef;
  isLoaded = false;
  isLoadingMedicDetails = false;
  isMedicsConnectedLoaded = false;
  isMedicsPendingLoaded = false;
  isSearchedMedicsLoaded = false;
  lastSearchedId: number;
  locale: string;
  medicId: number;
  medicOverview: MedicOverview;
  medics: MedicView[] = [];
  medicsConnected: MedicView[] = [];
  medicsPending: MedicView[] = [];
  medakteIconColors = MedakteIconColors;
  medakteIconTypes = MedakteIconTypes;
  pageNumber = 1;
  pageSize = 30;
  personIdParam: string;
  query: string;
  queryFilters: QueryFilter[] = [];
  searchedMedicOverview: MedicOverview;
  searchedMedics: MedicView[] = [];
  selectedMedic: MedicView;
  selectedSearchedMedic: MedicView;
  selectedSpecialties: string[] = [];
  videoChatUrl = 'https://video.medakte.com/';
  weekDays = [
    'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'
  ];
  private username: string;

  ConnectionStatus = ConnectionStatus;
  hasValue = hasValue;
  selected = [];
  selectedSearched = [];
  SelectionType = SelectionType;

  constructor(
    private activatedRoute: ActivatedRoute,
    private documentService: DocumentService,
    private localeResolver: LocaleResolverService,
    private modal: NgbModal,
    private profileService: ProfileService,
    private translateService: TranslateService,
    private medicalService: MedicalService,
  ) {
  }

  ngOnInit() {
    this.localeResolver.localeSubject.subscribe(() => {
      this.locale = this.localeResolver.obtainLocale();
    });

    this.activatedRoute.params.pipe(take(1)).subscribe(resp => {
      this.personIdParam = resp['id'];
      if ('medicId' in resp) {
        this.medicId = +(resp['medicId']);
      }

      this.medics = [];

      this.loadPerson();
      this.loadConnectedMedics();
      this.loadPendingMedics();
    });
  }

  getOfficeHours(medicOverview: MedicOverview, weekDay: string): string {
    const startHour = formatNumber(+medicOverview.medic[weekDay + 'S'].split(':')[0], this.locale, '2.0-0');
    const startMinute = formatNumber(+medicOverview.medic[weekDay + 'S'].split(':')[1], this.locale, '2.0-0');
    const endHour = formatNumber(+medicOverview.medic[weekDay + 'E'].split(':')[0], this.locale, '2.0-0');
    const endMinute = formatNumber(+medicOverview.medic[weekDay + 'E'].split(':')[1], this.locale, '2.0-0');
    return startHour + ':' + startMinute + ' - ' + endHour + ':' + endMinute;
  }

  getWeekDays(medicOverview: MedicOverview): string[] {
    return this.weekDays.filter(weekDay => this.hasOfficeHours(medicOverview, weekDay));
  }

  hasOfficeHours(medicOverview: MedicOverview, weekDay: string): boolean {
    return (
      medicOverview.medic[weekDay + 'S'] != null && medicOverview.medic[weekDay + 'S'] !== ':' &&
      medicOverview.medic[weekDay + 'E'] != null && medicOverview.medic[weekDay + 'E'] !== ':'
    );
  }

  isSelectedMedic(medic: MedicView, selectedMedic: MedicView): boolean {
    return selectedMedic && selectedMedic.med === medic.med && selectedMedic.rn === medic.rn;
  }

  isMedakteSupport(): boolean {
    return this.medicOverview.medic.email === 'medicalsupport@medakte.com';
  }

  isMedicConnectionConnected(medic: MedicView): boolean {
    return medic.connection.status === ConnectionStatus.CONNECTED;
  }

  isMedicConnectionDisconnected(medic: MedicView): boolean {
    return !hasValue(medic.connection.status) || medic.connection.status === ConnectionStatus.DISCONNECTED;
  }

  isMedicConnectionPending(medic: MedicView): boolean {
    return medic.connection.status === ConnectionStatus.PENDING;
  }

  isMedicVideoChatAvailable(medic: MedicView): boolean {
    return this.isMedicConnectionConnected(medic) && this.isMedakteSupport();
  }

  loadMedicDetail(medic: MedicView) {
    if (this.isSelectedMedic(medic, this.selectedMedic)) {
      this.selectedMedic = null;
      this.medicOverview = null;
      this.selected = [];
    } else {
      this.selectedMedic = null;
      this.medicOverview = null;
      this.isLoadingMedicDetails = true;
      this.medicalService.getMedicSearchOverview(this.personIdParam, medic.med + '').subscribe(r => {
        this.medicOverview = r.result;
        if (this.medicOverview && this.medicOverview.photoKey) {
          this.medicOverview.photoKey = this.documentService.resolvePhotoFileUrl(this.medicOverview.photoKey);
        }
        this.medicOverview.address = (
          this.medicOverview.addresses && this.medicOverview.addresses.length > 0 ? this.medicOverview.addresses[0] : null
        );
        this.selectedMedic = medic;

        this.isLoadingMedicDetails = false;
      });
    }
  }

  loadSearchedMedicDetail(searchedMedic: MedicView) {
    if (this.isSelectedMedic(searchedMedic, this.selectedSearchedMedic)) {
      this.selectedSearchedMedic = null;
      this.searchedMedicOverview = null;
    } else {
      this.selectedSearchedMedic = null;
      this.searchedMedicOverview = null;
      this.isLoadingMedicDetails = true;
      this.medicalService.getMedicSearchOverview(this.personIdParam, searchedMedic.med + '').subscribe(r => {
        this.searchedMedicOverview = r.result;
        if (this.searchedMedicOverview && this.searchedMedicOverview.photoKey) {
          this.searchedMedicOverview.photoKey = this.documentService.resolvePhotoFileUrl(this.searchedMedicOverview.photoKey);
        }
        this.searchedMedicOverview.address = (
          this.searchedMedicOverview.addresses && this.searchedMedicOverview.addresses.length > 0 ?
            this.searchedMedicOverview.addresses[0] : null
        );
        this.selectedSearchedMedic = searchedMedic;

        this.isLoadingMedicDetails = false;
      });
    }
  }

  // https://github.com/swimlane/ngx-datatable/issues/721
  onActive(event) {
    if (event.type === 'click') {
      event.cellElement.blur();
      this.loadMedicDetail(event.row);
    }
  }

  // https://github.com/swimlane/ngx-datatable/issues/721
  onActiveSearchedMedic(event) {
    if (event.type === 'click') {
      event.cellElement.blur();
      this.loadSearchedMedicDetail(event.row);
    }
  }

  onConnectMedic(event, medic: MedicView) {
    event.stopPropagation();
    this.medicId = medic.med;

    this.selectedSearchedMedic = null;
    this.searchedMedicOverview = null;

    if (!medic.connection.id) {
      this.medicalService.requestConnection(medic.med).subscribe(
          () => {
            medic.connection.status = ConnectionStatus.PENDING;
            this.reloadMedics();
          },
          e => console.log('Error ...')
      );
    } else {
      this.medicalService.changeConnection(medic.connection.id, ConnectionStatus.PENDING).subscribe(r => {
            medic.connection.status = ConnectionStatus.PENDING;
            this.reloadMedics();
          },
          e => console.log('Error ...')
      );
    }
  }

  onDisconnectMedic(event, firstChild, medic: MedicView) {
    event.stopPropagation();
    firstChild.parentElement.parentElement.blur();
    this.showDisconnectFromMedicModal(medic);
  }

  onSearchChange(search: string) {
    this.query = search;
    this.lastSearchedId = null;
    this.isSearchedMedicsLoaded = false;
    this.reloadMedics();
  }

  onSelectedSpecialtiesChange(selectedSpecialties: string[]) {
    this.selectedSpecialties = selectedSpecialties;

    this.queryFilters = [];
    if (this.selectedSpecialties.length) {
      this.queryFilters.push({name: 'codes', value: this.selectedSpecialties.join(',')});
    }

    // reload the list
    this.lastSearchedId = undefined;
    this.reloadMedics();
  }

  onToggleMedicDataVisible(event, medic: MedicView) {
    event.stopPropagation();
    medic.connection.dataVisible = !medic.connection.dataVisible;
    this.medicalService.changeConnection(medic.connection.id, medic.connection.status, medic.connection.dataVisible).subscribe(
      () => {},
      () => {}
    );
  }

  onVideoChatMedic(event, medic: MedicView) {
    event.stopPropagation();
    // TODO
  }

  private loadConnectedMedics() {
    const queryFilters = [
      {name: 'expdescription', value: this.query},
      {name: 'name', value: this.query},
    ];
    queryFilters.push(... this.queryFilters);

    this.medicalService.getPatientMedics(this.personIdParam, ConnectionStatus.CONNECTED, queryFilters).subscribe(
      resp => {
        const medicsResp = resp.results;
        medicsResp.forEach(m => {
          m.photo = this.documentService.resolvePhotoFileUrl(m.photo);
          m.specialtiesText = m.specialties ? m.specialties.split(',').map(e => this.translateService.instant('codes.specialty.' + e)).join(', ') : '';
        });

        this.medicsConnected = medicsResp;
        this.medics.push(... medicsResp);
        this.medics = this.medics.slice();

        if (this.medicId) {
          const medic = this.medics.find(m => m.med === this.medicId);
          if (medic) {
            this.loadMedicDetail(medic);
          }
        }

        this.isMedicsConnectedLoaded = true;
        this.isLoaded = this.isMedicsConnectedLoaded && this.isMedicsPendingLoaded;
      },
    );
  }

  private loadPendingMedics() {
    const queryFilters = [
      {name: 'expdescription', value: this.query},
      {name: 'name', value: this.query},
    ];
    queryFilters.push(... this.queryFilters);

    this.medicalService.getPatientMedics(this.personIdParam, ConnectionStatus.PENDING, queryFilters).subscribe(
      resp => {
        const medicsResp = resp.results;
        medicsResp.forEach(m => {
          m.photo = this.documentService.resolvePhotoFileUrl(m.photo);
          m.specialtiesText = m.specialties ? m.specialties.split(',').map(e => this.translateService.instant('codes.specialty.' + e)).join(', ') : '';
        });

        this.medicsPending = medicsResp;
        this.medics.push(... medicsResp);
        this.medics = this.medics.slice();

        if (this.medicId) {
          const medic = this.medics.find(m => m.med === this.medicId);
          if (medic) {
            this.loadMedicDetail(medic);
          }
        }

        this.isMedicsPendingLoaded = true;
        this.isLoaded = this.isMedicsConnectedLoaded && this.isMedicsPendingLoaded;
      },
    );
  }

  private loadPerson() {
    this.profileService.myProfileSubject.subscribe(person => {
      if (!!person) {
        this.username = person.firstName + ' ' + person.lastName;
        this.videoChatUrl += '?name=' + encodeURI(this.username) + '&room=democall';
      }
    });
  }

  private reloadMedics() {
    this.medics = [];
    this.medicsConnected = [];
    this.medicsPending = [];
    this.loadConnectedMedics();
    this.loadPendingMedics();
    this.searchMedics();
  }

  private searchMedics() {
    const queryFilters = [
      {name: 'expdescription', value: this.query},
      {name: 'lastId', value: this.lastSearchedId},
      {name: 'name', value: this.query},
    ];
    queryFilters.push(... this.queryFilters);

    this.medicalService.getPatientMedics(this.personIdParam, null, queryFilters)
      .subscribe(resp => {
        const medicsResp = resp.results.filter(
          medic => medic.connection.status !== ConnectionStatus.PENDING && medic.connection.status !== ConnectionStatus.CONNECTED
        );
        medicsResp.forEach(m => {
          m.photo = this.documentService.resolvePhotoFileUrl(m.photo);
          m.specialtiesText = m.specialties ? m.specialties.split(',').map(e => this.translateService.instant('codes.specialty.' + e)).join(', ') : '';
        });

        // search
        this.searchedMedics = this.lastSearchedId ? this.medics.concat(medicsResp) : medicsResp;
        this.lastSearchedId = resp.lastId;

        this.isSearchedMedicsLoaded = true;
      });
  }

  private showDisconnectFromMedicModal(medic: MedicView) {
    setTimeout(() => {
      const description = this.translateService.instant('myDoctors.disconnectModal.description');
      const header = medic.type === 2 ? medic.medicname : medic.name;
      this.currentModal = this.modal.open(DeleteModalComponent, {size: 'lg'});
      this.currentModal.componentInstance.itemDescription = description;
      this.currentModal.componentInstance.itemHeader = header;
      this.currentModal.componentInstance.itemTitle = this.translateService.instant('myDoctors.disconnectModal.title');
      this.currentModal.componentInstance.itemType = this.translateService.instant('myDoctors.disconnectModal.type');
      this.currentModal.result.then(isDisconnected => {
        if (isDisconnected) {
          this.medicalService.changeConnection(medic.connection.id, ConnectionStatus.DISCONNECTED).subscribe(
            () => {
              medic.connection.status = ConnectionStatus.DISCONNECTED;

              this.selectedMedic = null;
              this.medicOverview = null;

              this.reloadMedics();
            },
            e => console.log('Error ...')
          );
        }
      });
    }, 100);
  }
}
