import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FamilyDisease} from '../../../core/health/family-disease.model';
import {formatDate} from '../../../../lib/formats';

@Component({
  selector: 'app-emergency-card-family-diseases',
  templateUrl: './emergency-card-family-diseases.component.html',
  styleUrls: ['./emergency-card-family-diseases.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmergencyCardFamilyDiseasesComponent implements OnChanges, OnInit {
  @Input() familyDiseases: FamilyDisease[] = [];
  @Input() personIdParam: string;

  constructor(protected ref: ChangeDetectorRef) {
  }

  ngOnInit() {
    if (this.familyDiseases.length) {
      this.familyDiseases.forEach(familyDisease => this.localizeFamilyDisease(familyDisease));
      this.ref.markForCheck();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const change = changes.familyDiseases || undefined;
    if (change) {
      this.familyDiseases.forEach(familyDisease => this.localizeFamilyDisease(familyDisease));
      this.ref.markForCheck();
    }
  }

  private localizeFamilyDisease(familyDisease: FamilyDisease) {
    familyDisease.localized = { created_at: formatDate(familyDisease.created_at), relative: '' };
  }
}
