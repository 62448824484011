import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {Weight} from '../../../core/measurements/weight.model';
import {NgbDateParserFormatter, NgbDateStruct, NgbModal, NgbModalRef, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import {WeightService} from '../../../core/measurements/weight.service';
import * as moment from 'moment-timezone';
import {Person} from '../../../core/person/person.model';
import {ActivatedRoute} from '@angular/router';
import {ProfileService} from '../../../core/profile/profile.service';
import {take} from 'rxjs/operators';
import {forkJoin} from 'rxjs';
import {isMe} from '../../../../lib/utils';
import {WeightChartComponent} from './weight-chart/weight-chart.component';
import {BaseComponent} from '../../../../base/base-component';
import {LocaleResolverService} from '../../../core/locale/locale-resolver.service';
import {NgbDateDynamicParserFormatter} from '../../../../lib/ngb-date-dynamic-parser-formatter';
import {UsageAlertComponent} from '../../../shared/usage/usage-alert/usage-alert.component';
import {UsageTypeEnum} from '../../../shared/enums/usage-type.enum';
import {EmergencyPassConfig} from '../../emergency-card/emergency-card-config.model';
import {JournalConfig} from '../../../core/measurements/journal-config.model';
import {EmergencyPassService} from '../../emergency-card/emergency-pass.service';
import {JournalService} from '../../../core/measurements/journal.service';
import {DeleteModalComponent} from '../../../shared/delete-modal/delete-modal.component';
import {formatNumber} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {TranslatedToastrService} from '../../../core/translated-toastr/translated-toastr.service';

@Component({
    selector: 'app-weight',
    templateUrl: './weight.component.html',
    styleUrls: ['./weight.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateDynamicParserFormatter }
    ]
})
export class WeightComponent extends BaseComponent implements OnInit {
    @ViewChild('chart', { static: true }) chart: WeightChartComponent;
    @ViewChild('usageAlert', { static: true }) usageAlert: UsageAlertComponent;

    count: number;
    currentModal: NgbModalRef;
    date: NgbDateStruct;
    emergencyCardConfig: EmergencyPassConfig;
    journalConfig: JournalConfig;
    maxWeight = 600;
    minWeight = 1;
    pageNumber: number;
    pageSize = 30;
    personIdParam: string;
    readonly = false;
    requestedPerson: Person;
    submitted = false;
    time: NgbTimeStruct;
    unitLabel = 'kg';
    UsageTypeEnum = UsageTypeEnum;
    valid = true;
    weight: Weight;
    weights: Weight[];

    constructor(
        private emergencyPassService: EmergencyPassService,
        private journalService: JournalService,
        private weightService: WeightService,
        private modal: NgbModal,
        private route: ActivatedRoute,
        private translatedToastrService: TranslatedToastrService,
        private translateService: TranslateService,
        protected profileService: ProfileService,
        protected localeResolverService: LocaleResolverService,
        protected ref: ChangeDetectorRef
    ) {
        super(profileService, localeResolverService, ref);
    }

    ngOnInit() {
        super.ngOnInit();

        this.weight = new Weight();
        this.initDate();

        const routeParams$ = this.route.params.pipe(take(1));

        forkJoin([routeParams$]).subscribe(respList => {
            this.personIdParam = respList[0]['id'];
            this.profileService.setCurrentPersonIdParam(this.personIdParam);

            if (this.personIdParam === 'me') {
                this.emergencyPassService.getMyEmergencyPassConfig().subscribe(r => {
                    this.emergencyCardConfig = r.result;
                    this.ref.markForCheck();
                });
                this.journalService.getMyJournalConfig().subscribe(r => {
                    this.journalConfig = r.result;
                    this.ref.markForCheck();
                });
            }

            this.loadData();

            if (this.personIdParam !== 'me') {
                this.profileService
                    .getProfile(this.personIdParam)
                    .subscribe(resp => {
                        this.requestedPerson = resp.result;
                        this.ref.markForCheck();
                    });
            }
        });
    }

    private loadData() {
        this.initDate();
        this.setPage({ offset: 0 });
    }

    private initDate() {
        const now = moment();
        this.time = { hour: now.hour(), minute: now.minute(), second: 0 };
        this.date = { day: now.date(), month: (now.month() + 1), year: now.year() };
    }

    askToRemove(index: number, weight: Weight) {
        this.showDeleteModal(index, weight);
    }

    isMe() {
        return isMe(this.personIdParam, this.requester ? this.requester.id : null);
    }

    // https://github.com/swimlane/ngx-datatable/issues/721
    onActive(event) {
        if (event.type === 'click') {
            event.cellElement.blur();
        }
    }

    onEmergencyCardVisibleChange() {
        this.emergencyPassService.updateMyEmergencyPassConfig(this.emergencyCardConfig).subscribe(
          () => this.translatedToastrService.success('message.success.save'),
          () => this.translatedToastrService.error('message.error.save')
        );
    }

    onVisibleChange() {
        this.journalService.updateMyJournalConfig(this.journalConfig).subscribe(
          () => this.translatedToastrService.success('message.success.save'),
          () => this.translatedToastrService.error('message.error.save')
        );
    }

    validValues() {
        return this.weight.value != null && this.weight.value >= this.minWeight && this.weight.value <= this.maxWeight;
    }

    save() {
        this.submitted = true;
        if (this.valid && this.validValues()) {
            const date = moment(
                this.date.year + '-' +
                this.date.month + '-' +
                this.date.day + ' ' +
                this.time.hour + ':' +
                this.time.minute
                , 'YYYY-MM-DD HH:mm');

            this.weight.date = date.toDate();
            this.weightService.create(this.weight)
                .subscribe(() => {
                    this.translatedToastrService.success('message.success.save');
                    this.weight = new Weight();

                    this.loadData();
                    this.chart.fetchValues();
                    this.usageAlert.loadsUsage();
                },
                    () => this.translatedToastrService.error('message.error.save'));
        }
    }

    setPage(pageInfo) {
        this.pageNumber = pageInfo.offset;
        this.weightService.listWeights(this.personIdParam, this.pageNumber + 1, this.pageSize, null, null, null, 'desc')
            .subscribe(r => {
                this.weights = r.results;
                this.count = r.count;
                this.ref.markForCheck();
            });
    }

    showDeleteModal(index: number, weight: Weight) {
        setTimeout(() => {
            this.currentModal = this.modal.open(DeleteModalComponent, {size: 'lg'});
            this.currentModal.componentInstance.itemHeader = this.formatMonthDayYear(weight.date);
            this.currentModal.componentInstance.itemType = this.translateService.instant('journal.weight.title');
            this.currentModal.componentInstance.itemValue = formatNumber(weight.value, this.localeResolverService.locale, '1.1-1') +
              ' ' + this.unitLabel;
            this.currentModal.result.then(isDeleted => {
                if (isDeleted) {
                    this.removeRow(index, weight);
                }
            });
        }, 100);
    }

    removeRow(index: number, weight: Weight) {
        this.weightService.delete(weight.id).subscribe(() => {
            this.weights.splice(index, 1);
            this.weights = [...this.weights];
            this.chart.fetchValues();
            this.usageAlert.loadsUsage();
            this.ref.markForCheck();
        }, () =>
            this.translatedToastrService.error('message.error.delete'));
    }

    handleValid(valid) {
        this.valid = valid;
    }

    onIsLimitQuotaReached(limitQuotaReached) {
        this.readonly = limitQuotaReached;
        this.ref.markForCheck();
    }
}
