import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {DocumentHolder} from '../document.model';
import {DocumentEditComponent} from '../document-edit/document-edit.component';
import {forkJoin} from 'rxjs';
import {DocumentListResponse} from '../../../core/response/response.model';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {DocumentService} from '../document.service';
import {DocumentTypeEnum} from '../document-type.enum';
import {ProfileService} from '../../../core/profile/profile.service';
import {Person} from '../../../core/person/person.model';
import {canEdit, isMe} from '../../../../lib/utils';
import {EmergencyCardDocumentViewerComponent} from '../../emergency-card/emergency-card-document-viewer/emergency-card-document-viewer.component';
import {MedakteIconColors} from '../../../shared/icon/medakte-icon-colors';
import {MedakteIconTypes} from '../../../shared/icon/medakte-icon-types';

@Component({
  selector: 'app-personal-documents',
  templateUrl: './personal-documents.component.html',
  styleUrls: ['./personal-documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonalDocumentsComponent implements OnInit {
  @Input() code: string;
  @Input() isEmergencyCard = false;
  @Input() isVisible = true;
  @Input() personalDocuments: DocumentHolder[] = [];
  @Input() personIdParam: string;
  @ViewChild('addDocument') addDocument: DocumentEditComponent;
  @ViewChild('modalContent', {static: true}) modalContent: TemplateRef<any>;
  canEdit = canEdit;
  currentModal: NgbModalRef;
  isMe = isMe;
  medakteIconColors = MedakteIconColors;
  medakteIconTypes = MedakteIconTypes;
  personalDocument: DocumentHolder;
  personalDocumentTypes = [
    {label: 'codes.documentType.' + DocumentTypeEnum.LIVING_WILL, value: DocumentTypeEnum.LIVING_WILL},
    {label: 'codes.documentType.' + DocumentTypeEnum.BLOOD_GROUP_DETECTION, value: DocumentTypeEnum.BLOOD_GROUP_DETECTION},
    {label: 'codes.documentType.' + DocumentTypeEnum.IMPLANT_CARD, value: DocumentTypeEnum.IMPLANT_CARD},
    {label: 'codes.documentType.' + DocumentTypeEnum.ALLERGY_PASSPORT, value: DocumentTypeEnum.ALLERGY_PASSPORT},
    {label: 'codes.documentType.' + DocumentTypeEnum.ORGAN_DONATION_CARD, value: DocumentTypeEnum.ORGAN_DONATION_CARD},
    {label: 'codes.documentType.' + DocumentTypeEnum.VACCINATION_CARD, value: DocumentTypeEnum.VACCINATION_CARD},
    {label: 'codes.documentType.' + DocumentTypeEnum.PASSPORT, value: DocumentTypeEnum.PASSPORT},
    {label: 'codes.documentType.' + DocumentTypeEnum.COVID_19_TEST, value: DocumentTypeEnum.COVID_19_TEST},
    {label: 'codes.documentType.' + DocumentTypeEnum.GLASSES_OR_CONTACT_LENS_PASSPORT, value: DocumentTypeEnum.GLASSES_OR_CONTACT_LENS_PASSPORT},
    {label: 'codes.documentType.' + DocumentTypeEnum.OTHER, value: DocumentTypeEnum.OTHER}
  ];
  requester: Person;

  constructor(
    private documentService: DocumentService,
    private modal: NgbModal,
    private profileService: ProfileService,
    private ref: ChangeDetectorRef,
    ) {
  }

  ngOnInit() {
    this.personalDocument = new DocumentHolder();

    if (this.isEmergencyCard) {
      this.personalDocuments = this.personalDocuments.filter(
        personalDocument => this.personalDocumentTypes.some(personalDocumentType => personalDocumentType.value === personalDocument.docType)
      );
      this.ref.markForCheck();

    } else {
      this.profileService.getMyProfile().subscribe(r => {
        this.requester = r.result;
        this.loadPersonalDocuments();
      });
    }
  }

  addPersonalDocument() {
    this.showAddEditDocumentModal(this.personalDocument);
  }

  editPersonalDocument(personalDocument: DocumentHolder) {
    this.showAddEditDocumentModal(personalDocument);
  }

  onViewPersonalDocument(personalDocument: DocumentHolder) {
    if (this.isEmergencyCard) {
      this.showViewDocumentModal(personalDocument);
    } else {
      this.documentService.onView(
        this.personIdParam, personalDocument.id, personalDocument.docType, personalDocument.type, personalDocument.name
      );
    }
  }

  showAddEditDocumentModal(document?: DocumentHolder) {
    const documentTypes = Object.assign([], this.personalDocumentTypes);
    this.currentModal = this.modal.open(DocumentEditComponent, {size: 'lg'});
    this.currentModal.componentInstance.personIdParam = this.personIdParam;
    this.currentModal.componentInstance.requester = this.requester;
    this.currentModal.componentInstance.document = document;
    this.currentModal.componentInstance.documentTypes = documentTypes;
    if (document && document.id) {
      this.currentModal.componentInstance.readonly = !canEdit(this.personIdParam, this.requester.id, document.creator.id);
    }
    this.currentModal.result.then(saved => this.handleDocumentSaved(saved)).catch(e => {
    });
  }

  handleDocumentSaved(saved: boolean) {
    if (saved) {
      this.personalDocument = new DocumentHolder();
      this.loadPersonalDocuments();
    }
  }

  private loadPersonalDocuments() {
    const personalDocumentRequests = [];
    for (const personalDocumentType of this.personalDocumentTypes) {
      personalDocumentRequests.push(
        this.documentService.listDocuments(this.personIdParam, personalDocumentType.value, null, null, null, null, null)
      );
    }
    forkJoin(personalDocumentRequests).subscribe((respList: DocumentListResponse[]) => {
      this.personalDocuments = [];
      for (const personalDocumentsResponse of respList) {
        if (this.isEmergencyCard) {
          personalDocumentsResponse.results = personalDocumentsResponse.results.filter(document => document.emergencyCardVisible);
        }
        if (personalDocumentsResponse.results.length) {
          this.personalDocuments.push(personalDocumentsResponse.results[0]);
        }
      }
      this.ref.markForCheck();
    });
  }

  private showViewDocumentModal(document: DocumentHolder) {
    this.currentModal = this.modal.open(EmergencyCardDocumentViewerComponent, { size: 'lg', windowClass: 'document_viewer_ec_card' });

    this.currentModal.componentInstance.code = this.code;
    this.currentModal.componentInstance.document = document;

    this.currentModal.result.then();
  }
}
