import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Profile} from '../../../core/profile/profile.model';
import {calcBmi} from '../../../../lib/utils';
import {Weight} from '../../../core/measurements/weight.model';
import {ActivatedRoute} from '@angular/router';
import {WeightService} from '../../../core/measurements/weight.service';

declare var BMIComponent: any;

@Component({
  selector: 'app-profile-bmi',
  templateUrl: './profile-bmi.component.html',
  styleUrls: ['./profile-bmi.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileBmiComponent implements OnInit {
  @Input() isVisible = true;
  @Input() latestWeight: Weight | undefined;
  @Input() personIdParam = 'me';
  @Input() profile: Profile;
  bmi: string;

  constructor(private route: ActivatedRoute,
              private weightService: WeightService,
              protected ref: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.latestWeight === undefined) {
      this.weightService.loadLatestWeight(this.personIdParam).subscribe(
        r => {
          this.latestWeight = r.results.length > 0 ? r.results[0] : null;
          this.calculateBmi();
        },
        () => {
          this.calculateBmi();
        });
    } else {
      this.calculateBmi();
    }
  }

  private calculateBmi() {
    const height = this.profile ? this.profile.height : null;
    const weight = this.latestWeight ? this.latestWeight.value : (this.profile ? this.profile.weight : null);
    this.bmi = calcBmi(height, weight);
    BMIComponent.showValue(this.bmi);
    this.ref.markForCheck();
  }
}
