import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LocaleResolverService} from '../locale/locale-resolver.service';
import {BackendService} from '../backend/backend.service';
import {StripeCustomerResponse} from '../response/response.model';

@Injectable()
export class StripeService extends BackendService {

  constructor(
      protected http: HttpClient,
    protected localeResolverService: LocaleResolverService,
  ) {
    super(http, localeResolverService);
  }

  /**
   * Returns stripe customer of currently authenticated user
   */
  getStripeCustomer() {
    const httpOptions = this.createHttpOptions();
    return this.http.get<StripeCustomerResponse>(this.getApiUrl() + '/stripe/customers/me', httpOptions);
  }

  /**
   * Returns default stripe source of currently authenticated user
   */
  getSource() {
    const httpOptions = this.createHttpOptions();
    return this.http.get<StripeCustomerResponse>(this.getApiUrl() + '/stripe/customers/me/sources/default', httpOptions);
  }

  /**
   * Attaches source to stripe customer of currently authenticated user
   * @param source: any
   */
  attachSource(source) {
    const httpOptions = this.createHttpOptions();
    return this.http.post(this.getApiUrl() + '/stripe/customers/me/sources', source, httpOptions);
  }

  removeSource(sourceId) {
    const httpOptions = this.createHttpOptions();
    return this.http.delete(this.getApiUrl() + '/stripe/customers/me/sources/' + sourceId, httpOptions);
  }

}
