export enum UsageTypeEnum {
    ALLERGIES = 'allergies',
    BLOOD_GLUCOSES = 'bloodGlucoses',
    BLOOD_PRESSURES = 'bloodPressures',
    BODY_TEMPERATURES = 'bodyTemperatures',
    DISEASES = 'diseases',
    DOCUMENTS = 'documents',
    FAMILY_DISEASES = 'familyDiseases',
    FEELINGS = 'feelings',
    HEART_RATES = 'heartRates',
    IMMUNISATIONS = 'immunisations',
    MEDICATIONS = 'medications',
    OPERATIONS = 'operations',
    PAINS = 'pains',
    SLEEPS = 'sleeps',
    SPO2 = 'spo2',
    STRESS = 'stress',
    WEIGHTS = 'weights'
}
