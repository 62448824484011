import {Injectable} from '@angular/core';
import {BackendService} from '../backend/backend.service';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {IssueListResponse} from '../response/response.model';
import {Observable} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {Issue, IssueItem} from './issue.model';
import {LocaleResolverService} from '../locale/locale-resolver.service';

@Injectable()
export class IssueService extends BackendService {

    constructor(
        protected http: HttpClient,
        protected localeResolverService: LocaleResolverService,
    ) {
        super(http, localeResolverService);
    }

    create(personIdParam: string, issue: Issue): Observable<HttpResponse<any>> {
        const httpOptions = this.createHttpOptions();
        httpOptions['observe'] = 'response';
        return this.http.post<any>(this.getApiUrl() + '/people/' + personIdParam + '/issues', issue, httpOptions);
    }

    get(issueId: number) {
        const httpOptions = this.createHttpOptions();
        return this.http.get(this.getApiUrl() + '/issues/' + issueId, httpOptions);
    }

    update(issue: Issue) {
        const httpOptions = this.createHttpOptions();
        return this.http.put(this.getApiUrl() + '/issues/' + issue.id, issue, httpOptions);
    }

    delete(issueId: number) {
        const httpOptions = this.createHttpOptions();
        return this.http.delete(this.getApiUrl() + '/issues/' + issueId, httpOptions);
    }

    listIssues(personIdParam: string, page: number, pageSize: number) {
        const httpHeaders = this.createHttpHeaders();

        let params = new HttpParams();
        if (page !== undefined && page !== null) {
            params = params.append('page', page.toString());
        }
        if (pageSize !== undefined && pageSize !== null) {
            params = params.append('pageSize', pageSize.toString());
        }

        return this.http.get<IssueListResponse>(this.getApiUrl() + '/people/' + personIdParam + '/issues', (params.keys().length > 0) ? {
            headers: httpHeaders,
            params: params
        } : {headers: httpHeaders});
    }

    createIssueItem(personIdParam: string, issueId: number, issueItem: IssueItem): Observable<HttpResponse<any>> {
        const httpOptions = this.createHttpOptions();
        httpOptions['observe'] = 'response';
        return this.http.post<any>(this.getApiUrl() + '/people/' + personIdParam + '/issues/' + issueId + '/issueItems', issueItem, httpOptions);
    }

    updateIssueItem(issueItem: IssueItem) {
        const httpOptions = this.createHttpOptions();
        return this.http.put(this.getApiUrl() + '/issueItems/' + issueItem.id, issueItem, httpOptions);
    }

    deleteIssueItem(issueItemId: number) {
        const httpOptions = this.createHttpOptions();
        return this.http.delete(this.getApiUrl() + '/issueItems/' + issueItemId, httpOptions);
    }

}
