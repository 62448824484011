import {Component, ElementRef, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ProfileService} from '../../core/profile/profile.service';
import {DocumentService} from '../../patient/documents/document.service';
import {ToastrService} from 'ngx-toastr';
import {ImageCroppedEvent, ImageCropperComponent} from 'ngx-image-cropper';
import {Photo} from '../../core/profile/photo.model';
import {MedicalService} from '../../core/medical/medical.service';

@Component({
    selector: 'app-profile-edit-picture',
    templateUrl: './photo-edit.component.html',
    styleUrls: ['./photo-edit.component.scss']
})
export class PhotoEditComponent implements OnInit {

    @Input() photoType = 'person'; // person, medic
    @Input() personType: number;

    @ViewChild('modalContent', {static: true}) modalContent: TemplateRef<any>;

    @ViewChild('picture') picture: ElementRef;
    @ViewChild('avatarPreview') avatarPreview: ElementRef;
    @ViewChild('fileInput', {static: true}) fileInput: ElementRef;
    @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;

    photo: Photo;
    photoKey: string;
    currentModal: NgbModalRef;

    profilePicture: string;
    currentPicture: any;

    file: any;

    dirty: boolean;

    imageChangedEvent: any = '';
    croppedImage: any = '';
    showCropper = false;

    constructor(
        private modal: NgbModal,
        private profileService: ProfileService,
        private medicalService: MedicalService,
        private docService: DocumentService,
        private toastr: ToastrService
    ) {
    }

    ngOnInit() {
        this.loadProfilePhoto();
    }

    loadProfilePhoto() {
        if (this.photoType === 'person') {
            this.profileService
                .getMyProfile()
                .subscribe(r => {
                    this.photoKey = r.result.photoKey;
                    this.setProfilePicture();
                }, () => this.toastr.error('Could not load data', 'Error'));
        } else {
            this.medicalService
                .getMedic()
                .subscribe(r => {
                    this.photoKey = r.result.photoKey;
                    this.setProfilePicture();
                }, () => this.toastr.error('Could not load data', 'Error'));
        }
        this.dirty = false;
    }

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        this.file = event.file;
        // console.log(event);
    }

    imageLoaded() {
        this.showCropper = true;
    }

    cropperReady() {
    }

    loadImageFailed() {
    }

    setProfilePicture() {
        if (this.photoKey) {
            this.profilePicture = this.docService.resolvePhotoFileUrl(this.photoKey);
            this.currentPicture = this.profilePicture;
        }
    }

    private deletePicture() {

        if (this.photoType === 'person') {
            this.profileService.deleteProfilePhoto().subscribe(r => {
                this.toastr.success('Data deleted', 'Success');
                this.profilePicture = null;
            }, () => this.toastr.error('Could not delete data', 'Error'));
        } else {
            this.medicalService.deleteMedicPhoto().subscribe(r => {
                this.toastr.success('Data deleted', 'Success');
                this.profilePicture = null;
            }, () => this.toastr.error('Could not delete data', 'Error'));
        }

        this.currentModal.close();
    }

    onFileChange(event) {

        this.imageChangedEvent = event;

        if (event.target.files.length > 0) {
            const f = event.target.files[0];
            this.dirty = true;
            this.file = f;
            this.readURL(f);
        }
    }

    readURL(file) {
        const reader = new FileReader();
        const that = this;
        reader.onload = function (e) {
            that.currentPicture = (<FileReader>e.target).result;
        };
        reader.readAsDataURL(file);
    }

    removePicture(event) {

        event.preventDefault();

        if (this.dirty) {
            if (this.profilePicture) {
                this.file = null;
                this.currentPicture = this.profilePicture;
            }
        } else {
            this.currentModal = this.modal.open(this.modalContent, {size: 'lg'});
        }

        this.fileInput.nativeElement.value = null;
        this.dirty = false;
    }

    save() {

        if ((this.file.size / 1000) > 300) {
            this.toastr.error('File size exceeded 300 KB limit', 'Error');
        } else {
            const formData: FormData = new FormData();
            formData.append('profilePhoto', this.file, this.file.name);

            if (this.photoType === 'person') {
                this.docService.saveMyProfilePhoto(formData).subscribe(r => {
                    this.toastr.success('Data saved', 'Success');
                    this.imageChangedEvent = null;
                    this.dirty = false;
                    this.loadProfilePhoto();
                });
            } else {
                this.medicalService.saveProfilePhoto(formData).subscribe(r => {
                    this.toastr.success('Data saved', 'Success');
                    this.imageChangedEvent = null;
                    this.dirty = false;
                    this.loadProfilePhoto();
                });
            }
        }
    }
}
