import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ProfileService} from '../../../core/profile/profile.service';
import {ToastrService} from 'ngx-toastr';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {AuthenticationService} from '../../../core/authentication/authentication.service';
import {BaseAuthComponent} from '../../auth/base-auth.component';
import {TranslateService} from '@ngx-translate/core';
import {MultiFactorAuthentication} from '../../../core/multi-factor-authentication/multi-factor-authentication.model';
import {hasValue} from '../../../../lib/validator';

@Component({
    selector: 'app-multi-factor-authentication',
    templateUrl: './multi-factor-authentication.component.html',
    styleUrls: ['./multi-factor-authentication.component.scss']
})
export class MultiFactorAuthenticationComponent extends BaseAuthComponent implements OnInit {
    @ViewChild('modalContent', {static: true}) modalContent: TemplateRef<any>;
    currentModal: NgbModalRef;
    hasValue = hasValue;
    mfaModel: MultiFactorAuthentication;
    token: string;

    constructor(
        protected authService: AuthenticationService,
        protected profileService: ProfileService,
        protected toastr: ToastrService,
        protected translateService: TranslateService,
        protected modal: NgbModal
    ) {
        super(translateService);
    }

    ngOnInit() {
        this.getTimeBasedOneTimePassword();
    }

    askToRemove() {
        this.currentModal = this.modal.open(this.modalContent, {size: 'lg'});
    }

    createTimeBasedOneTimePassword() {
        this.authService.createTimeBasedOneTimePassword().subscribe(
          () => this.getTimeBasedOneTimePassword(),
          err => this.toastr.error(err.message, err.title)
        );
    }

    isMfaDisabled(): boolean {
        return (hasValue(this.mfaModel) && !this.mfaModel.mfaEnabled && !hasValue(this.mfaModel.otpAuthUrl));
    }

    isMfaEnabled(): boolean {
        return (hasValue(this.mfaModel) && this.mfaModel.mfaEnabled && !hasValue(this.mfaModel.otpAuthUrl));
    }

    isVerificationNeeded(): boolean {
        return (hasValue(this.mfaModel) && !this.mfaModel.mfaEnabled && !!this.mfaModel.otpAuthUrl);
    }

    removeTimeBasedOneTimePassword() {
        this.authService.removeTimeBasedOneTimePassword().subscribe(
          () => {
              this.currentModal.close();
              this.getTimeBasedOneTimePassword();
          },
          err => {
              this.toastr.error(err.message, err.title);
              this.currentModal.close();
          }
        );
    }

    verifyTimeBasedOneTimePassword() {
        if (this.token) {
            this.authService.verifyTimeBasedOneTimePassword(this.token).subscribe(
              () => this.getTimeBasedOneTimePassword(),
              err => this.toastr.error(err.message, err.title)
            );
        }
    }

    private getTimeBasedOneTimePassword() {
        this.authService.getTimeBasedOneTimePassword().subscribe(
          data => this.mfaModel = data.result,
          err => this.toastr.error(err.message, err.title)
        );
    }
}
