import {Injectable} from '@angular/core';
import {BackendService} from '../backend/backend.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {DiseaseCodeListResponse, DiseaseListResponse, DiseaseResponse, GeneralResponse} from '../response/response.model';
import {Disease} from './disease.model';
import {LocaleResolverService} from '../locale/locale-resolver.service';
import {QueryFilter} from '../filters/query-filter.model';

@Injectable()
export class DiseaseService extends BackendService {

  constructor(
      protected http: HttpClient,
    protected localeResolverService: LocaleResolverService,
  ) {
    super(http, localeResolverService);
  }

  createDisease(id, body) {
    const httpOptions = this.initHttpOptions();
    return this.http.post<GeneralResponse>(this.getApiUrl() + '/people/' + id + '/diseases', body, httpOptions);
  }

  updateDisease(id: number, disease: Disease) {
    const httpOptions = this.initHttpOptions();
    return this.http.put(this.getApiUrl() + '/diseases/' + disease.id, disease, httpOptions);
  }

  getDisease(personIdParam: string, id: number) {
    const httpOptions = this.initHttpOptions();
    return this.http.get<DiseaseResponse>(this.getApiUrl() + '/people/' + personIdParam +  '/diseases/' + id, httpOptions);
  }

  listDiseases(id: string, q: string, base: boolean, page: number, pageSize: number, lastId: number, queryFilters: QueryFilter[] = []) {
    const httpHeaders = this.buildBaseHttpHeaders();

    let params = new HttpParams();
    if (page !== undefined && page !== null) {
      params = params.append('page', page.toString());
    }
    if (pageSize !== undefined && pageSize !== null) {
      params = params.append('pageSize', pageSize.toString());
    }
    if (lastId !== undefined && lastId !== null) {
      params = params.append('lastId', lastId.toString());
    }
    if (base !== undefined && base !== null) {
      params = params.append('base', base.toString());
    }
    if (q !== undefined && q !== null) {
      params = params.append('q', q.toString());
    }
    for (const queryFilter of queryFilters) {
      params = params.append(queryFilter.name, String(queryFilter.value));
    }

    return this.http.get<DiseaseListResponse>(
        this.getApiUrl() + '/people/' + id + '/diseases',
        (params.keys().length > 0) ? { headers: httpHeaders, params: params } : { headers: httpHeaders });
  }

  listDiseasesCodes(query: string) {
    const httpHeaders = this.buildBaseHttpHeaders();

    const options: any = {
      headers: httpHeaders,
    };

    let params;
    if (query !== undefined && query !== null) {
      params = new HttpParams();
      params = params.append('q', query.toString());
      options.params = params;
    }
    return this.http.get<DiseaseCodeListResponse>(this.getApiUrl() + '/diseases-codes', (!params) ? {headers: httpHeaders} : {
      headers: httpHeaders,
      params: params
    });
  }

  deleteDisease(id: number) {
    const httpOptions = this.initHttpOptions();
    return this.http.delete(this.getApiUrl() + '/diseases/' + id, httpOptions);
  }

}
