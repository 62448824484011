import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {GoogleService} from '../../../+modules/core/google/google.service';

@Component({
  selector: 'app-google-callback',
  templateUrl: './google-callback.component.html',
  styleUrls: ['./google-callback.component.scss']
})
export class GoogleCallbackComponent implements OnInit {

  code: string;

  constructor(
    private route: ActivatedRoute,
    private googleService: GoogleService
              ) {
    this.route.queryParams.subscribe(params => {
      this.code = params['code'];
    });
  }

  ngOnInit() {
    this.googleService
      .getGoogleCallback(this.code)
      .subscribe(() => console.log('Success'), err => console.log('Error: ' + err));
  }

}
