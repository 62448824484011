export const environment = {
  production: false,
  wsUrl: 'http://localhost:3000',
  apiUrl: 'http://localhost:4200/api',
  cloudFront: '',
  auth: {
    adminSupportGroup: 'MED_ADM_SUPPORT'
  },
  aws: {
    cognito: {
      userPoolId:  'eu-central-1_ZrHzDBX5K',
      identityPoolId: 'eu-central-1:06f9b7fc-9dfd-4ec4-9151-a7156a889dbf',
      clientId: '2looa56tq7c6i25a7nfgrt2lvk',
      poolRegion: 'eu-central-1',
      tokenExpiration: 3600000,
      idpEndpoint: ''
    },
    s3: {
      staticUrl: 'https://s3.eu-central-1.amazonaws.com/test.static.medakte'
    }
  },
  stripe: {
    key: 'pk_test_fWb2q7yvlPZ0xfnH5ovZ22Aw003AGjk81p'
  },
  emergency: {
    apiUrl: 'http://localhost:3000/api',
    urlPattern: 'http://localhost:4200/emergencyPasses?code=%code%'
  },
  dicom: {
    url: 'http://demo.visuapps.com/va/renderer.html?sId='
  }
};
