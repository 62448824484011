import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Person} from '../../core/person/person.model';
import {ProfileService} from '../../core/profile/profile.service';
import {ActivatedRoute} from '@angular/router';
import {PersonResponse, SubscriptionResponse} from '../../core/response/response.model';
import {AddressService} from '../../core/address/address.service';
import {Address} from '../../core/address/address.model';
import {formatCalendarDateTime, formatDate} from '../../../lib/formats';
import {DocumentService} from '../documents/document.service';
import {calcAge} from '../../../lib/utils';
import '../../../../assets/js/bmi-component.js';
import {FavouriteService} from '../../core/favourite/favourite/favourite.service';
import {SubscriptionService} from '../../core/subscription/subscription.service';
import {Subscription} from '../../core/subscription/subscription.model';
import {Favourite} from '../../core/favourite/favourite/favourite.model';
import {SubscriptionPlanCodeEnum} from '../../shared/enums/subscription-plan-code.enum';
import {MedicalService} from '../../core/medical/medical.service';
import {Connection} from '../../core/patient/patient.model';

@Component({
  selector: 'app-patient-dashboard',
  templateUrl: './patient-dashboard.component.html',
  styleUrls: ['./patient-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatientDashboardComponent implements OnInit {
  address: Address;
  favourites: Favourite[];
  formatCalendarDateTime = formatCalendarDateTime;
  isPatientDataVisible = true;
  patientConnection: Connection;
  personSubscription: Subscription;
  person: Person;
  personIdParam = 'me';
  profilePicture: string;

  constructor(
    private addressService: AddressService,
    private documentService: DocumentService,
    private favouriteService: FavouriteService,
    private medicalService: MedicalService,
    private profileService: ProfileService,
    private ref: ChangeDetectorRef,
    private route: ActivatedRoute,
    private subscriptionService: SubscriptionService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.personIdParam = params['id'];
      this.personIdParam = !this.personIdParam ? 'me' : this.personIdParam;

      if (this.personIdParam !== 'me') {
        this.patientConnection = this.medicalService.patientConnection;
        this.isPatientDataVisible = this.patientConnection.dataVisible;
      }

      this.loadAddress();
      // this.loadFavourites();
      this.loadSubscription();
      this.loadProfile();
    });
  }

  getSubscriptionShortCode() {
    if (!this.personSubscription) {
      return 'basic';
    }

    switch (this.personSubscription.planCode) {
      case SubscriptionPlanCodeEnum.BASIC:
        return 'basic';
      case SubscriptionPlanCodeEnum.PRO:
        return 'pro';
      case SubscriptionPlanCodeEnum.PREMIUM:
        return 'premium';
    }
  }

  private loadAddress() {
    this.addressService.getPermanentAddress(this.personIdParam).subscribe(r => {
      const res = r.result;
      this.address = res.length > 0 ? res[0] : null;
      this.address = !this.address ? new Address() : this.address;
      this.ref.markForCheck();
    });
  }

  private loadFavourites() {
    this.favouriteService
      .listFavourites(this.personIdParam, null, null)
      .subscribe(r => {
        this.favourites = r.results;
        this.ref.markForCheck();
      });
  }

  private loadProfile() {
    this.profileService.setCurrentPersonIdParam(this.personIdParam);

    this.profileService.getProfile(this.personIdParam).subscribe(
      (data: PersonResponse) => {
        const resp = { ...data };
        this.person = resp.result;

        if (this.person) {
          this.person.localized = { gender: '', dateOfBirth: '', age: '' };
          this.person.localized.dateOfBirth = formatDate(this.person.dateOfBirth);
          this.person.localized.age = calcAge(this.person.dateOfBirth) + '';

          if (this.person.photoKey) {
            this.profilePicture = this.documentService.resolvePhotoFileUrl(this.person.photoKey);
          }
        }

        this.ref.markForCheck();
      },
      error => console.log(error)
    );
  }

  private loadSubscription() {
    this.subscriptionService.getSubscription(this.personIdParam).subscribe((data: SubscriptionResponse) => {
      this.personSubscription = data.result;
      this.ref.markForCheck();
    });
  }
}
