import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ProfileService} from '../../+modules/core/profile/profile.service';
import {GeneralResponse} from '../../+modules/core/response/response.model';
import {Profile} from '../../+modules/core/profile/profile.model';
import {OnboardingService} from '../../+modules/core/onboarding/onboarding.service';
import {Address} from '../../+modules/core/address/address.model';

import * as moment from 'moment-timezone';
import {NgbActiveModal, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {NgForm} from '@angular/forms';
import {AddressEditComponent} from '../../+modules/shared/address/address-edit/address-edit.component';
import {ToastrService} from 'ngx-toastr';
import {DATE_WITH_TZ_FORMAT_START} from '../../lib/formats';
import {Person} from '../../+modules/core/person/person.model';
import {BaseComponent} from '../../base/base-component';
import {NgbDateDynamicParserFormatter} from '../../lib/ngb-date-dynamic-parser-formatter';
import {LocaleResolverService} from '../../+modules/core/locale/locale-resolver.service';
import {enumKeys} from '../../lib/utils';
import {DietEnum} from '../../+modules/shared/enums/diet.enum';
import {PersonGenderEnum} from '../../+modules/shared/enums/gender.enum';
import {BloodGroupEnum} from '../../+modules/shared/enums/blood-group.enum';
import {AlcoholConsumptionEnum} from '../../+modules/shared/enums/alcohol-consumption.enum';
import {StressEnum} from '../../+modules/shared/enums/stress.enum';
import {SportEnum} from '../../+modules/shared/enums/sport.enum';
import {SleepEnum} from '../../+modules/shared/enums/sleep.enum';
import {YesNoEnum} from '../../+modules/shared/enums/yesno.enum';
import {NO_ANSWER} from '../../+modules/shared/enums/const';
import {SpectacleEnum} from '../../+modules/shared/enums/spectacle.enum';

@Component({
  selector: 'app-basic',
  templateUrl: './onboarding-profile-modal.component.html',
  styleUrls: ['./onboarding-profile-modal.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateDynamicParserFormatter }
  ]
})
export class OnboardingProfileModalComponent extends BaseComponent
  implements OnInit {
  @ViewChild('editAddress') editAddress: AddressEditComponent;
  @ViewChild(NgForm) form;

  NO_ANSWER = NO_ANSWER;

  person: Person;
  address: Address;

  model: any = {};

  dateOfBirth: NgbDateStruct;

  parseInt = parseInt;
  enumKeys = enumKeys;

  DietEnum = DietEnum;
  GenderEnum = PersonGenderEnum;
  BloodGroupEnum = BloodGroupEnum;
  AlcoholConsumptionEnum = AlcoholConsumptionEnum;
  SportEnum = SportEnum;
  YesNoEnum = YesNoEnum;
  SpectacleEnum = SpectacleEnum;
  SleepEnum = SleepEnum;
  StressEnum = StressEnum;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private onbService: OnboardingService,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal,
    protected profileService: ProfileService,
    protected localeResolverService: LocaleResolverService,
    protected ref: ChangeDetectorRef
  ) {
    super(profileService, localeResolverService, ref);
  }

  ngOnInit() {
    super.ngOnInit();

    this.person = new Person();
    this.person.gender = null;

    // const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.person.timezone = moment.tz.guess();
    this.person.profile = new Profile();
    this.person.profile.alcohol = null;
    this.person.profile.bloodGroup = null;
    this.person.profile.diet = null;
    this.person.profile.hearingAid = null;
    this.person.profile.organDonor = null;
    this.person.profile.sleep = null;
    this.person.profile.smoker = null;
    this.person.profile.spectacle = null;
    this.person.profile.sport = null;
    this.person.profile.stress = null;

    this.address = new Address();

    this.profileService.getMyOnboardingStatus().subscribe(r => {
      if (r.completed) {
        this.router.navigate(['/']);
      }
    });
  }

  addressPopulated(address: Address) {
    // console.log('Setting in parent: ' + JSON.stringify(address));
    this.address = address;
  }

  saveProfile() {
    this.editAddress.checkValidity();

    if (this.form.valid && this.address) {
      this.person.addresses = [this.address];
      this.person.dateOfBirth = this.dateOfBirth
        ? moment(
          this.dateOfBirth.year +
          '-' +
          this.dateOfBirth.month +
          '-' +
          this.dateOfBirth.day,
          DATE_WITH_TZ_FORMAT_START
        ).toDate()
        : null;

      const a = this.activeModal;
      this.onbService.postOnboardingProfile(this.person).subscribe(
        (res: GeneralResponse) => {
          this.toastr.success('Profile data sent', 'Success!');
          a.close(true);

          this.profileService.getMyProfile().subscribe(
            () => this.router.navigate(['/dashboard'])
          );
        },
        (err: GeneralResponse) =>
          this.toastr.error('Could not complete onboarding', 'Error!')
      );
    }
  }

  isChildValid() {
    this.editAddress.checkValidity();
    return this.address != null;
  }

  keys(obj): string[] {
    return Object.keys(obj);
  }

  close() {
    this.activeModal.close(false);
  }
}
