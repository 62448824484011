import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {BloodGlucose} from '../../../core/measurements/blood-glucose.model';
import {NgbDateParserFormatter, NgbDateStruct, NgbModal, NgbModalRef, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import {BloodGlucoseService} from '../../../core/measurements/blood-glucose.service';
import * as moment from 'moment-timezone';
import {Person} from '../../../core/person/person.model';
import {ActivatedRoute} from '@angular/router';
import {ProfileService} from '../../../core/profile/profile.service';
import {take} from 'rxjs/operators';
import {forkJoin} from 'rxjs';
import {BloodGlucoseChartComponent} from './blood-glucose-chart/blood-glucose-chart.component';
import {BaseComponent} from '../../../../base/base-component';
import {LocaleResolverService} from '../../../core/locale/locale-resolver.service';
import {NgbDateDynamicParserFormatter} from '../../../../lib/ngb-date-dynamic-parser-formatter';
import {BloodGlucoseUnitEnum} from '../../../shared/enums/blood-glucose-unit.enum';
import {hasValue} from '../../../../lib/validator';
import {UsageAlertComponent} from '../../../shared/usage/usage-alert/usage-alert.component';
import {UsageTypeEnum} from '../../../shared/enums/usage-type.enum';
import {EmergencyPassConfig} from '../../emergency-card/emergency-card-config.model';
import {JournalConfig} from '../../../core/measurements/journal-config.model';
import {EmergencyPassService} from '../../emergency-card/emergency-pass.service';
import {JournalService} from '../../../core/measurements/journal.service';
import {DeleteModalComponent} from '../../../shared/delete-modal/delete-modal.component';
import {TranslateService} from '@ngx-translate/core';
import {formatNumber} from '@angular/common';
import {TranslatedToastrService} from '../../../core/translated-toastr/translated-toastr.service';

@Component({
    selector: 'app-blood-glucose',
    templateUrl: './blood-glucose.component.html',
    styleUrls: ['./blood-glucose.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateDynamicParserFormatter }
    ]
})
export class BloodGlucoseComponent extends BaseComponent implements OnInit {
    @ViewChild('chart', { static: true }) chart: BloodGlucoseChartComponent;
    @ViewChild('usageAlert', { static: true }) usageAlert: UsageAlertComponent;
    bloodGlucose: BloodGlucose;
    bloodGlucoses: BloodGlucose[];
    count: number;
    currentModal: NgbModalRef;
    date: NgbDateStruct;
    emergencyCardConfig: EmergencyPassConfig;
    isSubmitted = false;
    journalConfig: JournalConfig;
    pageNumber: number;
    pageSize = 30;
    personIdParam: string;
    readonly = false;
    requestedPerson: Person;
    time: NgbTimeStruct;
    unit = BloodGlucoseUnitEnum.MG_DL;
    units = [
        {label: 'codes.bloodGlucose.unit.' + BloodGlucoseUnitEnum.MG_DL, value: BloodGlucoseUnitEnum.MG_DL},
        {label: 'codes.bloodGlucose.unit.' + BloodGlucoseUnitEnum.MMOL_L, value: BloodGlucoseUnitEnum.MMOL_L},
    ];
    unitLabel: string;
    UsageTypeEnum = UsageTypeEnum;
    valid = true;

    constructor(
        private bloodGlucoseService: BloodGlucoseService,
        private emergencyPassService: EmergencyPassService,
        private journalService: JournalService,
        private modal: NgbModal,
        private route: ActivatedRoute,
        private translatedToastrService: TranslatedToastrService,
        private translateService: TranslateService,
        protected profileService: ProfileService,
        protected localeResolverService: LocaleResolverService,
        protected ref: ChangeDetectorRef
    ) {
        super(profileService, localeResolverService, ref);
    }

    ngOnInit() {
        super.ngOnInit();

        this.bloodGlucose = new BloodGlucose();

        const routeParams$ = this.route.params.pipe(take(1));

        forkJoin([routeParams$]).subscribe(respList => {
            this.personIdParam = respList[0]['id'];
            this.profileService.setCurrentPersonIdParam(this.personIdParam);

            if (this.personIdParam === 'me') {
                this.emergencyPassService.getMyEmergencyPassConfig().subscribe(r => {
                    this.emergencyCardConfig = r.result;
                    this.ref.markForCheck();
                });
                this.journalService.getMyJournalConfig().subscribe(r => {
                    this.journalConfig = r.result;
                    this.ref.markForCheck();
                });
            }

            this.loadUnit();
            this.loadData();

            if (this.personIdParam !== 'me') {
                this.profileService
                    .getProfile(this.personIdParam)
                    .subscribe(resp => {
                        this.requestedPerson = resp.result;
                        this.ref.markForCheck();
                    });
            }
        });
    }

    askToRemove(index: number, bloodGlucose: BloodGlucose) {
        this.showDeleteModal(index, bloodGlucose);
    }

    getMax(): number {
        if (this.unit === BloodGlucoseUnitEnum.MMOL_L) {
            return 24.9;
        } else {
            return 2000;
        }
    }

    getMin(): number {
        if (this.unit === BloodGlucoseUnitEnum.MMOL_L) {
            return 0;
        } else {
            return 25;
        }
    }

    onIsLimitQuotaReached(limitQuotaReached) {
        this.readonly = limitQuotaReached;
        this.ref.markForCheck();
    }

    handleValid(valid) {
        this.valid = valid;
    }

    // https://github.com/swimlane/ngx-datatable/issues/721
    onActive(event) {
        if (event.type === 'click') {
            event.cellElement.blur();
        }
    }

    onEmergencyCardVisibleChange() {
        this.emergencyPassService.updateMyEmergencyPassConfig(this.emergencyCardConfig).subscribe(
          () => this.translatedToastrService.success('message.success.save'),
          () => this.translatedToastrService.error('message.error.save')
        );
    }

    onUnitChange() {
        this.handleUnitValueChange();
    }

    onVisibleChange() {
        this.journalService.updateMyJournalConfig(this.journalConfig).subscribe(
          () => this.translatedToastrService.success('message.success.save'),
          () => this.translatedToastrService.error('message.error.save')
        );
    }

    save() {
        this.isSubmitted = true;
        if (this.valid && this.validValues()) {
            const date = moment(
                this.date.year + '-' +
                this.date.month + '-' +
                this.date.day + ' ' +
                this.time.hour + ':' +
                this.time.minute
                , 'YYYY-MM-DD HH:mm');

            this.bloodGlucose.date = date.toDate();
            this.bloodGlucose.unit = this.unit;
            this.bloodGlucoseService.create(this.bloodGlucose)
                .subscribe(() => {
                    this.translatedToastrService.success('message.success.save');
                    this.bloodGlucose = new BloodGlucose();

                    this.loadData();
                    this.chart.fetchValues();
                    this.usageAlert.loadsUsage();
                    this.isSubmitted = false;
                },
                    () => this.translatedToastrService.error('message.error.save'));
        }
    }

    setPage(pageInfo) {
        this.pageNumber = pageInfo.offset;
        this.bloodGlucoseService.listBloodGlucoses(this.personIdParam, this.unit, this.pageNumber + 1, this.pageSize, null, null, null, 'desc')
            .subscribe(r => {
                this.bloodGlucoses = r.results;
                this.count = r.count;
                this.ref.markForCheck();
            });
    }

    validValues() {
        return this.bloodGlucose.value != null && this.bloodGlucose.value >= this.getMin() && this.bloodGlucose.value <= this.getMax();
    }

    private handleUnitValueChange() {
        localStorage.setItem('settings.bgUnit', this.unit.toString());

        this.loadData();

        if (this.unit === BloodGlucoseUnitEnum.MMOL_L) {
            this.unitLabel = 'mmol/L';
            this.chart.unitLabel = this.unitLabel;
            this.chart.unit = BloodGlucoseUnitEnum.MMOL_L;
        } else {
            this.unitLabel = 'mg/dL';
            this.chart.unitLabel = this.unitLabel;
            this.chart.unit = BloodGlucoseUnitEnum.MG_DL;
        }
        this.chart.markForCheck();
    }

    private initDate() {
        const now = moment();
        this.time = { hour: now.hour(), minute: now.minute(), second: 0 };
        this.date = { day: now.date(), month: (now.month() + 1), year: now.year() };
    }

    private loadData() {
        this.initDate();
        this.setPage({ offset: 0 });
    }

    private loadUnit() {
        const unit = localStorage.getItem('settings.bgUnit');
        this.unit = !hasValue(unit) || (unit !== BloodGlucoseUnitEnum.MMOL_L.toString() && unit !== BloodGlucoseUnitEnum.MG_DL.toString()) ?
          BloodGlucoseUnitEnum.MG_DL : parseInt(unit, 10);
        this.handleUnitValueChange();
    }

    private removeRow(index: number, bloodGlucose: BloodGlucose) {
        this.bloodGlucoseService.delete(bloodGlucose.id).subscribe(() => {
              this.bloodGlucoses.splice(index, 1);
              this.bloodGlucoses = [...this.bloodGlucoses];
              this.chart.fetchValues();
              this.usageAlert.loadsUsage();
              this.ref.markForCheck();
          },
          () => this.translatedToastrService.error('message.error.delete')
        );
    }

    private showDeleteModal(index: number, bloodGlucose: BloodGlucose) {
        setTimeout(() => {
            this.currentModal = this.modal.open(DeleteModalComponent, {size: 'lg'});
            this.currentModal.componentInstance.itemHeader = this.formatMonthDayYear(bloodGlucose.date);
            this.currentModal.componentInstance.itemType = this.translateService.instant('journal.bloodGlucose.title');
            this.currentModal.componentInstance.itemValue = formatNumber(bloodGlucose.value, this.localeResolverService.locale, '1.1-1') +
              ' ' + this.unitLabel;
            this.currentModal.result.then(isDeleted => {
                if (isDeleted) {
                    this.removeRow(index, bloodGlucose);
                }
            });
        }, 100);
    }
}
