export enum MedicationTypeEnum {
  TABLET_CAPSULE_DRAGEE = 1,
  SUPPOSITORY = 2,
  SYRINGE_INFUSION = 3,
  OINTMENT_TINCTURE = 4,
  PLASTER = 5,
  SPRAY = 6,
  DROPS = 7,
  OTHER = 8
}
