import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../../core/authentication/authentication.service';
import {take} from 'rxjs/operators';
import {forkJoin} from 'rxjs';
import {TranslatedToastrService} from '../../../core/translated-toastr/translated-toastr.service';

@Component({
    selector: 'app-confirmreg',
    templateUrl: './confirm-reg.component.html',
    styleUrls: ['./confirm-reg.component.scss']
})
export class ConfirmRegComponent implements OnInit {
    code: string;
    private email: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private translatedToastrService: TranslatedToastrService,
    ) { }

    ngOnInit() {
        const routeParams$ = this.route.params.pipe(take(1));
        const queryParams$ = this.route.queryParams.pipe(take(1));

        forkJoin([routeParams$, queryParams$]).subscribe(respList => {
            this.email = respList[0]['email'];
            this.code = respList[1]['code'];

            if (this.code) { this.onRegConfirmation(); }
        });
    }

    onRegConfirmation() {
        if (this.code == null) {
            this.translatedToastrService.error('authentication.errors.CodeMandatoryError');
            return;
        }

        this.authenticationService.confirmAccount(this.email, this.code).subscribe(
          () => this.onConfirmAccountSuccess(),
          err => this.onConfirmAccountError(err)
        );
    }

    resendConfirmationCode() {
        this.authenticationService.resendVerificationCode(this.email).subscribe(
          () => this.translatedToastrService.success('message.success.resendVerificationCode'),
          err => this.onConfirmAccountError(err)
        );
    }

    private onConfirmAccountError(err) {
        if (err && err.error && err.error.error) {
            const errCode = err.error.error.code;
            const translationCode = 'authentication.errors.' + errCode;
            this.translatedToastrService.error(translationCode);
        }
    }

    private onConfirmAccountSuccess() {
        this.router.navigate(['/signin']);
    }
}
