import {Injectable} from '@angular/core';
import {BackendService} from '../backend/backend.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BloodGlucoseListResponse} from '../response/response.model';
import {ToastrService} from 'ngx-toastr';
import {BloodGlucose} from './blood-glucose.model';
import {LocaleResolverService} from '../locale/locale-resolver.service';

@Injectable()
export class BloodGlucoseService extends BackendService {

  constructor(
      protected http: HttpClient,
      protected localeResolverService: LocaleResolverService,
  ) {
    super(http, localeResolverService);
  }

  create(bloodGlucose: BloodGlucose) {
    const httpOptions = this.createHttpOptions();
    return this.http.post(this.getApiUrl() + '/measurements/bloodGlucoses', bloodGlucose, httpOptions);
  }

  listBloodGlucoses(id: string, unit: number, page: number, pageSize: number, lastId: number, start: string, end: string, sort = 'asc') {
    const httpHeaders = this.buildBaseHttpHeaders();

    let params = new HttpParams();
    if (page !== undefined && page !== null) {
      params = params.append('page', page.toString());
    }
    if (pageSize !== undefined && pageSize !== null) {
      params = params.append('pageSize', pageSize.toString());
    }
    if (lastId !== undefined && lastId !== null) {
      params = params.append('lastId', lastId.toString());
    }
    if (start !== undefined && start !== null) {
      params = params.append('start', encodeURIComponent(start.toString()));
    }
    if (end !== undefined && end !== null) {
      params = params.append('end', encodeURIComponent(end.toString()));
    }
    if (unit !== undefined && unit !== null) {
      params = params.append('unit', encodeURIComponent(unit.toString()));
    }
    if (sort !== undefined && sort !== null) {
      params = params.append('sort', encodeURIComponent(sort.toString()));
    }

    return this.http.get<BloodGlucoseListResponse>(
        this.getApiUrl() + '/people/' + id + '/measurements/bloodGlucoses',
        (params.keys().length > 0) ? { headers: httpHeaders, params: params } : { headers: httpHeaders });
  }

  listBloodGlucoseStats(id: string, unit: number, start: string, end: string) {
    const httpHeaders = this.createHttpHeaders();

    let params = new HttpParams();
    if (start !== undefined && start !== null) {
      params = params.append('start', encodeURIComponent(start.toString()));
    }
    if (end !== undefined && end !== null) {
      params = params.append('end', encodeURIComponent(end.toString()));
    }
    if (unit !== undefined && unit !== null) {
      params = params.append('unit', encodeURIComponent(unit.toString()));
    }
    return this.http.get<BloodGlucoseListResponse>(
        this.getApiUrl() + '/people/' + id + '/measurements/bloodGlucoseStats',
        (params.keys().length > 0) ? { headers: httpHeaders, params: params } : { headers: httpHeaders });
  }

  loadLatestBloodGlucose(personIdParam: string) {
    return this.listBloodGlucoses(personIdParam, 1, 1, 1, null, null, null, 'desc');
  }

  delete(id: number) {
    const httpOptions = this.createHttpOptions();
    return this.http.delete(this.getApiUrl() + '/measurements/bloodGlucoses/' + id, httpOptions);
  }
}
