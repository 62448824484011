import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Medication} from '../../../core/health/medication.model';
import {MedicationService} from '../../../core/health/medication.service';
import {formatDate} from '../../../../lib/formats';
import {TranslateService} from '@ngx-translate/core';
import {MedicationTypeEnum} from '../../../shared/enums/medication-type.enum';

@Component({
  selector: 'app-emergency-card-medications',
  templateUrl: './emergency-card-medications.component.html',
  styleUrls: ['./emergency-card-medications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmergencyCardMedicationsComponent implements OnChanges, OnInit {
  @Input() medications: Medication[] = [];
  @Input() personIdParam: string;

  constructor(private medicationService: MedicationService,
              private translateService: TranslateService,
              protected ref: ChangeDetectorRef) {
  }

  ngOnInit() {
    if (this.medications.length) {
      this.medications.forEach(medication => this.localizeMedication(medication));
      this.ref.markForCheck();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const change = changes.medications || undefined;
    if (change) {
      this.medications.forEach(medication => this.localizeMedication(medication));
      this.ref.markForCheck();
    }
  }

  getMedicationTypeDescription(medication: Medication): string {
    return Medication.getMedicationTypeDescription(medication);
  }

  getMedicationUnit(medication: Medication): string {
    let unit = '';
    switch (medication.type) {
      case MedicationTypeEnum.TABLET_CAPSULE_DRAGEE:
      case MedicationTypeEnum.SUPPOSITORY:
      case MedicationTypeEnum.PLASTER:
        unit = '(' + this.translateService.instant('codes.medication.unit.piece') + ')';
        break;

      case MedicationTypeEnum.SYRINGE_INFUSION:
        unit = '(' + this.translateService.instant('codes.medication.unit.milliliter') + ')';
        break;

      case MedicationTypeEnum.SPRAY:
        unit = '(' + this.translateService.instant('codes.medication.unit.strokes') + ')';
        break;

      case MedicationTypeEnum.DROPS:
        unit = '(' + this.translateService.instant('codes.medication.unit.drops') + ')';
        break;
    }
    return unit;
  }

  showMedicationTypeInfo(medication: Medication): boolean {
    return !!medication.type && (!!medication.evening || !!medication.morning || !!medication.lunch || !!medication.night);
  }

  private localizeMedication(medication: Medication) {
    medication.localized = {
      type: '',
      appSummary: '',
      start: '',
      end: '',
      created_at: ''
    };
    medication.localized.appSummary = this.medicationService.applicationSummary(medication);
    if (medication.type) {
      medication.localized.type = this.translateService.instant('codes.medication.type.' + medication.type);
    }
    medication.localized.start = formatDate(medication.start);
    medication.localized.end = formatDate(medication.end);
  }
}
