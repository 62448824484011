import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-patient-registration-card',
  templateUrl: './patient-registration-card.component.html',
  styleUrls: ['./patient-registration-card.component.scss']
})
export class PatientRegistrationCardComponent  {
  @Input() title: string;
}
