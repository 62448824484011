export enum MedakteIconTypes {
  ADD = 'add',
  CALENDAR = 'calendar',
  DELETE = 'delete',
  DOWNLOAD = 'download',
  EDIT = 'edit',
  EMERGENCY_CARD_VISIBLE = 'emergency_card_visible',
  FAVOURITE = 'favourite',
  OPEN = 'open',
  PROFILE_PICTURE = 'profile_picture',
  SEARCH = 'search',
  VIEW = 'view',
  VISIBLE = 'visible',
}
