import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SelectedItem} from '../../../core/selected-item/selected-item';
import {SelectOption} from '../../../core/select-option/select-option';
import {MedicSpecialtyService} from '../../../core/medic/medic-specialty/medic-specialty.service';
import {MedicSpecialtyCode} from '../../../core/medic/medic-specialty/medic-specialty-code';

@Component({
  selector: 'app-specialty-filter',
  templateUrl: './specialty-filter.component.html',
  styleUrls: ['./specialty-filter.component.scss']
})
export class SpecialtyFilterComponent implements OnInit {
  @Input() selectedSpecialties: string[] = [];
  @Output() selectedSpecialtiesChange = new EventEmitter<string[]>();
  isLoading = true;
  specialties: SelectOption[] = [];

  constructor(
    private medicSpecialtyService: MedicSpecialtyService,
  ) { }

  ngOnInit() {
    this.isLoading = false;

    this.medicSpecialtyService.listSpecialtyCodes().subscribe(
      res => this.afterListSpecialtyCodes(res.results),
    );
  }

  onAddSpecialty(specialty: SelectOption) {
    this.selectedSpecialties.push(specialty.value);
    this.selectedSpecialtiesChange.emit(this.selectedSpecialties);
  }

  onClearSpecialties() {
    this.selectedSpecialties = [];
    this.selectedSpecialtiesChange.emit(this.selectedSpecialties);
  }

  onRemoveSpecialty(entry: SelectedItem<SelectOption>) {
    const specialty = entry.value;
    const idx = this.selectedSpecialties.findIndex(selectedSpecialty => selectedSpecialty === specialty.value);
    if (idx >= 0) {
      this.selectedSpecialties.splice(idx, 1);
      this.selectedSpecialtiesChange.emit(this.selectedSpecialties);
    }
  }

  private afterListSpecialtyCodes(specialtyCodes: MedicSpecialtyCode[]) {
    this.specialties = [];
    for (const specialtyCode of specialtyCodes) {
      this.specialties.push({label: 'codes.specialty.' + specialtyCode.code, value: specialtyCode.code});
    }
  }
}
