import {Recipient} from './recipient.model';

export class EmailMessage {
  id: number;
  body: string;
  bodyType: number;
  conversationId: number;
  created_at: Date;
  parentId: number;
  senderId: number;
  subject: string;
  type: number;

  recipients: Recipient[];
  recipientsIds: number[];

  collapsed = true;
}
