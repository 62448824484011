import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ProfileService} from '../../core/profile/profile.service';
import {Subscription} from 'rxjs';
import {Person} from '../../core/person/person.model';
import {AuthenticationService} from '../../core/authentication/authentication.service';
import {PersonTypeEnum} from '../enums/person-type.enum';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarComponent implements OnDestroy, OnInit {
  isLoggedIn = false;
  isMedic = false;
  isPatient = false;
  person: Person;
  private subscriptions: Subscription[] = [];

  constructor(
    private authenticationService: AuthenticationService,
    private profileService: ProfileService,
    private ref: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    this.subscriptions.push(
      this.authenticationService.isLoggedIn.subscribe(isLoggedIn => {
        this.isLoggedIn = isLoggedIn;
        this.ref.markForCheck();
      }),
      this.profileService.myProfileSubject.subscribe(person => {
        this.person = person;
        if (person) {
          switch (person.type) {
            case PersonTypeEnum.PATIENT:
              this.isMedic = false;
              this.isPatient = true;
              break;

            case PersonTypeEnum.DOCTOR:
            case PersonTypeEnum.MEDICAL_INSTITUTION:
              this.isMedic = true;
              this.isPatient = false;
              break;
          }
        } else {
          this.isMedic = false;
          this.isPatient = false;
        }
        this.ref.markForCheck();
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  isDefaultToolbarVisible(): boolean {
    return !this.isLoggedIn;
  }

  isOnboardingToolbarVisible(): boolean {
    return this.isLoggedIn && !this.isPatient && !this.isMedic;
  }

  isMedicToolbarVisible(): boolean {
    return this.isLoggedIn && this.isMedic;
  }

  isPatientToolbarVisible(): boolean {
    return this.isLoggedIn && this.isPatient;
  }
}
