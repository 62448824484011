import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-clear-input',
  templateUrl: './clear-input.component.html',
  styleUrls: ['./clear-input.component.scss']
})
export class ClearInputComponent implements OnInit {
  @Input() isHt = true;
  @Output() clearInputChange = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onClickClear() {
    this.clearInputChange.emit();
  }
}
