import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SubsPlan} from '../../../core/subs-plan/subs-plan.model';
import {OnboardingService} from '../../../core/onboarding/onboarding.service';
import {OnboardingStages} from '../../../core/onboarding/onboarding-stages';
import {Subscription} from '../../../core/subscription/subscription.model';
import {PaymentMethodEnum} from '../../../shared/enums/payment-method.enum';
import {RenewalPeriodEnum} from '../../../shared/enums/renewal-period.enum';
import {StripeCardComponent} from '../../../shared/payment/stripe/stripe-card/stripe-card.component';
import {hasValue} from '../../../../lib/validator';
import {TranslatedToastrService} from '../../../core/translated-toastr/translated-toastr.service';
import {NavigationService} from '../../../core/navigation/navigation.service';

@Component({
  selector: 'app-patient-onboarding-payment',
  templateUrl: './patient-onboarding-payment.component.html',
  styleUrls: ['./patient-onboarding-payment.component.scss']
})
export class PatientOnboardingPaymentComponent implements OnInit {
  @ViewChild('stripeCard') stripeCard: StripeCardComponent;
  isInProgress = false;
  isLoaded = false;
  isCardCompleted = false;
  paymentMethod = PaymentMethodEnum.STRIPE;
  renewalPeriod = RenewalPeriodEnum.YEARLY;
  subsPlan: SubsPlan;
  urlConditions = 'https://medakte.com/terms/';
  urlPrivacy = 'https://medakte.com/privacy-policy/';

  constructor(
    private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService,
    private onboardingService: OnboardingService,
    private router: Router,
    private translatedToastrService: TranslatedToastrService,
  ) {
  }

  ngOnInit() {
    this.checkOnboarding();
  }

  isValid() {
    return hasValue(this.subsPlan) &&
      hasValue(this.subsPlan.code) &&
      hasValue(this.renewalPeriod) &&
      hasValue(this.paymentMethod);
  }

  onClickBack() {
    this.navigationService.navigateToPatientOnboardingSubscription();
  }

  onClickPaymentConfirmation() {
    this.saveSubscriptionAndPayment();
  }

  onStripeCardCompleted(isStripeCardCompleted: boolean) {
    this.isCardCompleted = isStripeCardCompleted;
  }

  private checkOnboarding() {
    this.onboardingService.getPatientOnboardingStage().subscribe(
      data => {
        const onboardingUrl = '/onboarding/' + data.result.onboardingStage;
        // START should be DONE
        if (OnboardingStages.SUBSCRIPTION !== data.result.onboardingStage) {
          this.router.navigate([onboardingUrl]);
        } else {
          this.initOnboardingPayment();
        }
      }
    );
  }

  private initOnboardingPayment() {
    // get subsPlan
    this.activatedRoute.data.subscribe((data: { subsPlan: SubsPlan }) => {
      this.subsPlan = data.subsPlan;
      this.isLoaded = true;
    });
  }

  private async saveSubscriptionAndPayment() {
    // Stripe (Yearly)
    if (this.isCardCompleted && !this.isInProgress && this.isValid()) {
      this.isInProgress = true;

      const subscription = new Subscription();
      subscription.planCode = this.subsPlan.code;
      subscription.renewalPeriod = this.renewalPeriod;
      subscription.paymentMethod = this.paymentMethod;

      const source = await this.stripeCard.obtainSource();

      if (source) {
        this.onboardingService.startSubscriptionWithPaymentStripe(subscription)
          .subscribe(resp => {
            if (resp.result.approvalUrl) {
              // @ts-ignore
              window.location = resp.result.approvalUrl;
            } else {
                this.isInProgress = false;
                this.navigationService.navigateToPatientOnboardingPaymentSuccess();
              }
            },
            () => {
              this.isInProgress = false;
              this.translatedToastrService.error('message.error.payment.stripe');
            });
      } else {
        this.translatedToastrService.error('message.error.payment.creditCard');
        this.isInProgress = false;
      }
    } else {
      this.translatedToastrService.error('message.error.payment.creditCard');
      this.isInProgress = false;
    }
  }
}
