import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AllergyService} from '../../../../core/health/allergy.service';
import {DocumentHolder} from '../../../documents/document.model';
import {Allergy} from '../../../../core/health/allergy.model';
import {NgbActiveModal, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {fromNgbDateStructToDate, toNgbDateStruct} from '../../../../../lib/dates';
import {LocaleResolverService} from '../../../../core/locale/locale-resolver.service';
import {BaseComponent} from '../../../../../base/base-component';
import {NgbDateDynamicParserFormatter} from '../../../../../lib/ngb-date-dynamic-parser-formatter';
import * as moment from 'moment-timezone';
import {CustomValidators} from 'ngx-custom-validators';
import {ProfileService} from '../../../../core/profile/profile.service';
import {AllergyCategoryEnum} from '../../../../shared/enums/allergy-category.enum';
import {AllergySeverityEnum} from '../../../../shared/enums/allergy-severity.enum';
import {TranslatedToastrService} from '../../../../core/translated-toastr/translated-toastr.service';

@Component({
    selector: 'app-allergy-edit',
    templateUrl: './allergy-edit.component.html',
    styleUrls: ['./allergy-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateDynamicParserFormatter }
    ]
})
export class AllergyEditComponent extends BaseComponent implements OnInit {
    @Input() allergy: Allergy;
    @Input() personIdParam: string;
    @Input() readOnly = false;
    @Output() saved = new EventEmitter<boolean>();
    allergyFormGroup: FormGroup;
    categories = [
        {label: 'codes.allergy.category.' + AllergyCategoryEnum.DRUG, value: AllergyCategoryEnum.DRUG},
        {label: 'codes.allergy.category.' + AllergyCategoryEnum.FOOD, value: AllergyCategoryEnum.FOOD},
        {label: 'codes.allergy.category.' + AllergyCategoryEnum.ENVIRONMENT, value: AllergyCategoryEnum.ENVIRONMENT},
        {label: 'codes.allergy.category.' + AllergyCategoryEnum.OTHER, value: AllergyCategoryEnum.OTHER},
    ];
    documents: DocumentHolder[] = [];
    end: NgbDateStruct;
    isSubmitted = false;
    severities = [
        {label: 'codes.allergy.severity.' + AllergySeverityEnum.LOW, value: AllergySeverityEnum.LOW},
        {label: 'codes.allergy.severity.' + AllergySeverityEnum.MEDIUM, value: AllergySeverityEnum.MEDIUM},
        {label: 'codes.allergy.severity.' + AllergySeverityEnum.HIGH, value: AllergySeverityEnum.HIGH},
        {label: 'codes.allergy.severity.' + AllergySeverityEnum.CRITICAL, value: AllergySeverityEnum.CRITICAL},
    ];
    start: NgbDateStruct;
    tillNow = false;
    valid = true;

    constructor(
        private allergyService: AllergyService,
        public translatedToastrService: TranslatedToastrService,
        private fb: FormBuilder,
        protected profileService: ProfileService,
        protected localeResolverService: LocaleResolverService,
        protected ref: ChangeDetectorRef,
        public activeModal: NgbActiveModal
    ) {
        super(profileService, localeResolverService, ref);
    }

    ngOnInit() {
        super.ngOnInit();

        // init form group
        this.allergyFormGroup = this.fb.group({
            id: [null],
            title: ['', Validators.required],
            category: [null, Validators.required],
            severity: [null],
            start: [null, Validators.compose([Validators.required, CustomValidators.date])],
            end: [null, CustomValidators.date],
            doctor: [null],
            notes: [null],
            visible: [null],
            favourite: [null],
            emergencyCardVisible: [null]
        });

        this.initDate();

        if (!this.allergy) {
            this.allergy = new Allergy();
            this.ref.markForCheck();
        } else {
            this.initForm();
        }
    }

    close(saved: boolean) {
        this.saved.emit(saved);
        this.activeModal.close(saved);
    }

    onCategoryChange() {
        this.ref.markForCheck();
    }

    onEmergencyCardVisibleChange(emergencyCardVisible: boolean) {
        this.allergyFormGroup.controls['emergencyCardVisible'].setValue(emergencyCardVisible);
    }

    onEndChange(end: NgbDateStruct) {
        this.end = end;
        this.allergyFormGroup.controls['end'].setValue(fromNgbDateStructToDate(end));
        this.ref.markForCheck();
    }

    onFavouriteChange(favourite: boolean) {
        this.allergyFormGroup.controls['favourite'].setValue(favourite);
    }

    onStartChange(start: NgbDateStruct) {
        this.start = start;
        this.allergyFormGroup.controls['start'].setValue(fromNgbDateStructToDate(start));
        this.ref.markForCheck();
    }

    onSeverityChange() {
        this.ref.markForCheck();
    }

    onTillNowChange(tillNow: boolean) {
        this.tillNow = tillNow;
        this.allergyFormGroup.controls['end'].setValue(this.tillNow ? null : fromNgbDateStructToDate(this.end));
        this.ref.markForCheck();
    }

    onVisibleChange(visible: boolean) {
        this.allergyFormGroup.controls['visible'].setValue(visible);
    }

    save() {
        this.isSubmitted = true;
        this.allergyFormGroup.controls['start'].setValue(fromNgbDateStructToDate(this.start));
        this.allergyFormGroup.controls['end'].setValue(fromNgbDateStructToDate(this.end));

        if (this.allergyFormGroup.valid && this.valid) {
            this.allergy = this.allergyFormGroup.value;

            if (this.allergy.id) {
                // updating existing allergy
                this.allergyService.updateAllergy(this.allergy.id, this.allergy).subscribe(res => {
                    this.isSubmitted = false;
                    this.saved.emit(true);

                    this.translatedToastrService.success('message.success.allergy.update');
                    this.ref.markForCheck(); // trigger refresh
                    this.close(true);
                }, () => this.translatedToastrService.error('message.error.allergy.update'));

            } else {
                // create new allergy
                this.allergyService
                    .createAllergy(this.personIdParam, this.allergy)
                    .subscribe(res => {
                        this.isSubmitted = false;
                        this.saved.emit(true);
                        this.resetForm();
                        this.translatedToastrService.success('message.success.allergy.update');
                        this.ref.markForCheck(); // trigger refresh
                        this.close(true);
                    });
            }
        }
    }

    private initDate() {
        const now = moment();
        this.start = { day: now.date(), month: (now.month() + 1), year: now.year() };
    }

    private initForm() {
        this.start = toNgbDateStruct(this.allergy.start);
        this.end = toNgbDateStruct(this.allergy.end);
        this.tillNow = !this.allergy.end;
        this.allergyFormGroup.patchValue(this.allergy);
        if (this.readOnly) { this.allergyFormGroup.disable(); }
        this.ref.markForCheck();
    }

    private resetForm() {
        this.allergyFormGroup.reset();
        this.initDate();
        this.end = null;
        this.allergy = new Allergy();
        this.ref.markForCheck();
    }
}
