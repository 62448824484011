import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {FamilyDisease} from '../../../core/health/family-disease.model';
import {NgbDateStruct, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {FamilyDiseaseService} from '../../../core/health/family-disease.service';
import {ActivatedRoute} from '@angular/router';
import {Person} from '../../../core/person/person.model';
import {canEdit} from '../../../../lib/utils';
import {ProfileService} from '../../../core/profile/profile.service';
import {take} from 'rxjs/operators';
import {forkJoin} from 'rxjs';
import {UsageAlertComponent} from '../../../shared/usage/usage-alert/usage-alert.component';
import {FamilyDiseaseEditComponent} from './family-disease-edit/family-disease-edit.component';
import {UsageTypeEnum} from '../../../shared/enums/usage-type.enum';
import {BaseComponent} from '../../../../base/base-component';
import {LocaleResolverService} from '../../../core/locale/locale-resolver.service';
import {DeleteModalComponent} from '../../../shared/delete-modal/delete-modal.component';
import {TranslateService} from '@ngx-translate/core';
import {QueryFilter} from '../../../core/filters/query-filter.model';
import {fromNgbDateStructToDate} from '../../../../lib/dates';
import {TranslatedToastrService} from '../../../core/translated-toastr/translated-toastr.service';
import {SelectionType} from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-family-diseases',
  templateUrl: './family-diseases.component.html',
  styleUrls: ['./family-diseases.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FamilyDiseasesComponent extends BaseComponent implements OnInit {
  @ViewChild('usageAlert', { static: true }) usageAlert: UsageAlertComponent;
  currentModal: NgbModalRef;
  familyDiseaseId: number;
  familyDiseases: FamilyDisease[];
  filterEnd: NgbDateStruct;
  filterStart: NgbDateStruct;
  isLoadingFamilyDiseaseDetails = false;
  lastId: number;
  pageSize = 30;
  personIdParam: string;
  query: string;
  queryFilters: QueryFilter[] = [];
  quotaReached = false;
  requestedPerson: Person;
  selectedFamilyDisease: FamilyDisease;
  UsageTypeEnum = UsageTypeEnum;
  selected = [];
  SelectionType = SelectionType;

  constructor(
    private activatedRoute: ActivatedRoute,
    private familyDiseaseService: FamilyDiseaseService,
    private modal: NgbModal,
    private translatedToastrService: TranslatedToastrService,
    private translateService: TranslateService,
    protected profileService: ProfileService,
    protected localeResolverService: LocaleResolverService,
    protected ref: ChangeDetectorRef
  ) {
    super(profileService, localeResolverService, ref);
  }

  ngOnInit() {
    super.ngOnInit();

    const routeParams$ = this.activatedRoute.params.pipe(take(1));

    forkJoin([routeParams$]).subscribe(respList => {
      this.personIdParam = respList[0]['id'];
      if ('familyDiseaseId' in respList[0]) {
        this.familyDiseaseId = +(respList[0]['familyDiseaseId']);
      }

      this.loadFamilyDiseases();

      this.profileService.setCurrentPersonIdParam(this.personIdParam);

      if (this.personIdParam !== 'me') {
        this.profileService.getProfile(this.personIdParam).subscribe(resp => {
          this.requestedPerson = resp.result;
          this.ref.markForCheck();
        });
      }
    });
  }

  addNew() {
    this.showAddEditModal();
  }

  askToRemove(event, firstChild) {
    event.stopPropagation();
    firstChild.parentElement.parentElement.blur();
    this.showDeleteModal();
  }

  editItem(event, firstChild) {
    event.stopPropagation();
    firstChild.parentElement.parentElement.blur();
    this.showAddEditModal(this.selectedFamilyDisease);
  }

  onIsLimitQuotaReached(limitQuotaReached) {
    this.quotaReached = limitQuotaReached;
  }

  loadFamilyDiseaseDetail(familyDiseaseId) {
    if (this.selectedFamilyDisease && this.selectedFamilyDisease.id === familyDiseaseId) {
      this.selectedFamilyDisease = null;
      this.selected = [];
    } else {
      this.selectedFamilyDisease = null;
      this.isLoadingFamilyDiseaseDetails = true;
      this.familyDiseaseService.getFamilyDisease(this.personIdParam, familyDiseaseId).subscribe(r => {
        this.selectedFamilyDisease = r.result;
        this.isLoadingFamilyDiseaseDetails = false;
        this.ref.markForCheck();
      }, () => {
        this.selected = [];
        this.translatedToastrService.error('message.error.familyDisease.detail');
      });
    }
  }

  // https://github.com/swimlane/ngx-datatable/issues/721
  onActive(event) {
    if (event.type === 'click') {
      event.cellElement.blur();
      this.loadFamilyDiseaseDetail(event.row.id);
    }
  }

  onDateFilterChange(selectedDate: NgbDateStruct, filterName: string) {
    let filterValue;
    switch (filterName) {
      case 'end':
        this.filterEnd = selectedDate;
        if (this.filterEnd) {
          const endDate = fromNgbDateStructToDate(this.filterEnd);
          // check if date is valid
          if (!isNaN(endDate.getTime())) {
            endDate.setHours(23, 59, 59, 59);
            filterValue = endDate.toISOString();
          }
        }
        break;

      case 'start':
        this.filterStart = selectedDate;
        if (this.filterStart) {
          const startDate = fromNgbDateStructToDate(this.filterStart);
          // check if date is valid
          if (!isNaN(startDate.getTime())) {
            startDate.setHours(0, 0, 0, 0);
            filterValue = startDate.toISOString();
          }
        }
        break;
    }

    this.onFilterChange(filterName, filterValue);
  }

  onFilterChange(filterName: string, filterValue: any) {
    const filter = this.queryFilters.find(queryFilter => queryFilter.name === filterName);

    if (filter && filterValue) {
      // update value
      filter.value = filterValue;
    } else if (filter) {
      // unset value
      this.queryFilters.splice(this.queryFilters.lastIndexOf(filter), 1);
    } else if (filterValue) {
      // new value
      this.queryFilters.push({name: filterName, value: filterValue});
    }
    // reload the list
    this.lastId = undefined;
    this.loadFamilyDiseases();
  }

  onSearchChange(search: string) {
    this.query = search;
    this.lastId = null;
    this.loadFamilyDiseases();
  }

  toggleEmergencyCardVisible(event, familyDisease?: FamilyDisease) {
    event.stopPropagation();
    if (familyDisease) {
      familyDisease.emergencyCardVisible = !familyDisease.emergencyCardVisible;

      this.updateFamilyDisease(familyDisease);
    } else if (this.selectedFamilyDisease) {
      this.selectedFamilyDisease.emergencyCardVisible = !this.selectedFamilyDisease.emergencyCardVisible;
      const diseaseIndex = this.familyDiseases.findIndex(d => d.id === this.selectedFamilyDisease.id);
      this.familyDiseases[diseaseIndex].emergencyCardVisible = this.selectedFamilyDisease.emergencyCardVisible;

      this.updateSelectedFamilyDisease();
    }
  }

  toggleFavourite(familyDisease?: FamilyDisease) {
    if (familyDisease) {
      familyDisease.favourite = !familyDisease.favourite;

      this.updateFamilyDisease(familyDisease);
    } else if (this.selectedFamilyDisease) {
      this.selectedFamilyDisease.favourite = !this.selectedFamilyDisease.favourite;
      const idx = this.familyDiseases.findIndex(d => d.id === this.selectedFamilyDisease.id);
      this.familyDiseases[idx].favourite = this.selectedFamilyDisease.favourite;
      this.updateSelectedFamilyDisease();
    }
  }

  toggleVisibility(event, familyDisease?: FamilyDisease) {
    event.stopPropagation();
    if (familyDisease) {
      familyDisease.visible = !familyDisease.visible;

      this.updateFamilyDisease(familyDisease);
    } else if (this.selectedFamilyDisease) {
      this.selectedFamilyDisease.visible = !this.selectedFamilyDisease.visible;
      const idx = this.familyDiseases.findIndex(d => d.id === this.selectedFamilyDisease.id);
      this.familyDiseases[idx].visible = this.selectedFamilyDisease.visible;
      this.updateSelectedFamilyDisease();
    }
  }

  private handleSaved(saved: boolean) {
    if (saved) {
      this.lastId = null;
      this.usageAlert.loadsUsage();
      this.loadFamilyDiseases();
      this.loadFamilyDiseaseDetail(this.selectedFamilyDisease.id);
    }
  }

  private loadFamilyDiseases() {
    this.familyDiseaseService
      .listFamilyDiseases(
        this.personIdParam,
        this.query,
        null,
        this.lastId ? null : 1,
        this.pageSize,
        this.lastId,
        this.queryFilters
      )
      .subscribe(
        resp => {
          this.familyDiseases = this.lastId ? this.familyDiseases.concat(resp.results) : resp.results;
          this.familyDiseases.forEach(m => {
            m.localized = {
              relative: '',
              created_at: ''
            };
            m.localized.created_at = this.formatDayDate(m.created_at);
          });

          this.lastId = resp.lastId;
          this.familyDiseases = [...this.familyDiseases];

          if (this.familyDiseaseId) {
            this.loadFamilyDiseaseDetail(this.familyDiseaseId);
            this.familyDiseaseId = undefined;
          }

          this.ref.markForCheck(); // trigger refresh
        },
        e => {
          console.log(e);
          this.translatedToastrService.error('message.error.familyDisease.list');
        }
      );
  }

  private removeItem() {
    this.familyDiseaseService
      .deleteFamilyDisease(this.selectedFamilyDisease.id)
      .subscribe(
        () => {
          this.translatedToastrService.success('message.success.familyDisease.delete');

          this.familyDiseases = this.familyDiseases.filter(fd => fd.id !== this.selectedFamilyDisease.id);
          // this is a workaround - see https://stackoverflow.com/questions/51704888/remove-rows-with-ngx-datatable
          this.familyDiseases = [...this.familyDiseases];

          this.selectedFamilyDisease = null;
          this.currentModal.close();
          this.usageAlert.loadsUsage();
          this.ref.markForCheck(); // trigger refresh
        },
        () => this.translatedToastrService.error('message.error.familyDisease.delete')
      );
  }

  private showAddEditModal(familyDisease?: FamilyDisease) {
    setTimeout(() => {
      this.currentModal = this.modal.open(FamilyDiseaseEditComponent, {size: 'lg'});
      this.currentModal.componentInstance.personIdParam = this.personIdParam;
      this.currentModal.componentInstance.requester = this.requester;
      this.currentModal.componentInstance.familyDisease = familyDisease;
      if (familyDisease) {
        this.currentModal.componentInstance.readonly = !canEdit(this.personIdParam, this.requester.id, null);
      }
      this.currentModal.result.then(saved => this.handleSaved(saved)).catch(e => {
      });
    }, 100);
  }

  private showDeleteModal() {
    setTimeout(() => {
      this.currentModal = this.modal.open(DeleteModalComponent, {size: 'lg'});
      this.currentModal.componentInstance.itemDescription = this.selectedFamilyDisease.notes;
      this.currentModal.componentInstance.itemHeader = this.selectedFamilyDisease.diseaseCode ?
        this.selectedFamilyDisease.diseaseCode.text + ' (' + this.selectedFamilyDisease.diseaseCode.code + ')' :
        this.selectedFamilyDisease.title;
      this.currentModal.componentInstance.itemType = this.translateService.instant('familyDisease');
      this.currentModal.result.then(isDeleted => {
        if (isDeleted) {
          this.removeItem();
        }
      });
    }, 100);
  }

  private updateFamilyDisease(familyDisease: FamilyDisease) {
    if (familyDisease) {
      this.familyDiseaseService.updateFamilyDisease(familyDisease.id, familyDisease).subscribe(
        () => this.translatedToastrService.success('message.success.familyDisease.update'),
        () => this.translatedToastrService.error('message.error.familyDisease.update')
      );
    }
  }

  private updateSelectedFamilyDisease() {
    if (this.selectedFamilyDisease) {
      this.familyDiseaseService.updateFamilyDisease(this.selectedFamilyDisease.id, this.selectedFamilyDisease).subscribe(
        () => this.translatedToastrService.success('message.success.familyDisease.update'),
        () => this.translatedToastrService.error('message.error.familyDisease.update')
      );
    }
  }
}
