import {Injectable} from '@angular/core';
import {BackendService} from '../../core/backend/backend.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {DicomViewResponse, EmergencyPassConfigResponse, EmergencyPassResponse} from '../../core/response/response.model';
import {LocaleResolverService} from '../../core/locale/locale-resolver.service';
import {EmergencyPassConfig} from './emergency-card-config.model';
import {DocumentHolder} from '../documents/document.model';
import {DocumentService} from '../documents/document.service';

@Injectable()
export class EmergencyPassService extends BackendService {

    constructor(
        private documentService: DocumentService,
        protected http: HttpClient,
        protected localeResolverService: LocaleResolverService
    ) {
        super(http, localeResolverService);
    }

    getMyEmergencyPass() {
        const httpHeaders = this.buildBaseHttpHeaders();
        return this.http.get<EmergencyPassResponse>(this.getApiUrl() + '/people/me/emergencyPass', {headers: httpHeaders});
    }

    getPatientEmergencyPass(medakteId: string) {
        const httpHeaders = this.buildBaseHttpHeaders();
        return this.http.get<EmergencyPassResponse>(this.getApiUrl() + '/people/' + medakteId + '/emergencyPass', {headers: httpHeaders});
    }

    getEmergencyPass(code: string) {
        const httpHeaders = this.buildBaseHttpHeaders();

        let params = new HttpParams();
        params = params.append('code', code.toString());

        return this.http.get<EmergencyPassResponse>(this.getApiUrl() + '/emergencyPasses', {
            headers: httpHeaders,
            params: params
        });
    }

    getMyEmergencyPassConfig() {
        const httpHeaders = this.buildBaseHttpHeaders();
        const params = new HttpParams();

        return this.http.get<EmergencyPassConfigResponse>(
          this.getApiUrl() + '/people/me/emergencyPassConfig', {
            headers: httpHeaders,
            params: params
        });
    }

    updateMyEmergencyPassConfig(emergencyCardConfig: EmergencyPassConfig) {
        const httpOptions = this.createHttpOptions();
        return this.http.put(this.getApiUrl() + '/people/me/emergencyPassConfig', emergencyCardConfig, httpOptions);
    }

    getEmergencyPassDocumentUrl(document: DocumentHolder, code: string) {
        const httpHeaders = this.buildBaseHttpHeaders();

        let params = new HttpParams();
        params = params.append('code', code.toString());

        return this.http.get(
          this.getApiUrl() + '/emergencyPassDocuments/' + document.id + '/content/' + document.name, {
              headers: httpHeaders,
              params: params,
              responseType: 'blob'
          });
    }

    getDicomDocumentViewSessionId(body: any, code: string) {
        const httpOptions = this.createHttpOptions();

        let params = new HttpParams();
        params = params.append('code', code);
        httpOptions.params = params;

        return this.http.post<DicomViewResponse>(this.getApiUrl() + '/emergencyPass/dicomViews', body, httpOptions);
    }

    releaseDicomDocumentView(sessionId: string, code: string) {
        const httpOptions = this.createHttpOptions();

        let params = new HttpParams();
        params = params.append('code', code);
        httpOptions.params = params;

        return this.http.delete(this.getApiUrl() + '/emergencyPass/dicomViews/' + sessionId, httpOptions);
    }

    getEmergencyCardProfilePhotoUrl(photoKey: string, medakteId: string, code: string) {
        if (!photoKey) {
            return '';
        }

        return this.getApiUrl() + '/emergencyPass/profilePhoto/' + photoKey + '.jpg?code=' + code;
    }

    getTinyUrl(url: string) {
        const httpOptions = this.createHttpOptions();

        return this.http.get('https://tinyurl.com/api-create.php?url=' + url, httpOptions);
    }

    renewCode() {
        const httpOptions = this.createHttpOptions();
        return this.http.post(this.getApiUrl() + '/people/me/emergencyPass/codeRenewal', {}, httpOptions);
    }

    updateValidity(validTo) {
        const httpOptions = this.createHttpOptions();
        return this.http.put(this.getApiUrl() + '/people/me/emergencyPass/timeValidity', {validTo: validTo}, httpOptions);
    }
}
