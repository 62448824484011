import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ProfileService} from './+modules/core/profile/profile.service';
import * as moment from 'moment-timezone';
import {RefreshTokenService} from './+modules/shared/auth/refresh-token.service';
import {TranslateService} from '@ngx-translate/core';
import {LocaleResolverService} from './+modules/core/locale/locale-resolver.service';
import {NgcCookieConsentService} from 'ngx-cookieconsent';
import {Subscription} from 'rxjs';
import {BrowserInfoRx, DeviceInfoRx, DeviceStandardInfoRx, ResponsiveSizeInfoRx} from 'ngx-responsive';
import {ResponsiveSizes} from './+modules/core/responsive/responsive-sizes';
import {ResponsiveService} from './+modules/core/responsive/responsive.service';
import {AuthenticationService} from './+modules/core/authentication/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
  isUserLoggedIn: boolean;
  title = 'Medakte App';
  private subscriptionList: Subscription[] = [];

  constructor(
    private translateService: TranslateService,
    private authService: AuthenticationService,
    private browserInfoRx: BrowserInfoRx,
    private ccService: NgcCookieConsentService,
    private deviceInfoRx: DeviceInfoRx,
    private deviceStandardInfoRx: DeviceStandardInfoRx,
    private localeResolverService: LocaleResolverService,
    private profileService: ProfileService,
    private ref: ChangeDetectorRef,
    private refreshTokenService: RefreshTokenService,
    private responsiveService: ResponsiveService,
    private responsiveSizeInfoRx: ResponsiveSizeInfoRx,
  ) {
    const currentLocale = this.localeResolverService.obtainLocale();
    if (translateService.currentLang !== currentLocale) {
      translateService.use(currentLocale);
    }
    // const lang = currentLocale.split('-')[0];
    moment.locale(currentLocale);
  }

  ngOnInit() {
    this.refreshTokenService.start(); // TODO FIXME

    this.authService.isLoggedIn
      .subscribe(isUserLoggedIn => {
        this.isUserLoggedIn = isUserLoggedIn;
        if (this.isUserLoggedIn) {
          this.profileService.getMyProfile().subscribe();
        }
      });

    if (!this.authService.isNotProtectedLocation()) {
      this.authService.isAuthenticated().subscribe(
        () => this.onIsAuthenticatedSuccess(),
        () => this.onIsAuthenticatedError()
      );
    }

    // Ng cookie consent stuff
    this.subscriptionList.push(
      this.ccService.initialize$.subscribe(() => {
      }),
      this.ccService.noCookieLaw$.subscribe(() => {
      }),
      this.ccService.popupClose$.subscribe(() => {
      }),
      this.ccService.popupOpen$.subscribe(() => {
      }),
      this.ccService.revokeChoice$.subscribe(() => {
      }),
      this.ccService.statusChange$.subscribe(() => {
      })
    );
    // responsive stuff
    this.subscriptionList.push(
      this.browserInfoRx.getBrowser.subscribe((data: string) => this.responsiveService.setBrowser(data)),
      this.deviceInfoRx.getDevice.subscribe((data: string) => this.responsiveService.setDevice(data)),
      this.deviceStandardInfoRx.getStandardDevice.subscribe((data: string) => this.responsiveService.setDeviceType(data)),
      this.responsiveSizeInfoRx.getResponsiveSize.subscribe((data: ResponsiveSizes) => this.responsiveService.setResponsiveSize(data))
    );
    this.browserInfoRx.connect();
    this.deviceInfoRx.connect();
    this.deviceStandardInfoRx.connect();
    this.responsiveSizeInfoRx.connect();

    this.translateService
      .get(['cookie.header', 'cookie.message', 'cookie.dismiss', 'cookie.allow', 'cookie.deny', 'cookie.link', 'cookie.policy'])
      .subscribe(data => {

        this.ccService.getConfig().content = this.ccService.getConfig().content || {};
        this.ccService.getConfig().content.header = data['cookie.header'];
        this.ccService.getConfig().content.message = data['cookie.message'];
        this.ccService.getConfig().content.dismiss = data['cookie.dismiss'];
        this.ccService.getConfig().content.allow = data['cookie.allow'];
        this.ccService.getConfig().content.deny = data['cookie.deny'];
        this.ccService.getConfig().content.link = data['cookie.link'];
        this.ccService.getConfig().content.policy = data['cookie.policy'];
        this.ccService.destroy();
        this.ccService.init(this.ccService.getConfig());

        this.ref.markForCheck();
      });
  }

  ngOnDestroy() {
    this.subscriptionList.forEach((subscription: Subscription) => subscription.unsubscribe());
    // responsive
    this.browserInfoRx.disconnect();
    this.deviceInfoRx.disconnect();
    this.deviceStandardInfoRx.disconnect();
    this.responsiveSizeInfoRx.disconnect();
  }

  private onIsAuthenticatedError() {
    this.isUserLoggedIn = false;
    this.authService.setLoggedOut();
  }

  private onIsAuthenticatedSuccess() {
    this.isUserLoggedIn = true;
    this.authService.setLoggedIn();
  }
}
