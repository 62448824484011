import {AfterViewInit, Component} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {OnboardingProfileModalComponent} from './onboarding-profile-modal.component';

@Component({
  selector: 'app-onboarding-profile',
  template: ''
})
export class OnboardingProfileComponent implements AfterViewInit {
  constructor(private modalService: NgbModal) {}

  ngAfterViewInit() {
    this.openModal();
  }

  openModal() {
    this.modalService.open(OnboardingProfileModalComponent, {
      backdrop: 'static',
      keyboard: false,
      size: 'lg'
    });
  }
}
