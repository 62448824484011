import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {LocaleResolverService} from '../../../core/locale/locale-resolver.service';
import {MedakteIconTypes} from '../../icon/medakte-icon-types';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-date-from-to-filter',
  templateUrl: './date-from-to-filter.component.html',
  styleUrls: ['./date-from-to-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateFromToFilterComponent implements OnDestroy, OnInit {
  @Input() end: NgbDateStruct;
  @Input() hasEnd = true;
  @Input() hasStart = true;
  @Input() start: NgbDateStruct;
  @Output() endChange = new EventEmitter<NgbDateStruct>();
  @Output() startChange = new EventEmitter<NgbDateStruct>();
  dateFormat: string;
  maxStart: NgbDateStruct;
  minStart: NgbDateStruct = {day: 1, month: 1, year: 1900};
  medakteIconTypes = MedakteIconTypes;
  maxEnd: NgbDateStruct;
  minEnd: NgbDateStruct = {day: 1, month: 1, year: 1900};
  isLoading = true;
  private subscriptions: Subscription[] = [];

  constructor(protected localeResolverService: LocaleResolverService,
              protected ref: ChangeDetectorRef) { }

  ngOnInit() {
    this.dateFormat = this.localeResolverService.getDateFormat();
    this.subscriptions.push(
      this.localeResolverService.localeSubject.subscribe(locale => this.afterLocaleChange(locale))
    );

    this.setMaxEnd();
    this.setMaxStart();

    this.isLoading = false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onClickResetEnd() {
    this.end = undefined;
    this.onDateChange('end');
  }

  onClickResetStart() {
    this.start = undefined;
    this.onDateChange('start');
  }

  onDateChange(dateName: string) {
    switch (dateName) {
      case 'end':
        if (this.hasStart) {
          this.setMaxStart();
        }
        this.endChange.emit(this.end);
        break;

      case 'start':
        if (this.hasEnd) {
          this.setMinEnd();
        }
        this.startChange.emit(this.start);
        break;
    }
  }

  private afterLocaleChange(locale: string) {
    if (locale) {
      this.dateFormat = this.localeResolverService.getDateFormat();
      this.ref.markForCheck();
    }
  }

  private setMaxEnd() {
    const currentDate = new Date();
    this.maxEnd = {day: currentDate.getDate(), month: currentDate.getMonth() + 1, year: currentDate.getFullYear()};
  }

  private setMaxStart() {
    if (!this.end) {
      const currentDate = new Date();
      this.maxStart = {day: currentDate.getDate(), month: currentDate.getMonth() + 1, year: currentDate.getFullYear()};
    } else {
      this.maxStart = {day: this.end.day, month: this.end.month, year: this.end.year};
    }
    this.ref.markForCheck();
  }

  private setMinEnd() {
    if (!this.start) {
      this.minEnd = {day: 1, month: 1, year: 1900};
    } else {
      this.minEnd = {day: this.start.day, month: this.start.month, year: this.start.year};
    }
    this.ref.markForCheck();
  }
}
