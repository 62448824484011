import {Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {InboxService} from './inbox.service';
import {Mail} from './mail.model';
import {EmailConversation} from './email-conversation.model';
import {EmailMessage} from './email-message.mode';
import {truncateHTML} from '../lib/utils';
import {Person, RecipientPerson} from '../+modules/core/person/person.model';
import {catchError, debounceTime, distinctUntilChanged, map, switchMap, tap} from 'rxjs/operators';
import {concat, Observable, of, Subject} from 'rxjs';
import {PersonService} from '../+modules/core/person/person.service';
import {MessageRecipientListResponse} from '../+modules/core/response/response.model';
import {ProfileService} from '../+modules/core/profile/profile.service';
import {Message} from './message.model';

@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss'],
  providers: [InboxService]
})
export class InboxComponent implements OnInit {
  public isMessageSelected = false;
  closeResult: string;
  mail: Mail[];

  mailConversations: EmailConversation[];
  mailMessages: EmailMessage[];
  mailFoldersMap: any = {};
  conversationsMap: any = {};
  message: Message;

  unread = 0;

  subject: string;
  body: string;

  truncateHTML = truncateHTML;

  modalReference: any;

  me: Person;

  isMed: boolean;
  isPat: boolean;

  backgroundColourClass: string;

  recipients$: Observable<RecipientPerson[]>;
  recipientsLoading = false;
  recipientsInput$ = new Subject<string>();
  selectedPersons: RecipientPerson[] = [];

  @ViewChild('content', { static: true }) compose: TemplateRef<any>;

  constructor(
    private elRef: ElementRef,
    private modalService: NgbModal,
    private inboxService: InboxService,
    private profileService: ProfileService,
    private peopleService: PersonService
  ) {
  }

  ngOnInit() {
    $.getScript('./assets/js/inbox.js');

    this.inboxService
      .getUnseenEmailMessages()
      .subscribe(v => this.unread = v.result.total,
          e => console.log(e));

    this.profileService.myProfileSubject
      .subscribe(p => {
        this.me = p;
        if (this.me) {
          if (this.me.type === 0) {
            this.isPat = true;
          }
          if (this.me.type === 1 || this.me.type === 2) {
            this.isMed = true;
          }

          this.backgroundColourClass = this.isMed ? 'bgc-med' : 'bgc-pat';
        }
      });
  }

  private loadConversations(type: string) {
    this.inboxService.loadEmailConversations(type).subscribe(r => {
      this.mailFoldersMap[type] = {};
      this.mailFoldersMap[type].conversations = r.result;

      this.mailConversations = this.mailFoldersMap[type].conversations;

      console.log('Mail conversations loaded successfully: ' + type);

      if (type === 'inbox') {

        this.unread = 0;

        this.mailConversations.forEach(c => {
          if (c.read === false) {
            this.unread++;
          }
        });
      }
    }, e => console.log(e));
  }

  private decrementUnread() {
    if (this.unread > 0) {
      this.unread--;
    }
  }

  loadMessages(conversationId: number) {
    this.inboxService.loadEmailMessages(conversationId).subscribe(r => {
      this.conversationsMap[conversationId] = {};
      this.conversationsMap[conversationId].messages = r.result.reverse();
      this.conversationsMap[conversationId].messages.forEach(e => e.collapsed = true);

      this.mailMessages = this.conversationsMap[conversationId].messages;

      console.log('Mail messages loaded successfully: ' + conversationId);

    }, e => console.log(e));

  }

  // inbox user list click event function
  DisplayMessage(event, conversationId: number) {
    this.inboxService.markConversationAsSeen(conversationId).subscribe(
      () => console.log('Success mark as seen'),
      () => console.log('Error mark as seen')
    );

    if (this.mailFoldersMap['inbox']) {
      this.mailFoldersMap['inbox'].conversations.forEach(e => {
        if (e.id === conversationId) {
          if (!e.read) {
            e.read = true;
            this.decrementUnread();
          }
        }
      });
    }

    if (!this.conversationsMap[conversationId]) {
      this.loadMessages(conversationId);
    } else {
      this.mailMessages = this.conversationsMap[conversationId].messages;
    }

    this.isMessageSelected = true;

    const hElement: HTMLElement = this.elRef.nativeElement;
    const allAnchors = hElement.querySelectorAll('.users-list-padding > a.list-group-item');
    [].forEach.call(allAnchors, function (item: HTMLElement) {
      item.setAttribute('class', 'list-group-item list-group-item-action no-border');
    });

    let activeClass = 'list-group-item list-group-item-action bg-blue-grey bg-lighten-5 border-right-2';
    activeClass = this.isMed ? activeClass + ' border-right-med' : activeClass + ' border-right-pat';
    event.currentTarget.setAttribute('class', activeClass);
  }

  // compose popup start
  open(content) {
    this.loadRecipients();

    this.modalReference = this.modalService.open(content, {size: 'lg'});
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  GetEmailsByType(event, type: string) {
    if (!this.mailFoldersMap[type]) {
      this.loadConversations(type);
    } else {
      this.mailConversations = this.mailFoldersMap[type].conversations;
    }
    this.SetItemActive(event);
  }

  SetItemActive(event) {
    const hElement: HTMLElement = this.elRef.nativeElement;
    const allAnchors = hElement.querySelectorAll('.list-group-messages > a.list-group-item');
    [].forEach.call(allAnchors, function (item: HTMLElement) {
      item.setAttribute('class', 'list-group-item list-group-item-action no-border');
    });

    let activeClass = 'list-group-item no-border ';
    activeClass = this.isMed ? activeClass + 'active bgc-med-active' : activeClass + 'active bgc-pat-active';

    event.currentTarget.setAttribute('class', activeClass);
  }

  createEmail(subject) {
    console.log('Creating email... ');
    console.log('Subject: ' + this.subject);
    console.log('Body: ' + this.body);

    const email = new EmailMessage();
    email.subject = this.subject;
    email.body = this.body;
    email.bodyType = 1;
    email.recipientsIds = this.selectedPersons.map( p => p.id);

    this.inboxService.createEmailMessage(email).subscribe(() => {
        console.log('Success');
        if (this.modalReference) {
          this.modalReference.close();
        }
        this.subject = '';
        this.body = '';
        this.selectedPersons = [];
      },
      e => {
        console.log('Error');
        console.log(e);
      });
  }

  private loadRecipients() {
    this.recipients$ = concat(
      of([]), // default items
      this.recipientsInput$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => this.recipientsLoading = true),
        switchMap(term => this.peopleService.listMessageRecipients(term).pipe(
          catchError(() => of([])),
          map( (res: MessageRecipientListResponse) => {
            console.log(res);
            console.log(res.result);
            return res.result;
          }),
          tap(() => this.recipientsLoading = false)
        ))
      )
    );
  }

  reply(event, message) {
    event.stopPropagation();
    console.log('Reply message ... ' + message.id);
    console.log(message);

    this.subject = 'Re: ' + message.subject;
    this.body = '';
    this.selectedPersons = message.recipients.map(r => {
      return {id: r.recipientId, name: (r.detail.firstName + ' ' + r.detail.lastName)};
    });

    this.open(this.compose);
  }
}
