import {Injectable} from '@angular/core';
import {BackendService} from '../backend/backend.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ActivityLogListResponse} from '../response/response.model';
import {ToastrService} from 'ngx-toastr';
import {LocaleResolverService} from '../locale/locale-resolver.service';
import {QueryFilter} from '../filters/query-filter.model';

@Injectable()
export class ActivityLogService extends BackendService {

    constructor(
        protected http: HttpClient,
        protected localeResolverService: LocaleResolverService,
    ) {
        super(http, localeResolverService);
    }

    listMyActivityLogs(page: number, pageSize: number, queryFilters: QueryFilter[] = []) {
        const httpHeaders = this.buildBaseHttpHeaders();

        let params = new HttpParams();
        if (page !== undefined && page !== null) {
            params = params.append('page', page.toString());
        }
        if (pageSize !== undefined && pageSize !== null) {
            params = params.append('pageSize', pageSize.toString());
        }
        for (const queryFilter of queryFilters) {
            params = params.append(queryFilter.name, String(queryFilter.value));
        }

        return this.http.get<ActivityLogListResponse>(this.getApiUrl() + '/people/me/activityLogs', (params.keys().length > 0) ? {
            headers: httpHeaders,
            params: params
        } : {headers: httpHeaders});
    }
}
