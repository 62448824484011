import {Component, OnInit} from '@angular/core';
import {NavigationService} from '../../core/navigation/navigation.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../core/authentication/authentication.service';
import {TranslatedToastrService} from '../../core/translated-toastr/translated-toastr.service';
import {ProfileService} from '../../core/profile/profile.service';
import {PersonTypeEnum} from '../../shared/enums/person-type.enum';

@Component({
  selector: 'app-patient-login',
  templateUrl: './patient-login.component.html',
  styleUrls: ['./patient-login.component.scss']
})
export class PatientLoginComponent implements OnInit {
  isPasswordVisible = false;
  isSubmitted = false;
  isTokenRequired = false;
  isTokenVisible = false;
  userFormGroup: FormGroup;

  constructor(
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private navigationService: NavigationService,
    private profileService: ProfileService,
    private translatedToastrService: TranslatedToastrService,
  ) { }

  ngOnInit() {
    // auto-login
    this.authenticationService.isAuthenticated().subscribe(
      () => this.onIsAuthenticatedSuccess()
    );

    this.initFormGroup();
  }

  onClickForgotPassword(event: any) {
    event.preventDefault();
    const email = this.userFormGroup.get('email').value;
    this.navigationService.navigateToLoginForgotPassword(email);
  }

  onClickLogin() {
    this.isSubmitted = true;
    if (this.userFormGroup.valid) {
      const email = this.userFormGroup.get('email').value;
      const keepMeLoggedIn = this.userFormGroup.get('keepMeLoggedIn').value;
      const password = this.userFormGroup.get('password').value;
      const token = this.userFormGroup.get('token').value;
      this.authenticationService.signIn(email, password, token, keepMeLoggedIn).subscribe(
        () => this.onSignInSuccess(),
        err => this.onSignInError(err)
      );
    }
  }

  onClickRegistration(event: any) {
    event.preventDefault();
    this.navigationService.navigateToRegistrationStart();
  }

  private initFormGroup() {
    this.userFormGroup = this.formBuilder.group({
      email: ['', Validators.required],
      keepMeLoggedIn: [false],
      password: ['', Validators.required],
      token: ['']
    });
  }

  private onIsAuthenticatedSuccess() {
    this.authenticationService.setLoggedIn();
    this.authenticationService.clearCredentials();
    this.profileService.getMyProfile().subscribe(() => {
      const person = this.profileService.myProfile;
      if (person) {
        if (person.type === PersonTypeEnum.PATIENT) {
          this.navigationService.navigateToPatientDashboard();
        } else {
          this.authenticationService.setMedic();
          this.navigationService.navigateToMedicDashboard();
        }
      } else {
        this.navigationService.navigateToPatientOnboardingStart();
      }
    });
  }

  private onSignInError(err: any) {
    this.authenticationService.setLoggedOut();

    const errCode = err.error.error.code;
    this.translatedToastrService.error('authentication.errors.' + errCode);

    switch (errCode) {
      case 'UserAccountNotConfirmed':
        this.navigationService.navigateToRegistrationConfirmationSent(this.userFormGroup.get('email').value);
        break;

      case 'TotpTokenMissingError':
        this.userFormGroup.get('token').setValidators(Validators.required);
        this.isTokenRequired = true;
        break;
    }
  }

  private onSignInSuccess() {
    this.authenticationService.setLoggedIn();
    this.authenticationService.clearCredentials();
    this.profileService.getMyProfile().subscribe(() => {
      const person = this.profileService.myProfile;
      if (person) {
        if (person.type === PersonTypeEnum.PATIENT) {
          this.navigationService.navigateToPatientDashboard();
        } else {
          this.authenticationService.setMedic();
          this.navigationService.navigateToMedicDashboard();
        }
      } else {
        this.navigationService.navigateToPatientOnboardingStart();
      }
    });
  }
}
