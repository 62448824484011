import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../../../../environments/environment';
import {DocumentService} from '../document.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'app-dicom-viewer',
    templateUrl: './dicom-viewer.component.html',
    styleUrls: ['./dicom-viewer.component.scss']
})
export class DicomViewerComponent implements OnInit, OnDestroy {

    dicomUrl: any;

    private sub: any;
    private sessionId;

    constructor(
        private route: ActivatedRoute,
        private documentService: DocumentService,
        private sanitizer: DomSanitizer
    ) {
    }

    ngOnInit() {
        this.sub = this.route.queryParams.subscribe(params => {
            this.sessionId = params['sessionId'];
            this.dicomUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.dicom.url + this.sessionId);
        });
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }

        if (this.sessionId) {
            this.documentService
                .releaseDicomDocumentView(this.sessionId)
                .subscribe();
        }
    }
}
