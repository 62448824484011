import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {MedicView} from '../../../core/medic/medic-view.model';
import {DocumentService} from '../../documents/document.service';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-emergency-card-doctors',
  templateUrl: './emergency-card-doctors.component.html',
  styleUrls: ['./emergency-card-doctors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmergencyCardDoctorsComponent implements OnChanges, OnDestroy, OnInit {
  @Input() medics: MedicView[] = [];
  @Input() personIdParam: string;
  private subscriptions: Subscription[] = [];

  constructor(private documentService: DocumentService,
              private translateService: TranslateService,
              protected ref: ChangeDetectorRef) {
  }

  ngOnInit() {
    if (this.medics.length) {
      this.medics.forEach(medic => this.localizeMedic(medic));
      this.ref.markForCheck();
    }

    this.subscriptions.push(
      this.translateService.onLangChange.subscribe(() => this.afterLangChange())
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges): void {
    const change = changes.medics || undefined;
    if (change) {
      this.medics.forEach(medic => this.localizeMedic(medic));
      this.ref.markForCheck();
    }
  }

  private afterLangChange() {
    this.medics.forEach(medic => {
      medic.specialtiesText = medic.specialties ? medic.specialties.split(',').map(
        e => this.translateService.instant('codes.specialty.' + e)
      ).join(', ') : '';
    });
    this.ref.markForCheck();
  }

  private localizeMedic(medic: MedicView) {
    medic.photo = this.documentService.resolveStaticFileUrl(medic.photo);
    medic.medicphoto = this.documentService.resolveStaticFileUrl(medic.medicphoto);
    medic.specialtiesText = medic.specialties ? medic.specialties.split(',').map(
      e => this.translateService.instant('codes.specialty.' + e)
    ).join(', ') : '';
  }
}
