export enum FeelingEnum {
    EXCELLENT = 5,
    VERY_GOOD = 4,
    GOOD = 3,
    SLIGHTLY_GOOD = 2,
    ABOVE_AVERAGE = 1,
    AVERAGE = 0,
    BELOW_AVERAGE = -1,
    SLIGHTLY_BAD = -2,
    BAD = -3,
    VERY_BAD = - 4,
    TERRIBLE = -5
}
