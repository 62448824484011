import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {SubsPlan} from './subs-plan.model';
import {SubscriptionService} from '../subscription/subscription.service';
import {EMPTY, Observable, of} from 'rxjs';
import {mergeMap, take} from 'rxjs/operators';
import {Injectable} from '@angular/core';

@Injectable()
export class SubsPlanResolverService implements Resolve<SubsPlan> {
  constructor(
    private router: Router,
    protected subscriptionService: SubscriptionService
  ) {}

  resolve(
    activatedRouteSnapshot: ActivatedRouteSnapshot,
    routerStateSnapshot: RouterStateSnapshot
  ): Observable<SubsPlan> | Promise<SubsPlan> | SubsPlan {
    const subsPlanCode = activatedRouteSnapshot.paramMap.get('subsPlanCode');

    return this.subscriptionService.getSubscriptionPlan(subsPlanCode).pipe(
      take(1),
      mergeMap(data => {
        if (data) {
          return of(data.result);
        } else {
          const previousUrl = routerStateSnapshot.url
            .split('/')
            .slice(0, -1)
            .join('/');
          this.router.navigate([previousUrl]);
          return EMPTY;
        }
      })
    );
  }
}
