import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {Injectable} from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class NgbDateDynamicParserFormatter extends NgbDateParserFormatter {

    constructor() {
        super();
    }

    parse(value: string): NgbDateStruct {
        if (value) {
            value = value.trim();
            const mdt = moment(value, 'L');
            // console.log(mdt);
            // console.log(mdt.date());
            // console.log(mdt.month());
            // console.log(mdt.year());
            return {day: mdt.date(), month: mdt.month() + 1, year: mdt.year()};
        }
        return null;
    }

    format(date: NgbDateStruct): string {
        if (!date) {
            return '';
        }
        const mdt = moment([date.year, date.month - 1, date.day]);
        if (!mdt.isValid()) {
            return '';
        }
        return mdt.format('L');
    }
}
