import * as moment from 'moment-timezone';
import {HttpResponse} from '@angular/common/http';

export function objKeys(object: {}) {
    return Object.keys(object);
}

export function objValues(object: {}) {
    return Object.values(object);
}

export function enumKeys(objEnum: {}): any[] {
    const enumKeysArray = [];
    for (const item in objEnum) {
        if (isNaN(Number(item))) {
            enumKeysArray.push(item);
        }
    }
    return enumKeysArray;
}

export function enumKeysWithNoAnswer(objEnum: {}) {
    const array = enumKeys(objEnum);
    array.push('NO_ANSWER');
    return array;
}

export function enumNumericValues(objEnum: {}): any[] {
    const numericValues = [];
    for (const item in objEnum) {
        if (!isNaN(Number(item))) {
            numericValues.push(item);
        }
    }
    return numericValues;
}

export function truncateHTML(text: string): string {

    const charlimit = 30;
    if (!text || text.length <= charlimit) {
        return text;
    }

    const without_html = text.replace(/<(?:.|\n)*?>/gm, '');
    const shortened = without_html.substring(0, charlimit) + '...';
    return shortened;
}

export function calcAge(born: Date): string {
    if (!born) { return ''; }
    return moment().diff(moment(born), 'years').toString();
}

export function isMe(requestedPersonParamId: string, requesterPersonId: number): boolean {
    try {
        if (!requestedPersonParamId || !requesterPersonId) { return false; }
        if (requestedPersonParamId === 'me') { return true; }
        return (parseInt(requestedPersonParamId, 10) === requesterPersonId);
    } catch (e) {
        return false;
    }
}

export function canEdit(requestedPersonParamId: string, requesterPersonId: number, itemCreatorId: number): boolean {
    return isMe(requestedPersonParamId, requesterPersonId) || requesterPersonId === itemCreatorId;
}

export function calcBmi(height, weight) {
    if (!height || !weight) { return null; }
    return (weight / Math.pow(height / 100, 2)).toFixed(1);
}

export function extractResourceIdFromLocationHeader(resp: HttpResponse<any>): string {
    const location = resp.headers ? resp.headers.get('Location') : null;
    if (location) {
        return location.split('/').slice(-1)[0];
    }
    return null;
}
