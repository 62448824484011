import {NgModule} from '@angular/core';
import {PatientRoutingModule} from './patient-routing.module';
import {PatientOnboardingHealthComponent} from './patient-onboarding/patient-onboarding-health/patient-onboarding-health.component';
import {PatientOnboardingSubscriptionComponent} from './patient-onboarding/patient-onboarding-subscription/patient-onboarding-subscription.component';
import {PatientOnboardingStartComponent} from './patient-onboarding/patient-onboarding-start/patient-onboarding-start.component';
import {PatientOnboardingMfaComponent} from './patient-onboarding/patient-onboarding-mfa/patient-onboarding-mfa.component';
import {SharedModule} from '../shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {ProfileComponent} from './profile/profile.component';
import {PatientOnboardingFinishedComponent} from './patient-onboarding/patient-onboarding-finished/patient-onboarding-finished.component';
import {PatientOnboardingPaymentComponent} from './patient-onboarding/patient-onboarding-payment/patient-onboarding-payment.component';
import {PatientOnboardingPaymentSuccessComponent} from './patient-onboarding/patient-onboarding-payment-success/patient-onboarding-payment-success.component';
import {PatientSettingsProfileComponent} from './patient-settings/patient-settings-profile/patient-settings-profile.component';
import {PaymentSettingsPersonComponent} from './patient-settings/patient-settings-person/payment-settings-person.component';
import {PatientSettingsMenuComponent} from './patient-settings/patient-setting-menu/patient-settings-menu.component';
import {PersonComponent} from './person/person.component';
import {PatientSettingsSubscriptionComponent} from './patient-settings/patient-settings-subscription/patient-settings-subscription.component';
import {PaymentSettingsPaymentsComponent} from './patient-settings/patient-settings-payments/payment-settings-payments.component';
import {PatientSettingsPaymentComponent} from './patient-settings/patient-settings-payment/patient-settings-payment.component';
import {PatientRegistrationStartComponent} from './patient-registration/patient-registration-start/patient-registration-start.component';
import {PatientRegistrationSuccessComponent} from './patient-registration/patient-registration-success/patient-registration-success.component';
import {PatientRegistrationConfirmationSentComponent} from './patient-registration/patient-registration-confirmation-sent/patient-registration-confirmation-sent.component';
import {PatientRegistrationCardComponent} from './patient-registration/patient-registration-card/patient-registration-card.component';
import {PatientOnboardingCardComponent} from './patient-onboarding/patient-onboarding-card/patient-onboarding-card.component';
import {PatientRegistrationConfirmationComponent} from './patient-registration/patient-registration-confirmation/patient-registration-confirmation.component';
import {PatientLoginComponent} from './patient-login/patient-login.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {ChangePasswordComponent} from './change-password/change-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import {PatientDashboardComponent} from './patient-dashboard/patient-dashboard.component';
import {PatientDashboardAllergiesComponent} from './patient-dashboard/patient-dashboard-allergies/patient-dashboard-allergies.component';
import {PatientDashboardDiseasesComponent} from './patient-dashboard/patient-dashboard-diseases/patient-dashboard-diseases.component';
import {PatientDashboardDoctorsComponent} from './patient-dashboard/patient-dashboard-doctors/patient-dashboard-doctors.component';
import {PatientDashboardDocumentsComponent} from './patient-dashboard/patient-dashboard-documents/patient-dashboard-documents.component';
import {PatientDashboardFamilyDiseasesComponent} from './patient-dashboard/patient-dashboard-family-diseases/patient-dashboard-family-diseases.component';
import {PatientDashboardImmunisationsComponent} from './patient-dashboard/patient-dashboard-immunisations/patient-dashboard-immunisations.component';
import {PatientDashboardMedicationsComponent} from './patient-dashboard/patient-dashboard-medications/patient-dashboard-medications.component';
import {PatientDashboardOperationsComponent} from './patient-dashboard/patient-dashboard-operations/patient-dashboard-operations.component';
import {BloodGlucoseChartComponent} from './measurements/blood-glucose/blood-glucose-chart/blood-glucose-chart.component';
import {BloodGlucoseComponent} from './measurements/blood-glucose/blood-glucose.component';
import {BloodPressureChartComponent} from './measurements/blood-pressure/blood-pressure-chart/blood-pressure-chart.component';
import {BloodPressureComponent} from './measurements/blood-pressure/blood-pressure.component';
import {BodyTemperatureChartComponent} from './measurements/body-temperature/body-temperature-chart/body-temperature-chart.component';
import {BodyTemperatureComponent} from './measurements/body-temperature/body-temperature.component';
import {AllergiesComponent} from './health/allergies/allergies.component';
import {AllergyEditComponent} from './health/allergies/allergy-edit/allergy-edit.component';
import {DiseaseEditComponent} from './health/diseases/disease-edit/disease-edit.component';
import {DiseasesComponent} from './health/diseases/diseases.component';
import {DocumentEditComponent} from './documents/document-edit/document-edit.component';
import {DocumentUploadComponent} from './documents/document-upload/document-upload.component';
import {DocumentUploaderComponent} from './documents/document-uploader/document-uploader.component';
import {DocumentViewerComponent} from './documents/document-viewer/document-viewer.component';
import {DocumentsComponent} from './documents/documents.component';
import {DicomViewerComponent} from './documents/dicom-viewer/dicom-viewer.component';
import {EntityLinksComponent} from './health/entity-links/entity-links.component';
import {FamilyDiseaseEditComponent} from './health/family-diseases/family-disease-edit/family-disease-edit.component';
import {FamilyDiseasesComponent} from './health/family-diseases/family-diseases.component';
import {FeelingChartComponent} from './measurements/feeling/feeling-chart/feeling-chart.component';
import {FeelingComponent} from './measurements/feeling/feeling.component';
import {HeartRateComponent} from './measurements/heart-rate/heart-rate.component';
import {HearthRateChartComponent} from './measurements/heart-rate/hearth-rate-chart/hearth-rate-chart.component';
import {ImmunisationEditComponent} from './health/immunisations/immunisation-edit/immunisation-edit.component';
import {ImmunisationsComponent} from './health/immunisations/immunisations.component';
import {MedicationEditComponent} from './health/medications/medication-edit/medication-edit.component';
import {MedicationsComponent} from './health/medications/medications.component';
import {OperationEditComponent} from './health/operations/operation-edit/operation-edit.component';
import {OperationsComponent} from './health/operations/operations.component';
import {PainChartComponent} from './measurements/pain/pain-chart/pain-chart.component';
import {PainComponent} from './measurements/pain/pain.component';
import {PersonalDocumentsComponent} from './documents/personal-documents/personal-documents.component';
import {SleepChartComponent} from './measurements/sleep/sleep-chart/sleep-chart.component';
import {SleepComponent} from './measurements/sleep/sleep.component';
import {Spo2ChartComponent} from './measurements/spo2/spo2-chart/spo2-chart.component';
import {Spo2Component} from './measurements/spo2/spo2.component';
import {StressChartComponent} from './measurements/stress/stress-chart/stress-chart.component';
import {StressComponent} from './measurements/stress/stress.component';
import {WeightChartComponent} from './measurements/weight/weight-chart/weight-chart.component';
import {WeightComponent} from './measurements/weight/weight.component';
import {EmergencyCardAllergiesComponent} from './emergency-card/emergency-card-allergies/emergency-card-allergies.component';
import {EmergencyCardComponent} from './emergency-card/emergency-card.component';
import {EmergencyCardDiseasesComponent} from './emergency-card/emergency-card-diseases/emergency-card-diseases.component';
import {EmergencyCardDoctorsComponent} from './emergency-card/emergency-card-doctors/emergency-card-doctors.component';
import {EmergencyCardDocumentViewerComponent} from './emergency-card/emergency-card-document-viewer/emergency-card-document-viewer.component';
import {EmergencyCardDocumentsComponent} from './emergency-card/emergency-card-documents/emergency-card-documents.component';
import {EmergencyCardEditComponent} from './emergency-card/emergency-card-edit/emergency-card-edit.component';
import {EmergencyCardFamilyDiseasesComponent} from './emergency-card/emergency-card-family-diseases/emergency-card-family-diseases.component';
import {EmergencyCardImmunisationsComponent} from './emergency-card/emergency-card-immunisations/emergency-card-immunisations.component';
import {EmergencyCardMedicationsComponent} from './emergency-card/emergency-card-medications/emergency-card-medications.component';
import {EmergencyCardOperationsComponent} from './emergency-card/emergency-card-operations/emergency-card-operations.component';
import {EmergencyCardShareComponent} from './emergency-card/emergency-card-share/emergency-card-share.component';
import {ProfileBloodGlucoseComponent} from './profile/profile-blood-glucose/profile-blood-glucose.component';
import {ProfileBloodPressureComponent} from './profile/profile-blood-pressure/profile-blood-pressure.component';
import {ProfileBmiComponent} from './profile/profile-bmi/profile-bmi.component';
import {ProfileBodyTemperatureComponent} from './profile/profile-body-temperature/profile-body-temperature.component';
import {ProfileFeelingComponent} from './profile/profile-feeling/profile-feeling.component';
import {ProfileHeartRateComponent} from './profile/profile-heart-rate/profile-heart-rate.component';
import {ProfileHeightComponent} from './profile/profile-height/profile-height.component';
import {ProfilePainComponent} from './profile/profile-pain/profile-pain.component';
import {ProfileSleepComponent} from './profile/profile-sleep/profile-sleep.component';
import {ProfileSpo2Component} from './profile/profile-spo2/profile-spo2.component';
import {ProfileStressComponent} from './profile/profile-stress/profile-stress.component';
import {ProfileWeightComponent} from './profile/profile-weight/profile-weight.component';
import {PatientSettingsMfaComponent} from './patient-settings/patient-settings-mfa/patient-settings-mfa.component';
import {PatientSettingsPasswordComponent} from './patient-settings/patient-settings-password/patient-settings-password.component';
import {PatientSettingsPermissionsComponent} from './patient-settings/patient-settings-permissions/patient-settings-permissions.component';
import {PatientMedicsComponent} from './patient-medics/patient-medics.component';
import {PatientSettingsAccountDeletionComponent} from './patient-settings/patient-settings-account-deletion/patient-settings-account-deletion.component';
import {PatientSettingsActivityLogsComponent} from './patient-settings/patient-settings-activity-log/patient-settings-activity-logs.component';
import {TimelineComponent} from './timeline/timeline.component';
import { PatientRegistrationConfirmationInvalidComponent } from './patient-registration/patient-registration-confirmation-invalid/patient-registration-confirmation-invalid.component';

@NgModule({
  imports: [
    PatientRoutingModule,
    SharedModule,
    TranslateModule.forChild(),
  ],
  declarations: [
    AllergiesComponent,
    AllergyEditComponent,
    BloodGlucoseChartComponent,
    BloodGlucoseComponent,
    BloodPressureChartComponent,
    BloodPressureComponent,
    BodyTemperatureChartComponent,
    BodyTemperatureComponent,
    ChangePasswordComponent,
    DicomViewerComponent,
    DiseaseEditComponent,
    DiseasesComponent,
    DocumentEditComponent,
    DocumentUploadComponent,
    DocumentUploaderComponent,
    DocumentViewerComponent,
    DocumentsComponent,
    EmergencyCardAllergiesComponent,
    EmergencyCardComponent,
    EmergencyCardDiseasesComponent,
    EmergencyCardDoctorsComponent,
    EmergencyCardDocumentViewerComponent,
    EmergencyCardDocumentsComponent,
    EmergencyCardEditComponent,
    EmergencyCardFamilyDiseasesComponent,
    EmergencyCardImmunisationsComponent,
    EmergencyCardMedicationsComponent,
    EmergencyCardOperationsComponent,
    EmergencyCardShareComponent,
    EntityLinksComponent,
    FamilyDiseaseEditComponent,
    FamilyDiseasesComponent,
    FeelingChartComponent,
    FeelingComponent,
    ForgotPasswordComponent,
    HeartRateComponent,
    HearthRateChartComponent,
    ImmunisationEditComponent,
    ImmunisationsComponent,
    PatientLoginComponent,
    MedicationEditComponent,
    MedicationsComponent,
    OperationEditComponent,
    OperationsComponent,
    PainChartComponent,
    PainComponent,
    PatientDashboardComponent,
    PatientDashboardAllergiesComponent,
    PatientDashboardDiseasesComponent,
    PatientDashboardDoctorsComponent,
    PatientDashboardDocumentsComponent,
    PatientDashboardFamilyDiseasesComponent,
    PatientDashboardImmunisationsComponent,
    PatientDashboardMedicationsComponent,
    PatientDashboardOperationsComponent,
    PatientMedicsComponent,
    PatientOnboardingCardComponent,
    PatientOnboardingFinishedComponent,
    PatientOnboardingHealthComponent,
    PatientOnboardingMfaComponent,
    PatientOnboardingPaymentComponent,
    PatientOnboardingPaymentSuccessComponent,
    PatientOnboardingStartComponent,
    PatientOnboardingSubscriptionComponent,
    PatientRegistrationCardComponent,
    PatientRegistrationConfirmationComponent,
    PatientRegistrationConfirmationSentComponent,
    PatientRegistrationStartComponent,
    PatientRegistrationSuccessComponent,
    PatientSettingsAccountDeletionComponent,
    PatientSettingsActivityLogsComponent,
    PatientSettingsMenuComponent,
    PatientSettingsMfaComponent,
    PatientSettingsPasswordComponent,
    PatientSettingsPaymentComponent,
    PaymentSettingsPaymentsComponent,
    PatientSettingsPermissionsComponent,
    PaymentSettingsPersonComponent,
    PatientSettingsProfileComponent,
    PatientSettingsSubscriptionComponent,
    PersonalDocumentsComponent,
    PersonComponent,
    ProfileBloodGlucoseComponent,
    ProfileBloodPressureComponent,
    ProfileBmiComponent,
    ProfileBodyTemperatureComponent,
    ProfileComponent,
    ProfileFeelingComponent,
    ProfileHeartRateComponent,
    ProfileHeightComponent,
    ProfilePainComponent,
    ProfileSleepComponent,
    ProfileSpo2Component,
    ProfileStressComponent,
    ProfileWeightComponent,
    ResetPasswordComponent,
    SleepChartComponent,
    SleepComponent,
    Spo2ChartComponent,
    Spo2Component,
    StressChartComponent,
    StressComponent,
    TimelineComponent,
    WeightChartComponent,
    WeightComponent,
    PatientRegistrationConfirmationInvalidComponent,
  ],
  entryComponents: [
    AllergyEditComponent,
    DiseaseEditComponent,
    DocumentEditComponent,
    EmergencyCardDocumentViewerComponent,
    EmergencyCardEditComponent,
    EmergencyCardShareComponent,
    FamilyDiseaseEditComponent,
    ImmunisationEditComponent,
    MedicationEditComponent,
    OperationEditComponent,
    PatientSettingsPaymentComponent,
  ],
  exports: [
    AllergiesComponent,
    BloodGlucoseComponent,
    BloodPressureComponent,
    BodyTemperatureComponent,
    DiseaseEditComponent,
    DiseasesComponent,
    DicomViewerComponent,
    DocumentUploadComponent,
    DocumentUploaderComponent,
    DocumentViewerComponent,
    DocumentsComponent,
    EmergencyCardComponent,
    FamilyDiseasesComponent,
    FeelingComponent,
    HeartRateComponent,
    ImmunisationsComponent,
    MedicationsComponent,
    OperationsComponent,
    PainComponent,
    PatientDashboardComponent,
    PatientMedicsComponent,
    PatientSettingsMenuComponent,
    SleepChartComponent,
    SleepComponent,
    Spo2Component,
    StressComponent,
    WeightComponent,
  ],
  providers: []
})
// The PATIENT module should include all Components for the patient users.
export class PatientModule {
}
