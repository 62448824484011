import {Component, OnInit} from '@angular/core';
import {EmergencyPassService} from '../../emergency-card/emergency-pass.service';
import {EmergencyPassConfig} from '../../emergency-card/emergency-card-config.model';
import {TranslatedToastrService} from '../../../core/translated-toastr/translated-toastr.service';

@Component({
  selector: 'app-patient-settings-permissions',
  templateUrl: './patient-settings-permissions.component.html',
  styleUrls: ['./patient-settings-permissions.component.scss']
})
export class PatientSettingsPermissionsComponent implements OnInit {

  emergencyCardConfig: EmergencyPassConfig;

  constructor(
    private emergencyPassService: EmergencyPassService,
    private translatedToastrService: TranslatedToastrService
  ) {
  }

  ngOnInit() {
    this.emergencyPassService.getMyEmergencyPassConfig().subscribe(
      res => this.emergencyCardConfig = res.result,
        e => console.error(e)
    );
  }

  save() {
    if (this.emergencyCardConfig) {
      this.emergencyPassService
        .updateMyEmergencyPassConfig(this.emergencyCardConfig)
        .subscribe(
          () => this.translatedToastrService.success('message.success.save'),
          () => this.translatedToastrService.error('message.error.save')
        );
    }
  }
}
