import {DocumentHolder} from '../../patient/documents/document.model';
import {Disease} from './disease.model';
import {Operation} from './operation.model';
import {Creator} from '../creator/creator.model';
import {removeInsignificantTrailingZeros} from '../../../lib/formats';

export class Medication {
  id: number;
  created_at: Date;
  creator: Creator;
  drug: string;
  emergencyCardVisible: boolean;
  end: Date;
  evening: number;
  favourite: boolean;
  lunch: number;
  medicationCode: MedicationCode;
  morning: number;
  night: number;
  notes: string;
  otherType: string;
  prescribedBy: string;
  search: string; // CLIENT ONLY
  start: Date;
  strength: string;
  tillNow: boolean;
  title: string;
  type: number;
  visible: boolean;
  when: number;

  diseaseIds: number[];
  documentIds: number[];
  operationIds: number[];

  diseases: Disease[];
  documents: DocumentHolder[];
  operations: Operation[];

  localized: {
    type: string;
    appSummary: string;
    start: string;
    end: string;
    created_at: string;
  };

  constructor() {
    this.localized = {
      type: '',
      appSummary: '',
      start: '',
      end: '',
      created_at: ''
    };
  }

  static getMedicationTypeDescription(medication: Medication): string {
    return ''.concat(
      [
        removeInsignificantTrailingZeros(medication.morning),
        removeInsignificantTrailingZeros(medication.lunch),
        removeInsignificantTrailingZeros(medication.evening),
        removeInsignificantTrailingZeros(medication.night)
      ].join(' - '));
  }
}

export class MedicationCode {
  id: number;
  atcCode: string;
  text: string;
}
