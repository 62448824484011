import {Injectable} from '@angular/core';
import {ImageTypes} from './image-types';
import {ImageResizeTypes} from './image-resize-types';

@Injectable()
export class ImageHelperService {
  static getFileExtensionByImageType(imageType: ImageTypes) {
    let fileExtension: string;
    switch (imageType) {
      case ImageTypes.JPEG:
        fileExtension = 'jpeg';
        break;

      case ImageTypes.JPG:
        fileExtension = 'jpg';
        break;

      case ImageTypes.PNG:
        fileExtension = 'png';
        break;
    }
    return fileExtension;
  }

  static getFileFromDataURL(imageName: string, imageDataUrl: string) {
    let file: any;
    if (imageName && imageDataUrl) {
      // convert base64 to raw binary data held in a string
      const byteString = atob(imageDataUrl.split(',')[1]);
      // separate out the mime component
      const mimeString = imageDataUrl
        .split(',')[0]
        .split(':')[1]
        .split(';')[0];
      // write the bytes of the string to an ArrayBuffer
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      // create file
      const currentTime = new Date();
      if (window.navigator.msSaveBlob) {
        // Currently IE11, and Edge support the FileAPI but not the File constructor.
        file = new Blob([ab], {type: mimeString});
        file.lastModifiedDate = new Date();
        file.name = imageName;
      } else {
        file = new File([ab], imageName, {
          type: mimeString,
          lastModified: currentTime.getTime(),
        });
      }
    }
    return file;
  }

  static getImageDataUrl(
    image: HTMLImageElement,
    canvasHeight: number,
    canvasWidth: number,
    imageType: ImageTypes,
    imageSizeType: ImageResizeTypes
  ): string {
    // create empty canvas
    const canvas = document.createElement('canvas');
    canvas.height = canvasHeight;
    canvas.width = canvasWidth;

    let imageHeight = image.height;
    let imageWidth = image.width;

    const ratioHeight = canvas.height / imageHeight;
    const ratioWidth = canvas.width / imageWidth;

    // closest to zero
    let ratio: number;
    switch (imageSizeType) {
      case ImageResizeTypes.COVER:
        // highest ratio
        ratio = ratioHeight > ratioWidth ? ratioHeight : ratioWidth;
        break;

      case ImageResizeTypes.CONTAIN:
      default:
        // lowest ratio
        ratio = ratioHeight < ratioWidth ? ratioHeight : ratioWidth;
    }

    // scale image
    imageHeight *= ratio;
    imageWidth *= ratio;

    // center image
    const dstX = (canvas.width - imageWidth) / 2;
    const dstY = (canvas.height - imageHeight) / 2;

    // draw image to canvas
    canvas
      .getContext('2d')
      .drawImage(image, dstX, dstY, imageWidth, imageHeight);
    return canvas.toDataURL(imageType);
  }

  static resizeImage(
    file: File,
    canvasHeight: number,
    canvasWidth: number,
    imageType: ImageTypes,
    imageResizeType: ImageResizeTypes,
    callback: any
  ) {
    const imageName = file.name.split('.')[0];
    const fileName = imageName + '.' + this.getFileExtensionByImageType(imageType);
    // create canvas
    const canvas = document.createElement('canvas');
    canvas.height = canvasHeight;
    canvas.width = canvasWidth;

    // load image from data url with resized dimensions
    const canvasImage = new Image(canvasHeight, canvasWidth);
    canvasImage.onload = () => {
      // draw image to canvas
      canvas
        .getContext('2d')
        .drawImage(canvasImage, 0, 0, canvasWidth, canvasHeight);
      const fileDataUrl = canvas.toDataURL(imageType);
      callback(fileName, fileDataUrl);
    };

    const image = new Image();
    image.onload = () => {
      // fires canvasImage.onload function
      canvasImage.src = this.getImageDataUrl(
        image,
        canvasHeight,
        canvasWidth,
        imageType,
        imageResizeType
      );
    };

    const fileReader = new FileReader();
    fileReader.onloadend = (loadEvent: any) => {
      // fires image.onload function
      image.src = loadEvent.target.result;
    };
    // fires fileReader.onloadend function
    fileReader.readAsDataURL(file);
  }
}
