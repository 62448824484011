import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Person} from '../../../core/person/person.model';
import {AuthenticationService} from '../../../core/authentication/authentication.service';
import {ProfileService} from '../../../core/profile/profile.service';
import {NavigationService} from '../../../core/navigation/navigation.service';
import {Subscription} from 'rxjs';
import {MedicalService} from '../../../core/medical/medical.service';
import {MedicOverview} from '../../../core/medic/medic-overview.model';
import {PersonTypeEnum} from '../../enums/person-type.enum';

@Component({
  selector: 'app-user-settings-menu',
  templateUrl: './user-settings-menu.component.html',
  styleUrls: ['./user-settings-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSettingsMenuComponent implements OnDestroy, OnInit {
  @Input() isMedic = false;
  @Input() isPatient = false;
  @Input() person: Person;
  isAdmin = false;
  isLoggedIn = false;
  medicOverview: MedicOverview;
  private subscriptions: Subscription[] = [];

  constructor(
    private authenticationService: AuthenticationService,
    private medicalService: MedicalService,
    private navigationService: NavigationService,
    private profileService: ProfileService,
    private ref: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    if (this.isMedic && this.person.type === PersonTypeEnum.MEDICAL_INSTITUTION) {
      this.medicalService.getMedicOverview(this.person.id + '').subscribe(
        (res) => {
          this.medicOverview = res.result;
          this.ref.markForCheck();
        }
      );
    }

    this.subscriptions.push(
      this.authenticationService.isAdminSupport.subscribe(isAdmin => {
        this.isAdmin = isAdmin;
        this.ref.markForCheck();
      }),
      this.authenticationService.isLoggedIn.subscribe(isLoggedIn => {
        this.isLoggedIn = isLoggedIn;
        this.ref.markForCheck();
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  getUsername(): string {
    let username = this.person.firstName + ' ' + this.person.lastName;
    if (this.isMedic && this.person.type === PersonTypeEnum.MEDICAL_INSTITUTION && this.medicOverview) {
      username = this.medicOverview.medic.name;
    }
    return username;
  }

  onClickLogout() {
    this.authenticationService.signOut().subscribe(
      () => this.onSignOutSuccess(),
      () => this.onSignOutError()
    );
  }

  private onSignOutSuccess() {
    this.authenticationService.setLoggedOut();
    this.profileService.myProfileSubject.next(null);
    this.navigationService.navigateToLogin();
  }

  private onSignOutError() {
    this.authenticationService.setLoggedOut();
    this.profileService.myProfileSubject.next(null);
    this.navigationService.navigateToLogin();
  }
}
