import * as moment from 'moment-timezone';
import {DATE_WITH_TZ_FORMAT} from './formats';
import {_} from 'underscore';

function sum(arr, attr) {
  return _.reduce(arr, function(memo, num) { return memo + num[attr]; } , 0);
}

function average(arr, attr) {
  return sum(arr, attr) / arr.length;
}

/**
 * Groups values by days and calculates min, max, average values
 * specified by attr parameter
 *
 * @param data: any
 * @param attr: any
 */
export function calcStats(data, attr) {

  const groupedResults = _.groupBy(data, (res) => moment(res['date'], DATE_WITH_TZ_FORMAT).day());

  const result = {};
  for (const key of Object.keys(groupedResults)) {

    const max = _.max(groupedResults[key], function(v) { return v[attr]; });
    const min = _.min(groupedResults[key], function(v) { return v[attr]; });
    const avg = average(groupedResults[key], attr);

    result[key] = {};
    result[key].min = min;
    result[key].max = max;
    result[key].avg = avg ? Number(avg).toFixed(1) : avg;
  }

  return result;
}


