export let chartColors: Array<any> = [
  {
    backgroundColor: 'rgba(244,4,0,0.2)',
    borderColor: '#ff0400',
    pointBorderColor: '#ff0400',
    pointBackgroundColor: 'rgba(244,4,0,0.2)',
    pointBorderWidth: 1,
    pointHoverBorderWidth: 1,
    pointRadius: 4,
  },
  {
    backgroundColor: '#d4d3d3',
    borderColor: '#888',
    pointBorderColor: '#888',
    pointBackgroundColor: '#d4d3d3',
    pointBorderWidth: 1,
    pointHoverBorderWidth: 1,
    pointRadius: 4,
  }
];

export let chartColorsNoBackground: Array<any> = [
  {
    backgroundColor: 'transparent',
    borderColor: '#ff0400',
    pointBorderColor: '#ff0400',
    pointBackgroundColor: 'rgba(244,4,0,0.2)',
    pointBorderWidth: 1,
    pointHoverBorderWidth: 1,
    pointRadius: 4,
  },
  {
    backgroundColor: 'transparent',
    borderColor: '#888',
    pointBorderColor: '#888',
    pointBackgroundColor: '#d4d3d3',
    pointBorderWidth: 1,
    pointHoverBorderWidth: 1,
    pointRadius: 4,
  }
];

export let chartMultipleColors: Array<any> = [
  {
    backgroundColor: 'transparent',
    borderColor: '#ff0400',
    pointBorderColor: '#ff0400',
    pointBackgroundColor: 'rgba(244,4,0,0.2)',
    pointBorderWidth: 1,
    pointHoverBorderWidth: 1,
    pointRadius: 4,
  },
  {
    backgroundColor: 'transparent',
    borderColor: '#888',
    pointBorderColor: '#888',
    pointBackgroundColor: '#d4d3d3',
    pointBorderWidth: 1,
    pointHoverBorderWidth: 1,
    pointRadius: 4,
  }
];
