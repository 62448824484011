import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {take} from 'rxjs/operators';
import {SubscriptionService} from '../../core/subscription/subscription.service';
import {Invoice} from '../../core/invoice/invoice.model';
import {formatDate} from '../../../lib/formats';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {

  invoice: Invoice;

  print: boolean;

  constructor(
    private subscriptionService: SubscriptionService,
    private router: Router,
    private route: ActivatedRoute,
    public toastr: ToastrService
  ) {
  }

  ngOnInit() {
    // observables
    const routeParams$ = this.route.params.pipe(take(1));

    routeParams$.subscribe(p => {
      const paymentId = p['paymentId'];

      if (paymentId) {
        this.subscriptionService
          .getInvoiceByPayment(paymentId)
          .subscribe(r => {
            this.invoice = r.result;
            this.invoice.localized = {created_at: formatDate(this.invoice.created_at)};
          }, () => this.toastr.error('Could not load item', 'Error'));
      }
    });
  }

  printInvoice() {
    this.print = true;
    setTimeout(() => {
      window.print();
    }, 1000);
  }

}
