import {Injectable} from '@angular/core';
import {BackendService} from '../backend/backend.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {FeelingListResponse} from '../response/response.model';
import {ToastrService} from 'ngx-toastr';
import {Feeling} from './feeling.model';
import {LocaleResolverService} from '../locale/locale-resolver.service';

@Injectable()
export class FeelingService extends BackendService {

  constructor(
      protected http: HttpClient,
      protected localeResolverService: LocaleResolverService,
  ) {
    super(http, localeResolverService);
  }

  create(feeling: Feeling) {
    const httpOptions = this.createHttpOptions();
    return this.http.post(this.getApiUrl() + '/measurements/feelings', feeling, httpOptions);
  }

  listFeelings(id: string, page: number, pageSize: number, lastId: number, start: string, end: string, sort = 'asc') {
    const httpHeaders = this.createHttpHeaders();

    let params = new HttpParams();
    if (page !== undefined && page !== null) {
      params = params.append('page', page.toString());
    }
    if (pageSize !== undefined && pageSize !== null) {
      params = params.append('pageSize', pageSize.toString());
    }
    if (lastId !== undefined && lastId !== null) {
      params = params.append('lastId', lastId.toString());
    }
    if (start !== undefined && start !== null) {
      params = params.append('start', encodeURIComponent(start.toString()));
    }
    if (end !== undefined && end !== null) {
      params = params.append('end', encodeURIComponent(end.toString()));
    }
    if (sort !== undefined && sort !== null) {
      params = params.append('sort', encodeURIComponent(sort.toString()));
    }

      return this.http.get<FeelingListResponse>(
          this.getApiUrl() + '/people/' + id + '/measurements/feelings',
          (params.keys().length > 0) ? { withCredentials: true, headers: httpHeaders, params: params } : {
              withCredentials: true,
              headers: httpHeaders
          });
  }

  loadLatestFeeling(personIdParam: string) {
    return this.listFeelings(personIdParam, 1, 1, null, null, null, 'desc');
  }

  listFeelingStats(id: string, start: string, end: string) {
    const httpHeaders = this.createHttpHeaders();

    let params = new HttpParams();
    if (start !== undefined && start !== null) {
      params = params.append('start', encodeURIComponent(start.toString()));
    }
    if (end !== undefined && end !== null) {
      params = params.append('end', encodeURIComponent(end.toString()));
    }

    return this.http.get<FeelingListResponse>(
        this.getApiUrl() + '/people/' + id + '/measurements/feelingStats',
        (params.keys().length > 0) ? { withCredentials: true, headers: httpHeaders, params: params } : { withCredentials: true, headers: httpHeaders });
  }

  delete(id: number) {
    const httpOptions = this.createHttpOptions();
    return this.http.delete(this.getApiUrl() + '/measurements/feelings/' + id, httpOptions);
  }
}
