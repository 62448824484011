import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent {
  @Input() itemDescription: string;
  @Input() itemHeader: string;
  @Input() itemTitle = 'deleteQuestion';
  @Input() itemType: string;
  @Input() itemValue: string;

  constructor(
    private activeModal: NgbActiveModal
  ) { }

  onClose() {
    this.activeModal.close();
  }

  onRemoveItem() {
    this.activeModal.close(true);
  }
}
