import * as moment from 'moment-timezone';

export const DATE_WITH_TZ_FORMAT = 'YYYY-MM-DDTHH:mm:ssZZ';
export const DATE_WITH_TZ_FORMAT_START = 'YYYY-MM-DDT00:00:00ZZ';
export const DATE_WITH_TZ_FORMAT_END = 'YYYY-MM-DDT23:59:59ZZ';

/**
 * Returns formatted date according to locale:
 *
 * En:
 * Sunday, September 4 1986
 *
 * De:
 * Dienstag, 5. März 2019
 *
 * @param date: Date
 */
export function formatDayDate(date: Date): string {
  if (date === null) { return ''; }
  return moment(date).format('dddd, LL');
}

export function formatDate(date: Date): string {
  if (date === null) { return ''; }
  return moment(date).format('L');
}

export function formatMonthYear(date: Date): string {
  if (date === null) { return ''; }
  return moment(date).format('MMMM, YYYY');
}

export function formatMonthDayYear(date: Date): string {
  if (date === null) { return ''; }
  return moment(date).format('LLL');
}

export function removeInsignificantTrailingZeros(d: number): string {
  // TODO review
  const fl = Math.floor(d);
  if (d === fl) { return Number(d).toFixed(0); }
  return d === 0.00 ? '0' : Number(d).toString();
}

// TODO create Pipe
export function formatCalendarDateTime(date: Date): string {
  if (!date) { return ''; }

  return moment(date).calendar();
}

export function formatDateTime(date: Date): string {
  if (!date) { return ''; }

  return moment(date).format('DD.MM.YYYY, HH:mm');
}

// TODO create Pipe
export function formatBytes(bytes: number, decimals?: number): string {
  if (0 === bytes) {
    return '0 Bytes';
  }

  if (typeof decimals === 'undefined') {
    decimals = 2;
  }

  const k = 1024;
  const sizeArray = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const f = Math.floor(Math.log(bytes) / Math.log(k));

  return (
    parseFloat((bytes / Math.pow(k, f)).toFixed(decimals)) +
    ' ' +
    sizeArray[f]
  );
}
