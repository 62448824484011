import {Component, OnInit} from '@angular/core';
import {SubscriptionPlanCodeEnum} from '../../../shared/enums/subscription-plan-code.enum';
import {SubsPlan} from '../../../core/subs-plan/subs-plan.model';
import {SubscriptionService} from '../../../core/subscription/subscription.service';
import {Router} from '@angular/router';
import {OnboardingService} from '../../../core/onboarding/onboarding.service';
import {NavigationService} from '../../../core/navigation/navigation.service';
import {TranslatedToastrService} from '../../../core/translated-toastr/translated-toastr.service';

@Component({
  selector: 'app-patient-onboarding-subscription',
  templateUrl: './patient-onboarding-subscription.component.html',
  styleUrls: ['./patient-onboarding-subscription.component.scss']
})
export class PatientOnboardingSubscriptionComponent implements OnInit {
  isLoaded = false;
  isPremiumDisabled = true;
  subscriptionPlans: SubsPlan[] = [];

  constructor(private onboardingService: OnboardingService,
              private router: Router,
              private navigationService: NavigationService,
              private subscriptionService: SubscriptionService,
              private translatedToastsService: TranslatedToastrService) { }

  ngOnInit(): void {
    this.checkOnboarding();
  }

  getSubscriptionButtonTranslationKey(subsPlan: SubsPlan): string {
    if (this.isSubscriptionDisabled(subsPlan)) {
      return 'btn.subscription.disabled';
    } else {
      return subsPlan.annual ? 'btn.onboarding.subscription.payment' : 'btn.select';
    }
  }

  isSubscriptionDisabled(subsPlan: SubsPlan): boolean {
    return this.isPremiumDisabled && subsPlan.code === SubscriptionPlanCodeEnum.PREMIUM;
  }

  onClickSubsPlan(subsPlan: SubsPlan) {
    if (!this.isSubscriptionDisabled(subsPlan)) {
      switch (subsPlan.code) {
        case SubscriptionPlanCodeEnum.BASIC:
          this.onboardingService.startSubscriptionTrial().subscribe(
            () => this.navigationService.navigateToPatientOnboardingHealth(),
            () => this.translatedToastsService.error('message.error.subscription.basic')
          );
          break;

        case SubscriptionPlanCodeEnum.PREMIUM:
        case SubscriptionPlanCodeEnum.PRO:
          this.navigationService.navigateToPatientOnboardingPayment(subsPlan);
          break;
      }
    }
  }

  private checkOnboarding() {
    this.onboardingService.getPatientOnboardingStage().subscribe(
      data => {
        const currentOnboardingUrl = this.router.routerState.snapshot;
        const onboardingUrl = '/onboarding/' + data.result.onboardingStage;
        if (currentOnboardingUrl.url !== onboardingUrl) {
          this.router.navigate([onboardingUrl]);
        } else {
          this.initOnboardingSubscription();
        }
      }
    );
  }

  private initOnboardingSubscription() {
    this.subscriptionService.listSubscriptionPlans().subscribe(data => {
        this.subscriptionPlans = data.results;
        this.isLoaded = true;
      }
    );
  }
}
