import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthenticationService} from '../../../core/authentication/authentication.service';
import {ProfileService} from '../../../core/profile/profile.service';
import {NavigationService} from '../../../core/navigation/navigation.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-logout-side-navigation',
  templateUrl: './logout-side-navigation.component.html',
  styleUrls: ['./logout-side-navigation.component.scss']
})
export class LogoutSideNavigationComponent implements OnDestroy, OnInit {
  isLoggedIn = false;
  private subscriptions: Subscription[] = [];

  constructor(
    private authenticationService: AuthenticationService,
    private navigationService: NavigationService,
    private profileService: ProfileService,
  ) { }

  ngOnInit() {
    this.subscriptions.push(
      this.authenticationService.isLoggedIn.subscribe(isLoggedIn => {
        this.isLoggedIn = isLoggedIn;
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onClickLogout() {
    this.authenticationService.signOut().subscribe(
      () => this.onSignOutSuccess(),
      () => this.onSignOutError()
    );
  }

  private onSignOutSuccess() {
    this.authenticationService.setLoggedOut();
    this.profileService.myProfileSubject.next(null);
    this.navigationService.navigateToLogin();
  }

  private onSignOutError() {
    this.authenticationService.setLoggedOut();
    this.profileService.myProfileSubject.next(null);
    this.navigationService.navigateToLogin();
  }
}
