import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Profile} from '../../../core/profile/profile.model';
import {Weight} from '../../../core/measurements/weight.model';
import {WeightService} from '../../../core/measurements/weight.service';
import {Router} from '@angular/router';
import {formatDateTime} from '../../../../lib/formats';

@Component({
  selector: 'app-profile-weight',
  templateUrl: './profile-weight.component.html',
  styleUrls: ['./profile-weight.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileWeightComponent implements OnInit {
  @Input() isVisible = true;
  @Input() latestWeight: Weight | undefined;
  @Input() personIdParam = 'me';
  @Input() profile: Profile;
  @Input() readOnly = false;
  unit = 'kg';

  constructor(private router: Router,
              private weightService: WeightService,
              protected ref: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.latestWeight === undefined) {
      this.weightService.loadLatestWeight(this.personIdParam).subscribe(
        r => {
          this.latestWeight = r.results.length > 0 ? r.results[0] : null;
          this.ref.markForCheck();
        },
        () => {
        }
      );
    }
  }

  getSubLabel(): string {
    let subLabel = '-';
    if (this.latestWeight) {
      subLabel = formatDateTime(this.latestWeight.date);
    } else if (this.profile?.weight) {
      subLabel = formatDateTime(this.profile.createdAt);
    }
    return subLabel;
  }

  onClick() {
    if (!this.readOnly && this.isVisible) {
      const url = ((this.personIdParam === 'me') ? '/people/' : '/medic/patients/') + this.personIdParam + '/measurements/weight';
      this.router.navigate([url]);
    }
  }
}
