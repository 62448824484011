import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ContentLayoutComponent} from './+modules/shared/layouts/content/content-layout.component';
import {FullLayoutComponent} from './+modules/shared/layouts/full/full-layout.component';
import {MedicGuardService} from './+modules/core/medic/medic-guard.service';
import {HomeComponent} from './home/home.component';
import {AdminGuardService} from './+modules/core/admin/admin-guard.service';
import {ConfirmRegComponent} from './+modules/shared/auth/confirm-reg/confirm-reg.component';
import {NotAuthorizedComponent} from './+modules/shared/pages/not-authorized/not-authorized.component';
import {OnboardingProfileComponent} from './onboarding/profile/onboarding-profile.component';
import {SignUpComponent} from './+modules/shared/auth/sign-up/sign-up.component';
import {InvoiceComponent} from './+modules/shared/invoice/invoice.component';
import {ChatComponent} from './chat/chat.component';
import {InboxComponent} from './inbox/inbox.component';
import {AttrsComponent} from './+modules/shared/auth/attrs/attrs.component';
import {PrivacyComponent} from './privacy/privacy.component';
import {PaypalAgreementComponent} from './+modules/shared/payment/paypal/paypal-agreement/paypal-agreement.component';
import {TermsComponent} from './terms/terms.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {
    path: '',
    component: ContentLayoutComponent,
    // TODO check and remove/move routes if needed
    children: [
      {path: 'confirm-registration/:email', component: ConfirmRegComponent},
      {path: 'not-authorized', component: NotAuthorizedComponent},
      {path: 'person/onboarding/profile', component: OnboardingProfileComponent},
      {path: 'signup', component: SignUpComponent},
      {path: 'subscription/payments/:paymentId/invoice', component: InvoiceComponent},
    ]
  },
  {
    path: '',
    component: FullLayoutComponent,
    // TODO check and remove/move routes if needed
    children: [
      {path: 'chat', component: ChatComponent},
      {path: 'messages', component: InboxComponent},
      {path: 'person/onboarding/profile', component: OnboardingProfileComponent},
      {path: 'profile/attributes/:attr', component: AttrsComponent},
      {path: 'privacy-policy', component: PrivacyComponent},
      {path: 'subscription/processagreement', component: PaypalAgreementComponent},
      {path: 'terms', component: TermsComponent}
    ]
  },
  {
    path: 'admin',
    loadChildren: () => import('./+modules/+admin/admin.module').then(m => m.AdminModule),
    canLoad: [AdminGuardService],
  },
  // TODO lazy-load patient module and routes
  {
    path: 'medic',
    loadChildren: () => import('./+modules/+medic/medic.module').then(m => m.MedicModule),
    canLoad: [MedicGuardService],
  },
  {
    path: '',
    loadChildren: () => import('./+modules/patient/patient.module').then(m => m.PatientModule),
  },
  // default route
  {path: '**', redirectTo: 'signin'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
