import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {Operation} from '../../../core/health/operation.model';
import {NgbDateParserFormatter, NgbDateStruct, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {OperationService} from '../../../core/health/operation.service';
import {ActivatedRoute} from '@angular/router';
import {Person} from '../../../core/person/person.model';
import {ProfileService} from '../../../core/profile/profile.service';
import {take} from 'rxjs/operators';
import {forkJoin} from 'rxjs';
import {canEdit} from '../../../../lib/utils';
import {UsageTypeEnum} from '../../../shared/enums/usage-type.enum';
import {UsageAlertComponent} from '../../../shared/usage/usage-alert/usage-alert.component';
import {OperationEditComponent} from './operation-edit/operation-edit.component';
import {BaseComponent} from '../../../../base/base-component';
import {LocaleResolverService} from '../../../core/locale/locale-resolver.service';
import {QueryFilter} from '../../../core/filters/query-filter.model';
import {fromNgbDateStructToDate} from '../../../../lib/dates';
import {NgbDateDynamicParserFormatter} from '../../../../lib/ngb-date-dynamic-parser-formatter';
import {DeleteModalComponent} from '../../../shared/delete-modal/delete-modal.component';
import {TranslateService} from '@ngx-translate/core';
import {TranslatedToastrService} from '../../../core/translated-toastr/translated-toastr.service';
import {SelectionType} from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-operations',
  templateUrl: './operations.component.html',
  styleUrls: ['./operations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{provide: NgbDateParserFormatter, useClass: NgbDateDynamicParserFormatter}]
})
export class OperationsComponent extends BaseComponent implements OnInit {
  @ViewChild('usageAlert', { static: true }) usageAlert: UsageAlertComponent;
  currentModal: NgbModalRef;
  filterDate: NgbDateStruct;
  filterEnd: NgbDateStruct;
  isLoadingOperationDetails = false;
  lastId: number;
  operationId: number;
  operations: Operation[];
  pageSize = 30;
  personIdParam: string;
  query: string;
  queryFilters: QueryFilter[] = [];
  quotaReached = false;
  requestedPerson: Person;
  selectedOperation: Operation;
  UsageTypeEnum = UsageTypeEnum;
  selected = [];
  SelectionType = SelectionType;

  constructor(
    private activatedRoute: ActivatedRoute,
    private modal: NgbModal,
    private operationService: OperationService,
    private translatedToastrService: TranslatedToastrService,
    private translateService: TranslateService,
    protected profileService: ProfileService,
    protected localeResolverService: LocaleResolverService,
    protected ref: ChangeDetectorRef
  ) {
    super(profileService, localeResolverService, ref);
  }

  ngOnInit() {
    super.ngOnInit();

    const routeParams$ = this.activatedRoute.params.pipe(take(1));
    const me$ = this.profileService.getMyProfile();

    forkJoin([routeParams$, me$]).subscribe(respList => {
      this.personIdParam = respList[0]['id'];
      if ('operationId' in respList[0]) {
        this.operationId = +(respList[0]['operationId']);
      }
      this.requester = respList[1].result;
      this.loadOperations();

      this.profileService.setCurrentPersonIdParam(this.personIdParam);

      if (this.personIdParam !== 'me') {
        this.profileService.getProfile(this.personIdParam).subscribe(resp => {
          this.requestedPerson = resp.result;
          this.ref.markForCheck();
        });
      }
    });
  }

  addNew() {
    this.showAddEditModal();
  }

  askToRemove(event, firstChild) {
    event.stopPropagation();
    firstChild.parentElement.parentElement.blur();
    this.showDeleteModal();
  }

  editItem(event, firstChild) {
    event.stopPropagation();
    firstChild.parentElement.parentElement.blur();
    this.showAddEditModal(this.selectedOperation);
  }

  getOperationType(type: number): string {
    let operationType = '';
    if (type) {
      operationType = 'codes.operation.' + type;
    }
    return operationType;
  }

  onIsLimitQuotaReached(limitQuotaReached) {
    this.quotaReached = limitQuotaReached;
  }

  // https://github.com/swimlane/ngx-datatable/issues/721
  onActive(event) {
    if (event.type === 'click') {
      event.cellElement.blur();
      this.loadOperationDetail(event.row.id);
    }
  }

  onDateFilterChange(selectedDate: NgbDateStruct, filterName: string) {
    let filterValue;
    switch (filterName) {
      case 'date':
        this.filterDate = selectedDate;
        if (this.filterDate) {
          const date = fromNgbDateStructToDate(this.filterDate);
          // check if date is valid
          if (!isNaN(date.getTime())) {
            date.setHours(0, 0, 0, 0);
            filterValue = date.toISOString();
          }
        }
        break;

      case 'end':
        this.filterEnd = selectedDate;
        if (this.filterEnd) {
          const endDate = fromNgbDateStructToDate(this.filterEnd);
          // check if date is valid
          if (!isNaN(endDate.getTime())) {
            endDate.setHours(23, 59, 59, 59);
            filterValue = endDate.toISOString();
          }
        }
        break;
    }

    this.onFilterChange(filterName, filterValue);
  }

  onFilterChange(filterName: string, filterValue: any) {
    const filter = this.queryFilters.find(queryFilter => queryFilter.name === filterName);

    if (filter && filterValue) {
      // update value
      filter.value = filterValue;
    } else if (filter) {
      // unset value
      this.queryFilters.splice(this.queryFilters.lastIndexOf(filter), 1);
    } else if (filterValue) {
      // new value
      this.queryFilters.push({name: filterName, value: filterValue});
    }
    // reload the list
    this.lastId = undefined;
    this.loadOperations();
  }

  loadOperationDetail(operationId) {
    if (this.selectedOperation && this.selectedOperation.id === operationId) {
      this.selectedOperation = null;
      this.selected = [];
    } else {
      this.selectedOperation = null;
      this.isLoadingOperationDetails = true;
      this.operationService.getOperation(this.personIdParam, operationId).subscribe(r => {
        this.selectedOperation = r.result;
        this.isLoadingOperationDetails = false;
        this.ref.markForCheck();
      }, () => {
        this.selected = [];
        this.translatedToastrService.error('message.error.operation.detail');
      });
    }
  }

  onSearchChange(search: string) {
    this.query = search;
    this.lastId = null;
    this.loadOperations();
  }

  toggleEmergencyCardVisible(event, operation?: Operation) {
    event.stopPropagation();
    if (operation) {
      operation.emergencyCardVisible = !operation.emergencyCardVisible;

      this.updateOperation(operation);
    } else if (this.selectedOperation) {
      this.selectedOperation.emergencyCardVisible = !this.selectedOperation.emergencyCardVisible;
      const diseaseIndex = this.operations.findIndex(d => d.id === this.selectedOperation.id);
      this.operations[diseaseIndex].emergencyCardVisible = this.selectedOperation.emergencyCardVisible;

      this.updateSelectedOperation();
    }
  }

  toggleFavourite(operation?: Operation) {
    if (operation) {
      operation.favourite = !operation.favourite;

      this.updateOperation(operation);
    } else if (this.selectedOperation) {
      this.selectedOperation.favourite = !this.selectedOperation.favourite;
      const operationIndex = this.operations.findIndex(d => d.id === this.selectedOperation.id);
      this.operations[operationIndex].favourite = this.selectedOperation.favourite;

      this.updateSelectedOperation();
    }
  }

  toggleVisibility(event, operation?: Operation) {
    event.stopPropagation();
    if (operation) {
      operation.visible = !operation.visible;

      this.updateOperation(operation);
    } else if (this.selectedOperation) {
      this.selectedOperation.visible = !this.selectedOperation.visible;
      const operationIndex = this.operations.findIndex(d => d.id === this.selectedOperation.id);
      this.operations[operationIndex].visible = this.selectedOperation.visible;

      this.updateSelectedOperation();
    }
  }

  private handleSaved(saved: boolean) {
    if (saved) {
      this.lastId = null;
      this.usageAlert.loadsUsage();
      this.loadOperations();
      this.loadOperationDetail(this.selectedOperation.id);
    }
  }

  private loadOperations() {
    this.operationService
      .listOperations(
        this.personIdParam,
        null,
        null,
        this.lastId ? null : 1,
        this.pageSize,
        this.lastId,
        this.queryFilters
      )
      .subscribe(
        resp => {
          this.operations = this.lastId ? this.operations.concat(resp.results) : resp.results;

          this.lastId = resp.lastId;
          this.operations = [...this.operations];

          if (this.operationId) {
            this.loadOperationDetail(this.operationId);
            this.operationId = undefined;
          }

          this.ref.markForCheck();
        },
        e => {
          console.log(e);
          this.translatedToastrService.error('message.error.operation.list');
        }
      );
  }

  private removeItem() {
    this.operationService.deleteOperation(this.selectedOperation.id).subscribe(
      () => {
        this.translatedToastrService.success('message.success.operation.delete');

        this.operations = this.operations.filter(o => o.id !== this.selectedOperation.id);
        // this is a workaround - see https://stackoverflow.com/questions/51704888/remove-rows-with-ngx-datatable
        this.operations = [...this.operations];

        this.selectedOperation = null;
        this.currentModal.close();

        this.usageAlert.loadsUsage();

        this.ref.markForCheck();
      },
      () => this.translatedToastrService.error('message.error.operation.delete')
    );
  }

  private showAddEditModal(operation?: Operation) {
    setTimeout(() => {
      this.currentModal = this.modal.open(OperationEditComponent, {size: 'lg'});
      this.currentModal.componentInstance.personIdParam = this.personIdParam;
      this.currentModal.componentInstance.requester = this.requester;
      this.currentModal.componentInstance.operation = operation;
      if (operation) {
        this.currentModal.componentInstance.readonly = !canEdit(this.personIdParam, this.requester.id, operation.creator.id);
      }
      this.currentModal.result.then(saved => this.handleSaved(saved)).catch(e => {
      });
    }, 100);
  }

  private showDeleteModal() {
    setTimeout(() => {
      this.currentModal = this.modal.open(DeleteModalComponent, {size: 'lg'});
      this.currentModal.componentInstance.itemDescription = this.selectedOperation.notes;
      this.currentModal.componentInstance.itemHeader = this.selectedOperation.title;
      this.currentModal.componentInstance.itemType = this.translateService.instant('operation');
      this.currentModal.result.then(isDeleted => {
        if (isDeleted) {
          this.removeItem();
        }
      });
    }, 100);
  }

  private updateOperation(operation: Operation) {
    if (operation) {
      this.operationService.updateOperation(operation.id, operation).subscribe(
        () => this.translatedToastrService.success('message.success.operation.update'),
        () => this.translatedToastrService.error('message.error.operation.update')
      );
    }
  }

  private updateSelectedOperation() {
    if (this.selectedOperation) {
      this.operationService.updateOperation(this.selectedOperation.id, this.selectedOperation).subscribe(
        () => this.translatedToastrService.success('message.success.operation.update'),
        () => this.translatedToastrService.error('message.error.operation.update')
      );
    }
  }
}
