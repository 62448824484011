import {EventEmitter, Injectable} from '@angular/core';
import {ResponsiveSizes} from './responsive-sizes';

@Injectable()
export class ResponsiveService {
  // ['chrome', 'firefox', 'ie', 'na', 'safari', 'silk', 'opera', 'yandex']
  browser = '';
  browserChange = new EventEmitter<string>();
  // ['mobile', 'tablet', 'smarttv', 'desktop']
  device = '';
  deviceChange = new EventEmitter<string>();
  // ['android', 'blackberry', 'generic mobile', 'generic tablet', 'ipad', 'iphone', 'kindle', 'linux', 'mac', 'windows', 'windows phone']
  deviceType = '';
  deviceTypeChange = new EventEmitter<string>();
  // ['4', '4S', '5', '5S', '5C', 'SE', '6', '6+', '6S', '6S+', '7', '7+', '8', '8+', 'X']
  iPhoneTypeList = [];
  iPhoneTypeListChange = new EventEmitter<string[]>();
  // ['xs', 'sm', 'md', 'lg', 'xl']
  responsiveSize: ResponsiveSizes = ResponsiveSizes.UNDEFINED;
  responsiveSizeChange = new EventEmitter<ResponsiveSizes>();

  isMobile(): boolean {
    return this.responsiveSize === ResponsiveSizes.XS;
  }

  isResponsiveSizeSmallOrLower(responsiveSize: ResponsiveSizes): boolean {
    return [ResponsiveSizes.XS, ResponsiveSizes.SMALL].some(size => size === responsiveSize);
  }

  isResponsiveSizeXs(responsiveSize: ResponsiveSizes): boolean {
    return responsiveSize === ResponsiveSizes.XS;
  }

  setBrowser(browser: string) {
    browser = navigator.userAgent.match('CriOS') ? 'chrome' : browser;
    browser = navigator.userAgent.match('Silk') ? 'silk' : browser;
    this.browser = browser ? browser.toLowerCase() : '';
    this.browserChange.emit(browser);
  }

  setDevice(device: string) {
    this.device = device ? device.toLowerCase() : '';
    this.deviceChange.emit(device);
  }

  setDeviceType(deviceType: string) {
    this.deviceType = deviceType ? deviceType.toLowerCase() : '';
    this.deviceTypeChange.emit(deviceType);

    if (deviceType === 'iphone') {
      this.setIPhoneType();
    }
  }

  setIPhoneType() {
    const iPhoneTypeList: string[] = [];
    const dimensionHeight = screen.height * window.devicePixelRatio;
    const dimensionWidth = screen.width * window.devicePixelRatio;
    const size =
      dimensionHeight > dimensionWidth ? dimensionHeight : dimensionWidth;
    switch (size) {
      case 960:
        // [4, 4S]
        iPhoneTypeList.push('4', '4S');
        break;

      case 1134:
        // [6, 6S, 7, 8]
        iPhoneTypeList.push('6', '6S', '7', '8');
        break;

      case 1136:
        // [5, 5S, 5C, SE]
        iPhoneTypeList.push('5', '5S', '5C', 'SE');
        break;

      case 1920:
      case 2280:
        // [6+, 6S+, 7+, 8+]
        iPhoneTypeList.push('6+', '6S+', '7+', '8+');
        break;

      case 2436:
        iPhoneTypeList.push('X');
        break;
    }

    this.iPhoneTypeList = iPhoneTypeList;
    this.iPhoneTypeListChange.emit(iPhoneTypeList);
  }

  setResponsiveSize(responsiveSize: ResponsiveSizes) {
    this.responsiveSize = responsiveSize
      ? responsiveSize
      : ResponsiveSizes.UNDEFINED;
    this.responsiveSizeChange.emit(responsiveSize);
  }
}

/*
 Copyright 2019, ITELL.SOLUTIONS GmbH, All Rights Reserved.
 */
