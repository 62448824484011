import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DiseaseService} from '../../../../core/health/disease.service';
import {DocumentService} from '../../../documents/document.service';
import {DocumentHolder} from '../../../documents/document.model';
import {Disease, DiseaseCode} from '../../../../core/health/disease.model';
import {NgbActiveModal, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {concat, Observable, of, Subject} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, map, switchMap, tap} from 'rxjs/operators';
import {DiseaseCodeListResponse} from '../../../../core/response/response.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {fromNgbDateStructToDate, toNgbDateStruct} from '../../../../../lib/dates';
import {LocaleResolverService} from '../../../../core/locale/locale-resolver.service';
import {BaseComponent} from '../../../../../base/base-component';
import {NgbDateDynamicParserFormatter} from '../../../../../lib/ngb-date-dynamic-parser-formatter';
import {Operation} from '../../../../core/health/operation.model';
import {Medication} from '../../../../core/health/medication.model';
import {EntityLinksComponent} from '../../entity-links/entity-links.component';
import {UsageTypeEnum} from '../../../../shared/enums/usage-type.enum';
import * as moment from 'moment-timezone';
import {CustomValidators} from 'ngx-custom-validators';
import {diseaseValidator} from './disease-validator.directive';
import {ProfileService} from '../../../../core/profile/profile.service';
import {DocumentUploaderComponent} from '../../../documents/document-uploader/document-uploader.component';
import {TranslatedToastrService} from '../../../../core/translated-toastr/translated-toastr.service';

@Component({
    selector: 'app-disease-edit',
    templateUrl: './disease-edit.component.html',
    styleUrls: ['../diseases.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {provide: NgbDateParserFormatter, useClass: NgbDateDynamicParserFormatter}
    ]
})
export class DiseaseEditComponent extends BaseComponent implements OnInit {
    @Input() disease: Disease;
    @Input() personIdParam: string;
    @Input() readOnly = false;
    @Output() saved = new EventEmitter<boolean>();
    @ViewChild('entityLinks') entityLinks: EntityLinksComponent;
    @ViewChild('docUpload') docUpload: DocumentUploaderComponent;

    diseaseFormGroup: FormGroup;

    start: NgbDateStruct;
    end: NgbDateStruct;

    documents: DocumentHolder[] = [];

    valid = true;
    docUploadValid = true;

    diseasesCodes$: Observable<DiseaseCode[]>;
    diseasesCodesLoading = false;
    diseasesCodesInput$ = new Subject<string>();

    submitted = false;
    tillNow = false;
    fileToUploadCount: number;

    loadedOperations: Operation[] = [];
    loadedMedications: Medication[] = [];
    loadedDocuments: DocumentHolder[] = [];

    selectedOperations: Operation[] = [];
    selectedMedications: Medication[] = [];
    selectedDocuments: DocumentHolder[] = [];

    UsageTypeEnum = UsageTypeEnum;

    constructor(
        private diseaseService: DiseaseService,
        private docService: DocumentService,
        private fb: FormBuilder,
        public activeModal: NgbActiveModal,
        public translatedToastrService: TranslatedToastrService,
        protected profileService: ProfileService,
        protected localeResolverService: LocaleResolverService,
        protected ref: ChangeDetectorRef
    ) {
        super(profileService, localeResolverService, ref);
    }

    ngOnInit() {
        super.ngOnInit();

        // init form group
        this.diseaseFormGroup = this.fb.group({
            id: [null],
            diseaseCode: [null],
            end: [''],
            emergencyCardVisible: [false],
            favourite: [false],
            notes: [''],
            visible: [false],
            start: ['', Validators.compose([Validators.required, CustomValidators.date])],
            title: ['']
        }, {validators: diseaseValidator});

        this.initDate();

        if (!this.disease) {
            this.disease = new Disease();
            this.diseaseFormGroup.controls['start'].setValue(fromNgbDateStructToDate(this.start));
            this.ref.markForCheck();
        } else {
            this.loadedDocuments = this.disease.documents;
            this.loadedMedications = this.disease.medications;
            this.loadedOperations = this.disease.operations;

            this.selectedDocuments = this.disease.documents;
            this.selectedMedications = this.disease.medications;
            this.selectedOperations = this.disease.operations;

            this.initForm();
        }

        this.loadDiseasesCodes();
    }

    close(saved: boolean) {
        this.saved.emit(saved);
        this.activeModal.close(saved);
    }

    documentsPopulated(documents) {
        this.selectedDocuments = documents;
    }

    medicationsPopulated(medications) {
        this.selectedMedications = medications;
    }

    onDiseaseCodeClick() {
        // trigger load
        this.diseasesCodesInput$.next('');
    }

    onEmergencyCardVisibleChange(emergencyCardVisible: boolean) {
        this.diseaseFormGroup.controls['emergencyCardVisible'].setValue(emergencyCardVisible);
    }

    onEndChange(end: NgbDateStruct) {
        this.end = end;
        this.diseaseFormGroup.controls['end'].setValue(fromNgbDateStructToDate(end));
        this.ref.markForCheck();
    }

    onFavouriteChange(favourite: boolean) {
        this.diseaseFormGroup.controls['favourite'].setValue(favourite);
    }

    onStartChange(start: NgbDateStruct) {
        this.start = start;
        this.diseaseFormGroup.controls['start'].setValue(fromNgbDateStructToDate(start));
        this.ref.markForCheck();
    }

    onTillNowChange(tillNow: boolean) {
        this.tillNow = tillNow;
        this.diseaseFormGroup.controls['end'].setValue(this.tillNow ? null : fromNgbDateStructToDate(this.end));
        this.ref.markForCheck();
    }

    onVisibleChange(visible: boolean) {
        this.diseaseFormGroup.controls['visible'].setValue(visible);
    }

    operationsPopulated(operations) {
        this.selectedOperations = operations;
    }

    async save() {
        this.submitted = true;
        this.diseaseFormGroup.controls['start'].setValue(fromNgbDateStructToDate(this.start));
        this.diseaseFormGroup.controls['end'].setValue(this.tillNow ? null : fromNgbDateStructToDate(this.end));

        if (this.diseaseFormGroup.valid && this.docUploadValid) {

            this.disease = this.diseaseFormGroup.value;
            this.disease.code = this.diseaseFormGroup.controls['diseaseCode'].value ?
                this.diseaseFormGroup.controls['diseaseCode'].value.code : null;

            this.disease.operationIds = this.selectedOperations.map(e => e.id);
            this.disease.medicationIds = this.selectedMedications.map(e => e.id);
            this.disease.documentIds = this.selectedDocuments.map(e => e.id);

            /*let uploadedDocsId = [];
            if (this.docUpload.hasDocuments()) {
                uploadedDocsId = await this.docUpload
                    .save(this.disease.title,
                        DocumentTypeEnum.DISEASE,
                        this.disease.visible,
                        this.disease.favourite,
                        this.disease.emergencyCardVisible);
            }

            if (uploadedDocsId.length > 0) {
                this.disease.documentIds = this.disease.documentIds.concat(uploadedDocsId);
            }*/

            this.saveDisease();
        }
    }

    private initDate() {
        const now = moment();
        this.start = {day: now.date(), month: (now.month() + 1), year: now.year()};
    }

    private initForm() {
        this.start = toNgbDateStruct(this.disease.start);
        this.end = toNgbDateStruct(this.disease.end);
        this.tillNow = !this.disease.end;
        this.diseaseFormGroup.patchValue(this.disease);
        this.diseaseFormGroup.controls['diseaseCode'].setValue(this.disease.diseaseCode ?
            {
                code: this.disease.diseaseCode.code,
                text: this.disease.diseaseCode.code + ': ' +
                    this.disease.diseaseCode.text
            } : null);

        if (this.readOnly) {
            this.diseaseFormGroup.disable();
        }
        this.ref.markForCheck();
    }

    private loadDiseasesCodes() {
        this.diseasesCodes$ = concat(
            of([]), // default items
            this.diseasesCodesInput$.pipe(
                debounceTime(300),
                distinctUntilChanged(),
                tap(() => this.diseasesCodesLoading = true),
                switchMap(term => this.diseaseService.listDiseasesCodes(term).pipe(
                    catchError(() => of([])),
                    map((res: DiseaseCodeListResponse) => {
                        const diseasesCodes = res.results;
                        return diseasesCodes.map(d => {
                            return {
                                code: d.code,
                                text: d.code + ': ' + d.text
                            };
                        });
                    }),
                    tap(() => this.diseasesCodesLoading = false)
                ))
            )
        );
    }

    private resetForm() {
        this.initDate();
        this.end = null;
        this.documents = [];
        this.tillNow = null;
        this.disease = new Disease();
        this.selectedOperations = [];

        this.loadedOperations = [];
        this.loadedMedications = [];
        this.loadedDocuments = [];

        this.selectedOperations = [];
        this.selectedMedications = [];
        this.selectedDocuments = [];

        this.diseaseFormGroup.reset();
        this.ref.markForCheck();
    }

    private saveDisease() {
        if (this.disease.id) {
            this.diseaseService
                .updateDisease(this.disease.id, this.disease).subscribe(() => this.onSaveSuccess(),
                () => this.onSaveError()
            );
        } else {
            this.diseaseService
                .createDisease(this.personIdParam, this.disease)
                .subscribe(() => this.onSaveSuccess(),
                    () => this.onSaveError()
                );
        }
    }

    private onSaveSuccess() {
        this.entityLinks.resetSelects();
        this.submitted = false;
        this.saved.emit(true);
        this.resetForm();

        this.translatedToastrService.success('message.success.disease.update');
        this.close(true);
    }

    private onSaveError() {
        this.translatedToastrService.error('message.error.disease.update');
    }

    handleValidDocUpload(valid) {
        this.docUploadValid = valid;
    }
}
