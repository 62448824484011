import {Injectable} from '@angular/core';

@Injectable()
export class AppConfig {
  // private HOURS_MINUTES_PATTERN: string = "^(?:(?:([01]?\\d|2[0-3]):)?([0-5]?\\d):)?([0-5]?\\d)$";
  private HOURS_MINUTES_PATTERN = '^(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$';

  hoursMinutesPattern() {
    return this.HOURS_MINUTES_PATTERN;
  }
}
