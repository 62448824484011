import {Component, Input, OnInit} from '@angular/core';
import {MedakteIconTypes} from './medakte-icon-types';
import {MedakteIconColors} from './medakte-icon-colors';

@Component({
  selector: 'app-medakte-icon',
  templateUrl: './medakte-icon.component.html',
  styleUrls: ['./medakte-icon.component.scss']
})
export class MedakteIconComponent implements OnInit {
  @Input() medakteIconColor: MedakteIconColors = MedakteIconColors.BLACK;
  @Input() medakteIconType: MedakteIconTypes;
  @Input() tooltipMessage = '';
  isTooltipDisabled = true;

  ngOnInit() {
    this.isTooltipDisabled = this.tooltipMessage.length === 0;
  }
}
