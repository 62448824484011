import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {Disease} from '../../../core/health/disease.model';
import {NgbDateParserFormatter, NgbDateStruct, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {DiseaseService} from '../../../core/health/disease.service';
import {DocumentService} from '../../documents/document.service';
import {ActivatedRoute} from '@angular/router';
import {ProfileService} from '../../../core/profile/profile.service';
import {Person} from '../../../core/person/person.model';
import {forkJoin} from 'rxjs';
import {take} from 'rxjs/operators';
import {canEdit} from '../../../../lib/utils';
import {UsageTypeEnum} from '../../../shared/enums/usage-type.enum';
import {DiseaseEditComponent} from './disease-edit/disease-edit.component';
import {UsageAlertComponent} from '../../../shared/usage/usage-alert/usage-alert.component';
import {BaseComponent} from '../../../../base/base-component';
import {LocaleResolverService} from '../../../core/locale/locale-resolver.service';
import {QueryFilter} from '../../../core/filters/query-filter.model';
import {fromNgbDateStructToDate} from '../../../../lib/dates';
import {NgbDateDynamicParserFormatter} from '../../../../lib/ngb-date-dynamic-parser-formatter';
import {DeleteModalComponent} from '../../../shared/delete-modal/delete-modal.component';
import {TranslateService} from '@ngx-translate/core';
import {TranslatedToastrService} from '../../../core/translated-toastr/translated-toastr.service';
import {SelectionType} from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-diseases',
  templateUrl: './diseases.component.html',
  styleUrls: ['./diseases.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{provide: NgbDateParserFormatter, useClass: NgbDateDynamicParserFormatter}]
})
export class DiseasesComponent extends BaseComponent implements OnInit {
  @ViewChild('usageAlert', { static: true }) usageAlert: UsageAlertComponent;
  currentModal: NgbModalRef;
  diseaseId: number;
  diseases: Disease[];
  filterEnd: NgbDateStruct;
  filterStart: NgbDateStruct;
  isLimitReached = false;
  isLoadingDiseaseDetails = false;
  lastId: number;
  pageSize = 30;
  personIdParam: string;
  query: string;
  queryFilters: QueryFilter[] = [];
  requestedPerson: Person;
  selectedDisease: Disease;
  UsageTypeEnum = UsageTypeEnum;
  selected = [];
  SelectionType = SelectionType;
  constructor(
    private activatedRoute: ActivatedRoute,
    private diseaseService: DiseaseService,
    private docService: DocumentService,
    private modal: NgbModal,
    private translatedToastrService: TranslatedToastrService,
    private translateService: TranslateService,
    protected profileService: ProfileService,
    protected localeResolverService: LocaleResolverService,
    protected ref: ChangeDetectorRef
  ) {
    super(profileService, localeResolverService, ref);
  }

  ngOnInit() {
    super.ngOnInit();

    const routeParams$ = this.activatedRoute.params.pipe(take(1));

    forkJoin([routeParams$]).subscribe(respList => {
      this.personIdParam = respList[0]['id'];
      if ('diseaseId' in respList[0]) {
        this.diseaseId = +(respList[0]['diseaseId']);
      }

      this.loadDiseases();

      this.profileService.setCurrentPersonIdParam(this.personIdParam);

      if (this.personIdParam !== 'me') {
        this.profileService.getProfile(this.personIdParam).subscribe(resp => {
          this.requestedPerson = resp.result;
          this.ref.markForCheck();
        });
      }
    });
  }

  addNew() {
    this.showAddEditModal();
  }

  askToRemove(event, firstChild) {
    event.stopPropagation();
    firstChild.parentElement.parentElement.blur();
    this.showDeleteModal();
  }

  editItem(event, firstChild) {
    event.stopPropagation();
    firstChild.parentElement.parentElement.blur();
    this.showAddEditModal(this.selectedDisease);
  }

  loadDiseaseDetail(diseaseId: number) {
    if (this.selectedDisease && this.selectedDisease.id === diseaseId) {
      this.selectedDisease = null;
      this.selected = [];
    } else {
      this.selectedDisease = null;
      this.isLoadingDiseaseDetails = true;
      this.diseaseService.getDisease(this.personIdParam, diseaseId).subscribe(r => {
        this.selectedDisease = r.result;
        this.isLoadingDiseaseDetails = false;
        this.ref.markForCheck();
      }, () => {
        this.selected = [];
        this.translatedToastrService.error('message.error.disease.detail');
      });
    }
  }

  // https://github.com/swimlane/ngx-datatable/issues/721
  onActive(event) {
    if (event.type === 'click') {
      this.loadDiseaseDetail(event.row.id);
      event.cellElement.blur();
    }
  }

  onDateFilterChange(selectedDate: NgbDateStruct, filterName: string) {
    let filterValue;
    switch (filterName) {
      case 'end':
        this.filterEnd = selectedDate;
        if (this.filterEnd) {
          const endDate = fromNgbDateStructToDate(this.filterEnd);
          // check if date is valid
          if (!isNaN(endDate.getTime())) {
            endDate.setHours(23, 59, 59, 59);
            filterValue = endDate.toISOString();
          }
        }
        break;

      case 'start':
        this.filterStart = selectedDate;
        if (this.filterStart) {
          const startDate = fromNgbDateStructToDate(this.filterStart);
          // check if date is valid
          if (!isNaN(startDate.getTime())) {
            startDate.setHours(0, 0, 0, 0);
            filterValue = startDate.toISOString();
          }
        }
        break;
    }

    this.onFilterChange(filterName, filterValue);
  }

  onFilterChange(filterName: string, filterValue: any) {
    const filter = this.queryFilters.find(queryFilter => queryFilter.name === filterName);

    if (filter && filterValue) {
      // update value
      filter.value = filterValue;
    } else if (filter) {
      // unset value
      this.queryFilters.splice(this.queryFilters.lastIndexOf(filter), 1);
    } else if (filterValue) {
      // new value
      this.queryFilters.push({name: filterName, value: filterValue});
    }
    // reload the list
    this.lastId = undefined;
    this.loadDiseases();
  }

  onIsLimitQuotaReached(isLimitQuotaReached: boolean) {
    this.isLimitReached = isLimitQuotaReached;
  }

  onSearchChange(search: string) {
    this.query = search;
    this.lastId = null;
    this.loadDiseases();
  }

  toggleEmergencyCardVisible(event, disease?: Disease) {
    event.stopPropagation();
    if (disease) {
      disease.emergencyCardVisible = !disease.emergencyCardVisible;

      this.updateDisease(disease);
    } else if (this.selectedDisease) {
      this.selectedDisease.emergencyCardVisible = !this.selectedDisease.emergencyCardVisible;
      const diseaseIndex = this.diseases.findIndex(d => d.id === this.selectedDisease.id);
      this.diseases[diseaseIndex].emergencyCardVisible = this.selectedDisease.emergencyCardVisible;

      this.updateSelectedDisease();
    }
  }

  toggleFavourite(disease?: Disease) {
    if (disease) {
      disease.favourite = !disease.favourite;

      this.updateDisease(disease);
    } else if (this.selectedDisease) {
      this.selectedDisease.favourite = !this.selectedDisease.favourite;
      const diseaseIndex = this.diseases.findIndex(d => d.id === this.selectedDisease.id);
      this.diseases[diseaseIndex].favourite = this.selectedDisease.favourite;

      this.updateSelectedDisease();
    }
  }

  toggleVisibility(event, disease?: Disease) {
    event.preventDefault();
    event.stopPropagation();
    if (disease) {
      disease.visible = !disease.visible;

      this.updateDisease(disease);
    } else if (this.selectedDisease) {
      this.selectedDisease.visible = !this.selectedDisease.visible;
      const diseaseIndex = this.diseases.findIndex(d => d.id === this.selectedDisease.id);
      this.diseases[diseaseIndex].visible = this.selectedDisease.visible;
      this.updateSelectedDisease();
    }
  }

  private handleSaved(saved: boolean) {
    if (saved === true) {
      this.lastId = null;
      this.usageAlert.loadsUsage();
      this.loadDiseases();
      this.loadDiseaseDetail(this.selectedDisease.id);
    }
  }

  private loadDiseases() {
    this.diseaseService
      .listDiseases(
        this.personIdParam,
        this.query,
        null,
        this.lastId ? null : 1,
        this.pageSize,
        this.lastId,
        this.queryFilters
      )
      .subscribe(
        resp => {
          this.diseases = this.lastId ? this.diseases.concat(resp.results) : resp.results;

          this.lastId = resp.lastId;
          this.diseases = [...this.diseases];

          if (this.diseaseId) {
            this.loadDiseaseDetail(this.diseaseId);
            this.diseaseId = undefined;
          }

          this.ref.markForCheck();
        },
        e => {
          console.log(e);
          this.translatedToastrService.error('message.error.disease.list');
        }
      );
  }

  private removeItem() {
    this.diseaseService.deleteDisease(this.selectedDisease.id).subscribe(
      () => {
        this.translatedToastrService.success('message.success.disease.delete');

        this.diseases = this.diseases.filter(d => d.id !== this.selectedDisease.id);
        // this is a workaround - see https://stackoverflow.com/questions/51704888/remove-rows-with-ngx-datatable
        this.diseases = [...this.diseases];

        this.selectedDisease = null;
        this.currentModal.close();

        this.usageAlert.loadsUsage();

        this.ref.markForCheck();
      },
      () => this.translatedToastrService.error('message.error.disease.delete')
    );
  }

  private showAddEditModal(disease?: Disease) {
    setTimeout(() => {
      this.currentModal = this.modal.open(DiseaseEditComponent, {size: 'lg'});
      this.currentModal.componentInstance.personIdParam = this.personIdParam;
      this.currentModal.componentInstance.requester = this.requester;
      this.currentModal.componentInstance.disease = disease;
      if (disease) {
        this.currentModal.componentInstance.readonly = !canEdit(this.personIdParam, this.requester.id, disease.creator.id);
      }
      this.currentModal.result.then(saved => this.handleSaved(saved)).catch(e => {
      });
    }, 100);
  }

  private showDeleteModal() {
    setTimeout(() => {
      this.currentModal = this.modal.open(DeleteModalComponent, {size: 'lg'});
      this.currentModal.componentInstance.itemDescription = this.selectedDisease.notes;
      this.currentModal.componentInstance.itemHeader = this.selectedDisease.diseaseCode ?
        this.selectedDisease.diseaseCode.text + ' (' + this.selectedDisease.diseaseCode.code + ')' :
        this.selectedDisease.title;
      this.currentModal.componentInstance.itemType = this.translateService.instant('disease');
      this.currentModal.result.then(isDeleted => {
        if (isDeleted) {
          this.removeItem();
        }
      });
    }, 100);
  }

  private updateDisease(disease: Disease) {
    if (disease) {
      this.diseaseService.updateDisease(disease.id, disease).subscribe(
        () => this.translatedToastrService.success('message.success.disease.update'),
        () => this.translatedToastrService.error('message.error.disease.update')
      );
    }
  }

  private updateSelectedDisease() {
    if (this.selectedDisease) {
      this.diseaseService.updateDisease(this.selectedDisease.id, this.selectedDisease).subscribe(
        () => this.translatedToastrService.success('message.success.disease.update'),
        () => this.translatedToastrService.error('message.error.disease.update')
      );
    }
  }
}
