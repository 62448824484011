import {Component, Input, OnInit} from '@angular/core';
import {MenuItem} from '../../../+medic/medic-settings/medic-settings-menu/medic-settings-menu.component';

@Component({
  selector: 'app-patient-settings-menu',
  templateUrl: './patient-settings-menu.component.html',
  styleUrls: ['./patient-settings-menu.component.scss']
})
export class PatientSettingsMenuComponent implements OnInit {
  @Input() code: string;

  constructor() {
  }

  menuItems: MenuItem[] = [
    {
      path: '/patient/settings/person',
      code: 'person',
      text: 'Personal info',
      active: false
    }, {
      path: '/patient/settings/profile',
      code: 'profile',
      text: 'Profile data',
      active: false
    }, {
      path: '/patient/settings/permissions',
      code: 'permissions',
      text: 'Permissions',
      active: false
    }, {
      path: '/patient/settings/subscription',
      code: 'subscription',
      text: 'Subscription',
      active: false
    }, {
      path: '/patient/settings/mfa',
      code: 'mfa',
      text: 'MFA',
      active: false
    }, {
      path: '/patient/settings/password',
      code: 'password',
      text: 'Change password',
      active: false
    }, {
      path: '/patient/settings/activity-logs',
      code: 'activityLogs',
      text: 'Activity logs',
      active: false
    }, {
      path: '/patient/settings/account-deletion',
      code: 'accountDeletion',
      text: 'Account deletion',
      active: false
    }
  ];

  ngOnInit() {
    this.menuItems.forEach(e => {
      // TODO translate texts
      if (e.code === this.code) { e.active = true; }
    });
  }
}
