import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DocumentHolder} from '../../documents/document.model';
import {formatBytes, formatDate} from '../../../../lib/formats';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {EmergencyCardDocumentViewerComponent} from '../emergency-card-document-viewer/emergency-card-document-viewer.component';
import {DocumentService} from '../../documents/document.service';

@Component({
  selector: 'app-emergency-card-documents',
  templateUrl: './emergency-card-documents.component.html',
  styleUrls: ['./emergency-card-documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmergencyCardDocumentsComponent implements OnChanges, OnInit {
  @Input() code: string;
  @Input() documents: DocumentHolder[] = [];
  @Input() personIdParam: string;
  currentModal: NgbModalRef;
  formatBytes = formatBytes;

  constructor(private modal: NgbModal,
              protected ref: ChangeDetectorRef) {
  }

  ngOnInit() {
    if (this.documents.length) {
      this.documents.forEach(document => this.localizeDocument(document));
      this.ref.markForCheck();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const change = changes.documents || undefined;
    if (change) {
      this.documents.forEach(document => this.localizeDocument(document));
      this.ref.markForCheck();
    }
  }

  onViewDocument(document: DocumentHolder) {
    this.showViewDocumentModal(document);
  }

  private localizeDocument(document: DocumentHolder) {
    document.localized = { docType: '', created_at: formatDate(document.created_at), date: formatDate(document.date) };
  }

  private showViewDocumentModal(document: DocumentHolder) {
    this.currentModal = this.modal.open(EmergencyCardDocumentViewerComponent, { size: 'lg', windowClass: 'document_viewer_ec_card' });

    this.currentModal.componentInstance.code = this.code;
    this.currentModal.componentInstance.document = document;

    this.currentModal.result.then();
  }
}
