import {Injectable} from '@angular/core';
import {BackendService} from '../backend/backend.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AnyResponse, PeopleCountResponse, PersonOverviewListResponse} from '../response/response.model';
import {ToastrService} from 'ngx-toastr';
import {hasValue} from '../../../lib/validator';
import {LocaleResolverService} from '../locale/locale-resolver.service';

@Injectable()
export class AdminService extends BackendService {

    constructor(
        protected http: HttpClient,
        protected localeResolverService: LocaleResolverService,
    ) {
        super(http, localeResolverService);
    }

    // createSignedGetUrl(id: string, body) {
    //   const httpHeaders = this.buildBaseHttpHeaders();
    //   return this.http.post(this.getApiUrl() + '/people/' + id + '/signedget', body, httpHeaders);
    // }
    //
    // createPresignedPost(id: string, body) {
    //   const httpHeaders = this.buildBaseHttpHeaders();
    //   return this.http.post(this.getApiUrl() + '/people/' + id + '/documents/presignedpost', body, httpHeaders);
    // }
    //
    // postFile(url: string, formData) {
    //   return this.http.post(url, formData);
    // }
    //
    // getFile(url: string): Observable<Blob> {
    //   return this.http.get(url, {responseType: 'blob'});
    // }

    getCountPeople() {
        const httpHeaders = this.buildBaseHttpHeaders();
        return this.http.get<PeopleCountResponse>(this.getApiUrl() + '/a/people/count', {headers: httpHeaders});
    }

    disableUser(id: string) {
        const httpHeaders = this.buildBaseHttpHeaders();
        return this.http.post(this.getApiUrl() + '/a/people/' + id + '/disable', {}, {headers: httpHeaders});
    }

    enableUser(id: string) {
        const httpHeaders = this.buildBaseHttpHeaders();
        return this.http.post(this.getApiUrl() + '/a/people/' + id + '/enable', {}, {headers: httpHeaders});
    }

    clearName(id: string) {
        const httpHeaders = this.buildBaseHttpHeaders();
        return this.http.post(this.getApiUrl() + '/a/people/' + id + '/clearName', {}, {headers: httpHeaders});
    }

    obtainEnrollmentCode(userName: string) {
        const httpHeaders = this.buildBaseHttpHeaders();
        return this.http.post<AnyResponse>(this.getApiUrl() + '/a/enrollmentCode/', {userName: userName}, {headers: httpHeaders});
    }

    listPeopleOverview(id: string, q: string, page: number, pageSize: number) {
        const httpHeaders = this.buildBaseHttpHeaders();

        let params = new HttpParams();
        if (page !== undefined && page !== null) {
            params = params.append('page', page.toString());
        }
        if (pageSize !== undefined && pageSize !== null) {
            params = params.append('pageSize', pageSize.toString());
        }
        if (q !== undefined && q !== null) {
            params = params.append('q', q.toString());
        }
        if (hasValue(id)) {
            params = params.append('id', id.toString());
        }

        return this.http.get<PersonOverviewListResponse>(this.getApiUrl() + '/a/people',
            (params.keys().length > 0) ? {headers: httpHeaders, params: params} : {headers: httpHeaders});
    }

    setUserSubscription(personId: string, planCode, endDate: Date) {
        const httpHeaders = this.buildBaseHttpHeaders();
        return this.http.put(this.getApiUrl() + '/a/people/' + personId + '/subscription', {planCode: planCode, endDate: endDate}, {headers: httpHeaders});
    }
}
