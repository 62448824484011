import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {OnboardingService} from '../../../core/onboarding/onboarding.service';
import {Router} from '@angular/router';
import {Profile} from '../../../core/profile/profile.model';
import {ProfileService} from '../../../core/profile/profile.service';
import {NavigationService} from '../../../core/navigation/navigation.service';
import {TranslatedToastrService} from '../../../core/translated-toastr/translated-toastr.service';

@Component({
  selector: 'app-patient-onboarding-health',
  templateUrl: './patient-onboarding-health.component.html',
  styleUrls: ['./patient-onboarding-health.component.scss']
})
export class PatientOnboardingHealthComponent implements OnInit {
  isLoaded = false;
  profile: Profile;

  constructor(
    private fb: FormBuilder,
    private navigationService: NavigationService,
    private onboardingService: OnboardingService,
    private profileService: ProfileService,
    private router: Router,
    private translatedToastrService: TranslatedToastrService
  ) {
  }

  ngOnInit() {
    this.checkOnboarding();
  }

  onProfileChange(profile: Profile) {
    this.profile = profile;
  }

  onSaveProfile() {
    this.onboardingService.postOnboardingHealth(this.profile).subscribe(
      () => {
        this.translatedToastrService.success('message.success.save');
        this.navigationService.navigateToPatientOnboardingMultiFactorAuthentication();
      },
      () => this.translatedToastrService.error('message.error.save')
    );
  }

  private checkOnboarding() {
    this.onboardingService.getPatientOnboardingStage().subscribe(
      data => {
        const currentOnboardingUrl = this.router.routerState.snapshot;
        const onboardingUrl = '/onboarding/' + data.result.onboardingStage;
        if (currentOnboardingUrl.url !== onboardingUrl) {
          this.router.navigate([onboardingUrl]);
        } else {
          this.initOnboardingHealth();
        }
      }
    );
  }

  private initOnboardingHealth() {
    this.onboardingService.getOnboardingHealth().subscribe(data => {
      this.profile = data.result;
      this.isLoaded = true;
    });
  }
}
