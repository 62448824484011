import {RouteInfo} from './route-info.model';

export const PATIENT_ROUTE_INFOS: RouteInfo[] = [
    {
        module: 'dashboard',
        code: 'dashboard.title',
        path: '/dashboard',
        title: 'Dashboard',
        icon: 'icon-clipboard-pencil',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        isAdmin: false,
        isMedic: false,
        isPatient: true,
        submenu: []
    },
    {
        module: 'health',
        code: 'health.title',
        path: '/people/me/health',
        title: 'My Health',
        icon: 'icon-heart-pulse',
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        isAdmin: false,
        isMedic: false,
        isPatient: true,
        submenu: [
            {
                module: 'health',
                code: 'health.diseases.title',
                path: '/people/me/health/diseases',
                title: 'Diseases/Diagnosis',
                icon: 'icon-list3',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                isAdmin: false,
                isMedic: false,
                isPatient: true
            },
            {
                module: 'health',
                code: 'health.operations.title',
                path: '/people/me/health/operations',
                title: 'Operations/Treatment',
                icon: 'icon-bandages',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                isAdmin: false,
                isMedic: false,
                isPatient: true
            },
            {
                module: 'health',
                code: 'health.medications.title',
                path: '/people/me/health/medications',
                title: 'My Medications',
                icon: 'icon-pills',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                isAdmin: false,
                isMedic: false,
                isPatient: true
            },
            {
                module: 'health',
                code: 'health.immunisations.title',
                path: '/people/me/health/immunisations',
                title: 'My Immunisations',
                icon: 'icon-syringe',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                isAdmin: false,
                isMedic: false,
                isPatient: true
            },
            {
                module: 'health',
                code: 'health.allergies.title',
                path: '/people/me/health/allergies',
                title: 'My Allergies',
                icon: 'icon-lotus',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                isAdmin: false,
                isMedic: false,
                isPatient: true
            },
            {
                module: 'health',
                code: 'health.familyDiseases.title',
                path: '/people/me/health/family-diseases',
                title: 'Family diseases',
                icon: 'icon-dna',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                isAdmin: false,
                isMedic: false,
                isPatient: true
            }
        ]
    },
    {
        module: 'documents',
        code: 'documents.title',
        path: '/people/me/documents',
        title: 'Documents',
        icon: 'icon-files',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        isAdmin: false,
        isMedic: false,
        isPatient: true,
        submenu: []
    },
    {
        module: 'journal',
        code: 'journal.title',
        path: '/people/me/measurements',
        title: 'Journal',
        icon: 'icon-chart-growth',
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        isAdmin: false,
        isMedic: false,
        isPatient: true,
        submenu: [
          // Gewicht, Puls, Blutdruck, Blutzucker, Stress, Stimmung, Schlafqualität, Schmerzen, Temperatur, SpO2
            {
                module: 'journal',
                code: 'journal.weight.title',
                path: '/people/me/measurements/weight',
                title: 'Weight',
                icon: 'icon-weight',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                isAdmin: false,
                isMedic: false,
                isPatient: true
            },
            {
                module: 'journal',
                code: 'journal.heartRate.title',
                path: '/people/me/measurements/heart-rate',
                title: 'Heart rate',
                icon: 'icon-pulse',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                isAdmin: false,
                isMedic: false,
                isPatient: true
            },
            {
                module: 'journal',
                code: 'journal.bloodPressure.title',
                path: '/people/me/measurements/blood-pressure',
                title: 'Blood pressure',
                icon: 'icon-blood-pressure',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                isAdmin: false,
                isMedic: false,
                isPatient: true
            },
            {
                module: 'journal',
                code: 'journal.bloodGlucose.title',
                path: '/people/me/measurements/blood-glucose',
                title: 'Blood glucose',
                icon: 'icon-drop',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                isAdmin: false,
                isMedic: false,
                isPatient: true
            },
            {
                module: 'journal',
                code: 'journal.bodyTemperature.title',
                path: '/people/me/measurements/body-temperature',
                title: 'Temperature',
                icon: 'icon-thermometer',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                isAdmin: false,
                isMedic: false,
                isPatient: true
            },
            {
                module: 'journal',
                code: 'journal.stress.title',
                path: '/people/me/measurements/stress',
                title: 'Stress',
                icon: 'icon-alarm',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                isAdmin: false,
                isMedic: false,
                isPatient: true
            },
            {
                module: 'journal',
                code: 'journal.feeling.title',
                path: '/people/me/measurements/feeling',
                title: 'Feeling',
                icon: 'icon-wink',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                isAdmin: false,
                isMedic: false,
                isPatient: true
            },
            {
                module: 'journal',
                code: 'journal.pain.title',
                path: '/people/me/measurements/pain',
                title: 'Pain',
                icon: 'icon-power',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                isAdmin: false,
                isMedic: false,
                isPatient: true
            },
            {
                module: 'journal',
                code: 'journal.sleep.title',
                path: '/people/me/measurements/sleep',
                title: 'Sleep Quality',
                icon: 'icon-bed',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                isAdmin: false,
                isMedic: false,
                isPatient: true
            },
            {
                module: 'journal',
                code: 'journal.spo2.title',
                path: '/people/me/measurements/spo2',
                title: 'SPO2',
                icon: 'icon-leaf',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                isAdmin: false,
                isMedic: false,
                isPatient: true
            }
        ]
    },
    {
        module: 'doctors',
        code: 'myDoctors.title',
        path: '/people/me/medics',
        title: 'My doctors',
        icon: 'icon-users2',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        isAdmin: false,
        isMedic: false,
        isPatient: true,
        submenu: []
    },
    {
        module: 'emergency-card',
        code: 'emergencyPass.title',
        path: '/people/me/emergency-pass',
        title: 'Emergency Card',
        icon: 'icon-first-aid',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        isAdmin: false,
        isMedic: false,
        isPatient: true,
        submenu: []
    },
];
