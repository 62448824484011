import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from 'src/app/base/base-component';
import {PainService} from '../../../core/measurements/pain.service';
import {ActivatedRoute} from '@angular/router';
import {ProfileService} from 'src/app/+modules/core/profile/profile.service';
import {LocaleResolverService} from '../../../core/locale/locale-resolver.service';
import {NgbDateParserFormatter, NgbDateStruct, NgbModal, NgbModalRef, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import {Pain} from '../../../core/measurements/pain.model';
import {Person} from '../../../core/person/person.model';
import {isMe} from 'src/app/lib/utils';
import * as moment from 'moment-timezone';
import {PainChartComponent} from './pain-chart/pain-chart.component';
import {forkJoin} from 'rxjs';
import {take} from 'rxjs/operators';
import {PainEnum} from '../../../shared/enums/pain.enum';
import {UsageTypeEnum} from '../../../shared/enums/usage-type.enum';
import {EmergencyPassService} from '../../emergency-card/emergency-pass.service';
import {JournalService} from '../../../core/measurements/journal.service';
import {EmergencyPassConfig} from '../../emergency-card/emergency-card-config.model';
import {JournalConfig} from '../../../core/measurements/journal-config.model';
import {NgbDateDynamicParserFormatter} from '../../../../lib/ngb-date-dynamic-parser-formatter';
import {DeleteModalComponent} from '../../../shared/delete-modal/delete-modal.component';
import {TranslateService} from '@ngx-translate/core';
import {TranslatedToastrService} from '../../../core/translated-toastr/translated-toastr.service';

@Component({
  selector: 'app-pain',
  templateUrl: './pain.component.html',
  styleUrls: ['./pain.component.scss'],
  providers: [{provide: NgbDateParserFormatter, useClass: NgbDateDynamicParserFormatter}]
})
export class PainComponent extends BaseComponent implements OnInit {
  @ViewChild('chart', { static: true }) chart: PainChartComponent;
  count: number;
  currentModal: NgbModalRef;
  date: NgbDateStruct;
  emergencyCardConfig: EmergencyPassConfig;
  isSubmitted = false;
  isValid = true;
  journalConfig: JournalConfig;
  pageNumber: number;
  pageSize = 30;
  pain: Pain;
  pains: Pain[];
  painKeys = [
    {label: 'codes.pain.' + PainEnum.EXCELLENT, value: PainEnum.EXCELLENT},
    {label: 'codes.pain.' + PainEnum.VERY_GOOD, value: PainEnum.VERY_GOOD},
    {label: 'codes.pain.' + PainEnum.GOOD, value: PainEnum.GOOD},
    {label: 'codes.pain.' + PainEnum.SLIGHTLY_GOOD, value: PainEnum.SLIGHTLY_GOOD},
    {label: 'codes.pain.' + PainEnum.ABOVE_AVERAGE, value: PainEnum.ABOVE_AVERAGE},
    {label: 'codes.pain.' + PainEnum.AVERAGE, value: PainEnum.AVERAGE},
    {label: 'codes.pain.' + PainEnum.BELOW_AVERAGE, value: PainEnum.BELOW_AVERAGE},
    {label: 'codes.pain.' + PainEnum.SLIGHTLY_BAD, value: PainEnum.SLIGHTLY_BAD},
    {label: 'codes.pain.' + PainEnum.BAD, value: PainEnum.BAD},
    {label: 'codes.pain.' + PainEnum.VERY_BAD, value: PainEnum.VERY_BAD},
    {label: 'codes.pain.' + PainEnum.TERRIBLE, value: PainEnum.TERRIBLE},
  ];
  personIdParam: string;
  readonly = false;
  requestedPerson: Person;
  time: NgbTimeStruct;
  UsageTypeEnum = UsageTypeEnum;

  constructor(
    private emergencyPassService: EmergencyPassService,
    private journalService: JournalService,
    private painService: PainService,
    private modal: NgbModal,
    private route: ActivatedRoute,
    private translatedToastrService: TranslatedToastrService,
    private translateService: TranslateService,
    protected profileService: ProfileService,
    protected localeResolverService: LocaleResolverService,
    protected ref: ChangeDetectorRef
  ) {
    super(profileService, localeResolverService, ref);
  }

  ngOnInit() {
    super.ngOnInit();

    this.pain = new Pain();
    this.initDate();

    const routeParams$ = this.route.params.pipe(take(1));

    forkJoin([routeParams$]).subscribe(respList => {
      this.personIdParam = respList[0]['id'];
      this.profileService.setCurrentPersonIdParam(this.personIdParam);

      if (this.personIdParam === 'me') {
        this.emergencyPassService.getMyEmergencyPassConfig().subscribe(r => {
          this.emergencyCardConfig = r.result;
          this.ref.markForCheck();
        });
        this.journalService.getMyJournalConfig().subscribe(r => {
          this.journalConfig = r.result;
          this.ref.markForCheck();
        });
      }

      this.loadData();

      if (this.personIdParam !== 'me') {
        this.profileService
          .getProfile(this.personIdParam)
          .subscribe(resp => {
            this.requestedPerson = resp.result;
            this.ref.markForCheck();
          });
      }
    });
  }

  private loadData() {
    this.initDate();
    this.setPage({ offset: 0 });
  }

  private initDate() {
    const now = moment();
    this.time = { hour: now.hour(), minute: now.minute(), second: 0 };
    this.date = { day: now.date(), month: (now.month() + 1), year: now.year() };
  }

  askToRemove(index: number, pain: Pain) {
    this.showDeleteModal(index, pain);
  }

  handleValid(valid) {
    this.isValid = valid;
  }

  onIsLimitQuotaReached(limitQuotaReached) {
    this.readonly = limitQuotaReached;
    this.ref.markForCheck();
  }

  isMe() {
    return isMe(this.personIdParam, this.requester ? this.requester.id : null);
  }

  // https://github.com/swimlane/ngx-datatable/issues/721
  onActive(event) {
    if (event.type === 'click') {
      event.cellElement.blur();
    }
  }

  onEmergencyCardVisibleChange() {
    this.emergencyPassService.updateMyEmergencyPassConfig(this.emergencyCardConfig).subscribe(
      () => this.translatedToastrService.success('message.success.save'),
      () => this.translatedToastrService.error('message.error.save')
    );
  }

  onVisibleChange() {
    this.journalService.updateMyJournalConfig(this.journalConfig).subscribe(
      () => this.translatedToastrService.success('message.success.save'),
      () => this.translatedToastrService.error('message.error.save')
    );
  }

  setPage(pageInfo) {
    this.pageNumber = pageInfo.offset;
    this.painService.listPains(this.personIdParam, this.pageNumber + 1, this.pageSize, null, null, null, 'desc')
      .subscribe(r => {
        this.pains = r.results;
        this.count = r.count;
        this.ref.markForCheck();
      });
  }

  showDeleteModal(index: number, pain: Pain) {
    setTimeout(() => {
      this.currentModal = this.modal.open(DeleteModalComponent, {size: 'lg'});
      this.currentModal.componentInstance.itemHeader = this.formatMonthDayYear(pain.date);
      this.currentModal.componentInstance.itemType = this.translateService.instant('journal.pain.title');
      this.currentModal.componentInstance.itemValue = this.translateService.instant('codes.pain.' + pain.value);
      this.currentModal.result.then(isDeleted => {
        if (isDeleted) {
          this.removeRow(index, pain);
        }
      });
    }, 100);
  }

  removeRow(index: number, pain: Pain) {
    this.painService.delete(pain.id).subscribe(() => {
      this.pains.splice(index, 1);
      this.pains = [...this.pains];
      this.chart.fetchValues();

      this.ref.markForCheck();
    }, () =>
      this.translatedToastrService.error('message.error.delete'));
  }

  validValues() {
    return this.pain.value != null;
  }

  save() {
    this.isSubmitted = true;
    if (this.isValid && this.validValues()) {
      const date = moment(
        this.date.year + '-' +
        this.date.month + '-' +
        this.date.day + ' ' +
        this.time.hour + ':' +
        this.time.minute
        , 'YYYY-MM-DD HH:mm');

      this.pain.date = date.toDate();
      this.painService.create(this.pain)
        .subscribe(() => {
          this.translatedToastrService.success('message.success.save');
          this.pain = new Pain();

          this.loadData();
          this.chart.fetchValues();
          this.ref.markForCheck();
        },
          () => this.translatedToastrService.error('message.error.save')
        );
    }
  }
}
