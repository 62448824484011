import {SubsPlan} from '../subs-plan/subs-plan.model';

export class Subscription {
  currentPlan: SubsPlan;
  currentPlanCode: number;
  end: Date;
  paymentMethod: number;
  personId: number;
  plan: SubsPlan;
  planCode: number;
  renewalDate: Date;
  renewalPeriod: number;
  renewalType: number;
  start: Date;
  state: number;
  status: string;

  localized: {
    start: string;
    end: string;
    renewalDate: string;
    renewalPeriod: string;
    renewalType: string;
    paymentMethod: string;
  };
}

export class SubscriptionAgreement {
  approvalUrl: string;
}
