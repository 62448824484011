import {RouteInfo} from './route-info.model';

export const MEDIC_ROUTE_INFOS: RouteInfo[] = [
    {
        module: 'dashboard',
        code: 'dashboard.title',
        path: '/medic/dashboard',
        title: 'Dashboard',
        icon: 'icon-clipboard-pencil',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        isAdmin: false,
        isMedic: true,
        isPatient: false,
        submenu: []
    },
    {
        module: 'patients',
        code: 'myPatients',
        path: '/medic/patients',
        title: 'Patient Data',
        icon: 'icon-users2',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        isAdmin: false,
        isMedic: true,
        isPatient: false,
        submenu: [
            {
                module: 'dashboard',
                code: 'dashboard.title',
                path: '/medic/patients/:id/dashboard',
                title: 'Dashboard',
                icon: 'icon-clipboard-pencil',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                isAdmin: false,
                isMedic: false,
                isPatient: true,
                submenu: []
            },
            {
                module: 'health',
                code: 'health.title',
                path: '/medic/patients/:id/health',
                title: 'My Health',
                icon: 'icon-heart-pulse',
                class: 'has-sub',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                isAdmin: false,
                isMedic: false,
                isPatient: true,
                submenu: [
                    {
                        module: 'health',
                        code: 'health.diseases.title',
                        path: '/medic/patients/:id/health/diseases',
                        title: 'Diseases/Diagnosis',
                        icon: 'icon-list3',
                        class: '',
                        badge: '',
                        badgeClass: '',
                        isExternalLink: false,
                        submenu: [],
                        isAdmin: false,
                        isMedic: false,
                        isPatient: true
                    },
                    {
                        module: 'health',
                        code: 'health.operations.title',
                        path: '/medic/patients/:id/health/operations',
                        title: 'Operations/Treatment',
                        icon: 'icon-bandages',
                        class: '',
                        badge: '',
                        badgeClass: '',
                        isExternalLink: false,
                        submenu: [],
                        isAdmin: false,
                        isMedic: false,
                        isPatient: true
                    },
                    {
                        module: 'health',
                        code: 'health.medications.title',
                        path: '/medic/patients/:id/health/medications',
                        title: 'My Medications',
                        icon: 'icon-pills',
                        class: '',
                        badge: '',
                        badgeClass: '',
                        isExternalLink: false,
                        submenu: [],
                        isAdmin: false,
                        isMedic: false,
                        isPatient: true
                    },
                    {
                        module: 'health',
                        code: 'health.allergies.title',
                        path: '/medic/patients/:id/health/allergies',
                        title: 'My Allergies',
                        icon: 'icon-syringe',
                        class: '',
                        badge: '',
                        badgeClass: '',
                        isExternalLink: false,
                        submenu: [],
                        isAdmin: false,
                        isMedic: false,
                        isPatient: true
                    },
                    {
                        module: 'health',
                        code: 'health.immunisations.title',
                        path: '/medic/patients/:id/health/immunisations',
                        title: 'My Immunisations',
                        icon: 'icon-lotus',
                        class: '',
                        badge: '',
                        badgeClass: '',
                        isExternalLink: false,
                        submenu: [],
                        isAdmin: false,
                        isMedic: false,
                        isPatient: true
                    },
                    {
                        module: 'health',
                        code: 'health.familyDiseases.title',
                        path: '/medic/patients/:id/health/family-diseases',
                        title: 'Family diseases',
                        icon: 'icon-dna',
                        class: '',
                        badge: '',
                        badgeClass: '',
                        isExternalLink: false,
                        submenu: [],
                        isAdmin: false,
                        isMedic: false,
                        isPatient: true
                    }
                ]
            },
            {
                module: 'documents',
                code: 'documents.title',
                path: '/medic/patients/:id/documents',
                title: 'Documents',
                icon: 'icon-files',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                isAdmin: false,
                isMedic: false,
                isPatient: true,
                submenu: []
            },
            {
                module: 'journal',
                code: 'journal.title',
                path: '/medic/patients/:id/measurements',
                title: 'Journal',
                icon: 'icon-chart-growth',
                class: 'has-sub',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                isAdmin: false,
                isMedic: false,
                isPatient: true,
                submenu: [
                    {
                        module: 'journal',
                        code: 'journal.weight.title',
                        path: '/medic/patients/:id/measurements/weight',
                        title: 'Weight',
                        icon: 'icon-weight',
                        class: '',
                        badge: '',
                        badgeClass: '',
                        isExternalLink: false,
                        submenu: [],
                        isAdmin: false,
                        isMedic: false,
                        isPatient: true
                    },
                    {
                        module: 'journal',
                        code: 'journal.heartRate.title',
                        path: '/medic/patients/:id/measurements/heart-rate',
                        title: 'Heart rate',
                        icon: 'icon-pulse',
                        class: '',
                        badge: '',
                        badgeClass: '',
                        isExternalLink: false,
                        submenu: [],
                        isAdmin: false,
                        isMedic: false,
                        isPatient: true
                    },
                    {
                        module: 'journal',
                        code: 'journal.bloodPressure.title',
                        path: '/medic/patients/:id/measurements/blood-pressure',
                        title: 'Blood pressure',
                        icon: 'icon-blood-pressure',
                        class: '',
                        badge: '',
                        badgeClass: '',
                        isExternalLink: false,
                        submenu: [],
                        isAdmin: false,
                        isMedic: false,
                        isPatient: true
                    },
                    {
                        module: 'journal',
                        code: 'journal.bloodGlucose.title',
                        path: '/medic/patients/:id/measurements/blood-glucose',
                        title: 'Blood glucose',
                        icon: 'icon-drop',
                        class: '',
                        badge: '',
                        badgeClass: '',
                        isExternalLink: false,
                        submenu: [],
                        isAdmin: false,
                        isMedic: false,
                        isPatient: true
                    },
                    {
                        module: 'journal',
                        code: 'journal.bodyTemperature.title',
                        path: '/medic/patients/:id/measurements/body-temperature',
                        title: 'Temperature',
                        icon: 'icon-thermometer',
                        class: '',
                        badge: '',
                        badgeClass: '',
                        isExternalLink: false,
                        submenu: [],
                        isAdmin: false,
                        isMedic: false,
                        isPatient: true
                    },
                    {
                        module: 'journal',
                        code: 'journal.stress.title',
                        path: '/medic/patients/:id/measurements/stress',
                        title: 'Stress',
                        icon: 'icon-alarm',
                        class: '',
                        badge: '',
                        badgeClass: '',
                        isExternalLink: false,
                        submenu: [],
                        isAdmin: false,
                        isMedic: false,
                        isPatient: true
                    },
                    {
                        module: 'journal',
                        code: 'journal.feeling.title',
                        path: '/medic/patients/:id/measurements/feeling',
                        title: 'Feeling',
                        icon: 'icon-wink',
                        class: '',
                        badge: '',
                        badgeClass: '',
                        isExternalLink: false,
                        submenu: [],
                        isAdmin: false,
                        isMedic: false,
                        isPatient: true
                    },
                    {
                        module: 'journal',
                        code: 'journal.pain.title',
                        path: '/medic/patients/:id/measurements/pain',
                        title: 'Pain',
                        icon: 'icon-power',
                        class: '',
                        badge: '',
                        badgeClass: '',
                        isExternalLink: false,
                        submenu: [],
                        isAdmin: false,
                        isMedic: false,
                        isPatient: true
                    },
                    {
                        module: 'journal',
                        code: 'journal.sleep.title',
                        path: '/medic/patients/:id/measurements/sleep',
                        title: 'Sleep Quality',
                        icon: 'icon-bed',
                        class: '',
                        badge: '',
                        badgeClass: '',
                        isExternalLink: false,
                        submenu: [],
                        isAdmin: false,
                        isMedic: false,
                        isPatient: true
                    },
                    {
                        module: 'journal',
                        code: 'journal.spo2.title',
                        path: '/medic/patients/:id/measurements/spo2',
                        title: 'SPO2',
                        icon: 'icon-leaf',
                        class: '',
                        badge: '',
                        badgeClass: '',
                        isExternalLink: false,
                        submenu: [],
                        isAdmin: false,
                        isMedic: false,
                        isPatient: true
                    }
                ]
            },
            /*{
                module: 'doctors',
                code: 'myDoctors.title',
                path: '/medic/patients/:id/medics',
                title: 'My doctors',
                icon: 'icon-users2',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                isAdmin: false,
                isMed: false,
                isPat: true,
                submenu: []
            },*/
            {
                module: 'emergency-card',
                code: 'emergencyPass.title',
                path: '/medic/patients/:id/emergency-pass',
                title: 'Emergency Card',
                icon: 'icon-first-aid',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                isAdmin: false,
                isMedic: false,
                isPatient: true,
                submenu: []
            }
        ]
    }
];
