import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FamilyDisease} from '../../../core/health/family-disease.model';
import {FamilyDiseaseService} from '../../../core/health/family-disease.service';
import {LocaleResolverService} from '../../../core/locale/locale-resolver.service';
import {BaseComponent} from '../../../../base/base-component';
import {ProfileService} from '../../../core/profile/profile.service';
import {Router} from '@angular/router';
import {UsageTypeEnum} from '../../../shared/enums/usage-type.enum';
import {hasValue} from '../../../../lib/validator';
import {FamilyDiseaseEditComponent} from '../../health/family-diseases/family-disease-edit/family-disease-edit.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {UsageService} from '../../../core/usage/usage.service';
import {UsageItem} from '../../../core/usage/usage.model';

@Component({
  selector: 'app-patient-dashboard-family-diseases',
  templateUrl: './patient-dashboard-family-diseases.component.html',
  styleUrls: ['./patient-dashboard-family-diseases.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatientDashboardFamilyDiseasesComponent extends BaseComponent implements OnInit {
  @Input() isVisible = true;
  @Input() personIdParam: string;
  currentModal: NgbModalRef;
  familyDiseases: FamilyDisease[] = [];
  isAddActive = true;
  titleLink = '';
  total = 0;
  usageItem: UsageItem;
  private pageSize = 3;

  constructor(private familyDiseaseService: FamilyDiseaseService,
              private modal: NgbModal,
              private router: Router,
              private usageService: UsageService,
              protected profileService: ProfileService,
              protected localeResolverService: LocaleResolverService,
              protected ref: ChangeDetectorRef) {
    super(profileService, localeResolverService, ref);
  }

  ngOnInit() {
    super.ngOnInit();
    this.titleLink = ((this.personIdParam === 'me') ? '/people/' : '/medic/patients/') + this.personIdParam + '/health/family-diseases';
    this.loadFamilyDiseases();
    this.loadFamilyDiseaseLimits();
  }

  onAddChange() {
    this.showAddModal();
  }

  onClick(id: number) {
    const url = this.titleLink + '/' + id + '/view';
    this.router.navigate([url]);
  }

  private handleSaved(saved: boolean) {
    if (saved === true) {
      this.loadFamilyDiseases();
      this.loadFamilyDiseaseLimits();
    }
  }

  private loadFamilyDiseases() {
    this.familyDiseaseService
      .listFamilyDiseases(this.personIdParam, null, null, 1, this.pageSize, null)
      .subscribe(
        resp => {
          this.total = resp.count;
          this.familyDiseases = resp.results;
          this.familyDiseases.forEach(familyDisease => {
            familyDisease.localized = { created_at: this.formatDate(familyDisease.created_at), relative: '' };
          });
          this.ref.markForCheck();
        },
        e => {
          console.log(e);
        }
      );
  }

  private loadFamilyDiseaseLimits() {
    this.usageService.getUsageItem(this.personIdParam, UsageTypeEnum.FAMILY_DISEASES).subscribe(res => {
      this.usageItem = res.result;
      if (this.usageItem) {
        // Limit Reached
        if (hasValue(this.usageItem.value) && hasValue(this.usageItem.limit) && this.usageItem.value >= this.usageItem.limit) {
          this.isAddActive = false;
        }

        // Insufficient Storage
        if (hasValue(this.usageItem.quota) && hasValue(this.usageItem.sumSize) && this.usageItem.sumSize >= this.usageItem.quota) {
          this.isAddActive = false;
        }

        this.ref.markForCheck();
      }
    });
  }

  private showAddModal() {
    setTimeout(() => {
      this.currentModal = this.modal.open(FamilyDiseaseEditComponent, {size: 'lg'});
      this.currentModal.componentInstance.personIdParam = this.personIdParam;
      this.currentModal.componentInstance.requester = this.requester;
      this.currentModal.result.then(saved => this.handleSaved(saved)).catch();
    }, 100);
  }
}
