import {Component, OnInit} from '@angular/core';
import {take} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {BillingService} from '../../../../core/billing/billing.service';
import {PaypalAgreement} from './paypal-agreement.model';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-paypal-agreement',
  templateUrl: './paypal-agreement.component.html',
  styleUrls: ['./paypal-agreement.component.scss']
})
export class PaypalAgreementComponent implements OnInit {


  success: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private billingService: BillingService
  ) { }

  ngOnInit() {
    const routeParams$ = this.route.queryParams.pipe(take(1));

    routeParams$.subscribe(params => {

      const agreement = new PaypalAgreement();
      agreement.token = params['token'];

      this.billingService
        .executePaypalAgreement(agreement)
        .subscribe(r => {

          this.toastr.success('Payment method updated', 'Success');

          this.router.navigate(['/subscription/my']);

        }, () =>  this.toastr.error('Could not execute billing agreement', 'Error'));
    });
  }

}
