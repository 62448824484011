import {Component, OnInit} from '@angular/core';
import {NavigationService} from '../../../core/navigation/navigation.service';

@Component({
  selector: 'app-patient-registration-success',
  templateUrl: './patient-registration-success.component.html',
  styleUrls: ['./patient-registration-success.component.scss']
})
export class PatientRegistrationSuccessComponent implements OnInit {

  constructor(
    private navigationService: NavigationService,
  ) { }

  ngOnInit() {
    // TODO get code, enable user, login user
  }

  onClickContinue() {
    this.navigationService.navigateToLogin();
  }
}
