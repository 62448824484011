import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LocaleResolverService} from '../../../core/locale/locale-resolver.service';
import * as moment from 'moment-timezone';

@Component({
    selector: 'app-language-flags',
    templateUrl: './language-flags.component.html',
    styleUrls: ['./language-flags.component.scss']
})
export class LanguageFlagsComponent implements OnInit {

    locale: string;

    constructor(
        private translateService: TranslateService,
        private localeResolverService: LocaleResolverService
    ) {
    }

    ngOnInit() {
        this.locale = this.localeResolverService.obtainLocale();
    }

    // TODO extract me
    setLocale(locale: string) {
        this.locale = locale;
        this.translateService.use(locale);
        moment.locale(locale);
        this.localeResolverService.setLocale(locale);
    }

}
