import {Injectable} from '@angular/core';
import {BackendService} from '../backend/backend.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {WeightListResponse} from '../response/response.model';
import {ToastrService} from 'ngx-toastr';
import {Weight} from './weight.model';
import {LocaleResolverService} from '../locale/locale-resolver.service';

@Injectable()
export class WeightService extends BackendService {

    constructor(
        protected http: HttpClient,
        protected localeResolverService: LocaleResolverService,
    ) {
        super(http, localeResolverService);
    }

    create(weight: Weight) {
        const httpOptions = this.createHttpOptions();
        return this.http.post(this.getApiUrl() + '/measurements/weights', weight, httpOptions);
    }

    listWeights(id: string, page: number, pageSize: number, lastId: number, start: string, end: string, sort = 'asc') {
        const httpHeaders = this.buildBaseHttpHeaders();

        let params = new HttpParams();
        if (page !== undefined && page !== null) {
            params = params.append('page', page.toString());
        }
        if (pageSize !== undefined && pageSize !== null) {
            params = params.append('pageSize', pageSize.toString());
        }
        if (lastId !== undefined && lastId !== null) {
            params = params.append('lastId', lastId.toString());
        }
        if (start !== undefined && start !== null) {
            params = params.append('start', encodeURIComponent(start.toString()));
        }
        if (end !== undefined && end !== null) {
            params = params.append('end', encodeURIComponent(end.toString()));
        }
        if (sort !== undefined && sort !== null) {
            params = params.append('sort', encodeURIComponent(sort.toString()));
        }

        return this.http.get<WeightListResponse>(this.getApiUrl() + '/people/' + id + '/measurements/weights', (params.keys().length > 0) ? {
            headers: httpHeaders,
            params: params
        } : {headers: httpHeaders});
    }

    listWeightStats(id: string, start: string, end: string) {
        const httpHeaders = this.createHttpHeaders();

        let params = new HttpParams();
        if (start !== undefined && start !== null) {
            params = params.append('start', encodeURIComponent(start.toString()));
        }
        if (end !== undefined && end !== null) {
            params = params.append('end', encodeURIComponent(end.toString()));
        }

        return this.http.get<WeightListResponse>(this.getApiUrl() + '/people/' + id + '/measurements/weightStats', (params.keys().length > 0) ? {
            headers: httpHeaders,
            params: params
        } : {headers: httpHeaders});
    }

    loadLatestWeight(personIdParam: string) {
        return this.listWeights(personIdParam, 1, 1, null, null, null, 'desc');
    }

    delete(id: number) {
        const httpOptions = this.createHttpOptions();
        return this.http.delete(this.getApiUrl() + '/measurements/weights/' + id, httpOptions);
    }
}
