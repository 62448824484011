import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {OnboardingService} from '../../../core/onboarding/onboarding.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MultiFactorAuthentication} from '../../../core/multi-factor-authentication/multi-factor-authentication.model';
import {AuthenticationService} from '../../../core/authentication/authentication.service';
import {hasValue} from '../../../../lib/validator';
import {NavigationService} from '../../../core/navigation/navigation.service';
import {TranslatedToastrService} from '../../../core/translated-toastr/translated-toastr.service';

@Component({
  selector: 'app-patient-onboarding-mfa',
  templateUrl: './patient-onboarding-mfa.component.html',
  styleUrls: ['./patient-onboarding-mfa.component.scss']
})
export class PatientOnboardingMfaComponent implements OnInit {
  isLoaded = false;
  isSubmitted = false;
  mfaFormGroup: FormGroup;
  mfaModel: MultiFactorAuthentication;
  urlGoogleAuthenticator = 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2';

  constructor(
    private authenticationService: AuthenticationService,
    private fb: FormBuilder,
    private navigationService: NavigationService,
    private onboardingService: OnboardingService,
    private router: Router,
    private translatedToastrService: TranslatedToastrService
  ) { }

  ngOnInit() {
    this.checkOnboarding();
  }

  onClickDisableMultiFactorAuthentication() {
    // Step 2 (Disable): mfaEnabled = false, mfaSecret = null
    this.disableMultiFactorAuthentication();
  }

  onClickEnableMultiFactorAuthentication() {
    // Step 2 (Enable): mfaEnabled = true
    this.isSubmitted = true;
    if (this.mfaFormGroup.valid) {
      this.enableMultiFactorAuthentication();
    }
  }

  private checkOnboarding() {
    this.onboardingService.getPatientOnboardingStage().subscribe(
      data => {
        const currentOnboardingUrl = this.router.routerState.snapshot;
        const onboardingUrl = '/onboarding/' + data.result.onboardingStage;
        if (currentOnboardingUrl.url !== onboardingUrl) {
          this.router.navigate([onboardingUrl]);
        } else {
          this.initOnboardingMultiFactorAuthentication();
        }
      }
    );
  }

  private createMultiFactorAuthenticationUrl() {
    this.authenticationService.createTimeBasedOneTimePassword().subscribe(
      data => {
        this.mfaModel = data.result;
        this.isLoaded = true;
      },
      err => this.translatedToastrService.error(err.message, err.title)
    );
  }

  private initOnboardingMultiFactorAuthentication() {
    this.mfaFormGroup = this.fb.group({
      token: ['', Validators.required]
    });

    // Step1 (Enable & Disable): mfaEnabled = false, mfaSecret = <otpAuthUrl>
    this.getMultiFactorAuthentication();
  }

  private disableMultiFactorAuthentication() {
    this.authenticationService.removeTimeBasedOneTimePassword().subscribe(
      () => this.navigationService.navigateToPatientOnboardingFinished(),
      err => this.translatedToastrService.error(err.message, err.title)
    );
  }

  private enableMultiFactorAuthentication() {
    if (this.mfaFormGroup.valid) {
      const token = this.mfaFormGroup.get('token').value;
      this.authenticationService.verifyTimeBasedOneTimePassword(token).subscribe(
        () => {
          this.translatedToastrService.success('message.error.mfa.verifyToken');
          this.navigationService.navigateToPatientOnboardingFinished();
        },
        err => this.translatedToastrService.error('message.error.mfa.verifyToken')
      );
    }
  }

  private getMultiFactorAuthentication() {
    this.authenticationService.getTimeBasedOneTimePassword().subscribe(
      data => {
        if (!hasValue(data.result.otpAuthUrl)) {
          this.createMultiFactorAuthenticationUrl();
        } else {
          this.mfaModel = data.result;
          this.isLoaded = true;
        }
      },
      err => this.translatedToastrService.error(err.message, err.title)
    );
  }
}
