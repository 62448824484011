import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot} from '@angular/router';
import {NavigationService} from '../navigation/navigation.service';
import {AuthenticationService} from '../authentication/authentication.service';

@Injectable()
export class MedicGuardService implements CanActivate, CanLoad {
  constructor(
    private authenticationService: AuthenticationService,
    private navigationService: NavigationService,
    ) {}

  canActivate(
    activatedRouteSnapshot: ActivatedRouteSnapshot,
    routerStateSnapshot: RouterStateSnapshot
  ) {
    const url: string = routerStateSnapshot.url;

    return this.checkLogin(url);
  }

  canLoad(route: Route): boolean {
    const url = '/' + route.path;

    return this.checkLogin(url);
  }

  checkLogin(url: string): boolean {
    if (this.authenticationService.isLoggedIn.getValue() && this.authenticationService.isMedic.getValue()) {
      return true;
    }

    // Navigate to the authentication page
    this.navigationService.navigateToLogin();

    return false;
  }
}
