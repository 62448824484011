import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {LocaleResolverService} from '../locale/locale-resolver.service';

export abstract class BackendService {

    protected constructor(
        protected http: HttpClient,
        protected localeResolverService: LocaleResolverService
    ) {
    }

    // TODO cleanup

    protected initOptions() {
        return {
            withCredentials: true,
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
    }

    getApiUrl(): string {
        return window.location.origin + '/api';
    }

    protected initHttpOptions() {
        return {
            withCredentials: true,
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
    }

    protected createHttpOptions() {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            params: new HttpParams(),
            withCredentials: true,
        };
    }

    protected createHttpHeaders(): HttpHeaders {
        return new HttpHeaders({
            'Content-Type': 'application/json'
        });
    }

    protected buildBaseHttpHeaders(): HttpHeaders {
        return new HttpHeaders({});
    }

    protected buildBaseHttpParams(): HttpParams {
        const lang = this.localeResolverService.getLanguage();
        let params = new HttpParams();
        params = params.append('lang', lang);
        return params;
    }
}
