import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DocumentHolder} from '../document.model';
import {DocumentService} from '../document.service';
import {DocumentTypeEnum} from '../document-type.enum';
import {UsageAlertComponent} from '../../../shared/usage/usage-alert/usage-alert.component';
import {ToastrService} from 'ngx-toastr';
import {UsageTypeEnum} from '../../../shared/enums/usage-type.enum';

@Component({
  selector: 'app-document-uploader',
  templateUrl: './document-uploader.component.html',
  styleUrls: ['./document-uploader.component.scss']
})
export class DocumentUploaderComponent implements OnInit {
  // TODO check if we need this component else remove
  @Input() personIdParam: string;
  @Input() readonly = false;
  @Output() valid = new EventEmitter<boolean>();
  @ViewChild('docUsageAlert') docUsageAlert: UsageAlertComponent;

  documents: DocumentHolder[] = [];

  UsageTypeEnum = UsageTypeEnum;

  constructor(
      protected docService: DocumentService,
      protected ref: ChangeDetectorRef,
      protected toastr: ToastrService
  ) {
  }

  ngOnInit() {
  }

  hasDocuments(): boolean {
    return this.documents && this.documents.length > 0;
  }

  async save(title, docType: DocumentTypeEnum, visible, favourite, emergencyCardVisible): Promise<string[]> {
    const documentUploads: DocumentHolder[] = [];
    if (this.hasDocuments()) {
      for (const doc of this.documents) {
        if (doc.file) {
          const d = new DocumentHolder();
          doc.title = title;
          doc.docType = docType;
          doc.visible = visible;
          doc.favourite = favourite;
          doc.emergencyCardVisible = emergencyCardVisible;

          documentUploads.push(d);
        }
      }
    }

    this.toastr.info('Uploading ' + this.documents.length + ' file/s', 'Saving documents', {'progressBar': true});

    const uploadedDocsId = await this.docService.createDocumentBulk(this.personIdParam, this.documents);

    return new Promise((resolve, reject) => {
      resolve(uploadedDocsId);
    });
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      this.documents = [];
      for (const file of event.target.files) {
        const d = new DocumentHolder();
        d.name = file.name;
        d.size = file.size;
        d.file = file;
        this.documents.push(d);
      }

      this.validatesQuotas();
    }
  }

  setDocumentsReadonly(readonly) {
    this.readonly = readonly;
    this.valid.emit(true);
    this.ref.markForCheck();
  }

  setValid(valid) {
    this.valid.emit(valid);
    this.ref.markForCheck();
  }

  private validatesQuotas() {
    let sumSize = 0;
    this.documents.forEach(d => {
      sumSize += d.size;
    });
    this.docUsageAlert.validatesQuotasLimits(sumSize);
  }

  removeDocument(document, index) {
    if (document.id) {
      this.docService.deleteDocument(document.id).subscribe(
          () => this.toastr.success('File deleted ' + document.name, 'Success'),
          () => this.toastr.error('Could not delete file ' + document.name, 'Error')
      );
    }
    this.documents.splice(index, 1);
    this.validatesQuotas();
  }
}
