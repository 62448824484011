import {Component, OnDestroy, OnInit} from '@angular/core';
import {RouteInfo} from '../../../core/routes/route-info.model';
import {Person} from '../../../core/person/person.model';
import {Subscription} from 'rxjs';
import {ProfileService} from '../../../core/profile/profile.service';
import {NavigationEnd, Router} from '@angular/router';
import {DocumentService} from '../../../patient/documents/document.service';
import {filter} from 'rxjs/operators';
import {MEDIC_ROUTE_INFOS} from '../../../core/routes/medic-route-infos';
import {MedicalService} from '../../../core/medical/medical.service';

@Component({
  selector: 'app-medic-side-navigation',
  templateUrl: './medic-side-navigation.component.html',
  styleUrls: ['./medic-side-navigation.component.scss'],
})
export class MedicSideNavigationComponent implements OnDestroy, OnInit {
  activeMenuItem: RouteInfo;
  activeFirstLevelMenuItem: RouteInfo;
  activeSecondLevelMenuItem: RouteInfo;
  activeThirdLevelMenuItem: RouteInfo;
  menuItems: RouteInfo[] = MEDIC_ROUTE_INFOS;
  patient: Person;
  patientIdParam: string;
  patientPhoto: string;
  person: Person;
  private subscriptions: Subscription[] = [];

  constructor(
    private documentService: DocumentService,
    private medicalService: MedicalService,
    private profileService: ProfileService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.profileService.personIdParamSubject.subscribe(personIdParam => {
        this.patientIdParam = personIdParam;
        if (this.patientIdParam) {
          this.profileService.getProfile(this.patientIdParam).subscribe(
            res => {
              this.patient = res.result;
              if (this.patient) {
                this.patientPhoto = this.documentService.resolvePhotoFileUrl(this.patient.photoKey);
              }
            }
          );
        }
        this.initMenuItems();
      }),
      this.profileService.myProfileSubject.subscribe(person => {
        this.person = person;
        this.initMenuItems();
      }),
      this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => this.initMenuItems()),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  getSubMenuRouterLink(subMenuItem: RouteInfo): string[] {
    return [subMenuItem.path.replace(':id', this.patientIdParam)];
  }

  hasSecondLevel(firstLevelMenuItem: RouteInfo): boolean {
    return firstLevelMenuItem.submenu.length > 0 && !!this.patientIdParam;
  }

  hasThirdLevel(secondLevelMenuItem: RouteInfo): boolean {
    return secondLevelMenuItem.submenu.length > 0 && !!this.patientIdParam;
  }

  isShowSecondLevelMenuItems(firstLevelMenuItem: RouteInfo): boolean {
    return firstLevelMenuItem.submenu.length > 0 && (this.activeFirstLevelMenuItem === firstLevelMenuItem) && !!this.patientIdParam;
  }

  isShowThirdLevelMenuItems(secondLevelMenuItem: RouteInfo): boolean {
    return secondLevelMenuItem.submenu.length > 0 && (this.activeSecondLevelMenuItem === secondLevelMenuItem) && !!this.patientIdParam;
  }

  onClickExitPatient() {
    if (this.patientIdParam) {
      this.profileService.deleteProfileDataFromCache(this.patientIdParam);
    }
    this.medicalService.unsetPatientConnection();
  }

  private initMenuItems() {
    this.menuItems = [];
    if (this.person && this.person.onboardingStage === 1) {
      // deep copy
      this.menuItems = JSON.parse(JSON.stringify(MEDIC_ROUTE_INFOS));

      const patientConnection = this.medicalService.patientConnection;
      if (!patientConnection.dataVisible) {
        const patientsModule = this.menuItems.find(menuItem => menuItem.module === 'patients');
        patientsModule.submenu = patientsModule.submenu.filter(menuItem => menuItem.module === 'dashboard');
      }
    }
    this.setActiveMenuItemsFromRoute();
  }

  private setActiveMenuItemsFromRoute() {
    this.activeFirstLevelMenuItem = undefined;
    this.activeSecondLevelMenuItem = undefined;
    this.activeThirdLevelMenuItem = undefined;
    this.activeMenuItem = undefined;

    const currentRoute = this.router.url;
    const firstLevelMenuItem = this.menuItems.find(item => currentRoute.startsWith(item.path));
    if (firstLevelMenuItem) {
      this.activeFirstLevelMenuItem = firstLevelMenuItem;
      this.activeMenuItem = firstLevelMenuItem;

      const secondLevelMenuItem = firstLevelMenuItem.submenu.find(
        item => currentRoute.startsWith(item.path.replace(':id', this.patientIdParam))
      );
      if (secondLevelMenuItem) {
        this.activeSecondLevelMenuItem = secondLevelMenuItem;
        this.activeMenuItem = secondLevelMenuItem;

        const thirdLevelMenuItem = secondLevelMenuItem.submenu.find(
          item => currentRoute.startsWith(item.path.replace(':id', this.patientIdParam))
        );
        if (thirdLevelMenuItem) {
          this.activeThirdLevelMenuItem = thirdLevelMenuItem;
          this.activeMenuItem = thirdLevelMenuItem;
        }
      }
    }
  }
}
