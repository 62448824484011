import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {take} from 'rxjs/operators';

@Component({
    selector: 'app-attrs',
    templateUrl: './attrs.component.html',
    styleUrls: ['./attrs.component.scss']
})
export class AttrsComponent implements OnInit {
    attr: string;

    constructor(
        private route: ActivatedRoute,
    ) {
    }

    ngOnInit() {
        const routeParams$ = this.route.params.pipe(take(1));
        routeParams$.subscribe(p => {
            this.attr = p['attr'];
        });
    }
}
