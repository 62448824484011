export enum ActivityLogResourceEnum {
  PERSON = 0,
  PROFILE = 1,

  BLOOD_GROUP_CARD = 2,
  DOCUMENTS = 3,

  HEALTH_DISEASES = 100,
  HEALTH_OPERATIONS = 101,
  HEALTH_MEDICATIONS = 102,
  HEALTH_ALLERGIES = 103,
  HEALTH_IMMUNISATIONS = 104,
  HEALTH_FAMILY_DISEASES = 105,

  JOURNAL_HEART_RATES = 200,
  JOURNAL_BLOOD_PRESSURES = 201,
  JOURNAL_BLOOD_GLUCOSES = 202,
  JOURNAL_FEELINGS = 203,
  JOURNAL_WEIGHTS = 204,
  JOURNAL_PAINS = 205,
  JOURNAL_OXYGEN_SATURATION = 206,
  JOURNAL_SLEEP = 207,
  JOURNAL_STRESS = 208,
  JOURNAL_BODY_TEMPERATURES = 209
}

export function activityLogResourceTranslationKey(activityLogResource: number) {
  switch (activityLogResource) {
    case ActivityLogResourceEnum.PERSON:
      return 'menu.pat.person';
    case ActivityLogResourceEnum.PROFILE:
      return 'menu.pat.profile';

    case ActivityLogResourceEnum.BLOOD_GROUP_CARD:
      return 'menu.pat.bloodGroup';
    case ActivityLogResourceEnum.DOCUMENTS:
      return 'documents.title';

    case ActivityLogResourceEnum.HEALTH_DISEASES:
      return 'health.diseases.title';
    case ActivityLogResourceEnum.HEALTH_OPERATIONS:
      return 'health.operations.title';
    case ActivityLogResourceEnum.HEALTH_MEDICATIONS:
      return 'health.medications.title';
    case ActivityLogResourceEnum.HEALTH_ALLERGIES:
      return 'health.allergies.title';
    case ActivityLogResourceEnum.HEALTH_IMMUNISATIONS:
      return 'health.immunisations.title';
    case ActivityLogResourceEnum.HEALTH_FAMILY_DISEASES:
      return 'health.familyDiseases.title';

    case ActivityLogResourceEnum.JOURNAL_HEART_RATES:
      return 'journal.heartRate.title';
    case ActivityLogResourceEnum.JOURNAL_BLOOD_PRESSURES:
      return 'journal.bloodPressure.title';
    case ActivityLogResourceEnum.JOURNAL_BLOOD_GLUCOSES:
      return 'journal.bloodGlucose.title';
    case ActivityLogResourceEnum.JOURNAL_FEELINGS:
      return 'journal.feeling.title';
    case ActivityLogResourceEnum.JOURNAL_WEIGHTS:
      return 'journal.weight.title';
    case ActivityLogResourceEnum.JOURNAL_PAINS:
      return 'journal.pain.title';
    case ActivityLogResourceEnum.JOURNAL_OXYGEN_SATURATION:
      return 'journal.spo2.title';
    case ActivityLogResourceEnum.JOURNAL_SLEEP:
      return 'journal.sleep.title';
    case ActivityLogResourceEnum.JOURNAL_STRESS:
      return 'journal.stress.title';
    case ActivityLogResourceEnum.JOURNAL_BODY_TEMPERATURES:
      return 'journal.bodyTemperature.title';
  }
}
