import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MedicView} from '../../../core/medic/medic-view.model';
import {MedicalService} from '../../../core/medical/medical.service';
import {DocumentService} from '../../documents/document.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {TranslatedToastrService} from '../../../core/translated-toastr/translated-toastr.service';

@Component({
  selector: 'app-patient-dashboard-doctors',
  templateUrl: './patient-dashboard-doctors.component.html',
  styleUrls: ['./patient-dashboard-doctors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatientDashboardDoctorsComponent implements OnDestroy, OnInit {
  @Input() isVisible = true;
  @Input() personIdParam: string;
  medics: MedicView[] = [];
  titleLink = '';
  total = 0;
  private connected = true;
  private pageSize = 3;
  private subscriptions: Subscription[] = [];

  constructor(private documentService: DocumentService,
              private medicalService: MedicalService,
              private router: Router,
              private translatedToastrService: TranslatedToastrService,
              private translateService: TranslateService,
              protected ref: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.titleLink = ((this.personIdParam === 'me') ? '/people/' : '/medic/patients/') + this.personIdParam + '/medics';
    this.loadMedics();

    this.subscriptions.push(
      this.translateService.onLangChange.subscribe(() => this.afterLangChange())
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onClick(id: number) {
    const url = this.titleLink + '/' + id + '/view';
    this.router.navigate([url]);
  }

  private afterLangChange() {
    this.medics.forEach(medic => {
      medic.specialtiesText = medic.specialties ? medic.specialties.split(',').map(
        e => this.translateService.instant('codes.specialty.' + e)
      ).join(', ') : '';
    });
    this.ref.markForCheck();
  }

  private loadMedics() {
    this.medicalService.searchMedicsFulltext(this.personIdParam, null, null, 1, this.pageSize, this.connected)
      .subscribe(
        resp => {
          this.total = resp.count;
          this.medics = resp.results;
          this.medics.forEach(medic => this.localizeMedic(medic));
          this.ref.markForCheck();
        },
        () => this.translatedToastrService.error('message.error.dashboard.doctors')
      );
  }

  private localizeMedic(medic: MedicView) {
    medic.photo = this.documentService.resolveStaticFileUrl(medic.photo);
    medic.medicphoto = this.documentService.resolveStaticFileUrl(medic.medicphoto);
    medic.specialtiesText = medic.specialties ? medic.specialties.split(',').map(
      e => this.translateService.instant('codes.specialty.' + e)
    ).join(', ') : '';
  }
}
