import {Injectable} from '@angular/core';
import {Mail} from './mail.model';
import {BackendService} from '../+modules/core/backend/backend.service';
import {HttpClient} from '@angular/common/http';
import {EmailConversationListResponse, EmailMessageListResponse, GeneralResponse} from '../+modules/core/response/response.model';
import {EmailConversation} from './email-conversation.model';
import {EmailMessage} from './email-message.mode';
import {LocaleResolverService} from '../+modules/core/locale/locale-resolver.service';
import {Message} from './message.model';

@Injectable()
export class InboxService  extends BackendService {
  inbox: Mail[] = [];
  message: Message[] = [];
  result: EmailConversation[] = [];

  constructor(
    protected http: HttpClient,

    protected localeResolverService: LocaleResolverService
  ) {
    super(http, localeResolverService);
  }

  getUnseenEmailMessages() {
    const httpOptions = this.createHttpOptions();
    return this.http.get<GeneralResponse>(this.getApiUrl() + '/people/me/email/messages/unseen', httpOptions);
  }

  loadEmailConversations(type: string) {
    const httpOptions = this.createHttpOptions();
    return this.http.get<EmailConversationListResponse>(this.getApiUrl() + '/people/me/emails/' + type, httpOptions);
  }

  loadEmailMessages(conversationId: number) {
    const httpOptions = this.createHttpOptions();
    return this.http.get<EmailMessageListResponse>(this.getApiUrl() + '/people/me/emails/' + conversationId + '/messages', httpOptions);
  }

  createEmailMessage(msg: EmailMessage) {
    const httpOptions = this.createHttpOptions();
    return this.http.post(this.getApiUrl() + '/emails', msg, httpOptions);
  }

  markConversationAsSeen(conversationId: number) {
    const httpOptions = this.createHttpOptions();
    return this.http.post(this.getApiUrl() + '/people/me/emails/' + conversationId + '/seen', {}, httpOptions);
  }
}
