import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Medication} from '../../../core/health/medication.model';
import {MedicationService} from '../../../core/health/medication.service';
import {LocaleResolverService} from '../../../core/locale/locale-resolver.service';
import {BaseComponent} from '../../../../base/base-component';
import {ProfileService} from '../../../core/profile/profile.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {MedicationTypeEnum} from '../../../shared/enums/medication-type.enum';
import {UsageTypeEnum} from '../../../shared/enums/usage-type.enum';
import {hasValue} from '../../../../lib/validator';
import {MedicationEditComponent} from '../../health/medications/medication-edit/medication-edit.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {UsageService} from '../../../core/usage/usage.service';
import {UsageItem} from '../../../core/usage/usage.model';

@Component({
  selector: 'app-patient-dashboard-medications',
  templateUrl: './patient-dashboard-medications.component.html',
  styleUrls: ['./patient-dashboard-medications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatientDashboardMedicationsComponent extends BaseComponent implements OnInit {
  @Input() isVisible = true;
  @Input() personIdParam: string;
  currentModal: NgbModalRef;
  isAddActive = true;
  medications: Medication[] = [];
  titleLink = '';
  total = 0;
  usageItem: UsageItem;
  private pageSize = 3;

  constructor(private medicationService: MedicationService,
              private modal: NgbModal,
              private router: Router,
              private translateService: TranslateService,
              private usageService: UsageService,
              protected profileService: ProfileService,
              protected localeResolverService: LocaleResolverService,
              protected ref: ChangeDetectorRef) {
    super(profileService, localeResolverService, ref);
  }

  ngOnInit() {
    super.ngOnInit();
    this.titleLink = ((this.personIdParam === 'me') ? '/people/' : '/medic/patients/') + this.personIdParam + '/health/medications';
    this.loadMedications();
    this.loadMediationLimits();
  }

  getMedicationTypeDescription(medication: Medication): string {
    return Medication.getMedicationTypeDescription(medication);
  }

  getMedicationUnit(medication: Medication): string {
    let unit = '';
    switch (medication.type) {
      case MedicationTypeEnum.TABLET_CAPSULE_DRAGEE:
      case MedicationTypeEnum.SUPPOSITORY:
      case MedicationTypeEnum.PLASTER:
        unit = '(' + this.translateService.instant('codes.medication.unit.piece') + ')';
        break;

      case MedicationTypeEnum.SYRINGE_INFUSION:
        unit = '(' + this.translateService.instant('codes.medication.unit.milliliter') + ')';
        break;

      case MedicationTypeEnum.SPRAY:
        unit = '(' + this.translateService.instant('codes.medication.unit.strokes') + ')';
        break;

      case MedicationTypeEnum.DROPS:
        unit = '(' + this.translateService.instant('codes.medication.unit.drops') + ')';
        break;
    }
    return unit;
  }

  onAddChange() {
    this.showAddModal();
  }

  onClick(id: number) {
    const url = this.titleLink + '/' + id + '/view';
    this.router.navigate([url]);
  }

  showMedicationTypeInfo(medication: Medication): boolean {
    return !!medication.type && (!!medication.evening || !!medication.morning || !!medication.lunch || !!medication.night);
  }

  private handleSaved(saved: boolean) {
    if (saved === true) {
      this.loadMedications();
      this.loadMediationLimits();
    }
  }

  private loadMedications() {
    this.medicationService
      .listMedications(this.personIdParam, null, null, 1, this.pageSize, null)
      .subscribe(
        resp => {
          this.total = resp.count;
          this.medications = resp.results;
          this.medications.forEach(medication => {
            medication.localized = {
              type: '',
              appSummary: '',
              start: '',
              end: '',
              created_at: ''
            };
            // medication.localized.appSummary = this.medicationService.applicationSummary(medication);
            if (medication.type) {
              medication.localized.type = this.translateService.instant('codes.medication.type.' + medication.type);
            }
            medication.localized.start = this.formatDate(medication.start);
            medication.localized.end = this.formatDate(medication.end);
          });
          this.ref.markForCheck();
        },
        e => {
          console.log(e);
        }
      );
  }

  private loadMediationLimits() {
    this.usageService.getUsageItem(this.personIdParam, UsageTypeEnum.MEDICATIONS).subscribe(res => {
      this.usageItem = res.result;
      if (this.usageItem) {
        // Limit Reached
        if (hasValue(this.usageItem.value) && hasValue(this.usageItem.limit) && this.usageItem.value >= this.usageItem.limit) {
          this.isAddActive = false;
        }

        // Insufficient Storage
        if (hasValue(this.usageItem.quota) && hasValue(this.usageItem.sumSize) && this.usageItem.sumSize >= this.usageItem.quota) {
          this.isAddActive = false;
        }

        this.ref.markForCheck();
      }
    });
  }

  private showAddModal() {
    setTimeout(() => {
      this.currentModal = this.modal.open(MedicationEditComponent, {size: 'lg'});
      this.currentModal.componentInstance.personIdParam = this.personIdParam;
      this.currentModal.componentInstance.requester = this.requester;
      this.currentModal.result.then(saved => this.handleSaved(saved)).catch();
    }, 100);
  }
}
