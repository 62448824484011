import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Subscription} from './subscription.model';
import {BackendService} from '../backend/backend.service';
import {
    InvoiceResponse,
    PaymentListResponse,
    SubscriptionAgreementResponse, SubscriptionPlanListResponse,
    SubscriptionPlanResponse,
    SubscriptionResponse
} from '../response/response.model';
import {LocaleResolverService} from '../locale/locale-resolver.service';

@Injectable()
export class SubscriptionService extends BackendService {
    constructor(
        protected http: HttpClient,
        protected localeResolverService: LocaleResolverService,
    ) {
        super(http, localeResolverService);
    }

    /**
     * Calls service for saving subscription resource
     *
     * @param subscription: Subscription
     */
    save(subscription: Subscription) {
        const httpOptions = this.createHttpOptions();
        return this.http.put<SubscriptionAgreementResponse>(this.getApiUrl() + '/subscription', subscription, httpOptions);
    }

    /**
     * Calls service for creation subscription with Trial plan
     */
    createTrial() {
        const httpOptions = this.createHttpOptions();
        return this.http.post(this.getApiUrl() + '/subscription/trial', {}, httpOptions);
    }

    /**
     * Returns my subscription
     */
    getMySubscription() {
        const httpHeaders = this.createHttpHeaders();
        return this.http.get<SubscriptionResponse>(this.getApiUrl() + '/subscription', {headers: httpHeaders});
    }

    getSubscription(personIdParam: string) {
        const httpHeaders = this.createHttpHeaders();
        return this.http.get<SubscriptionResponse>(this.getApiUrl() + '/people/' + personIdParam + '/subscription', {headers: httpHeaders});
    }

    getSubscriptionPlan(code) {
        const httpHeaders = this.createHttpHeaders();
        return this.http.get<SubscriptionPlanResponse>(this.getApiUrl() + '/subscription-plans/' + code, {headers: httpHeaders});
    }

    listSubscriptionPlans(page: number = null, pageSize: number = null) {
        const httpHeaders = this.createHttpHeaders();

        let params = new HttpParams();
        if (page !== undefined && page !== null) {
            params = params.append('page', page.toString());
        }
        if (pageSize !== undefined && pageSize !== null) {
            params = params.append('pageSize', pageSize.toString());
        }

        return this.http.get<SubscriptionPlanListResponse>(this.getApiUrl() + '/subscription-plans', (params.keys().length > 0) ? {
            headers: httpHeaders,
            params: params
        } : {headers: httpHeaders});
    }

    /**
     * Cancels my subscription
     */
    cancelMySubscription() {
        const httpHeaders = this.createHttpHeaders();
        return this.http.post(this.getApiUrl() + '/subscription/cancel', {}, {headers: httpHeaders});
    }

    listPayments(page: number, pageSize: number) {
        const httpHeaders = this.createHttpHeaders();

        let params = new HttpParams();
        if (page !== undefined && page !== null) {
            params = params.append('page', page.toString());
        }
        if (pageSize !== undefined && pageSize !== null) {
            params = params.append('pageSize', pageSize.toString());
        }

        return this.http.get<PaymentListResponse>(this.getApiUrl() + '/subscription/payments', (params.keys().length > 0) ? {
            headers: httpHeaders,
            params: params
        } : {headers: httpHeaders});
    }

    /**
     * Returns invoice by payment id
     *
     * @param paymentId: number
     */
    getInvoiceByPayment(paymentId: number) {
        const httpHeaders = this.createHttpHeaders();
        return this.http.get<InvoiceResponse>(this.getApiUrl() + '/subscription/payments/' + paymentId + '/invoice', {headers: httpHeaders});
    }

    listProducts(page: number = null, pageSize: number = null, lastId: number = null) {
        const httpHeaders = this.createHttpHeaders();

        let params = new HttpParams();
        if (page !== undefined && page !== null) {
            params = params.append('page', page.toString());
        }
        if (pageSize !== undefined && pageSize !== null) {
            params = params.append('pageSize', pageSize.toString());
        }
        if (lastId !== undefined && lastId !== null) {
            params = params.append('lastId', lastId.toString());
        }

        return this.http.get<PaymentListResponse>(this.getApiUrl() + '/stripe/products', (params.keys().length > 0) ? {
            headers: httpHeaders,
            params: params
        } : {headers: httpHeaders});
    }
}
