import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LocaleResolverService} from '../+modules/core/locale/locale-resolver.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  lang = 'en';

  constructor(
      private translateService: TranslateService,
      private localeResolver: LocaleResolverService
  ) { }

  ngOnInit() {
    this.lang = this.localeResolver.getLanguage();
    this.localeResolver.langSubject
        .subscribe(v => {
          // console.log(v);
          if (v) {
            this.lang = v;
          }
        });
  }
}
