import {Address} from '../address/address.model';
import {Profile} from '../profile/profile.model';

export class Person {
    id: number;
    about: string;
    age: number;
    code: string;
    dateOfBirth: Date;
    firstName: string;
    gender: number;
    lastName: string;
    notes: string;
    onboardingStage: number;
    phone: string;
    photoKey: string;
    role: string;
    skype: string;
    timezone: string;
    title: string;
    type: number;
    url: string;

    profile: Profile;

    addresses: Array<Address>;

    modules: string[];

    localized: {
        gender: string;
        dateOfBirth: string;
        age: string;
    };
}

export class RecipientPerson extends Person {
    name: string;
}
