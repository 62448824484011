import {Injectable} from '@angular/core';
import {BackendService} from '../backend/backend.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {GeneralResponse, ImmunisationListResponse, ImmunisationResponse} from '../response/response.model';
import {Immunisation} from './immunisation.model';
import {LocaleResolverService} from '../locale/locale-resolver.service';
import {QueryFilter} from '../filters/query-filter.model';

@Injectable()
export class ImmunisationService extends BackendService {

  constructor(
      protected http: HttpClient,

      protected localeResolverService: LocaleResolverService
  ) {
    super(http, localeResolverService);
  }

  createImmunisation(id, body) {
    const httpOptions = this.createHttpOptions();
    return this.http.post<GeneralResponse>(this.getApiUrl() + '/people/' + id + '/immunisations', body, httpOptions);
  }

  updateImmunisation(id: number, immunisation: Immunisation) {
    const httpOptions = this.createHttpOptions();
    return this.http.put(this.getApiUrl() + '/immunisations/' + immunisation.id, immunisation, httpOptions);
  }

  getImmunisation(personIdParam: string, id: number) {
    const httpOptions = this.createHttpOptions();
    return this.http.get<ImmunisationResponse>(this.getApiUrl() + '/people/' + personIdParam +  '/immunisations/' + id, httpOptions);
  }

  listImmunisations(
    id: string, q: string, base: boolean, page: number, pageSize: number, lastId: number, queryFilters: QueryFilter[] = []
  ) {
    // TODO added q and base param
    const httpHeaders = this.buildBaseHttpHeaders();

    let params = new HttpParams();
    if (page !== undefined && page !== null) {
      params = params.append('page', page.toString());
    }
    if (pageSize !== undefined && pageSize !== null) {
      params = params.append('pageSize', pageSize.toString());
    }
    if (lastId !== undefined && lastId !== null) {
      params = params.append('lastId', lastId.toString());
    }
    if (base !== undefined && base !== null) {
      params = params.append('base', base.toString());
    }
    if (q !== undefined && q !== null) {
      params = params.append('q', q.toString());
    }
    for (const queryFilter of queryFilters) {
      params = params.append(queryFilter.name, String(queryFilter.value));
    }

    return this.http.get<ImmunisationListResponse>(
        this.getApiUrl() + '/people/' + id + '/immunisations',
        (params.keys().length > 0) ? { headers: httpHeaders, params: params } : { headers: httpHeaders });
  }

  deleteImmunisation(id: number) {
    const httpOptions = this.createHttpOptions();
    return this.http.delete(this.getApiUrl() + '/immunisations/' + id, httpOptions);
  }

}
