import {Component, OnInit} from '@angular/core';
import {SubscriptionService} from '../../../core/subscription/subscription.service';
import {SubsPlan} from '../../../core/subs-plan/subs-plan.model';
import {Subscription} from '../../../core/subscription/subscription.model';
import {formatDate} from '../../../../lib/formats';
import {TranslatedToastrService} from '../../../core/translated-toastr/translated-toastr.service';
import {PatientSettingsPaymentComponent} from '../patient-settings-payment/patient-settings-payment.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {SubscriptionPlanCodeEnum} from '../../../shared/enums/subscription-plan-code.enum';
import {DeleteModalComponent} from '../../../shared/delete-modal/delete-modal.component';
import {TranslateService} from '@ngx-translate/core';
import {RenewalTypeEnum} from '../../../shared/enums/renewal-type.enum';
import {SubscriptionStateEnum} from '../../../shared/enums/subscription-state.enum';

@Component({
    selector: 'app-patient-settings-subscription',
    templateUrl: './patient-settings-subscription.component.html',
    styleUrls: ['./patient-settings-subscription.component.scss']
})
export class PatientSettingsSubscriptionComponent implements OnInit {
    currentModal: NgbModalRef;
    formatDate = formatDate;
    isLoaded = false;
    isPremiumDisabled = true;
    otherSubsPlans: SubsPlan[] = [];
    subsPlan: SubsPlan;
    subsPlans: SubsPlan[] = [];
    subscription: Subscription;

    constructor(
        private modal: NgbModal,
        private subscriptionService: SubscriptionService,
        private translatedToastrService: TranslatedToastrService,
        private translateService: TranslateService,
    ) {
    }

    ngOnInit() {
        this.getMySubscription();
    }

    getSubscriptionButtonTranslationKey(subsPlan: SubsPlan): string {
        if (this.isSubscriptionDisabled(subsPlan)) {
            return 'btn.subscription.disabled';
        } else {
            return this.isSubscriptionDowngrade(subsPlan) ? 'settings.subscription.change' : 'btn.onboarding.subscription.payment';
        }
    }

    getSubscriptionRenewalTypeTranslation(): string {
        return 'codes.renewalType.' + this.subscription.renewalType;
    }

    isActiveRenewalSubscription(): boolean {
        return this.isSubscriptionActive()
            && this.subscription.renewalType === RenewalTypeEnum.ACTIVE;
    }

    isSubscriptionDisabled(subsPlan: SubsPlan): boolean {
        return this.isPremiumDisabled && subsPlan.code === SubscriptionPlanCodeEnum.PREMIUM;
    }

    isSubscriptionDowngrade(subsPlan: SubsPlan): boolean {
        return this.subsPlan.annual && !!subsPlan.annual && this.subsPlan.annual > subsPlan.annual;
    }


    onClickCancel() {
        this.showCancelSubscriptionModal();
    }

    onClickSubsPlan(subsPlan: SubsPlan) {
        if (!this.isSubscriptionDisabled(subsPlan)) {
            this.showPaymentModal(subsPlan);
        }
    }

    private cancelSubscription() {
        this.subscriptionService.cancelMySubscription().subscribe(
            () => {
                this.translatedToastrService.success('message.success.subscription.cancel');
                this.getMySubscription();
            },
            () => this.translatedToastrService.error('message.error.subscription.cancel')
        );
    }

    private getMySubscription() {
        this.subscriptionService.getMySubscription().subscribe(response => {
            this.subscription = response.result;
            this.getSubscriptionPlans();
        });
    }

    private getSubscriptionPlans() {
        this.subscriptionService.listSubscriptionPlans().subscribe(data => {
                this.subsPlans = data.results;
                this.subsPlan = this.subsPlans.find(subsPlan => subsPlan.code === this.subscription.planCode);
                this.otherSubsPlans = this.subsPlans.filter(subsPlan => {
                    const activeSubPlan = this.subscription.status === SubscriptionStateEnum.ACTIVE
                        ? subsPlan.code !== this.subscription.planCode
                        : true;

                    return activeSubPlan && subsPlan.code !== 1;
                });
                this.isLoaded = true;
            }
        );
    }

    private handleSaved(isSaved: boolean) {
        if (isSaved === true) {
            this.isLoaded = false;
            this.getMySubscription();
        }
    }

    private showCancelSubscriptionModal() {
        setTimeout(() => {
            const description = this.subsPlan.annual ?
                ('EUR ' + this.subsPlan.annual + ' ' + this.translateService.instant('subscriptionPlan.yearly')) :
                this.translateService.instant('subscriptionPlan.free');
            this.currentModal = this.modal.open(DeleteModalComponent, {size: 'lg'});
            this.currentModal.componentInstance.itemDescription = description;
            this.currentModal.componentInstance.itemHeader = this.translateService.instant('subscriptionPlan.plan.' + this.subsPlan.name);
            this.currentModal.componentInstance.itemTitle = this.translateService.instant('settings.subscription.cancelQuestion');
            this.currentModal.componentInstance.itemType = this.translateService.instant('settings.subscription.header');
            this.currentModal.result.then(isCancelled => {
                if (isCancelled) {
                    this.cancelSubscription();
                }
            });
        }, 100);
    }

    private showPaymentModal(subsPlan: SubsPlan) {
        setTimeout(() => {
            this.currentModal = this.modal.open(PatientSettingsPaymentComponent, {
                size: 'lg',
                windowClass: 'settings-payment',
            });
            this.currentModal.componentInstance.subsPlan = subsPlan;
            this.currentModal.result.then(saved => this.handleSaved(saved)).catch(() => {
            });
        }, 100);
    }

    isSubscriptionActive() {
        return this.subscription.status === SubscriptionStateEnum.ACTIVE;
    }
}
