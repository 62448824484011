import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {ProfileService} from '../../../core/profile/profile.service';
import {AuthDone, AuthenticationService, AuthError} from '../../../core/authentication/authentication.service';
import {BaseAuthComponent} from '../base-auth.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent extends BaseAuthComponent implements OnInit, AuthDone {
    currentPassword: string;
    email: string;
    errorMessage: string;
    isCurrentPasswordVisible = false;
    isNewPasswordVisible = false;
    isNewPasswordConfirmVisible = false;
    isSubmitted: boolean;
    newPassword: string;
    newPasswordConfirm: string;
    personType: number;
    passwordPolicy: string;

    constructor(
        private router: Router,
        private authService: AuthenticationService,
        protected translateService: TranslateService,
        private toastr: ToastrService,
        private profileService: ProfileService
    ) {
        super(translateService);
    }

    ngOnInit() {
        super.ngOnInit();

        const me$ = this.profileService.getMyProfile();
        me$.subscribe(r => {
            const mePerson = r.result;
            this.personType = mePerson ? mePerson.type : null;
        });
    }

    onChangePassword() {
        this.isSubmitted = true;

        const errTitle = this.translations['error'];
        this.passwordPolicy = this.translations['authentication.errors.PasswordPolicyError'];

        if (!this.currentPassword || !this.newPassword || !this.newPasswordConfirm) {
            this.errorMessage = this.translations['validation.allMandatory'];
            this.toastr.error(this.errorMessage, errTitle);
            return;
        }

        if (this.newPassword !== this.newPasswordConfirm) {
            this.errorMessage = this.translations['authentication.errors.PasswordsNotMatched'];
            this.toastr.error(this.errorMessage, errTitle);
            return;
        }

        this.authService.changePassword(this.currentPassword, this.newPassword, this);
    }

    done(error: AuthError, result: any): void {
        if (error != null) {
            this.errorMessage = error.message;
            this.toastr.error(this.errorMessage, this.translations['error']);
            return;
        } else {
            this.currentPassword = '';
            this.newPassword = '';
            this.newPasswordConfirm = '';
            this.toastr.info(this.translations['authentication.passwordChanged'], this.translations['success']);
        }
    }
}
