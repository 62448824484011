import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Profile} from '../../../core/profile/profile.model';
import {Router} from '@angular/router';
import {formatDateTime} from '../../../../lib/formats';
import {Sleep} from '../../../core/measurements/sleep.model';
import {SleepService} from '../../../core/measurements/sleep.service';

@Component({
  selector: 'app-profile-sleep',
  templateUrl: './profile-sleep.component.html',
  styleUrls: ['./profile-sleep.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileSleepComponent implements OnInit {
  @Input() isVisible = true;
  @Input() latestSleep: Sleep | undefined;
  @Input() personIdParam = 'me';
  @Input() profile: Profile;
  @Input() readOnly = false;

  constructor(private router: Router,
              private sleepService: SleepService,
              protected ref: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.latestSleep === undefined) {
      this.sleepService.loadLatestSleep(this.personIdParam).subscribe(
        r => {
          this.latestSleep = r.results.length > 0 ? r.results[0] : null;
          this.ref.markForCheck();
        },
        () => {
        }
      );
    }
  }

  getSubLabel(): string {
    let subLabel = '-';
    if (this.latestSleep) {
      subLabel = formatDateTime(this.latestSleep.date);
    }
    return subLabel;
  }

  onClick() {
    if (!this.readOnly && this.isVisible) {
      const url = ((this.personIdParam === 'me') ? '/people/' : '/medic/patients/') + this.personIdParam + '/measurements/sleep';
      this.router.navigate([url]);
    }
  }
}
