import {DiseaseCode} from './disease.model';
import {Creator} from '../creator/creator.model';

export class FamilyDisease {
  id: number;
  code: string;
  created_at: Date;
  creator: Creator;
  diseaseCode: DiseaseCode;
  diseaseCodeText: string;
  emergencyCardVisible: boolean;
  favourite: boolean;
  notes: string;
  relative: number;
  search: string;
  title: string;
  visible: boolean;

  localized: {
    relative: string;
    created_at: string;
  };

  constructor() {
    this.localized = {
      relative: '',
      created_at: ''
    };
  }
}
