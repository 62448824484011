import {Component, OnInit} from '@angular/core';
import {OnboardingService} from '../../../core/onboarding/onboarding.service';
import {Router} from '@angular/router';
import {NavigationService} from '../../../core/navigation/navigation.service';
import {ProfileService} from '../../../core/profile/profile.service';

@Component({
  selector: 'app-patient-onboarding-finished',
  templateUrl: './patient-onboarding-finished.component.html',
  styleUrls: ['./patient-onboarding-finished.component.scss']
})
export class PatientOnboardingFinishedComponent implements OnInit {
  isLoaded = false;

  constructor(
    private navigationService: NavigationService,
    private onboardingService: OnboardingService,
    private profileService: ProfileService,
    private router: Router
  ) { }

  ngOnInit() {
    this.checkOnboarding();
  }

  onClickFinishOnboarding() {
    this.onboardingService.finishOnboardingStage().subscribe(
      () => {
        this.profileService.getMyProfile().subscribe();
        this.navigationService.navigateToPatientDashboard();
      },
      () => {}
    );
  }

  private checkOnboarding() {
    this.onboardingService.getPatientOnboardingStage().subscribe(
      data => {
        const currentOnboardingUrl = this.router.routerState.snapshot;
        const onboardingUrl = '/onboarding/' + data.result.onboardingStage;
        if (currentOnboardingUrl.url !== onboardingUrl) {
          this.router.navigate([onboardingUrl]);
        } else {
          this.initOnboardingFinished();
        }
      }
    );
  }

  private initOnboardingFinished() {
    this.isLoaded = true;
  }
}
