import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MedakteIconColors} from '../icon/medakte-icon-colors';
import {MedakteIconTypes} from '../icon/medakte-icon-types';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {
  @Input() class: string;
  @Input() hasAdd = false;
  @Input() isAddActive = true;
  @Input() isVisible = true;
  @Input() title: string;
  @Input() titleLink: string;
  @Input() titleLinkDescription: string;
  @Output() addChange = new EventEmitter<boolean>();
  medakteIconColors = MedakteIconColors;
  medakteIconTypes = MedakteIconTypes;

  onClickAdd(event: any) {
    event.preventDefault();
    if (this.isAddActive) {
      this.addChange.emit(true);
    }
  }
}
