import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {LocaleResolverService} from '../../core/locale/locale-resolver.service';
import {NgbDateDynamicParserFormatter} from '../../../lib/ngb-date-dynamic-parser-formatter';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {provide: NgbDateParserFormatter, useClass: NgbDateDynamicParserFormatter}
  ]
})
export class DatePickerComponent implements OnInit {
  @Input() classNames = 'form-control';
  @Input() inputId: string;
  @Input() inputLabel: string;
  @Input() isInvalid = false;
  @Input() isReadOnly = false;
  @Input() isRequired = false;
  @Input() maxDate: NgbDateStruct;
  @Input() minDate: NgbDateStruct = {day: 1, month: 1, year: 1900};
  @Input() selectedDate: NgbDateStruct;
  @Output() selectedDateChange = new EventEmitter<NgbDateStruct>();
  dateFormat: string;
  isLoading = true;

  constructor(protected localeResolverService: LocaleResolverService,
              protected ref: ChangeDetectorRef) { }

  ngOnInit() {
    this.dateFormat = this.localeResolverService.getDateFormat();
    this.localeResolverService.localeSubject
      .subscribe(locale => {
        if (locale) {
          this.dateFormat = this.localeResolverService.getDateFormat();
          this.ref.markForCheck();
        }
      });

    if (!this.maxDate) {
      const currentDate = new Date();
      this.maxDate = {day: currentDate.getDate(), month: currentDate.getMonth() + 1, year: currentDate.getFullYear()};
    }
    this.ref.markForCheck();
    this.isLoading = false;
  }

  onDateChange(event: any) {
    // console.log(event);
    // console.log(this.selectedDate);
    this.selectedDateChange.emit(this.selectedDate);
  }
}
