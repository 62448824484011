import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DiseaseCode} from '../../../../core/health/disease.model';
import {NgbActiveModal, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {DocumentHolder} from '../../../documents/document.model';
import {concat, Observable, of, Subject} from 'rxjs';
import {DiseaseService} from '../../../../core/health/disease.service';
import {Router} from '@angular/router';
import {catchError, debounceTime, distinctUntilChanged, map, switchMap, tap} from 'rxjs/operators';
import {DiseaseCodeListResponse} from '../../../../core/response/response.model';
import {FamilyDisease} from '../../../../core/health/family-disease.model';
import {FamilyDiseaseService} from '../../../../core/health/family-disease.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LocaleResolverService} from '../../../../core/locale/locale-resolver.service';
import {BaseComponent} from '../../../../../base/base-component';
import {NgbDateDynamicParserFormatter} from '../../../../../lib/ngb-date-dynamic-parser-formatter';
import {familyDiseaseValidator} from './family-disease-validator.directive';
import {ProfileService} from '../../../../core/profile/profile.service';
import {RelativeEnum} from '../../../../shared/enums/relative.enum';
import {TranslatedToastrService} from '../../../../core/translated-toastr/translated-toastr.service';

@Component({
    selector: 'app-family-disease-edit',
    templateUrl: './family-disease-edit.component.html',
    styleUrls: ['./family-disease-edit.component.scss'],
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateDynamicParserFormatter }
    ]
})
export class FamilyDiseaseEditComponent extends BaseComponent implements OnInit {
    @Input() familyDisease: FamilyDisease;
    @Input() personIdParam: string;
    @Input() readOnly = false;
    @Output() saved = new EventEmitter<boolean>();
    diseasesCodes$: Observable<DiseaseCode[]>;
    diseasesCodesInput$ = new Subject<string>();
    diseasesCodesLoading = false;
    documents: DocumentHolder[] = [];
    end: NgbDateStruct;
    isValid = true;
    isSubmitted = false;
    familyDiseaseFormGroup: FormGroup;
    relatives = [
        {label: 'codes.relative.' + RelativeEnum.MOTHER, value: RelativeEnum.MOTHER},
        {label: 'codes.relative.' + RelativeEnum.FATHER, value: RelativeEnum.FATHER},
        {label: 'codes.relative.' + RelativeEnum.GRANDFATHER, value: RelativeEnum.GRANDFATHER},
        {label: 'codes.relative.' + RelativeEnum.GRANDMOTHER, value: RelativeEnum.GRANDMOTHER},
        {label: 'codes.relative.' + RelativeEnum.SON, value: RelativeEnum.SON},
        {label: 'codes.relative.' + RelativeEnum.DAUGHTER, value: RelativeEnum.DAUGHTER},
    ];
    selectedDiseaseCode: DiseaseCode;
    start: NgbDateStruct;

    constructor(
        private diseaseService: DiseaseService,
        private familyDiseaseService: FamilyDiseaseService,
        private fb: FormBuilder,
        private router: Router,
        private translatedToastrService: TranslatedToastrService,
        public activeModal: NgbActiveModal,
        protected profileService: ProfileService,
        protected localeResolverService: LocaleResolverService,
        protected ref: ChangeDetectorRef
    ) {
        super(profileService, localeResolverService, ref);
    }

    ngOnInit() {
        super.ngOnInit();

        // init form group
        this.familyDiseaseFormGroup = this.fb.group({
            id: [null],
            diseaseCode: [null],
            emergencyCardVisible: [null],
            favourite: [null],
            notes: [null],
            relative: [null, Validators.required],
            title: [''],
            visible: [null],
        }, {validators: familyDiseaseValidator});

        if (!this.familyDisease) {
            this.familyDisease = new FamilyDisease();
            this.ref.markForCheck();
        } else {
            this.initForm();
        }

        this.loadDiseasesCodes();
    }

    back() {
        this.router.navigate(['/people/' + this.personIdParam + '/health/family-diseases']);
    }

    close(saved: boolean) {
        this.saved.emit(saved);
        this.activeModal.close(saved);
    }

    onDiseaseCodeClick() {
        // trigger load
        this.diseasesCodesInput$.next('');
    }

    onEmergencyCardVisibleChange(emergencyCardVisible: boolean) {
        this.familyDiseaseFormGroup.controls['emergencyCardVisible'].setValue(emergencyCardVisible);
    }

    onFavouriteChange(favourite: boolean) {
        this.familyDiseaseFormGroup.controls['favourite'].setValue(favourite);
    }

    onRelativeChange() {
        this.ref.markForCheck();
    }

    onVisibleChange(visible: boolean) {
        this.familyDiseaseFormGroup.controls['visible'].setValue(visible);
    }

    save() {
        this.isSubmitted = true;

        if (this.familyDiseaseFormGroup.valid && this.isValid) {
            this.familyDisease = this.familyDiseaseFormGroup.value;
            this.familyDisease.code = this.familyDiseaseFormGroup.controls['diseaseCode'].value ? this.familyDiseaseFormGroup.controls['diseaseCode'].value.code : null;

            if (this.familyDisease.id) {
                // updating existing disease
                this.familyDiseaseService
                  .updateFamilyDisease(this.familyDisease.id, this.familyDisease)
                  .subscribe(() => {
                      this.isSubmitted = false;
                      this.saved.emit(true);

                      this.translatedToastrService.success('message.success.disease.update');
                      this.close(true);
                  },
                    () => this.translatedToastrService.error('message.error.disease.update'));

            } else {
                // create new disease
                this.familyDiseaseService
                  .createFamilyDisease(this.personIdParam, this.familyDisease)
                  .subscribe(() => {
                      this.isSubmitted = false;
                      this.saved.emit(true);
                      this.resetForm();

                      this.translatedToastrService.success('message.success.disease.update');
                      this.close(true);
                  });
            }
        }
    }

    setValid(valid) {
        this.isValid = valid;
        this.ref.markForCheck();
    }

    setReadonly(readonly) {
        this.readOnly = readonly;
        this.ref.markForCheck();
    }

    private initForm() {
        this.selectedDiseaseCode = this.familyDisease.diseaseCode ?
          {
              code: this.familyDisease.diseaseCode.code,
              text: this.familyDisease.diseaseCode.code + ': ' +
                this.familyDisease.diseaseCode.text
          } : null;

        this.familyDiseaseFormGroup.patchValue(this.familyDisease);
        this.familyDiseaseFormGroup.controls['diseaseCode'].setValue(this.familyDisease.diseaseCode ?
          {
              code: this.familyDisease.diseaseCode.code,
              text: this.familyDisease.diseaseCode.code + ': ' +
                this.familyDisease.diseaseCode.text
          } : null);

        if (this.readOnly) { this.familyDiseaseFormGroup.disable(); }
        this.ref.markForCheck();
    }

    private loadDiseasesCodes() {
        this.diseasesCodes$ = concat(
          of([]), // default items
          this.diseasesCodesInput$.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            tap(() => this.diseasesCodesLoading = true),
            switchMap(term => this.diseaseService.listDiseasesCodes(term).pipe(
              catchError(() => of([])),
              map((res: DiseaseCodeListResponse) => {
                  const diseasesCodes = res.results;
                  return diseasesCodes.map(d => {
                      return {
                          code: d.code,
                          text: d.code + ': ' + d.text
                      };
                  });
              }),
              tap(() => this.diseasesCodesLoading = false)
            ))
          )
        );
    }

    private resetForm() {
        this.familyDiseaseFormGroup.reset();
        this.familyDisease = new FamilyDisease();
        this.ref.markForCheck();
    }
}
