import {Injectable} from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpXsrfTokenExtractor
} from '@angular/common/http';

import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {NavigationService} from '../navigation/navigation.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

    private xsrfHeaderName = 'X-XSRF-TOKEN';

    constructor(
      private navigationService: NavigationService,
      private tokenService: HttpXsrfTokenExtractor,
      private router: Router
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (request.method === 'POST' || request.method === 'PUT' || request.method === 'DELETE' || request.method === 'PATCH') {
            const token = this.tokenService.getToken();
            if (token !== null && !request.headers.has(this.xsrfHeaderName)) {
                request = request.clone({headers: request.headers.set(this.xsrfHeaderName, token)});
            }
        }

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // console.log('event--->>>', event);
                }
                return event;
            }),

            catchError((error: HttpErrorResponse) => {
                console.log(error);

                if (error.status === 401 &&
                    error.error &&
                    error.error.error &&
                    error.error.error.code === 'AuthorizationError') {
                    this.router.navigate(['/not-authorized']);
                }

                if (error.status === 401 &&
                    error.error &&
                    error.error.error &&
                    error.error.error.code === 'Unauthorized') {
                    // this.router.navigate(['/signin']);
                    this.navigationService.navigateToLogin();
                }

                if (error.status === 400 &&
                    error.error &&
                    error.error.error &&
                    error.error.error.code === 'OnboardingNotCompleted') {
                    // this.router.navigate(['/onboarding/start']);
                    this.navigationService.navigateToPatientOnboardingStart();
                }

                if (error.status === 400 &&
                    error.error &&
                    error.error.error &&
                    error.error.error.code === 'NotValidSubscription') {
                    this.navigationService.navigateToPatientSettingsSubscription();
                }

                return throwError(error);
            }));
    }
}
