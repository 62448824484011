import { Component, OnInit } from '@angular/core';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-sign-in-teaser',
  templateUrl: './sign-in-teaser.component.html',
  styleUrls: ['./sign-in-teaser.component.scss']
})
export class SignInTeaserComponent implements OnInit {
  timezone: string;
  currentDate: string;

  constructor() { }

  ngOnInit() {
    this.timezone = moment.tz.guess();

    this.currentDate = moment.tz(new Date(), this.timezone).format();

  }

}
