import {Injectable} from '@angular/core';
import {BackendService} from '../backend/backend.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {GeneralResponse} from '../response/response.model';
import {LocaleResolverService} from '../locale/locale-resolver.service';

@Injectable()
export class GoogleService extends BackendService {

  constructor(
      protected http: HttpClient,
    protected localeResolverService: LocaleResolverService,
  ) {
    super(http, localeResolverService);
  }

  getGoogleAuthUrl() {
    const httpHeaders = this.createHttpHeaders();
    return this.http.get<GeneralResponse>(this.getApiUrl() + '/googleAuthUrl', { headers: httpHeaders });
  }

  getGoogleCallback(code: string) {
    const httpHeaders = this.createHttpHeaders();
    let params = new HttpParams();
    params = params.append('code', code);
    return this.http.get<GeneralResponse>(this.getApiUrl() + '/googleCallback', { headers: httpHeaders, params: params });
  }


}
