import {BackendService} from '../backend/backend.service';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {LanguageListResponse} from '../response/response.model';
import {LocaleResolverService} from '../locale/locale-resolver.service';

@Injectable()
export class LanguageService extends BackendService {

    constructor(
        protected http: HttpClient,

        protected localeResolverService: LocaleResolverService
    ) {
        super(http, localeResolverService);
    }

    listLanguages(q: string) {
        const httpHeaders = this.createHttpHeaders();

        let params = new HttpParams();
        if (q !== undefined && q !== null) {
            params = params.append('q', q.toString());
        }

        return this.http.get<LanguageListResponse>(this.getApiUrl() + '/languages', (params.keys().length > 0) ? {
            headers: httpHeaders,
            params: params
        } : {headers: httpHeaders});
    }

    getMyLanguages() {
        const httpHeaders = this.createHttpHeaders();
        return this.http.get<LanguageListResponse>(this.getApiUrl() + '/medics/me/languages', {headers: httpHeaders});
    }

    saveMyLanguages(body) {
        const httpHeaders = this.createHttpHeaders();
        return this.http.put(this.getApiUrl() + '/medics/me/languages', body, {headers: httpHeaders});
    }
}
