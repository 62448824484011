import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ResponsiveService} from '../../../core/responsive/responsive.service';

@Component({
  selector: 'app-onboarding-toolbar',
  templateUrl: './onboarding-toolbar.component.html',
  styleUrls: ['./onboarding-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingToolbarComponent implements OnInit {
  constructor(
    private ref: ChangeDetectorRef,
    public responsiveService: ResponsiveService,
  ) { }

  ngOnInit() {
    this.responsiveService.responsiveSizeChange.subscribe(() => this.ref.markForCheck());
  }
}
