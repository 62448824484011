import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {AuthenticationService} from '../../../core/authentication/authentication.service';

@Component({
  selector: 'app-admin-side-navigation',
  templateUrl: './admin-side-navigation.component.html',
  styleUrls: ['./admin-side-navigation.component.scss']
})
export class AdminSideNavigationComponent implements OnDestroy, OnInit {
  isAdmin = false;
  private subscriptions: Subscription[] = [];

  constructor(
    private authenticationService: AuthenticationService,
  ) { }

  ngOnInit() {
    this.subscriptions.push(
      this.authenticationService.isAdminSupport.subscribe(isAdmin => {
        this.isAdmin = isAdmin;
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
