import {Injectable} from '@angular/core';
import {BackendService} from '../../backend/backend.service';
import {HttpClient} from '@angular/common/http';
import {LocaleResolverService} from '../../locale/locale-resolver.service';
import {SpecialtyCodeListResponse} from '../../response/response.model';

@Injectable()
export class MedicSpecialtyService extends BackendService {
  constructor(
    protected http: HttpClient,
    protected localeResolverService: LocaleResolverService,
  ) {
    super(http, localeResolverService);
  }

  listSpecialtyCodes() {
    const httpHeaders = this.createHttpHeaders();

    return this.http.get<SpecialtyCodeListResponse>(
      this.getApiUrl() + '/specialtiesCodes', { headers: httpHeaders });
  }
}
