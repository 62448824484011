import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ProfileService} from '../../../core/profile/profile.service';
import {PersonTypeEnum} from '../../enums/person-type.enum';
import {AuthenticationService} from '../../../core/authentication/authentication.service';
import {NavigationService} from '../../../core/navigation/navigation.service';
import {TranslatedToastrService} from '../../../core/translated-toastr/translated-toastr.service';

@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
    isTokenRequired: boolean;
    mfaData = {
        destination: '',
        callback: null
    };
    mfaStep = false;
    userFormGroup: FormGroup;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private navigationService: NavigationService,
        private profileService: ProfileService,
        private translatedToastsService: TranslatedToastrService,
        private formBuilder: FormBuilder,
    ) {
        this.userFormGroup = this.formBuilder.group({
            email: '',
            keepMeLoggedIn: null,
            password: '',
            token: '',
        });
    }

    ngOnInit() {
        if (this.authenticationService.userName && this.authenticationService.password) {
            // TODO automatic signin
            // this.authenticationService.authenticate(this.authService.userName, this.authService.password, this);
        }
    }

    onSignIn({email, password, token, keepMeLoggedIn}: { email: string, password: string, token: string, keepMeLoggedIn: boolean }) {
        this.authenticationService.signIn(email, password, token, keepMeLoggedIn).subscribe(
          () => this.onSignInSuccess(),
          err => this.onSignInError(err)
        );
    }

    private onSignInError(err: any) {
        this.authenticationService.setLoggedOut();

        const errCode = err.error.error.code;
        this.translatedToastsService.error('authentication.errors.' + errCode);

        switch (errCode) {
            case 'UserAccountNotConfirmed':
                this.router.navigate(['/confirm-registration/', this.userFormGroup.value.email]);
                // this.navigationService.navigateToRegistrationConfirmation(this.userFormGroup.get('email').value);
                break;

            case 'TotpTokenMissingError':
                this.userFormGroup.get('token').setValidators(Validators.required);
                this.isTokenRequired = true;
                break;
        }
    }

    private onSignInSuccess() {
        this.authenticationService.setLoggedIn();
        this.authenticationService.clearCredentials();
        this.profileService.getMyProfile().subscribe(() => {
            const person = this.profileService.myProfile;
            if (person) {
                if (person.type === PersonTypeEnum.PATIENT) {
                    this.navigationService.navigateToPatientDashboard();
                }

                if (person.type === PersonTypeEnum.DOCTOR || person.type === PersonTypeEnum.MEDICAL_INSTITUTION) {
                    this.authenticationService.setMedic();
                    this.navigationService.navigateToMedicDashboard();
                }
            } else {
                this.navigationService.navigateToPatientOnboardingStart();
            }
        });
    }
}
