import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ChatService} from './chat.service';

import * as $ from 'jquery';
import {Conversation} from './conversation.model';
import {objKeys} from '../lib/utils';
import {concat, Observable, of, Subject} from 'rxjs';
import {Person, RecipientPerson} from '../+modules/core/person/person.model';
import {catchError, debounceTime, distinctUntilChanged, map, switchMap, tap} from 'rxjs/operators';
import {PersonService} from '../+modules/core/person/person.service';
import {ProfileService} from '../+modules/core/profile/profile.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {MessageRecipientListResponse} from '../+modules/core/response/response.model';
import {DocumentService} from '../+modules/patient/documents/document.service';
import {ConversationMessage} from './conversation-message.model';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ChatService]
})
export class ChatComponent implements AfterViewInit, OnInit {

  conversationsMap: any = {};
  chatMessages: ConversationMessage[];

  activeChatUser: string;
  activeChatUserImg: string;
  activeRecipient: number;
  activeConversationId: number;
  activeConversationType: number;

  recipients$: Observable<RecipientPerson[]>;
  recipientsLoading = false;
  recipientsInput$ = new Subject<string>();
  selectedPerson: RecipientPerson;

  @ViewChild('messageInput', { static: true }) messageInputRef: ElementRef;

  objKeys = objKeys;

  newConvId = Number.MAX_SAFE_INTEGER;

  me: Person;

  isMed: boolean;
  isPat: boolean;

  backgroundColourClass: string;

  constructor(
    private elRef: ElementRef,
    private ref: ChangeDetectorRef,
    private chatService: ChatService,
    private peopleService: PersonService,
    private profileService: ProfileService,
    private spinner: NgxSpinnerService,
    private documentService: DocumentService
  ) {
  }

  ngOnInit() {
    this.chatService.messages.subscribe(msg => {

      console.log('Message received: ');
      console.log(msg);

      if (msg.senderId) {

        for (const convId of this.objKeys(this.conversationsMap)) {
          console.log(this.conversationsMap[convId].recipient === msg.senderId);
          if (this.conversationsMap[convId].recipient === msg.senderId) {

            if (!this.conversationsMap[convId].loaded) {
              this.conversationsMap[convId].unseen++;
            } else {

              console.log(this.activeConversationId !== parseInt(convId, 10));
              console.log(this.activeConversationId);
              console.log(parseInt(convId, 10));

              if (this.activeConversationId !== parseInt(convId, 10)) {
                this.conversationsMap[convId].unseen++;
              }

              this.conversationsMap[convId].chatMessages.push(msg);
            }
            this.ref.markForCheck();
            break;
          }
        }
      }
    });

    this.loadChatConversations();

    this.loadRecipients();

    this.profileService.myProfileSubject
      .subscribe(p => {
        this.me = p;
        if (this.me) {
          if (this.me.type === 0) { this.isPat = true; }
          if (this.me.type === 1 || this.me.type === 2) { this.isMed = true; }

          this.backgroundColourClass = this.isMed ? 'bgc-med' : 'bgc-pat';
        }
      });

  }

  private loadChatConversations(callback = null) {
    this.chatService.loadChatConversations().subscribe(resp => {
      console.log('Successfully loaded conversations: ' + JSON.stringify(resp.result));
      const convs = resp.result;

      this.conversationsMap = {};

      for (const conv of convs) {
        this.initConversation(conv);
      }

      this.chatService.loadChatConversationsOverview().subscribe(res => {
        console.log('Successfully loaded conversations overview: ' + JSON.stringify(res.result));
        const conversations = res.result;

        for (const conversation of conversations) {
          this.conversationsMap[conversation.id].unseen = conversation.unseen;
        }

        this.ref.markForCheck(); // trigger refresh

        if (callback) { callback(); }

      }, e => console.log(e));
    }, e => console.log(e));
  }

  private initConversation(conv) {
    this.conversationsMap[conv.id] = conv;
    this.conversationsMap[conv.id].avatar = this.documentService.resolveStaticFileUrl(conv.photo);
    this.conversationsMap[conv.id].unseen = 0;
    this.conversationsMap[conv.id].loaded = false;
    this.conversationsMap[conv.id].chatMessages = [];
  }

  ngAfterViewInit() {
    $.getScript('./assets/js/chat.js');
  }

  onAddMessage() {

    if (!this.activeChatUser) { return; }

    if (this.messageInputRef.nativeElement.value !== '') {

      // send message by websocket
      const message = this.chatService.newChatMessage(this.activeRecipient, this.messageInputRef.nativeElement.value);
      this.chatService.sendMessage(message);
      this.conversationsMap[this.activeConversationId].chatMessages.push(message);


      const main = this;

      // create message by service
      this.chatService.createChatMessage(this.activeRecipient, message)
        .subscribe(resp => {
          console.log('Message created ... ');

          const that = this;
          if (this.activeConversationType === -1) {
            this.loadChatConversations(() => {
              // that.setActiveConversationByRecipient(that.activeRecipient)

              main.spinner.show();

              setTimeout(() => {
                main.setActiveConversationByRecipient(that.activeRecipient);
                main.ref.markForCheck();
                main.spinner.hide();
              }, 1000);

            });
          }

          // setTimeout(() => {this.setActiveConversationByRecipient(that.activeRecipient)}, 1000);
        }, e => console.log(e));
    }

    this.messageInputRef.nativeElement.value = '';
    this.messageInputRef.nativeElement.focus();
  }



  SetActive(event, convId: number) {
    const hElement: HTMLElement = this.elRef.nativeElement;
    const allAnchors = hElement.getElementsByClassName('list-group-item');
    [].forEach.call(allAnchors, function (item: HTMLElement) {
      item.setAttribute('class', 'list-group-item no-border');
    });

    let activeClass = 'list-group-item bg-blue-grey bg-lighten-5 border-right-2';
    activeClass = this.isMed ? activeClass + ' border-right-med' : activeClass + ' border-right-pat';

    event.currentTarget.setAttribute('class', activeClass);

    this.activeChatUser = this.conversationsMap[convId].recipients;
    this.activeChatUserImg = this.conversationsMap[convId].avatar;
    this.activeRecipient = this.conversationsMap[convId].recipient;
    this.conversationsMap[convId].unseen = 0;
    this.activeConversationId = convId;
    this.activeConversationType = this.conversationsMap[convId].type;

    if (this.conversationsMap[convId].type !== -1) {

      this.loadChatMessages(convId);

    } else {
      this.chatMessages = this.conversationsMap[convId].chatMessages;
      this.ref.markForCheck();
    }
  }

  private loadChatMessages(convId) {

      this.chatService
        .markConversationAsSeen(this.activeConversationId)
        .subscribe(r => console.log('Success mark as seen'), e => console.log('Error mark as seen'));

    if (!this.conversationsMap[convId].loaded) {

      // For the first time messages are loaded from db
      this.chatService.loadChatMessages(convId).subscribe(msgs => {

        this.conversationsMap[convId].chatMessages = msgs.result.reverse(); // messages sent ordered DESC
        this.chatMessages = this.conversationsMap[convId].chatMessages;

        this.conversationsMap[convId].loaded = true; // mark it as already loaded
        this.ref.markForCheck(); // trigger angular to refresh ui
      });
    } else {
      this.chatMessages = this.conversationsMap[convId].chatMessages; // load just from cache
      this.ref.markForCheck();
    }
  }

  isMe(senderId) {
    return this.activeRecipient !== senderId;
  }

  /**
   * Compares message at requested index with previous and return
   * true if previous message has different sender
   *
   * @param currentIndex: number
   */
  senderChanged(index: number) {
    if (index === 0) { return true; }
    return this.chatMessages[index].senderId !== this.chatMessages[index - 1].senderId;
  }

  private loadRecipients() {
    this.recipients$ = concat(
      of([]), // default items
      this.recipientsInput$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => this.recipientsLoading = true),
        switchMap(term => this.peopleService.listMessageRecipients(term).pipe(
          catchError(() => of([])),
          map((res: MessageRecipientListResponse) => {
            console.log(res);
            console.log(res.result);
            return res.result;
          }),
          tap(() => this.recipientsLoading = false)
        ))
      )
    );
  }

  onAdd($event) {
    console.log($event);

    if (this.selectedPerson) {
      console.log('Creating conversations ... ');

      const recipient = this.selectedPerson.id;

      if (this.setActiveConversationByRecipient(recipient)) { return; }

      const conv = new Conversation();
      conv.id = this.newConvId--;
      conv.recipient = recipient;
      conv.recipients = this.selectedPerson.name;
      conv.type = -1;
      this.initConversation(conv);

      if (this.selectedPerson) { this.selectedPerson = null; }
    }
  }

  setActiveConversationByRecipient(recipientId) {
    for (const convId of this.objKeys(this.conversationsMap)) {
      if (this.conversationsMap[convId].recipient === recipientId) {
        const elem = document.getElementById('conv-' + convId) as HTMLElement;
        if (this.selectedPerson) { this.selectedPerson = null; }
        if (elem) { elem.click(); }
        return true;
      }
    }
    return false;
  }
}
