import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NavigationService} from '../../core/navigation/navigation.service';
import {TranslatedToastrService} from '../../core/translated-toastr/translated-toastr.service';
import {AuthenticationService, AuthError} from '../../core/authentication/authentication.service';

@Component({
  selector: 'pat-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  isCurrentPasswordVisible = false;
  isLoaded = false;
  isNewPasswordVisible = false;
  isNewPasswordConfirmVisible = false;
  isSubmitted = false;
  userFormGroup: FormGroup;

  constructor(
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private navigationService: NavigationService,
    private translatedToastrService: TranslatedToastrService,
  ) { }

  ngOnInit() {
    this.initFormGroup();
    this.isLoaded = true;
  }

  done(error: AuthError, result: any): void {
    // TODO change!
    if (error) {
      this.translatedToastrService.error(error.message);
      return;
    } else {
      this.translatedToastrService.success('authentication.passwordChanged');
      this.navigationService.navigateToLogin();
    }
  }

  onClickConfirm() {
    this.isSubmitted = true;
    if (this.checkUserFormGroupIsValid() && this.userFormGroup.valid) {
      const currentPassword = this.userFormGroup.get('currentPassword').value;
      const newPassword = this.userFormGroup.get('newPassword').value;
      this.authenticationService.changePassword(currentPassword, newPassword, this);
    }
  }

  private checkUserFormGroupIsValid(): boolean {
    const currentPassword = this.userFormGroup.get('currentPassword').value;
    const newPassword = this.userFormGroup.get('newPassword').value;
    const newPasswordConfirm = this.userFormGroup.get('newPasswordConfirm').value;

    if (!currentPassword || !newPassword || !newPasswordConfirm) {
      this.translatedToastrService.error('authentication.errors.EmailPasswordMandatoryError');
      return false;
    }

    if (newPassword !== newPasswordConfirm) {
      this.translatedToastrService.error('authentication.errors.PasswordsNotMatched');
      return false;
    }

    return true;
  }

  private initFormGroup() {
    this.userFormGroup = this.formBuilder.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      newPasswordConfirm: ['', Validators.required],
    });
  }
}
