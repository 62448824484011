import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {MatMenuModule} from '@angular/material/menu';
import {MatToolbarModule} from '@angular/material/toolbar';

import {NgbDatepickerModule, NgbModalModule, NgbModule, NgbTimepickerModule} from '@ng-bootstrap/ng-bootstrap';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {TranslateModule} from '@ngx-translate/core';
import {CalendarModule} from 'angular-calendar';
import {QRCodeModule} from 'angularx-qrcode';
import {ChartsModule} from 'ng2-charts';
import {ImageViewerModule} from 'ng2-image-viewer';
import {RECAPTCHA_LANGUAGE, RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings} from 'ng-recaptcha';
import {NgxFilesizeModule} from 'ngx-filesize';
import {ImageCropperModule} from 'ngx-image-cropper';
import {NgxSelectModule} from 'ngx-select-ex';
import {NgxSpinnerModule} from 'ngx-spinner';

import {FooterComponent} from './footer/footer.component';
import {NotAuthorizedComponent} from './pages/not-authorized/not-authorized.component';
import {AttrsComponent} from './auth/attrs/attrs.component';
import {LanguageFlagsComponent} from './language/language-flags/language-flags.component';
import {CardComponent} from './card/card.component';
import {LabelValueComponent} from './label-value/label-value.component';
import {CheckboxComponent} from './checkbox/checkbox.component';
import {IsRequiredComponent} from './is-required/is-required.component';
import {SignOutComponent} from './auth/sign-out/sign-out.component';
import {SignInComponent} from './auth/sign-in/sign-in.component';
import {SignInTeaserComponent} from './auth/sign-in-teaser/sign-in-teaser.component';
import {SignUpComponent} from './auth/sign-up/sign-up.component';
import {ConfirmRegComponent} from './auth/confirm-reg/confirm-reg.component';
import {MultiFactorAuthenticationComponent} from './multi-factor-authentications/multi-factor-authentication/multi-factor-authentication.component';
import {MultiFactorAuthenticationCodeComponent} from './multi-factor-authentications/multi-factor-authentication-code/multi-factor-authentication-code.component';
import {LocationComponent} from './location/location.component';
import {ForgotPasswordEmailComponent, ForgotPasswordNewPasswordComponent} from './auth/forgot-password/forgot-password.component';
import {ChangePasswordComponent} from './auth/change-password/change-password.component';
import {AddressEditComponent} from './address/address-edit/address-edit.component';
import {AddressComponent} from './address/address.component';
import {LanguageComponent} from './language/language.component';
import {SafeUrlPipe} from './pipes/safe-url.pipe';
import {DatePickerComponent} from './date-picker/date-picker.component';
import {DeleteModalComponent} from './delete-modal/delete-modal.component';
import {MedakteIconComponent} from './icon/medakte-icon.component';
import {DateFromToFilterComponent} from './filters/date-from-to-filter/date-from-to-filter.component';
import {PrivacyFilterComponent} from './filters/privacy-filter/privacy-filter.component';
import {SearchFilterComponent} from './filters/search-filter/search-filter.component';
import {ClearInputComponent} from './clear-input/clear-input.component';
import {StripeCardComponent} from './payment/stripe/stripe-card/stripe-card.component';
import {SpecialtyFilterComponent} from './filters/specialty-filter/specialty-filter.component';
import {LocaleResolverService} from '../core/locale/locale-resolver.service';
import {AccountDeletionComponent} from './account-deletion/account-deletion.component';
import {PhotoEditComponent} from './photo/photo-edit.component';
import {FullLayoutComponent} from './layouts/full/full-layout.component';
import {ContentLayoutComponent} from './layouts/content/content-layout.component';
import {UsageComponent} from './usage/usage.component';
import {UsageAlertComponent} from './usage/usage-alert/usage-alert.component';
import {FormatDatePipe} from './pipes/format-date.pipe';
import {ActivityLogsComponent} from './activity-logs/activity-logs.component';
import {PaypalAgreementComponent} from './payment/paypal/paypal-agreement/paypal-agreement.component';
import {InvoiceComponent} from './invoice/invoice.component';
import {ToolbarComponent} from './toolbar/toolbar.component';
import {UserSettingsMenuComponent} from './menus/user-settings-menu/user-settings-menu.component';
import {UserLanguageMenuComponent} from './menus/user-language-menu/user-language-menu.component';
import {SideNavigationComponent} from './side-navigation/side-navigation.component';
import {PatientToolbarComponent} from './toolbar/patient-toolbar/patient-toolbar.component';
import {MedicToolbarComponent} from './toolbar/medic-toolbar/medic-toolbar.component';
import {DefaultToolbarComponent} from './toolbar/default-toolbar/default-toolbar.component';
import {MedicSideNavigationComponent} from './side-navigation/medic-side-navigation/medic-side-navigation.component';
import {PatientSideNavigationComponent} from './side-navigation/patient-side-navigation/patient-side-navigation.component';
import {DefaultSideNavigationComponent} from './side-navigation/default-side-navigation/default-side-navigation.component';
import {UserLanguageNavigationComponent} from './side-navigation/user-language-side-navigation/user-language-navigation.component';
import {AdminSideNavigationComponent} from './side-navigation/admin-side-navigation/admin-side-navigation.component';
import {LogoutSideNavigationComponent} from './side-navigation/logout-side-navigation/logout-side-navigation.component';
import { OnboardingToolbarComponent } from './toolbar/onboarding-toolbar/onboarding-toolbar.component';
import { OnboardingSideNavigationComponent } from './side-navigation/onboarding-side-navigation/onboarding-side-navigation.component';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
  imports: [
    // Angular Modules
    CommonModule,
    FormsModule,
    HttpClientModule,
    MatMenuModule,
    MatToolbarModule,
    MatTooltipModule,
    ReactiveFormsModule,
    RouterModule,
    // 3rd Party Modules
    CalendarModule,
    ChartsModule,
    ImageCropperModule,
    ImageViewerModule,
    NgbModule,
    NgbModalModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgSelectModule,
    NgxDatatableModule,
    NgxFilesizeModule,
    NgxSelectModule,
    NgxSpinnerModule,
    QRCodeModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    TranslateModule.forChild()
  ],
  declarations: [
    // Components
    AccountDeletionComponent,
    ActivityLogsComponent,
    AddressComponent,
    AddressEditComponent,
    AdminSideNavigationComponent,
    AttrsComponent,
    CardComponent,
    ChangePasswordComponent,
    CheckboxComponent,
    ClearInputComponent,
    ConfirmRegComponent,
    ContentLayoutComponent,
    DateFromToFilterComponent,
    DatePickerComponent,
    DefaultSideNavigationComponent,
    DefaultToolbarComponent,
    DeleteModalComponent,
    FooterComponent,
    ForgotPasswordEmailComponent,
    ForgotPasswordNewPasswordComponent,
    FullLayoutComponent,
    InvoiceComponent,
    IsRequiredComponent,
    LabelValueComponent,
    LanguageComponent,
    LanguageFlagsComponent,
    LocationComponent,
    LogoutSideNavigationComponent,
    MedakteIconComponent,
    MedicSideNavigationComponent,
    MedicToolbarComponent,
    MultiFactorAuthenticationCodeComponent,
    MultiFactorAuthenticationComponent,
    NotAuthorizedComponent,
    PatientSideNavigationComponent,
    PatientToolbarComponent,
    PaypalAgreementComponent,
    PhotoEditComponent,
    PrivacyFilterComponent,
    SearchFilterComponent,
    SignUpComponent,
    SignInComponent,
    SignInTeaserComponent,
    SignOutComponent,
    SpecialtyFilterComponent,
    StripeCardComponent,
    ToolbarComponent,
    UsageAlertComponent,
    UsageComponent,
    UserLanguageMenuComponent,
    UserLanguageNavigationComponent,
    SideNavigationComponent,
    UserSettingsMenuComponent,
    // Pipes
    SafeUrlPipe,
    FormatDatePipe,
    OnboardingToolbarComponent,
    OnboardingSideNavigationComponent,
  ],
  entryComponents: [
    DeleteModalComponent,
  ],
  exports: [
    // Angular Modules
    CommonModule,
    FormsModule,
    MatMenuModule,
    MatToolbarModule,
    MatTooltipModule,
    ReactiveFormsModule,
    // 3rd Party Modules
    CalendarModule,
    ChartsModule,
    ImageCropperModule,
    ImageViewerModule,
    NgbModule,
    NgbModalModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgSelectModule,
    NgxDatatableModule,
    NgxFilesizeModule,
    NgxSelectModule,
    NgxSpinnerModule,
    QRCodeModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    // Components
    AccountDeletionComponent,
    ActivityLogsComponent,
    AddressComponent,
    AddressEditComponent,
    AdminSideNavigationComponent,
    AttrsComponent,
    CardComponent,
    ChangePasswordComponent,
    CheckboxComponent,
    ClearInputComponent,
    ConfirmRegComponent,
    ContentLayoutComponent,
    DateFromToFilterComponent,
    DatePickerComponent,
    DefaultSideNavigationComponent,
    DefaultToolbarComponent,
    DeleteModalComponent,
    FooterComponent,
    ForgotPasswordEmailComponent,
    ForgotPasswordNewPasswordComponent,
    FullLayoutComponent,
    InvoiceComponent,
    IsRequiredComponent,
    LabelValueComponent,
    LanguageComponent,
    LanguageFlagsComponent,
    LocationComponent,
    LogoutSideNavigationComponent,
    MedakteIconComponent,
    MedicSideNavigationComponent,
    MedicToolbarComponent,
    MultiFactorAuthenticationCodeComponent,
    MultiFactorAuthenticationComponent,
    NotAuthorizedComponent,
    PatientSideNavigationComponent,
    PatientToolbarComponent,
    PaypalAgreementComponent,
    PhotoEditComponent,
    PrivacyFilterComponent,
    SearchFilterComponent,
    SignUpComponent,
    SignInComponent,
    SignInTeaserComponent,
    SignOutComponent,
    SpecialtyFilterComponent,
    StripeCardComponent,
    ToolbarComponent,
    UsageAlertComponent,
    UsageComponent,
    UserLanguageMenuComponent,
    UserLanguageNavigationComponent,
    SideNavigationComponent,
    UserSettingsMenuComponent,
    // Pipes
    SafeUrlPipe,
    FormatDatePipe,
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: '6Lc-vfEUAAAAAEpr8j7zXA2jdsHuwX4rujWjIgFB' } as RecaptchaSettings,
    },
    {
        provide: RECAPTCHA_LANGUAGE,
        useFactory: (localResolverService: LocaleResolverService) => {
            return localResolverService.getLanguage();
        },
        deps: [LocaleResolverService]
    }
  ]
})
// The SHARED module provides all shared Modules, Components, Directives and Pure Pipes between multiple modules of the APP.
export class SharedModule {
}
