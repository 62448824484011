import {Creator} from '../creator/creator.model';

export class Immunisation {
  id: number;
  batchNumber: string;
  created_at: Date;
  creator: Creator;
  date: Date;
  doctor: string;
  emergencyCardVisible: boolean;
  favourite: boolean;
  notes: string;
  taken: number;
  title: string;
  vaccine: number;
  visible: boolean;

  localized: {
    taken: string;
    created_at: string;
    date: string;
  };

  constructor() {
    this.localized = {
      taken: '',
      created_at: '',
      date: ''
    };
  }
}
